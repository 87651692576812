import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-wishlist',
  templateUrl: './customer-wishlist.component.html',
  styleUrls: ['./customer-wishlist.component.scss']
})
export class CustomerWishlistComponent implements OnInit {
  _id: any
  headingName:any ='WISHLIST'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Wishlist',route:'/wishlist-customer'}
  ]
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }

  ngOnInit(): void {
    this.getWishListProducts();
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  currentPage: any = 1
  limit: any = 15
  totalItems: number

  // get retailer detail by id
  getWishListProducts() {
    let apiReqUrl: any = `user/wishList?page=${this.currentPage}&limit=${this.limit}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        

        this.productDetails = res.result.docs;
        this.totalItems = res.result.total
        // this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.productDetails = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.productDetails = []
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getWishListProducts();
  }



  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        
        this.commonService.hideSpinner();
        this.getWishListProducts()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  navigateToViewProduct(_id) {
    this.router.navigate(['/product-view-customer'], { queryParams: { _id: _id } })
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
}
