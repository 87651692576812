import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-service-provider-service',
  templateUrl: './service-provider-service.component.html',
  styleUrls: ['./service-provider-service.component.scss']
})
export class ServiceProviderServiceComponent implements OnInit {
  _id: any
  currentPage = 1;
  pageSize = 15;
  serviceName: any
   headingName:any ='BOOKINGS'
  linkerRouteItems:any =[
    { name:'Home',route: '/service-order-detail'},
    { name:'Bookings',route: '/services'},
  ]
  transport: any;
  serviceArray: any;
  field: any;
  standard: any;
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }

  flag = false
  ngOnInit(): void {
    if(this.getEndUrlSegment() == 'services'){
     
      this.myService()
      this.flag= false
      this.headingName ='Please configure the services'
    }
    if(this.getEndUrlSegment() == 'services-provider'){
      this.flag = true
      this.getServiceList();
      
    }
   
  }
  serviceList : any

  showService = false
 
  myService(){
    let url = `user/myServiceList`
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        let result = res.result.services
        this.transport = result.find((ele)=>{return ele.category.categoryType == 'PICKUP_PARTNER'})
       
        this.field = result.find((ele)=>{return ele.category.categoryType == 'FIELD_ENTITY'})
        
        this.standard = result.find((ele)=>{return ele.category.categoryType == 'SERVICE_PROVIDER'})
       
        this.serviceList = res.result.services
        if (!res.result.isConfig) {
          if (res.result.services.length > 1) {
            this.showService  =true


          }
          else if (res.result.services.length == 1) {
            if (this.transport || this.field) {
              this.router.navigate(['service-provider-list-deals'], { queryParams: { _id: res.result.services[0].category._id, name: res.result.services[0].category.categoryName } })

            }
            if (this.standard) {
              this.router.navigate(['services-provider'])

            }

          }
          this.serviceList = res.result.services
        }
      
   
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
       
      }

    }, (err: any) => {
     
      if (err.responseCode == 409) {
        
      }
    })
  }

  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }




  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  totalItems: any
  isLoading: boolean = false
  // get retailer detail by id
  getServiceList() {
    let apiReqUrl: any = `service/listCategoryService`
    let apiReqData = {
      search: this.serviceName,
      page: this.currentPage,
      limit: this.pageSize,
    }
    this.commonService.removeEmptyKey(apiReqData)
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    if(!this.serviceName){

      this.commonService.showSpinner();
    }
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, apiReqData, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        

        this.productDetails = res.result.docs;
        if (!this.productDetails.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        // this.imgurl = res.result.productImage[0]
        this.totalItems = res.result.total
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading = true;
        this.productDetails = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.isLoading = true;
      this.productDetails = []
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  navigateToViewProduct(_id, catName) {
    this.router.navigate(['/service-provider-list-deals'], { queryParams: { _id: _id, catName: catName } })
  }
  pagination(event) {
    this.currentPage = event;
    this.getServiceList();
  }
}
