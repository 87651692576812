<body class="bg-light">
    <div class="container-fluid p-3" style="height: 523px;">
        <span (click)="backToView()" class="circle-arrow">
            <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">
        </span>
        <div class="row justify-content-center p-4 mt-5">
            <div class="col-md-5" style="margin-top: 60px;">
                <div class="innerDiv">
                    <h3 class="head mb-2 p-1">OTP Verification</h3>
                    <p style="color: gray;">Please enter the 4-digit verification code that was sent to customer email.
                        The
                        code is valid for 3 minute.</p>
                    <form [formGroup]="otpForm">
                        <div class="otp">
                            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
                            <div class="mt-2 d-flex " style="justify-content: space-evenly; margin: auto;">
                                <p class="mt-10">{{remainingTime}}</p>
                                <a class="res-otp" (click)="resendOtp()"  *ngIf="isExpire">Resend OTP</a>
                                <a class="res-otp"  *ngIf="!isExpire">Resend OTP</a>
                            </div>
                        </div>

                        <div class="vca mt-3">
                            <button class="o-f" (click)="verifyOTP()">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</body>





<!-- <div class="content">
        <div class="o-c">
          <div>
            <div class="card">
              <p class="a-o-i">Enter verification code</p>
              <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
              <span  class="o-t-p">
                <ng-container *ngIf="otp">
                  Entered otp :-{{otp}}
                </ng-container>
                </span>
            </div>
          </div>
        </div>
      </div> -->