import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-otp-verification-reset-password-customer',
  templateUrl: './otp-verification-reset-password-customer.component.html',
  styleUrls: ['./otp-verification-reset-password-customer.component.scss']
})
export class OtpVerificationResetPasswordCustomerComponent implements OnInit {
  // otp: any;
  otpForm!: FormGroup
  otp: any;
  myEmail: any;
  countDowon: any = 2.59
  isLoading =false

  userType = 'CUSTOMER'

  contDownTimer() {
    var a = window.setInterval(() => {
      if (this.countDowon - (parseInt(this.countDowon)) > 0.60) {

        this.countDowon = this.countDowon - 0.40
      }
      if (this.countDowon > 0) {
        this.countDowon = this.countDowon - 0.01
        this.getTime((this.countDowon).toFixed(2))
      }
      else {
        this.resend_otp_active = true
        clearInterval(a)
      }
    }, 1000)
  }

  resend_otp_active: boolean = false
  time!: string
  getTime(e: any) {
    this.time = String(e).replace('.', ':')
    this.time = String(e).replace('-', '')
  }
  constructor(private http: HttpClient, public commonService: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.myEmail = res.email
    })
  }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", [
        Validators.required,

      ]),
    });
    let a  = Number(localStorage.getItem('remainingTime'))
    this.timer(a || 180);

    if(localStorage.getItem('userType') == 'RETAILER'){
      this.userType = 'RETAILER'
    }
  }
  timerOn = true;


  onOtpChange(otp: any) {
    this.otp = otp;
  }

  navigateToResetPassword() {
    if (!this.otp) {
      return this.commonService.errorToast('Please enter otp')
    }
    let userData = {
      email: this.myEmail,
      otp: this.otp,
      userType: this.userType,
    }
    this.isLoading = true

    this.commonService.postApi('user/verifyOTP', userData, 0).subscribe((res: any) => {

      // if(res['']) 
      if (res['responseCode'] == 200) {
        this.isLoading = false
        this.commonService.successToast(res['responseMessage'])
        this.router.navigate(['/reset-customer'])

        // if (!this.isActivate) {
        //   this.router.navigate(['/homepage'])
        // }
        // if (this.currentUrl === '/auth/signUp') {
        //   this.router.navigate(['/auth/login'])
        // } else {
        //   localStorage.setItem('verifyOtpToken', res['result']['token'])
        //   this.router.navigate(['/auth/reset-password'])
        // }
      }

    }, err => {
      this.isLoading = false
      if (err['responseCode'] == 400) {
        this.commonService.warningToast(err['responseMessage'])
      } else {
        this.commonService.warningToast(err['responseMessage'])
      }

    })

  }


  // resend otp 
  resendOtp() {
    if (!this.isExpire) {
      return
    }
    // this.commonService.showSpinner()
    this.myEmail = localStorage.getItem('email');
    var email = {
      'email': this.myEmail,
      userType: this.userType,
    }
    this.commonService.postApi('user/resendOTP', email, 0).subscribe(res => {
      if (res['responseCode'] == 200) {
        // this.commonService.hideSpinner()
        this.timer(180);
        this.commonService.successToast(res['responseMessage'])
      }

    }, err => {
      // this.commonService.hideSpinner();
      this.commonService.errorToast(err['responseMessage'])

    })
  }

  remainingTime: any
  isExpire: boolean = false
  timer(remaining) {
    let m: any = Math.floor(remaining / 60);
    let s: any = remaining % 60;

    m = m < 10 ? '' + m : m;
    s = s < 10 ? '0' + s : s;
    this.remainingTime = m + ':' + s

    // document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;
    let me = this
    localStorage.setItem('remainingTime',remaining)
    if (remaining >= 0 && this.timerOn) {
      setTimeout(function () {
        me.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    localStorage.removeItem('remainingTime')
    this.isExpire = true
  }
}
