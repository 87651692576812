
<div class="container  fw-b " *ngIf="!showService">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<div class="container mb-5 wrapper-content" *ngIf="flag">

    <div class="row justify-content-center">
      <div class="form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="serviceName"
          placeholder="Search by service name"
          (input)="getServiceList();currentPage=1"
        />
      </div>
    </div>
    <div class="row  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
      <div
        class="col newCardWidth mb-3"
        *ngFor="
          let item of productDetails
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: currentPage,
                  totalItems: totalItems
                };
          let i = index
        "
      >
        <div class="innerDiv" [routerLink]="['/service-provider-list-deals']" [queryParams]="{_id: item?._id, name: item?.categoryName}">
    
          <category-layout-home [data]="item" [catName]="item?.categoryName" [showDescription]="true"></category-layout-home>
        </div>
      </div>
      <div *ngIf="isLoading" class="no-data">
        <p style="text-align: center; font-size: 16px; font-weight: 500">
          No Data Found...
        </p>
      </div>
    </div>
    <div
      class="custom-pagination mt-2 text-align-right"
      style="text-align: right"
      *ngIf="totalItems > 12"
    >
      <pagination-controls
        (pageChange)="pagination($event)"
      ></pagination-controls>
    </div>
  </div>


<div class="container mb-5 wrapper-content" *ngIf="!flag">

    <ng-container *ngIf="!showService">
      <div class="row  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
        <div class="col newCardWidth mb-3" *ngIf="standard" style="border: 1px solid #a5a5a5;
        min-width: 250px;
        margin: 0 5px;">
          <div class="innerDiv"  [queryParams]="{_id: item?._id, name: item?.categoryName}">
      
            <category-layout-home [data]="{categoryImage : 'https://exobe.s3.af-south-1.amazonaws.com/1694516619481_convert2.jpeg' , categoryName : 'Standard Services'}" [catName]="{categoryName : 'Standard Services'}" [showDescription]="true"></category-layout-home>
          </div>
        </div>
        <div class="col newCardWidth mb-3" *ngIf="field"  style="border: 1px solid #a5a5a5;
        min-width: 250px;
        margin: 0 5px;">
          <div class="innerDiv" [routerLink]="['/service-provider-list-deals']" [queryParams]="{_id: field?.category?._id, name: transport?.category?.categoryName}">
      
            <category-layout-home [data]="{categoryImage : field?.category?.categoryImage , categoryName : field?.category?.categoryName , _id : field?.category?._id}" [catName]="{categoryName : field?.category?.categoryName}" [showDescription]="true"></category-layout-home>
          </div>
        </div>
        <div class="col newCardWidth mb-3" *ngIf="transport"  style="border: 1px solid #a5a5a5;
        min-width: 250px;
        margin: 0 5px;">
          <div class="innerDiv" [routerLink]="['/service-provider-list-deals']" [queryParams]="{_id: transport?.category?._id, name: transport?.category?.categoryName}">
      
            <category-layout-home [data]="{categoryImage : transport?.category?.categoryImage , categoryName : transport?.category?.categoryName ,  _id : transport?.category?._id}" [catName]="{categoryName : transport?.category?.categoryName}" [showDescription]="true"></category-layout-home>
          </div>
        </div>
        <div *ngIf="isLoading" class="no-data">
          <p style="text-align: center; font-size: 16px; font-weight: 500">
            No Data Found...
          </p>
        </div>
      </div>
      <div
        class="custom-pagination mt-2 text-align-right"
        style="text-align: right"
        *ngIf="totalItems > 12"
      >
        <pagination-controls
          (pageChange)="pagination($event)"
        ></pagination-controls>
      </div>
    </ng-container>
    <ng-container *ngIf="showService"></ng-container>
  </div>
  <select-service-type *ngIf="showService" [serviceList]="serviceList" title="Please configure the services"></select-service-type>