<auth-layout title="Upload Documentation" type="SIGNUP" subTitile="" sideImage="assets/latest/service-provider-signup.png">

    <div class="d-flex justify-content-between flex-wrap">
        <ng-container *ngIf="false">
            <div class="w-100">
                <p class="Letter">Certificate Of Incorporation ( Business
                    Registration Letter )</p>
    
                <div class=" textaligncenter">
                    <div class="form-group jj ">
               
                        <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                            (change)="uploadImg($event,'INCORPORATIONFORNT')">
                      
    
                    </div>
                </div>
    
            </div>
    
    
    
            <div class="w-100">
                <p class="Letter">VAT Registration Confirmation ( If Applicable )</p>
    
                <div class=" textaligncenter">
                    <div class="form-group jj ">
                        <!-- <label for="file-upload" class="custom-file-upload">
                        Upload Image
                    </label> -->
    
                        <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control custom-file-input "
                            (change)="uploadImg($event,'VATFORNT')">
                        <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->
    
                    </div>
                </div>
    
            </div>
    
    
    
    
            <div class="w-100">
                <p class="Letter">Director Consent Form ( To Be Completed )</p>
    
                <div class=" textaligncenter">
                    <div class="form-group jj ">
                        <!-- <label for="file-upload" class="custom-file-upload">
                        Upload Image
                    </label> -->
                        <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                            (change)="uploadImg($event,'DIRECTORFORNT')">
                        <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->
    
                    </div>
                </div>
    
            </div>
    
    
            <div class="w-100">
                <p class="Letter">Director (s) ID Document</p>
    
                <div class=" textaligncenter">
                    <div class="form-group jj ">
                        <!-- <label for="file-upload" class="custom-file-upload">
                        Upload Image
                    </label> -->
                        <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                            (change)="uploadImg($event,'DIRECTORDOCUMENT')">
                        <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->
    
                    </div>
                </div>
    
            </div>
    
    
            <div class="w-100">
                <p class="Letter">Bank Confirmation Letter </p>
    
                <div class=" textaligncenter">
                    <div class="form-group jj ">
                        <!-- <label for="file-upload" class="custom-file-upload">
                        Upload Image
                    </label> -->
    
                        <input id="file-upload" type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                            (change)="uploadImg($event,'CONFIRMATIONFORNT')">
                        <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->
    
                    </div>
                </div>
    
            </div>
        </ng-container>

        <ng-container >
            <ng-container *ngFor="let item of pickupDocumentVerification;let i = index">
                <div class="w-100">
                    <p class="Letter">{{item.name}}</p>
        
                    <div class=" textaligncenter">
                        <div class="form-group jj ">
                            
                            <input *ngIf="isPDF(item.name)" id="file-upload" type="file" [attr.id]="'fileInput_'+i" accept=".pdf,.docx" class="form-control custom-file-input"
                                (change)="uploadImg($event,item.key,i)">
                            <input *ngIf="!isPDF(item.name)" id="file-upload" type="file" [attr.id]="'fileInput_'+i" accept=".pdf,.docx,.jpg,.png,.jpeg" class="form-control custom-file-input"
                                (change)="uploadImg($event,item.key,i)">
                          
        
                        </div>
                    </div>
        
                </div>
            </ng-container>
      
        </ng-container>
      


        <div class="d-flex justify-content-center submit-div">
            <button class="btn btn-outline-success2 my-2 my-sm-0 mt-3 mr-3" (click)="goBack()" 
            type="submit">Back</button>
  
            <button class="btn btn-outline-success2 my-2 my-sm-0 mt-3" [disabled]="flag" *ngIf="!loading" (click)="load();imgeUplode2()"
                type="submit">Submit</button>
            <button class="btn btn-outline-success2 my-2 my-sm-0 mt-3 op05" *ngIf="loading"
                type="submit">Loading...</button>
        
        </div>

    </div>

</auth-layout>