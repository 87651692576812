<div class="container  mt-4">

    <hr class="mt-0 mb-4">
    <div class="row">
        <div class="col-xl-4" style="border-right: 1px solid #e5e5e5;">
            <!-- Profile picture card-->
            <div class=" mb-4 mb-xl-0">
                <!-- <div class="card-header">Profile Picture</div> -->
                <div class="card-body text-center">
                    <!-- Profile picture image-->
                    <img class="img-account-profile rounded-circle mb-2"
                        [src]="data?.profilePic || 'assets/images/man.png'" alt="">
                    <!-- Profile picture help block-->
                    <div class="new-email mb-1">{{data?.firstName}} {{data?.lastName}}</div>
                    <div class="new-name mb-4">{{data?.email}}</div>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-1 lineSvg">
            <img src="assets/latest/vr.svg" alt="">
        </div> -->
        <div class="col-xl-7">
            <!-- Account details card-->
            <div class=" mb-4">
                <div class="myprofile">My Profile</div>

                <div class="card-body pl-0">
                    <form>
                        <!-- Form Group (username)-->
                        <div class="mb-3">
                            <div class="mt-3">
                                <p class="prsnlInfo">Personal Information</p>
                            </div>
                            <label class="smallTes mb-1" for="inputUsername">Username (how your name will appear to
                                other users on the site)</label>
                            <!-- <input class="form-control" id="inputUsername" type="text" placeholder="Enter your username" attr.value="username"> -->
                        </div>
                        <!-- Form Row-->
                        <div class="row gx-3 mb-3">
                            <!-- Form Group (first name)-->
                            <div class="col-md-4 pl-0" >
                                <label class="smallLeb" for="inputFirstName">Full Name</label>
                                <input class="form-control" readonly id="inputFirstName" type="text"
                                    placeholder="Your full name" [attr.value]="data?.firstName + ' ' + data?.lastName">
                            </div>
                            <!-- Form Group (last name)-->
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb" for="inputLastName">Email Address</label>
                                <input class="form-control" readonly id="inputLastName" type="text"
                                    placeholder="Your email address" [attr.value]="data?.email">
                            </div>
                            <!-- Form Group (last name)-->
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb" for="inputLastName">Mobile Number</label>
                                <input class="form-control" readonly id="inputLastName" type="text" placeholder="-r"
                                    [attr.value]="data?.countryCode + ' ' + data?.mobileNumber">
                            </div>
                        </div>
                        <!-- Form Row        -->
                        <div class="row gx-3 mb-3">
                            <!-- Form Group (organization name)-->
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb" for="inputOrgName">Country</label>
                                <input class="form-control" readonly id="inputOrgName" type="text" placeholder="-"
                                    [attr.value]="data?.country">
                            </div>
                            <!-- Form Group (location)-->
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb" for="inputLocation">State</label>
                                <input class="form-control" readonly id="inputLocation" type="text" placeholder="-"
                                    [attr.value]="data?.state">
                            </div>
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb" for="inputLocation">City</label>
                                <input class="form-control" readonly id="inputLocation" type="text" placeholder="-"
                                    [attr.value]="data?.city">
                            </div>
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb">Address</label>
                                <p class="popi" readonly placeholder="-">{{data?.addressLine1}} {{' '}}
                                    {{data?.addressLine2}}</p>
                            </div>
                            <!-- Form Group (birthday)-->
                            <div class="col-md-4 pl-0">
                                <label class="smallLeb" for="inputBirthday">Zip Code</label>
                                <input class="form-control" readonly id="inputBirthday" type="text" name="birthday"
                                    placeholder="-" [attr.value]="data?.zipCode">
                            </div>
                        </div>
                        <!-- Form Group (email address)-->
                        <!-- <div class="mb-3">
                            <label class="smallLeb" for="inputEmailAddress">Email address</label>
                            <input class="form-control" readonly id="inputEmailAddress" type="email" placeholder="Enter your email address" attr.value="name@example.com">
                        </div> -->
                        <!-- Form Row-->
                        <!-- Save changes button-->
                      
                        <div class="d-flex" style="    justify-content: center;
                        align-items: center;
                        text-align: center;">
                            <div class="d-flex mt-2" style="flex-wrap:wrap">
                                <!-- <img src="assets/latest/edit-profile.svg" alt=""> -->
                                <a class="textA mt-2 ml-4" routerLink="/edit-customer-profile">Edit Profile</a>
                                <a class="textA mt-2 ml-4" routerLink="/change-password-customer">Change Password</a>

                                <ng-container *ngIf="data?.userType != 'CUSTOMER' && data?.userType == 'RETAILER'">
                                    <a class="textA mt-2 ml-4" routerLink="/edit-business-form">Edit Business Document</a>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.userType != 'CUSTOMER' && data?.userType == 'SERVICE_PROVIDER'">
                                    <a class="textA mt-2 ml-4" routerLink="/edit-business-detail-service">Edit Business
                                        Document</a>
                                </ng-container>
                                <!-- <ng-container *ngIf="serviceRole == 'PICKUP' || serviceRole == 'DELIVERY' || serviceRole == 'FIELD'">
                                    <a class="textA mt-2 ml-4" routerLink="/update-fee-pickup-partner">Edit Fee Configuration</a>
                                </ng-container> -->
                                
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>