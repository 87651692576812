import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-retailer-order-list-view',
  templateUrl: './retailer-order-list-view.component.html',
  styleUrls: ['./retailer-order-list-view.component.scss']
})
export class RetailerOrderListViewComponent implements OnInit {
  _id: any
  qtyA: any
  serviceData: any
  idOfAddress: any
  loggedInUserType: any;
  lng: any
  lat: any

  headingName:any ='Order Details'
  linkerRouteItems:any =[
    {name:'Home',route: '/retailer-home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Order History',route:'/retailer-order-list'},
    {name:'View',route:'/retailer-order-history-view'}

  ]
  constructor(private activatedroute: ActivatedRoute, public authService: AuthService, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      // this.idOfAddress =res.idOfAddress
      if (navigator && navigator.geolocation) {
        const position = (pos) => {
          this.lng = pos.coords.longitude;
          this.lat = pos.coords.latitude;
        };

        const error = (error) => {
          alert(JSON.stringify(error));
        };


        // navigator.geolocation.getCurrentPosition( position, error );
        navigator.geolocation.watchPosition(position, error);

      }
    })
  }
  
  ngOnInit(): void {
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res

    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    this.viewOpenOrder()
    this.viewTransection()
    navigator.geolocation.getCurrentPosition(function (position) {
      // alert('allow');  
    }, function () {
      alert('You need to enable geolocation services in your browser to use this feature.');
    });
    this.getPosition().then(pos => {
      
      localStorage.setItem('location', JSON.stringify(pos))
      
    });
    // this.getLocation();

  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  viewOrderData: any = []
  orderDataDetials: any
  viewOpenOrder() {
    let url = `order/v2/viewOrder?orderId=${this._id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.orderDataDetials = res["result"];
        // this.viewOrderData = res['result']['productDetails']
        // this.viewOrderData = res['result']['myOrders']
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  transactionData: any
  viewTransection() {
    let url = `order/viewOrderTransaction?orderId=${this._id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.viewOrderData = res['result']['orderId']['productDetails']
        this.transactionData = res["result"];
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  getPrice(e: any) {
    
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  date: any = Date

  getAddedDate(createdAt: string, days: number) {
    let date = new Date(createdAt).setDate(new Date(createdAt).getDate() + 3);
    return date
  }
  openModal : boolean = false
  markAsDone() {
    let url = `user/productMarkAsDone`
    let data = {
      orderId: this._id
    }
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        // this.router.navigate(['/retailer-order-otp-verify'], { queryParams: { orderId: this._id } })
        this.openModal = true
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }

  closeModal(){
    this.openModal = false
  }
  itemSplit(){

  }
}
