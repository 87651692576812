<body class="bg-light">
    <div class="container-fluid p-3 mt-5" style="height: 523px;">
      <div class="row justify-content-center p-4 mt-5">
        <div class="col-md-5">
          <div class="card shadow-lg">
            <h2 class="head mb-4 p-4">Verify Your Email ID</h2>
            <form [formGroup]="otpForm">
              <div class="otp">
                <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
              </div>
              <div class="vca mt-3">
                <span class="o-f" (click)="navigateToResetPassword()">Verify</span>
              </div>
              <div class="mt-2 forcenter">
                <!-- <a class="res-otp" (click)="resendOtp()">Resend OTP</a> -->
                <a class="res-otp" (click)="resendOtp()"  *ngIf="isExpire">Resend OTP</a>
                <a class="res-otp"  *ngIf="!isExpire">Resend OTP</a>
                <!-- <p class="mt-10">{{time == '1.59' || time == '0.59' ? '' : time }}</p> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
  </body>
  
  
  
  
  
  <!-- <div class="content">
        <div class="o-c">
          <div>
            <div class="card">
              <p class="a-o-i">Enter verification code</p>
              <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
              <span  class="o-t-p">
                <ng-container *ngIf="otp">
                  Entered otp :-{{otp}}
                </ng-container>
                </span>
            </div>
          </div>
        </div>
      </div> -->