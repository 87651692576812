<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<div class="container mb-5 wrapper-content">
  
    <div class="row  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
        <div class="col newCardWidth mb-3"
            *ngFor="let item of productDetails | paginate : {itemsPerPage: limit, currentPage: currentPage, totalItems: totalItems};let i = index">
            <div class="innerDiv">
                <div class="d-flex justify-content-end mb-3">
                    <!-- <img class="img" [src]="item?.productId?.thumbnail" alt=""
                        (click)="navigateToViewProduct(item?.productId?._id)"> -->
                    <span class="heart" (click)="addWishlist(item?.productId?._id)">
                        <i class="fa fa-heart-o" *ngIf="item?.isLike == false"></i>

                        <i class="fa fa-heart" style="color: red;" *ngIf="item?.isLike == true"></i>
                    </span>
                </div>
                <!-- <div class="mt-3" (click)="navigateToViewProduct(item?.productId?._id)">
                    <h3 class="text-center">{{item?.productId?.productName}}</h3>
                </div>
                <div class="mt-2" (click)="navigateToViewProduct(item?.productId?._id)">
                    <h3 class="text-center">{{item?.productId?.priceSizeDetails[0]?.price &&
                        getPrice(item?.productId?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</h3>
                </div> -->
                <wishlist-card [data]="item" (on_Click)="navigateToViewProduct(item?.productId?._id)"></wishlist-card>
            </div>
        </div>
    </div>
    <div class="custom-pagination mt-2 text-align-right" style="text-align: right;" *ngIf="totalItems > 15">
        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
    </div>
</div>