import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
// import { StoreLocatorDialogComponent } from './modals/store-locator-dialog/store-locator-dialog.component';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component2.scss', './header.component.scss']
})
export class HeaderComponent implements OnInit {


  isMobile: boolean = true;
  isMenuOpen: boolean;



  collection = false
  logoResultArray: any = [];
  type: any;
  topHeaderTitle: any
  // for sub menu hover 
  isShowCollection: boolean = false
  isShowSocial: boolean = false
  isShowSwatches: boolean = false
  isShowApponitment: boolean = false
  isShowShip: boolean = false
  isShowTrunk: boolean = false
  isShowProfile: boolean = false

  isLoggedIn: boolean;
  myProfile: any;
  loggedInUserType: any;
  topHeaderTitleLink: any;
  totalItemsInShoppingBag: any;
  userType: any
  activeTab: any = 'home'
  activebar: any
  comPlrofile: any
  userRequestStatus: any
  isSubService : boolean = false
  constructor(public authService: AuthService, private router: Router, public commonService: CommonService, private activatedroute: ActivatedRoute) {
    this.authService.SharingData.subscribe((res: any) => {
      this.SharingData = res
    })
    this.authService.userRequestStatus.subscribe((res: any) => {
      this.userRequestStatus = res
    })
    this.userRequestStatus = this.authService.checkuserRequestStatus()
    this.commonService.profileUpdateItem.subscribe(updated => {
      this.getProfile()
    })

  }
  //
  SharingData: any
  serviceRole : any
  subType : string
  flagMe = true
  ngOnInit(): void {
    this.getProfile()
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationEnd)) {
        console.log(evt);
        this.activebar = this.getEndUrlSegment()
        window.scroll(0, 0)

      }

    });

    // end scroll to top 


    this.activatedroute.queryParams.subscribe((res) => {
      this.type = "LOGO";
    })
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res
      if(localStorage.getItem('userType') == 'DELIVERY_PARTNER' || localStorage.getItem('userType') == 'FIELD_ENTITY' || localStorage.getItem('userType') == 'PICKUP_PARTNER'){
        this.subType = localStorage.getItem('userType')
        this.loggedInUserType = 'SERVICE_PROVIDER'
        this.isSubService = true
      }

    })

    // complete profile

    this.authService.SharingData.subscribe((res: any) => {
      this.SharingData = res

    })

    this.SharingData = this.authService.profileC()
    // userRequestStatus
    this.authService.userRequestStatus.subscribe((res: any) => {
      this.userRequestStatus = res

    })
    this.userRequestStatus = this.authService.checkuserRequestStatus()
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    
    this.userType = localStorage.getItem('userType')
    if(localStorage.getItem('userType') == 'DELIVERY_PARTNER' || localStorage.getItem('userType') == 'FIELD_ENTITY' || localStorage.getItem('userType') == 'PICKUP_PARTNER'){
      this.loggedInUserType = 'SERVICE_PROVIDER'
      this.isSubService = true
    }
  
    this.commonService.totalItemsInShoppingBag.subscribe((res: any) => {

      if (res) {
        this.totalItemsInShoppingBag = res
      } else {
        // this.isLoggedIn ? this.myShopingBagApi() : ''
      }
    });

    $(document).ready(function () {
      $('body').on('mouseup', function (e) {
        if (!$(e.target).hasClass("dropdown-menu")) {
          $(".dropdown-menu").each(function () {
            $(this).removeClass("show");
          });
        }
      });
    });


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // When the route changes and navigation is complete, update the currentUrl
        let change_url = event.url
        if(change_url == '/business-details-retailer' || change_url =='/upload-document-retailer' 
        || change_url == '/business-details-service-provider' || change_url == "/upload-document-service-provider" ||
        change_url == '/login-service-provider' || change_url == '/login' || change_url == '/login-customer' || change_url == '/login-retailer'
        ){
          this.flagMe = false
        }
        else{
          this.flagMe = true
        }
        console.log('Current URL:', event.url);
      }
    });
  

  }
  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }
  // params["userType"] = "CUSTOMER"
  // params["userType"] = "RETAILER"
  // params["userType"] = "SERVICE_PROVIDER"

  loginNavigate(userType) {
    if(userType == 'SERVICE_PROVIDER'){
      localStorage.setItem('userType', userType)
      this.router.navigate(['/login-service-provider'])
      this.commonService.userTypeSelectionUpdate.next(userType);
    }
    else{
      localStorage.setItem('userType', userType)
      this.router.navigate(['/login'])
      this.commonService.userTypeSelectionUpdate.next(userType);
    }

   
  }
  logOutModal() {
    $('#logoutModal').modal('show')
  }

  logout() {
    $('#logoutModal').modal('hide')
    this.authService.logout()

  }

  selectedTab(tab) {
    this.activeTab = tab
  }


  // navigateTo(path) {
  //   switch (path) {
  //     case 'my-profile':
  //       this.router.navigate(['/profile-management'], { queryParams: { showTab: 'PROFILE' } })
  //       break;
  //     case 'my-wishlist':
  //       this.router.navigate(['/profile-management'], { queryParams: { showTab: 'WISHLIST' } })
  //       break;
  //     case 'my-shopping-bag':
  //       this.router.navigate(['/my-shopping-bag'])
  //       break;
  //   }
  // }

  menuNavigateTo(path) {

    this.activeTab = path
    localStorage.setItem('activeTab', this.activeTab)
    switch (path) {
      case 'Customize':
        this.router.navigate(['/dress-collection'])
        break;
      case 'Social Shopping':
        // $('#deliveryInfoModalId').modal('show')
        this.router.navigate(['/curation-list'])
        break;
      case 'my-profile':
        // this.router.navigate(['/my-profile'])
        this.router.navigate(['/profile-management'], { queryParams: { showTab: 'PROFILE' } })
        break
      case 'search':
        this.router.navigate(['/search'])
        break;
      case 'addtoCart-customer':
        this.router.navigate(['/addtoCart-customer'])
        break;
      case 'wishlist-customer':
        this.router.navigate(['/wishlist-customer'])
        break;
      case 'customer-service-list':
        this.router.navigate(['/customer-service-list'])
        break;
      case 'customer-category-list':
        this.router.navigate(['/customer-category-list'])
        break;
      case 'services':
        this.router.navigate(['/services'])
        break;
      case 'service-provider-payment-from-customers':
        this.router.navigate(['/service-provider-payment-from-customers'])
        break;
      case 'service-provider-deals-to-customer':
        this.router.navigate(['/service-provider-deals-to-customer'])
        break;
      case 'service-provider-request-services':
        this.router.navigate(['/service-provider-request-services'])
        break;
      case 'deal-list':
        this.router.navigate(['/customer-product-deal-list'])
        break;
      case 'deal-service':
        this.router.navigate(['/customer-service-deal-list'])
        break;
      case 'deal-service-list':
        this.router.navigate(['/customer-service-request-list-table'])
        break;
      case 'retailer-order-list':
        this.router.navigate(['/retailer-order-list'])
        break;
      case 'reatiler-payment-customer':
        this.router.navigate(['/retailer-payment-from-customer'])
        break;
      case 'reatiler-payment-wholesaler':
        this.router.navigate(['/retailer-payment-from-wholesaler'])
        break;
      case 'retailer-product-list':
        this.router.navigate(['/retailer-product-list'])
        break;
      case 'retailer-deal-list':
        this.router.navigate(['/retailer-deal-list'])
        break;
      case 'customer-product-deal-list':
        this.router.navigate(['/customer-product-deal-list'])
        break;
    }
  }


  menuSubNavigateTo(categoryId, subCategoryId, categoryName, subCategoryName) {
    switch (categoryName) {
      // collection
      case 'Collection':
        this.router.navigate(['/bridal-collection-list'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })
        break;

      // ready to ship
      case 'Ready To Ship':
        // this.router.navigate(['/bridal-party'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })
        this.router.navigate(['/readytoship-bridal'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })

        break;

      // swatches
      case 'Swatches':
        if (subCategoryName == 'Motee Maids Bridesmaids') {
          this.router.navigate(['/swatches-collection-list'])
        } else if (subCategoryName == 'Nouvelle Motee Maids Bridesmaids') {
          this.router.navigate(['/nouvelle-swatches-list'])
        } else {
          this.router.navigate(['/mask-list'])
        }
        break;

      // book an appointment
      case 'Book An Appointment':
        // if (subCategoryName == 'Visit Nearest Salon') {
        if (subCategoryName == 'Appointments') {
          // this.router.navigate(['/visit-nyc-salon'])
          this.router.navigate(['/select-date'])
        } else if (subCategoryName == 'Store Locator') {
          this.router.navigate(['/store-locator'])
        } else {
          this.router.navigate(['/faq'])
        }
        break;

      // trunk show
      case 'Trunk Shows':
        if (subCategoryName == 'Trunk') {
          this.router.navigate(['/trunk'])
        } else {
          this.router.navigate(['/trade'])
        }
        break;

      // story
      case 'Story':
        if (subCategoryName == 'Real Brides') {
          this.router.navigate(['/real-brides'])
        } else if (subCategoryName == 'Blog') {
          this.router.navigate(['/blog'])
        } else {
          this.router.navigate(['/press'])
        }
        break;

      // default

      default:
        this.router.navigate(['/bridal-collection-list'], { queryParams: { categoryId: categoryId, subCategoryId: subCategoryId, categoryName: categoryName, subCategoryName: subCategoryName } })
        break;
    }
  }

  navigateToAccount() {
    if (this.userRequestStatus == 'APPROVED') {
      if (this.SharingData) {
        this.router.navigate(['/customer-profile'])
      }
    }
  }
  profileData: any
  getProfile() {
    // if(!this.isLoggedIn){
    //   return
    // }
    if(!localStorage.getItem('token')){
      return
    }
    this.commonService.getApi("user/myProfile", 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.profileData = res["result"];
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  openRoleModal(){
    $('#exampleModal').modal('show')
  }

  navigateToRejected(){
    this.router.navigate(['/service-order-rejected-detail'])
  }
  navigateHome(url){
    if(this.flagMe){
      this.router.navigate([url])
    }
    else{
      this.authService.logout()
      this.router.navigate(['/home'])
    }
  }
}
