import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'select-service-type',
  templateUrl: './select-service-type.component.html',
  styleUrls: ['./select-service-type.component.scss']
})
export class SelectServiceTypeComponent implements OnInit {
@Input() serviceList : any
@Input() title : any
  constructor( public router : Router,public commonService: CommonService) { }

  ngOnInit(): void {
    $("#roleModal").modal({backdrop: 'static', keyboard: false},"show")
  }
  onSelect(e){
    $("#roleModal").modal("hide")
    localStorage.setItem('serviceRole',e)
    this.router.navigate(['/login'])
  }
  serviceArray = []
  setService(e,type){
    if(e){
      this.serviceArray.push(type)
    }
    else{
      let ind = this.serviceArray.findIndex((ele)=>{return ele == type})
      this.serviceArray.splice(ind,1)
    }
    console.log(this.serviceArray);
    
  }
  toggleCheckbox(checkbox: HTMLInputElement) {
    checkbox.checked = !checkbox.checked;
  }
  onNavigate(item){
    $("#roleModal").modal("hide")
   if(item.category.categoryType == 'SERVICE_PROVIDER'){
    this.router.navigate(['services-provider'])
   }
   else{
    this.router.navigate(['service-provider-list-deals'],{queryParams : {_id : item.category._id, name : item.category.categoryName}})

   }
  }

}
