import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

declare var $
@Component({
  selector: 'app-product-view-customer',
  templateUrl: './product-view-customer.component.html',
  styleUrls: ['./product-view-customer.component.scss']
})
export class ProductViewCustomerComponent implements OnInit {
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  iaAPILoaded: boolean = false
  isLoaded: boolean = false
  headingName:any ='PRODUCT DETAILS'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Product',route:'/product-view-customer'}
  ]
  afterDiscountPrice: number;
  isLoading: boolean =false;
  subCatId : any = []
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      if(res.q){
        this.linkerRouteItems[1] =  {name:res.q,route:'/product-view-customer'}
      }
    })
  }

   ngOnInit() {
    this.isLoading =false
    this.authService.isLoggedIn.subscribe((res: any) => {
      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res
    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
     this.viewRetailerProductd();
    this.getLocation();

  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  firstPriceSize: any = [];
  productDetailsSize: any = []
  latitude: any;
  longitude: any;

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
    
          
          this.getProducts()

        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  // get retailer detail by id
  getProducts() {
    if (this.isLoggedIn) {
      this.getSimiliarProductwithToken()
    } else {
      this.getSimiliarProduct()
    }
  }
  // get retailer detail by id
   viewRetailerProductd(id?) {
    let apiReqUrl: any = `product/viewProduct?productId=${id || this._id}`
    this._id = id ? id : this._id
      let isLogin = this.isLoggedIn ? 1 : 0
      this.iaAPILoaded = false
      this.commonService.postApi(apiReqUrl, {}, isLogin).subscribe(res=>{
        if (res['responseCode'] == 200) {
          this.iaAPILoaded = false
          this.productDetails = res['result'];
          this.isLoading =true
          this.productDetailsSize = res['result'].priceSizeDetails
          this.firstPriceSize = res['result'].priceSizeDetails[0]
          this.afterDiscountPrice = this.calDiscountOn(this.firstPriceSize.price)
          this.price(this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit)
          this.imgurl = res['result'].productImage[0]
          this.imageLinks = res["result"]["thumbnail"]
          this.commonService.hideSpinner();
          this.getProducts()
        } else {
          this.isLoading =false
          this.commonService.hideSpinner();
          this.commonService.errorToast(res['responseMessage'])
        }
      })
     



  }




  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    
    this.imgurl = url
  }


  getPrice(e: any) {
    if(e){
      let a = e.replace(/,/g, ' ')
      return a.replace(/R/g, 'R ')
    }
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {
    this.mrp = e,
    this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
    this.afterDiscountPrice = this.calDiscountOn(this.mrp)
  }
  calDiscountOn(realPrice){
    let dsp  = (Number(this.productDetails.dealDiscount)/ 100) * realPrice
    return this.mrp - dsp
  }
  imgSrc: any
  productImage: any
  serviceData: any = []
  user: any
  currentPage = 1;
  pageSize = 12;
  getSimiliarProduct() { 
    // if (this.loggedInUserType == 'RETAILER') {
    //   this.user = 'WHOLE_SALER'
    // } else {
    //   this.user = 'RETAILER'
    // }
    let data = {
      "userType": this.authService.checkUserTypeLoggedIn(),
      'lng': this.longitude,
      'lat': this.latitude,
      "categoryId": [
        this.productDetails && this.productDetails.categoryId._id
      ]
    }
    let apiReqUrl: any = `product/listProductBySortion`
    this.iaAPILoaded = false

    this.commonService.postApi(apiReqUrl, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res.result.docs;
        this.iaAPILoaded = true
        $(document).ready(function () {
          $('.sevice').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        });
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  getSimiliarProductwithToken() {
    
    // if (this.loggedInUserType == 'RETAILER') {
    //   this.user = 'WHOLE_SALER'
    // } else {
    //   this.user = 'RETAILER'
    // }
    let data = {
      "userType": this.authService.checkUserTypeLoggedIn(),
      'lng': this.longitude,
      'lat': this.latitude,
      "categoryId": [
        this.productDetails && this.productDetails.categoryId._id
      ]
    }
    // let apiReqUrl: any = `product/productMightLike`
    let apiReqUrl: any = `product/listProductBySortion`

    // let apiReqUrl: any = `product/viewProduct?productId=${this._id}`/    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    // this.commonService.showSpinner();
    this.iaAPILoaded = false

    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.serviceData = res.result.docs;
        
        // this.afterDiscountPrice = this.calDiscountOn(this.firstPriceSize.price)
        this.iaAPILoaded = true

        $(document).ready(function () {
          $('.sevice').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        
        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/logi')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  addToCart() {
    let apiReqUrl = `user/v2/productAddToCart`
    let apiReqData = {

      "productId": this._id,
      "priceSizeDetails": {
        "value": this.value,
        "price": Number(this.mrp),
        "unit": this.unit,
        "id": this.pdId,
        // "quantity" :this.quantity

      },
      "orderType": "PRODUCT",
      "quantity": 1,
      "totalPrice": this.mrp,
      "addType": "PRODUCT"

    }
    if (this.loggedInUserType == 'RETAILER') {
      apiReqData.priceSizeDetails['quantity'] = this.quantity
    }

    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/addtoCart-customer'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  viewProducts(_id) {
    this.viewRetailerProductd(_id)
    // this.router.navigate(['/product-view-customer'],{queryParams:{_id:_id}})
  }
  showMessage() {
    return this.commonService.warningToast('Please Login')
  }

  onImageLoad(){
    this.isLoaded = true
  }
}
