import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-forgot-customer',
  templateUrl: './forgot-customer.component.html',
  styleUrls: ['./forgot-customer.component.scss']
})
export class ForgotCustomerComponent implements OnInit {
  forgotPassword!: FormGroup;
  sideImage:any;
  userType: any = 'CUSTOMER';
  isLoading = false
  constructor(private fb: FormBuilder, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.forgot();
    if(localStorage.getItem('userType') == 'RETAILER'){
      this.userType = 'RETAILER'
    }
  }

  forgot() {
    this.forgotPassword = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
    })
  }



  add() {
    var data = {
      email: this.forgotPassword.value.email,
      userType: this.userType
    }
    localStorage.setItem('email', data['email'])
    this.commonService.showSpinner()
    this.isLoading = true
    this.commonService.postApi('user/forgotPassword', data, 0).subscribe((res: any) => {
      
      this.isLoading = false
      if (res['responseCode'] == 200) {
        localStorage.setItem('email', this.forgotPassword.value.email)
        this.isLoading = false
        this.commonService.successToast(res['responseMessage'])
        // set current url to localstorage 
        this.router.navigate(['/reset-otp-customer'],{queryParams : {email:this.forgotPassword.value.email}});
      }else{
        this.isLoading = false
      this.commonService.errorToast(res['responseMessage'])
      }
    }, err => {
      if(err['responseCode'] == 404){
        this.isLoading = false
        this.commonService.errorToast(err['responseMessage'])
      }
    else{
      this.isLoading = false
      this.commonService.errorToast(err['responseMessage'])
    }
    })
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

}
