import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-select-login-category',
  templateUrl: './select-login-category.component.html',
  styleUrls: ['./select-login-category.component.scss']
})
export class SelectLoginCategoryComponent implements OnInit {
  transport: any;
  field: any;
  service: any;
  standard: any;

  constructor( public router : Router,public commonService: CommonService) { }

  ngOnInit(): void {
    $("#roleModal").modal({backdrop: 'static', keyboard: false},"show")
    if(this.getEndUrlSegment() == 'service-provider-update-role'){
      this.myService()
    }
  }
  serviceList : any
  myService(){
    let url = `user/myServiceList`
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        let result = res.result.services
        this.transport = result.find((ele)=>{return ele.category.categoryType == 'PICKUP_PARTNER'})
        if(this.transport){
          this.serviceArray.push('TRANSAPORTATION')
        }
        this.field = result.find((ele)=>{return ele.category.categoryType == 'FIELD_ENTITY'})
        if(this.field){
          this.serviceArray.push('FULLFILLMENT')
        }
        this.standard = result.find((ele)=>{return ele.category.categoryType == 'SERVICE_PROVIDER'})
        if(this.standard){
          this.serviceArray.push('STANDARD')
        }
        this.serviceList = res.result.services
      
   
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
       
      }

    }, (err: any) => {
     
      if (err.responseCode == 409) {
        
      }
    })
  }
  onSelect(e){
    $("#roleModal").modal("hide")
    localStorage.setItem('serviceRole',e)
    this.router.navigate(['/login'])
  }
  serviceArray = []
  setService(e,type){
    if(e){
      this.serviceArray.push(type)
    }
    else{
      let ind = this.serviceArray.findIndex((ele)=>{return ele == type})
      this.serviceArray.splice(ind,1)
    }
    console.log(this.serviceArray);
    
  }
  toggleCheckbox(checkbox: HTMLInputElement) {
    checkbox.checked = !checkbox.checked;
  }
  onContinue(){
    let url = `user/selectService?flag=2`
    this.commonService.postApi(url, this.serviceArray,1).subscribe((res)=>{
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.router.navigate(['/upload-document-service-provider'])
        $("#roleModal").modal("hide")
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res['responseMessage'])
      }

    }, (err: any) => {
    
     
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      
    
    })
  }

  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }
}
