<!-- -----------------------------------------------again----------------------------------- -->



<!-- <div class="container bg-danger fw-b ">
    <hr>
</div> -->


<div class="container  mt-3 mb-4 wrapper-content">
    <div class="innerDiv" style="padding: 30px 0px;">
        <!-- <div class="row mt-4 mb-4">
            <span><i class="fa fa-arrow-left titt" style=" cursor: pointer;"
                    (click)="commonService.backLocation()"></i></span>
            <h2 class="ml-5 deal-2">Edit Deal</h2>
        </div> -->
        <div class="container  fw-b ">
            <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
          </div>
        <div class="grid-container">
            <div class="section1">
                <form [formGroup]="addDeals">
                    <div>
        
                        <!-- <div class="ff mb-3">
        
                            <div class="profle-sec1">
                                <img [src]="imageUrl" class="" alt="" id="ert">                       
                                    <i class="fa fa-camera cmra-icon" id="rr1" aria-hidden="true">
                                    <input type="file" class="input-file" accept=".png, .jpg, .jpeg"
                                        (change)="uploadImg($event)">
        
                                </i>
                            </div>
                        </div> -->
        
        
                        <!-- <mat-card class="mat1 m-auto"> -->
        
                   
                        <!-- </mat-card> -->
                        <!-- ===================================== -->
                        <div class="form-row mt-2">
                            <div class="col-md-12">
                                <label for="inputEmail4" class="tite">Service</label>



                                <select class="form-control" id="deal-5" placeholder="Enter Service"
                                    formControlName="description" (change)="getServiceId($event.target.value)">
                                    <option value="" selected disabled>Enter Service</option>
                                    <option *ngFor="let item of services" value="{{item?._id}}">{{item?.serviceName}}
                                    </option>
                                </select>


                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('description')?.hasError('required') && addDeals.get('description')?.touched ">
                                    *Please select service.</div>

                            </div>
                        </div>
                        <div class="form-row mt-2">
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Category</label>
                                <div>
                                    <select class="form-control" id="deal-5" maxlength="256" formControlName="category"
                                        disabled="">
                                        <option value="" selected disabled>Select Category</option>
                                        <option *ngFor="let item of catList" value="{{item?._id}}">
                                            {{item?.categoryName}}</option>
                                    </select>
                                    <div class="for-validation text-danger"
                                        *ngIf="addDeals.get('category')?.hasError('required') && addDeals.get('category')?.touched ">
                                        *Please select category.</div>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Sub-Category</label>


                                <!-- <input type="text" class="form-control" id="deal-5" placeholder="Enter Subcategory"
                            maxlength="256" formControlName="subCategory" (keypress)="preventSpace($event)"> -->
                                <select class="form-control" id="deal-5" placeholder="Select Sub-Category"
                                    maxlength="256" formControlName="subCategory" disabled>
                                    <option value="" selected disabled>Select Sub-Category</option>
                                    <option *ngFor="let item of subCatList" value="{{item?._id}}">
                                        {{item?.subCategoryName}}
                                    </option>
                                </select>
                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('subCategory')?.hasError('required') && addDeals.get('subCategory')?.touched ">
                                    *Please select sub-category.</div>

                            </div>
                        </div>

                        <div class="form-row mb-3 mt-3">
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Actual Amount (R)</label>
                                <input type="text" class="form-control" id="deal-5" placeholder="Enter actual price"
                                    maxlength="12" (keypress)="NumOnly($event)" formControlName="actual" disable
                                    (keypress)="preventSpace($event)">





                            </div>


                            <div class="col-md-6">

                                <label for="inputEmail4" class="tite">Discount %</label>
                                <input type="text" class="form-control" id="deal-5"
                                    placeholder="Enter discount percentage" maxlength="12" (keypress)="NumOnly($event)"
                                    onkeyup="if(this.value > 99) this.value = 99; if(this.value < 0) this.value = 0;"
                                    formControlName="discountPercentage" (input)="calculatePrice($event.target.value)" (keypress)="preventSpace($event)">

                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('discountPercentage')?.hasError('required') && addDeals.get('discountPercentage')?.touched ">
                                    *Discount percentage is required.</div>




                            </div>





                        </div>
                        <div class="form-row mb-3 mt-3">
                            <div class="col-md-6">

                                <label for="inputEmail4" class="tite">Deal Price (R)</label>
                                <input type="text" class="form-control" id="deal-5" placeholder="Enter deal price"
                                    maxlength="12" (keypress)="NumOnly($event)" formControlName="mrp" (input)="calculatePercentage($event.target.value)"
                                    (keypress)="preventSpace($event)">

                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('mrp')?.hasError('required') && addDeals.get('mrp')?.touched ">
                                    *Deal price is required.</div>




                            </div>
                        </div>
        
                        <div class="form-row mb-3 mt-3">
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Start Date & Time</label>
                                <input type="datetime-local" class="form-control" id="deal-5" formControlName="dealStartTime"
                                    [min]="today">
                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('dealStartTime')?.hasError('required') && addDeals.get('dealStartTime')?.touched ">
                                    *Please enter deal start time.</div>
                            </div>
                            <div class="col-md-6 ">
                                <label for="inputEmail4" class="tite">End Date & Time</label>
                                <input type="datetime-local" class="form-control" id="deal-5" formControlName="dealEndTime"
                                    [min]="today">
                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('dealEndTime')?.hasError('required') && addDeals.get('dealEndTime')?.touched ">
                                    *Please enter deal end time.</div>
        
                            </div>
                        </div>
        
                        <div class="text-center mt-4 mb-4">
                            <button type="button" class="SAVE" style=" cursor: pointer;" [disabled]="addDeals.invalid"
                                (click)="editServiceDeals()">UPDATE</button>
        
                        </div>
        
                    </div>
        
                </form>
            </div>
            <div class="section2">
                <div class="image-upload" *ngIf="imageUrl">
                    <div class=""  style="background-color: #f2f2f2;">
                        <i class="fa fa-times" aria-hidden="true" (click)="closeimage()" id="closeImage"></i>
                        <img mat-card-sm-image [src]="imageUrl" alt="image">
                    </div>
                </div>
                  <div class="image-upload" *ngIf="!imageUrl?.length">
                    <div class=""  >
                        <img mat-card-sm-image src="assets/latest/dummy-place.svg" alt="image">
                    </div>
                </div>
                <div class="row justify-content-start">
                    <div class="col-md-3 mt-4">
                        <div class="input-file-container file">
                            <input class="input-file image hidden" id="my-file" accept=".png, .jpg, .jpeg, .webp "
                                multiple type="file" (change)="uploadImg($event)">
                            <label tabindex="0" for="my-file" class="input-file-trigger">Upload Image</label>
                        </div>
                    </div>


                </div>
            </div>
          </div>


    </div>

</div>