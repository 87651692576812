<div class="container fw-b">
  <app-linker-route
    [headingName]="headingName"
    [linkerRouteItems]="linkerRouteItems"
  ></app-linker-route>
</div>
<div class="container ss mb-5">
  <!-- <span (click)="commonService.backLocation()" class="circle-arrow">
    <img class="arrow-image-add-new" src="assets/svg/ar.svg" alt="">
  </span> -->
  <div class="">
    <!-- <div class="text-center" style="padding-top: 20px;">
      <h2 class=" pro-2">Product Details</h2>
    </div> -->
    <div class="row mt-3 mb-5">
      <div class="col-md-7 dealpaddingBox">
        <div class="row justify-content-center">
          <span>
            <img
              class="image-custom qwqwqw"
              [hidden]="!imageLoaded"
              (load)="onImageLoad()"
              [src]="imgurl"
              alt=""
            />
            <ngx-skeleton-loader
              *ngIf="!imageLoaded"
              [theme]="{ width: '670px', height: '405px' }"
              count="1"
            ></ngx-skeleton-loader>
          </span>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <div
            *ngFor="let item of productDetails?.dealImage"
            class=""
          >
            <img
              class="custom-img h-100 change-img"
              style="cursor: pointer"
              [src]="item"
              (click)="changeProductimage(item)"
            />
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isLoading">
        <app-table-loader></app-table-loader>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <div class="col-md-5 p-0">
          <div class="row">
            <div class="col-12 dealpaddingBox">
              <p class="prdct-home">{{ productDetails?.dealName }}</p>
            </div>
            <div class="col-12 dealpaddingBox">
              <p class="para-text-common mb-0">
                Product ID : {{ productDetails?._id | slice : 0 : 7 }}
              </p>
            </div>
            <div class="col-6 dealpaddingBox">
              <div class="row justify-content-between">
                <span [ngClass]="{ crossPrice: productDetails?.dealDiscount }">
                  {{
                    mrp
                      ? getPrice(mrp | currency : "ZAR" : "R")
                      : productDetails?.dealDetails[0]?.price &&
                        getPrice(
                          productDetails?.dealDetails[0]?.price
                            | currency : "ZAR" : "R"
                        )
                  }}
                </span>
                <span
                  *ngIf="productDetails?.dealDiscount"
                  class="priceTestPColor"
                >
                  {{
                    dealP
                      ? getPrice(dealP | currency : "ZAR" : "R")
                      : productDetails?.dealDetails[0]?.dealPrice &&
                        getPrice(
                          productDetails?.dealDetails[0]?.dealPrice
                            | currency : "ZAR" : "R"
                        )
                  }}
                </span>
                <span
                  *ngIf="productDetails?.dealDiscount"
                  class="priceTestPColorD"
                >
                  {{ productDetails?.dealDiscount }}% off
                </span>
              </div>
            </div>
            <div class="col-12 mt-2 mb-2">
              <img
                src="assets/latest/veriticalline.svg"
                style="height: 2px; width: 100%"
                alt=""
              />
            </div>
          </div>

          <div class="" style="margin-top: 1em">
            <div class="row">
              <div class="col-4 pr-0 dealpaddingBox">
                <p class="pid">Product Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1 dealpaddingBox">
                  {{ productDetails?.productId[0]?.productName }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 dealpaddingBox">
                <p class="pid">Category Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1 dealpaddingBox">
                  {{ productDetails?.productId[0]?.categoryId.categoryName }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 dealpaddingBox">
                <p class="pid">Quantity Available</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{
                    quantity
                      ? quantity
                      : productDetails?.dealDetails[0]?.quantity
                  }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 dealpaddingBox">
                <p class="pid">Unit</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{ productDetails?.unit }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 dealpaddingBox">
                <p class="pid">Size/Value</p>
              </div>
              <div class="">:</div>
              <div class="col-7 flex-data">
                <ng-container *ngFor="let item of productDetails?.priceSizeDetails">
                  <p class="pid-1 new-loop-para"
                    (click)="price(item?.price, item?.quantity, item?.value, item?.id, item?.unit)"
                    [ngClass]="{ active: value == item?.value }">
                    {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="mt-4 pt-3 dealpaddingBox">
            <span class="d-flex justify-content-between">
              <h1 class="text-center desc">Description</h1>
            </span>
            <p style="word-break: break-word" class="text-left descrip ml-3" [innerHTML]="getRawText( productDetails?.description)">
              <!-- {{ productDetails?.description }} -->
            </p>
          </div>

          <div class="ml-3 mt-3 dealpaddingBox">
            <button
              class="add-cart"
              *ngIf="isLoggedIn && quantity == 0"
              disabled
            >
              Out of stock
            </button>
            <button
              class="add-cart"
              *ngIf="isLoggedIn && quantity > 0 && flag"
              (click)="addToCart()"
            >
              Add to Cart
            </button>
            <button
              class="add-cart" style="opacity: 0.75;"
              *ngIf="isLoggedIn && quantity > 0 && !flag"
              
            >
              Loading...
            </button>
            <button
              class="add-cart"
              *ngIf="!isLoggedIn"
              (click)="showMessage()"
             
            >
              Add to Cart
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
