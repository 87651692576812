import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn = new BehaviorSubject<boolean>(false); // login status
  public loggedInUserType = new Subject() // CONSUMER, RETAILER and INFLUENCER
  public SharingData = new Subject()
  public userRequestStatus = new BehaviorSubject<any>('')
  constructor(private router: Router) { }

  
  public checkAuthToken() {
    return localStorage.getItem('token') !== null;
  }

  public checkUserTypeLoggedIn() {
    return localStorage.getItem('userType')
  }

  public checkuserRequestStatus() {
    return localStorage.getItem('userRequestStatus')
  }
  public isLogin(): boolean {
    return localStorage.getItem('token') ? true : false
  }
  public profileC(): boolean {
    return localStorage.getItem('completeProfile') ? true : false
  }
  
  isHeadingTitle(userType){
    if(userType=='CUSTOMER'){
      return'Customer'
    }
    if(userType=='RETAILER'){
      return 'Retailer'
    }
    if(userType=='SERVICE_PROVIDER'){
      return 'Service Provider'
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId')
    localStorage.removeItem('email')
    localStorage.removeItem('primaryAddressId')
    localStorage.removeItem('userRequestStatus')
    localStorage.removeItem('myId')
    localStorage.removeItem('Ids')
    localStorage.removeItem('completeProfile')
    localStorage.removeItem('serviceRole')

    this.isLoggedIn.next(false);
    this.loggedInUserType.next('')
    localStorage.setItem('userType','CUSTOMER')
    this.router.navigate(['/login']);
  }
}
