<div class="container  fw-b " >
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<!-- jj -->
<div class="container mb-4 wrapper-content" >
    <div class="d-flex mt-1" *ngIf="serviceRole != 'ALL'">
        <div class="radio-group">
            <input type="radio" [checked]="getEndUrlSegment() == 'service-order-detail'" name="del" (change)="setUrl('service-order-detail')" id="standard">
            <label for="standard" class="ml-2">Standard Services</label>
        </div>
        <div class="radio-group">
            <input type="radio" [checked]="getEndUrlSegment() == 'assigned-service-pickup'" name="del" (change)="setUrl('assigned-service-pickup')" id="pickup">
            <label for="pickup" class="ml-2">Transportation Services</label>
        </div>
        <!-- <div class="radio-group">
            <input type="radio" [checked]="getEndUrlSegment() == 'assigned-service-delivery'" name="del" (change)="setUrl('assigned-service-delivery')" id="delivery">
            <label for="delivery" class="ml-2">Delivery Services</label>
        </div> -->
        <div class="radio-group">
            <input type="radio" [checked]="getEndUrlSegment() == 'assigned-service-field'" name="del" (change)="setUrl('assigned-service-field')" id="field">
            <label for="field" class="ml-2">Fulfilment Services</label>
        </div>
    </div>
    <div class="row mt-3" *ngIf="!isRejected || serviceRole != 'ALL'">
        <div class="col-lg-3 col-md-2 col-sm-6" style="padding: 0;">
            <button class="tabBtn" [ngClass]="{active : btn == 'Pending'}" (click)="selectBtn('Pending')">PENDING
            </button>
        </div>
        <div class="col-lg-3 col-md-2 col-sm-6" style="padding: 0;">
            <button class="tabBtn" [ngClass]="{active : btn == 'Complete'}"
                (click)="selectBtn('Complete')">COMPLETED</button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 table-responsive" *ngIf="serviceRole == 'SERVICE' ">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Service Id</th>
                        <th scope="col">Service Date</th>
                        <th scope="col">Category</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col" >Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of orderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <th>{{10 * (currentPage - 1) + i+1}}</th>
                        <th scope="row">{{item?.userId?.firstName}} {{item?.userId?.lastName}}</th>
                        <td>{{item?.orderId}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td>{{item?.myOrders[0]?.serviceId?.categoryId?.categoryName}}</td>
                        <td>{{item?.orderStatus}}</td>
                        <td [ngClass]="{
                            'green-color': item?.paymentStatus=='COMPLETED',
                            'red-color': item?.paymentStatus!='COMPLETED'}">
                            {{item?.paymentStatus}}</td>
                        <td><i class="fa fa-eye mpl10" aria-hidden="true" style="cursor: pointer;"
                                [queryParams]="{_id : item?._id, status: item?.orderStatus}"
                                [routerLink]="['/service-order-detail-view']"></i></td>
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10"
                [isWidth]="'55px'"></app-table-loader>

            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
        <div class="col-12 table-responsive position-relative" *ngIf="serviceRole == 'TRANS' || serviceRole == 'FIELD' || serviceRole == 'DELIVERY' || serviceRole == 'ALL'">
                <!-- <div class="text-center wallet"><span>Wallet Balance</span> &nbsp;&nbsp; <span class="red-color">{{walletDetail?.walletAmount ?  getPrice(walletDetail?.walletAmount  |
                    currency:"ZAR":"R") : "--"}}</span> </div> -->
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Order Id</th>
                        <th scope="col" *ngIf="serviceRole == 'TRANS'">Retailer Name</th>
                        <th scope="col" *ngIf="serviceRole == 'TRANS' || serviceRole == 'FIELD'">Customer Name</th>
                        <th scope="col" *ngIf="serviceRole == 'TRANS' || serviceRole == 'FIELD'">Customer Email</th>
                        <!-- <th scope="col">Field Entity</th> -->
                        <th scope="col">Total Amount</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Delivery Type</th>
                        <th scope="col">Order Date</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Role</th>
                        <th scope="col" *ngIf="!isRejected">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of pickupOrderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <th>{{10 * (currentPage - 1) + i+1}}</th>
                        <th scope="row">{{item?.orderId?.orderId}}</th>
                        <td *ngIf="serviceRole == 'TRANS'">{{item?.orderId?.productDetails[0].productId?.userId?.firstName + " " +item?.orderId?.productDetails[0].productId?.userId?.lastName|| '--'}}</td>
                        <td *ngIf="serviceRole == 'TRANS' || serviceRole == 'FIELD'">{{item?.orderId?.userId?.firstName + " " +item?.orderId?.userId?.lastName|| '--'}}</td>
                        <td *ngIf="serviceRole == 'TRANS' || serviceRole == 'FIELD'">{{item?.orderId?.userId?.email|| '--'}}</td>
                        <!-- <td>{{item?.fieldEntity || 'NA'}}</td> -->
                        <td class="red-color">{{item?.orderId?.orderPrice ?  getPrice(item?.orderId?.orderPrice  |
                            currency:"ZAR":"R") : "--"}}</td>
                      
                        <td>{{getNoOfquantity(item?.orderId?.productDetails)}}</td>
                        <td>{{getDeliveryName(item?.orderId?.deliveryType)}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td>{{item?.requestStatus == 'ONTHEWAY' ? 'On the way' : item?.requestStatus || '--'}}</td>
                        <td>{{getRole(item?.userType) || '--'}}</td>
                        <td *ngIf="!isRejected">
                            <i class="fa fa-eye mpl10" aria-hidden="true" style="cursor: pointer;" 
                                [queryParams]="{_id : item?._id, status: item?.orderStatus,type : btn}"
                                [routerLink]="['/service-order-detail-view']" ></i></td>
                     
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10"
                [isWidth]="'55px'"></app-table-loader>

            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
    </div>



</div>
<select-service-type *ngIf="showService" [serviceList]="serviceList"></select-service-type>