<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container">
  <div class="" id="xyz">
    <form [formGroup]="addProduct" class="form mt-3 " style="width: 84%;">
      <div class="row">
        <h1 class="top-text col-md-12">Personal Details</h1>
      </div>
      <div class="row mb-4">
        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">First Name<span class="astict">*</span></label>
            <input type="text" placeholder="Enter first name" formControlName="fName" maxlength="60"
              (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('fName')?.hasError('required') && addProduct.get('fName')?.touched ">
              *First name is required.</div>

            <div class="for-validation text-danger"
              *ngIf="addProduct.get('fName')?.hasError('minlength') && addProduct.get('fName')?.dirty ">
              *Please enter minimum 2 character.</div>
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('fName')?.hasError('pattern') && addProduct.get('fName')?.dirty">
              *Please enter valid first name.

            </div>
          </div>
        </div>
        <div class="col-md-4 fna">

          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Last Name<span class="astict">*</span></label>
            <input type="text" id="form3Examplea8" placeholder=" Enter last name" formControlName="lName"
            maxlength="60" (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('lName')?.hasError('required') && addProduct.get('lName')?.touched ">
              *Last name is required.</div>

            <div class="for-validation text-danger"
              *ngIf="addProduct.get('lName')?.hasError('minlength') && addProduct.get('lName')?.dirty ">
              *Please enter minimum 2 character.</div>

            <div class="for-validation text-danger"
              *ngIf="addProduct.get('lName')?.hasError('pattern') && addProduct.get('lName')?.dirty">
              *Please enter valid last name.</div>
          </div>
        </div>
        <div class="col-md-4 fna">
          <label class="form-label Eadd">Mobile Number<span class="astict">*</span></label>
          <div class="">
            <div class="input-group">
              <div class="input-group-prepend ">
                <span class="asd3"> 
                  <input list="browsers" class="form-control email-pass-pro ddd"
                    formControlName="countryCode" name="browser"
                    id="browser">
                  
                  </span>
                <datalist id="browsers">
                  <option *ngFor="let code of datas" value="{{code?.phonecode}}">{{code?.flag}}
                    {{code?.isoCode}} </option>
                </datalist>
                <input type="text" class="fbb" (keypress)="preventSpace($event)"
                  (keypress)="NumOnly($event)"
                  formControlName="mobileNumber" placeholder="Enter mobile number" maxlength="15" class="form-control-small form-control">
              </div>
              <div class="for-validation text-danger"
                *ngIf="addProduct.get('mobileNumber').hasError('required') &&  addProduct.get('mobileNumber').touched"
                class="text-danger" padding>*Mobile number is required.</div>
              <div class="for-validation text-danger"
                *ngIf="addProduct.get('mobileNumber').hasError('pattern') &&  addProduct.get('mobileNumber').touched"
                class="text-danger" padding>*Invalid mobile number.</div>
              <div class="for-validation text-danger isRed"
                *ngIf="addProduct.get('mobileNumber').hasError('minlength') || addProduct.get('mobileNumber').hasError('maxlength')"
                class="text-danger" padding>*Please enter valid number between 8-15 digit.
              </div>
           
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Email<span class="astict">*</span></label>
            <input type="text" id="addr" placeholder="Enter your email " formControlName="email" maxlength="100"
              (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('email')?.hasError('required') && addProduct.get('email')?.touched ">
              *Email is required.</div>
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('email')?.hasError('pattern') && addProduct.get('email')?.dirty ">
              *Please enter valid email.</div>
          </div>
        </div>
      </div>



      <div class="row mt-5">
        <h1 class="top-text col-md-12">Address Details</h1>
      </div>
      <!-- name  -->
      <div class="row mb-4">
        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Address Line 1<span class="astict">*</span></label>
            <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
              (onAddressChange)="handleAddressChange($event)" id="addr" placeholder="Enter address"
              formControlName="address1"  maxlength="350" (keypress)="preventSpace($event)" class="form-control form-control-small" />
            <div class="for-validation text-danger"
              *ngIf="addProduct.get('address1')?.hasError('required') && addProduct.get('address1')?.touched ">
              *Address1 is required.</div>

            <!-- <div class="for-validation text-danger"
              *ngIf="!addProduct.get('address1')?.hasError('required') && addProduct.get('address1')?.touched && is_addressInValid">
              *Please enter valid address.</div> -->
          </div>
        </div>
        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Address Line 2</label>
            <input type="text" id="addr" placeholder="Enter your address2 " formControlName="address2" maxlength="100"
              (keypress)="preventSpace($event)" class="form-control form-control-small" />
              <!-- <div class="for-validation text-danger"
              *ngIf="addProduct.get('address2')?.hasError('required') && addProduct.get('address2')?.touched ">
              *Address2 is required.</div> -->

          </div>
        </div>


        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">Country<span class="astict">*</span></label>
            <select class="form-control email-pass-pro" id="asd3" formControlName="country"
              (change)="getStateList($event)">
              <option value="" selected style="text-align: start;">Select your country
              </option>
              <option *ngFor="let item of countryList" [value]="item.isoCode">
                {{item?.name}}</option>
            </select>
            <div class="for-validation text-danger"
            *ngIf="addProduct.get('country')?.hasError('required') && addProduct.get('country')?.touched ">
            *Country is required.</div>
   
          </div>
        </div>

      </div>

      <div class="row mt-4">

        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">State<span class="astict">*</span></label>
            <select class="form-control email-pass-pro" id="asd3" formControlName="state"
              (change)="getCityList($event)">
              <option value="" selected>Select your state</option>
              <option *ngFor="let item of stateList" [value]="item.isoCode">{{item?.name}}
              </option>
            </select>
            <div class="for-validation text-danger"
            *ngIf="addProduct.get('state')?.hasError('required') && addProduct.get('state')?.touched ">
            *State is required.</div>
          </div>
        </div>
        <div class="col-md-4 fna">
          <div class="form-outline form-white">
            <label class="form-label vantiy" for="form3Examplea8">City<span class="astict">*</span></label>
            <select class="form-control email-pass-pro" id="asd3" formControlName="city">
              <option value="" selected>Select your city</option>
              <option *ngFor="let item of cityList" [value]="item.name">{{item?.name}}
              </option>
            </select>
            <div class="for-validation text-danger"
            *ngIf="addProduct.get('city')?.hasError('required') && addProduct.get('city')?.touched ">
            *City is required.</div>

          </div>
        </div>
        <div class="col-md-4 ">

          <div class="form-outline form-white editzip">
            <label class="vantiy" for="form3Examplea8">Zip Code<span class="astict">*</span></label>
            <input type="text" id="asd" formControlName="zipCode" placeholder=" Enter zip code"
              (keypress)="preventSpace($event)"  class="form-control email-pass-pro"
              maxlength="8" />
            <div class="for-validation text-danger mt-1 mb-1"
              *ngIf="addProduct.get('zipCode')?.hasError('required') && addProduct.get('zipCode')?.touched">
              *Zip code is required.</div>
              <div class="for-validation text-danger"
              *ngIf="addProduct.get('zipCode')?.hasError('pattern') && addProduct.get('zipCode')?.dirty">
              *Please enter valid zipcode.

            </div>

          </div>
        </div>
      </div>
      <div class="ml-0 mt-5 mb-4">
        <button class="btn btn-outline-success mr-3 back-button back-can" type="submit"
          (click)="commonService.backLocation()">Cancel</button>
        <button [disabled]="addProduct.invalid" class="btn btn-outline-success my-2 my-sm-0 back-button" type="submit"
        (click)="this.id ?  editProfileDetail() : addAddress()">{{this.id ? 'Update' : 'Add Address'}}</button>
      </div>
    </form>

  </div>
</div>