<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mt-4 mb-5 topfooter">

  <div class="">


    <div class="row mt-3 mb-5">
      <div class="col-md-7 mt-4">
        <div class="row categoryimageView">
          <span>
            <img class="image-custom qwqwqw" [hidden]="!imageLoaded" (load)="onImageLoad()" [src]="imgurl" alt="" />
            <ngx-skeleton-loader *ngIf="!imageLoaded" [theme]="{
                width: '670px',
                height: '405px',
                position: 'relative'
              }" count="1"></ngx-skeleton-loader>
            <span class="heart" *ngIf="isLoggedIn" (click)="addWishlist(productDetails?._id)">
              <i class="fa fa-heart-o" *ngIf="productDetails?.isLike == false"></i>
              <i class="fa fa-heart" style="color: red" *ngIf="productDetails?.isLike == true"></i>
            </span>
            <span class="heart" *ngIf="!isLoggedIn" (click)="addBufferWishlist(productDetails?._id)">
              <i class="fa fa-heart-o" *ngIf="productDetails?.isLike == false"></i>
              <i class="fa fa-heart" style="color: red" *ngIf="productDetails?.isLike == true"></i>
            </span>
          </span>
        </div>
        <div class="d-flex mt-4" [ngClass]="productDetails?.productImage > 2 ? 'justify-content-between' : ''" *ngIf="productDetails?.productImage?.length > 1">
          <div *ngFor="let item of productDetails?.productImage ; let i = index" class="">
            <img class="custom-img h-100 change-img" style="cursor: pointer" [src]="item"
              (click)="changeProductimage(item)" />
          </div>
        </div>
      </div>
      <div class="col-md-5 p-0 digitalartBox">
        <div class="row">
          <div class="col-12 digitalartconBox">
            <p class="prdct-home">{{ productDetails?.productName }}</p>
          </div>
          <div class="col-12 digitalartconBox">
            <p class="para-text-common mb-0 digitalartconBox">
              Product ID : {{ productDetails?._id }}
            </p>
          </div>
          <div class="col-6 digitalartconBox">
            <div class="row justify-content-between" style="margin-right: 18px">
              <span [ngClass]="{ crossPrice: productDetails?.isDealActive }">
                {{
                mrp
                ? getPrice(mrp | currency : "ZAR" : "R")
                : productDetails?.priceSizeDetails[0]?.price &&
                getPrice(
                productDetails?.priceSizeDetails[0]?.price
                | currency : "ZAR" : "R"
                )
                }}
              </span>
              <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                {{
                afterDiscountPrice
                ? getPrice(afterDiscountPrice | currency : "ZAR" : "R")
                : getPrice(afterDiscountPrice | currency : "ZAR" : "R")
                }}
              </span>
              <span *ngIf="productDetails?.isDealActive" class="priceTestPColorD">
                {{ productDetails?.dealDiscount }}% off
              </span>
            </div>
          </div>
          <div class="bordernone col-12 mt-2 mb-2">
            <img src="assets/latest/veriticalline.svg" style="height: 2px; width: 100%" alt="" />
          </div>
        </div>
        <ng-container *ngIf="!isLoading">
          <app-table-loader></app-table-loader>
        </ng-container>
        <ng-container *ngIf="isLoading">
          <div class="" style="margin-top: 1em">
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Product Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{ productDetails?.productName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Category Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{ productDetails?.categoryId.categoryName }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Quantity Available</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{
                  isOutofStock(
                  quantity,
                  productDetails?.priceSizeDetails[0]?.quantity
                  )
                  }}
                </p>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Unit</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{ productDetails?.priceSizeDetails[0]?.unit }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Size/Value</p>
              </div>
              <div class="">:</div>
              <div class="col-7 flex-data">
                <p
                  class="pid-1 new-loop-para"
                  *ngFor="let item of productDetails?.priceSizeDetails"
                  (click)="
                    price(
                      item?.price,
                      item?.quantity,
                      item?.value,
                      item?.id,
                      item?.unit
                    )
                  "
                  [ngClass]="{ active: value == item?.value }"
                >
                  {{ item?.value }}
                </p>
              </div>
            </div> -->
            <!-- <div class="row" *ngIf="productDetails?.priceSizeDetails[0]?.unit === 'other'">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Unit</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">
                  {{ productDetails?.priceSizeDetails[0]?.unit }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Size/Value</p>
              </div>
              <div class="">:</div>
              <div class="col-7 flex-data">
                <p
                  class="pid-1 new-loop-para"
                  *ngFor="let item of productDetails?.priceSizeDetails"
                  (click)="price(item?.price, item?.quantity, item?.value, item?.id, item?.unit)"
                  [ngClass]="{ active: value == item?.value }"
                >
                  {{ item?.value }}
                </p>
              </div>
            </div> -->
            <div class="row">
              <div class="col-4 pr-0 digitalartconBox">
                <p class="pid">Size/Value</p>
              </div>
              <div class="">:</div>
              <div class="col-7 flex-data">
                <!-- <ng-container *ngIf="productDetails?.priceSizeDetails[0]?.unit === 'other'">
                  Show the "Unit" row separately when the unit is "other"
                  <div class="row">
                    <div class="col-4 pr-0 digitalartconBox">
                      <p class="pid">Unit</p>
                    </div>
                    <div class="">:</div>
                    <div class="col-7">
                      <p class="pid-1">
                        {{ productDetails?.priceSizeDetails[0]?.unit }}
                      </p>
                    </div>
                  </div>
                </ng-container> -->

                <!-- Show the value in the "Size/Value" row -->
                <ng-container *ngFor="let item of productDetails?.priceSizeDetails">
                  <p class="pid-1 new-loop-para"
                    (click)="price(item?.price, item?.quantity, item?.value, item?.id, item?.unit)"
                    [ngClass]="{ active: value == item?.value }">
                    {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                  </p>
                </ng-container>
              </div>
            </div>


          </div>

          <div class="mt-4 pt-3 descriptionBox">
            <span class="d-flex justify-content-between">
              <h1 class="text-center desc">Description</h1>
            </span>
            <p style="word-break: break-word" class="text-left descrip ml-3"
              [innerHTML]="rawText(productDetails?.description)">
              <!-- {{  }} -->
            </p>
          </div>

          <div class="ml-3 mt-3 addtocartBox">
            <button class="add-cart" *ngIf="isLoggedIn && quantity == 0" disabled>
              Out of stock
            </button>
            <button class="add-cart" *ngIf="isLoggedIn && quantity > 0 && flag" (click)="addToCart()">
              Add to Cart
            </button>
            <button class="add-cart" *ngIf="isLoggedIn && quantity > 0 && !flag" style="opacity: 0.75;">
              Loading..
            </button>
            <button class="add-cart" *ngIf="!isLoggedIn" (click)="showMessage()" >
              Add to Cart
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- service slider -->
<div class="container mt-5 pt-5 likeBox" *ngIf="iaAPILoaded && serviceData?.length">
  <!-- <hr class="mt-3"> -->
  <div class="d-flex justify-content-start">
    <h1 class="heading-text-cat" style="color: #000000 !important; font-size: 22px !important">
      YOU MAY ALSO LIKE
    </h1>
  </div>
  <div class="items mt-4">
    <div class="slick_slider">
      <div class="service_slider slider d-flex slider-customize">

          <ng-container *ngIf="serviceData?.length">
            <ng-container *ngFor="let data of serviceData; let i = index">
              <div *ngIf="productDetails._id !== data._id">
                <product-card [data]="data" (on_Click)="viewProducts(data?._id)"></product-card>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!serviceData?.length">

            <div >
              <ng-container *ngFor="let data of length(5)">
                <product-card [data]="data" width="228px" height="300px"></product-card>
              </ng-container>
            </div>
          </ng-container>



      </div>
    </div>


  </div>
</div>

<div class="container mt-5 pt-5 likeBox" *ngIf="!iaAPILoaded">
  <!-- <hr class="mt-3"> -->
  <div class="d-flex justify-content-start">
    <h1 class="heading-text-cat" style="color: #000000 !important; font-size: 22px !important">
      YOU MAY ALSO LIKE
    </h1>
  </div>
  <div class="items mt-4">




      <div class="d-flex flex-wrap justify-content-between">
        <ng-container *ngFor="let data of length(5)">
          <product-card [data]="data" width="228px" height="300px"></product-card>
        </ng-container>
      </div>

  </div>
</div>
