import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

declare var $: any
@Component({
  selector: 'app-service-order-detail-view',
  templateUrl: './service-order-detail-view.component.html',
  styleUrls: ['./service-order-detail-view.component.scss']
})
export class ServiceOrderDetailViewComponent implements OnInit {
  @ViewChild('button') button: ElementRef;
  @ViewChild('loader') loader: ElementRef;
  headingName: any = 'Service Details'
  linkerRouteItems: any = [
    { name: 'Home', route: '/service-order-detail' },
    { name: 'Account', route: '/customer-profile' },
    { name: 'Order History', route: '/service-order-detail' },
    { name: 'View', route: '/service-order-detail-view' }
  ]
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  userId: any;
  productDetails: any;
  serviceDetail: any
  productId: any;
  serviceImage: any
  orderStatus: any
  serviceRole: string;
  type: string
  navDetail: { text: string; navLat: any; navLong: any; };
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.orderStatus = res.status;
      this.type = res.type
    })
  }

  ngOnInit(): void {
    this.serviceRole = localStorage.getItem('serviceRole')
    if (this.serviceRole == 'SERVICE') {

      this.serviceOrderList();
      this.txnIdView();
    }
    else if (this.serviceRole != 'SERVICE') {
      this.getpickupOrderDeltail()
      this.linkerRouteItems = [
        { name: 'Home', route: '/service-order-detail' },

        { name: 'View', route: '/service-order-detail-view' }
      ]
    }
  }







  serviceOrderList() {
    let url = `v2/service/viewOrder?orderId=${this._id}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.productDetails = res.result.serviceDetails
        this.serviceImage = res.result.serviceDetails[0]
        this.serviceDetail = res.result
        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  updateServiceOrderStatus(status) {
    let url = `v2/service/updateServiceStatus`
    let data = {
      orderId : this._id,
      status : status
    }
    this.commonService.showSpinner()
    this.commonService.postApi(url,data, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.serviceOrderList()
        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  orderResultDetail: any

  getpickupOrderDeltail() {
    let url = `deliveryOrders/viewAssignedOrder?_id=${this._id}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.searching = true
        this.orderResultDetail = res.result
        this.deliveryDriverDetail = res.result.deliveryDriver

        this.serviceRole = res.result.userType == 'PICKUP_PARTNER' ? 'PICKUP' : res.result.userType == 'FIELD_ENTITY' ? 'FIELD' :
        res.result.userType == 'DELIVERY_PARTNER' ? 'DELIVERY' : 'SERVICE'
        
        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }


  showModal = false
  showModal2 = false
  showModal3 = false
  retailerId: any
  markAsDelivered(e, flag?) {
    this.retailerId = e
    let fieldEntityId = this.orderResultDetail.filedEntity.assignedUser._id
    if (flag) {
      fieldEntityId = this.orderResultDetail.orderId.userId._id
    }
    let url = `deliveryOrders/sendOtp?_id=${this._id}&retailerId=${fieldEntityId}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        if (!e && !flag) {
          this.showModal2 = true
        }
        else if (!e && flag) {
          this.showModal3 = true
        }
        else {

          this.showModal = true
        }
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  searching: boolean = false
  outForDelivered() {

    let url = `deliveryOrders/outForDelivery?_id=${this._id}`
    this.commonService.showSpinner()
    this.searching = false
    $('#driverSearchingModal').modal({ backdrop: 'static' });
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        // this.router.navigate(['/service-order-detail'])
      
        this.getpickupOrderDeltail()
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  deliveryDriverDetail
  
  reassiginDelivery() {

    let url = `deliveryOrders/reAssignDelivery?_id=${this._id}`
    this.commonService.showSpinner()
    $('#driverSearchingModal').modal({ backdrop: 'static' });

    this.searching = false
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        // this.router.navigate(['/service-order-detail'])
      

        this.getpickupOrderDeltail()
        this.commonService.hideSpinner()
       
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }
  navigateModal = false

  navLat : any
  navLong : any
  navText : any

  onSumbitOtp(e) {

    let fieldEntityId = this.orderResultDetail.filedEntity.assignedUser._id

    let url = `deliveryOrders/verifyOtp?_id=${this._id}&retailerId=${this.retailerId}&fieldEntityId=${fieldEntityId}`
    this.commonService.showSpinner()
    this.commonService.postApi(url, { otp: e }, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.showModal = false
    
        // // let open = confirm('Start navigate to field Entity?')
        // if (open) {
         
        //   // this.openGoogleMapsWithRouting(coordinates[1], coordinates[0])
        // }
        // else {
          this.router.navigate(['/service-order-detail'])
        // }
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  onSumbitOtp2(e) {

    let fieldEntityId = this.orderResultDetail.filedEntity.assignedUser._id

    let url = `deliveryOrders/verifyOtp?_id=${this._id}&fieldEntityId=${fieldEntityId}`
    this.commonService.showSpinner()
    this.commonService.postApi(url, { otp: e }, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.showModal2 = false

        this.navigateModal = true
        let coordinates = res.result.storeLocation.coordinates
      

       this.navDetail = {
        text : 'Start navigate to customer location?',
        navLat : coordinates[1],
        navLong : coordinates[0],

      }


        // let open = confirm('Start navigate to customer location?')
        // this.router.navigate(['/service-order-detail'])
        // if (open) {
        //   let coordinates = res.result.storeLocation.coordinates
        //   this.openGoogleMapsWithRouting(coordinates[1], coordinates[0])
        // }
        // else {
        //   this.router.navigate(['/service-order-detail'])
        // }

        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }
  onSumbitOtp3(e) {

    let fieldEntityId = this.orderResultDetail.orderId.userId._id

    let url = `deliveryOrders/verifyOtp?_id=${this._id}&customerId=${fieldEntityId}`
    this.commonService.showSpinner()
    this.commonService.postApi(url, { otp: e }, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.showModal3 = false
        this.router.navigate(['/service-order-detail'])

        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  txnId: any
  txnIdView() {
    let url = `order/viewOrderTransaction?orderId=${this._id}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.txnId = res.result
        // this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  message: any

  flag: boolean = false
  rejectOrder() {
    let url = `deliveryOrders/rejectOrder?_id=${this._id}`
    const data = {
      rejectedReason: this.reason
    }
    this.flag = true
    this.commonService.showSpinner()
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      this.flag = false
      if (res['responseCode'] == 200) {
        this.router.navigate(['/service-order-detail'])
        $('#rejectModal').modal('hide')


        this.commonService.hideSpinner()
      }


    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
      if (err.responseCode == 404) {
        this.commonService.hideSpinner()
        this.message = err.responseMessage
        $('#rejectModal').modal('hide')
        $('#rejectCancel').modal('show')

      }
    })
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {
    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
  }
  imgSrc: any
  productImage: any
  serviceData: any = []
  openModal: boolean = false
  openModal2: boolean = false
  serviceOrderId: any
  openModalView(e) {
    this.serviceOrderId = e
    this.openModal = true
  }
 


  navigateBack(){
     this.router.navigate(['/service-order-detail'])
  }
  onProceed(retailerId) {
    this.router.navigate(['/product-review'], { queryParams: { _id: this._id, retailerId: retailerId } })
  }
  openGoogleMapsWithRouting(lat, long) {
    var origin = "Your Current Location"; // You can replace this with your actual origin address or coordinates
    var destination = lat + "," + long;

    var mapsUrl = "https://www.google.com/maps/dir/?api=1&origin=" + encodeURIComponent(origin) + "&destination=" + encodeURIComponent(destination);

    window.open(mapsUrl, '_blank');
  }
  reason: string = ""
  openRejectModal() {
    $('#rejectModal').modal('show')
  }
  getDeliveryName(e: string) {
    let deliveryOption = e == 'SUNDAY' ? 'Sunday' :
      e == 'SAMEDAY' ? 'Sameday' :
        e == 'BUISNESSDAY' ? 'Next business day' :
          e == 'SATURDAY' ? 'Saturday' : 'Standard'

    return deliveryOption

  }
  closeModal(){
    // this.searching = false
    $('#driverSearchingModal').modal('hide');

  }
}


