import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit {
  @Input() data : any
  @Output() on_Click = new EventEmitter
  serviceRole: string;

  constructor() { 
    this.serviceRole = localStorage.getItem('serviceRole')
  }

  ngOnInit(): void {
  }
  navigateToViewProduct(e){
    this.on_Click.emit(e)
  }

}
