import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
// import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { HttpResInterceptor } from './interceptor/http-res.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './pages/sharing/header/header.component';
import { FooterComponent } from './pages/sharing/footer/footer.component';
import { LoginCustomerComponent } from './pages/customer/login-customer/login-customer.component';
import { SignupCustomerComponent } from './pages/customer/signup-customer/signup-customer.component';
import { BusinessDetailCustomerComponent } from './pages/customer/business-detail-customer/business-detail-customer.component';
import { UploadDocumentCustomerComponent } from './pages/customer/upload-document-customer/upload-document-customer.component';
import { ForgotCustomerComponent } from './pages/customer/forgot-customer/forgot-customer.component';
import { ResetCustomerComponent } from './pages/customer/reset-customer/reset-customer.component';
import { ChangePasswordCustomerComponent } from './pages/customer/change-password-customer/change-password-customer.component';
import { OtpVerificationCustomerComponent } from './pages/customer/otp-verification-customer/otp-verification-customer.component';
import { SearchProductComponent } from './pages/search-product/search-product.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MaterialModule } from './material/material.module';
import { LoginServiceProviderComponent } from './pages/service-provider/auth/login-service-provider/login-service-provider.component';
import { SignupServiceProviderComponent } from './pages/service-provider/auth/signup-service-provider/signup-service-provider.component';
import { BusinessDetailsServiceProviderComponent } from './pages/service-provider/auth/business-details-service-provider/business-details-service-provider.component';
import { UploadDocumentServiceProviderComponent } from './pages/service-provider/auth/upload-document-service-provider/upload-document-service-provider.component';
import { ForgotServiceProviderComponent } from './pages/service-provider/auth/forgot-service-provider/forgot-service-provider.component';
import { ResetServiceProviderComponent } from './pages/service-provider/auth/reset-service-provider/reset-service-provider.component';
import { OtpVerificationServiceProviderComponent } from './pages/service-provider/auth/otp-verification-service-provider/otp-verification-service-provider.component';
import { ChangePasswordServiceProviderComponent } from './pages/service-provider/auth/change-password-service-provider/change-password-service-provider.component';
import { HomepageServiceProviderComponent } from './pages/service-provider/auth/homepage-service-provider/homepage-service-provider.component';
import { CustomerProfileComponent } from './pages/customer/customer-profile/customer-profile.component';
import { OtpVerificationResetPasswordCustomerComponent } from './pages/customer/otp-verification-reset-password-customer/otp-verification-reset-password-customer.component';
import { FaqComponent } from './pages/static-content-management/faq/faq.component';
import { AboutusComponent } from './pages/static-content-management/aboutus/aboutus.component';
import { ContactusComponent } from './pages/static-content-management/contactus/contactus.component';
import { TermsAndConditionComponent } from './pages/static-content-management/terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './pages/static-content-management/privacy-policy/privacy-policy.component';
import { CustomerEditProfileComponent } from './pages/customer/customer-edit-profile/customer-edit-profile.component';
import { ProductViewCustomerComponent } from './pages/customer/product-view-customer/product-view-customer.component';
import { LoginRetailerComponent } from './pages/retailer/auth/login-retailer/login-retailer.component';
import { OtpVerificationRetailerComponent } from './pages/retailer/auth/otp-verification-retailer/otp-verification-retailer.component';
import { ServiceProviderServiceComponent } from './pages/service-provider/service-provider-service/service-provider-service.component';
import { ServiceOrderDetailComponent } from './pages/service-provider/service-order-detail/service-order-detail.component';
import { ServiceOrderDetailViewComponent } from './pages/service-provider/service-order-detail-view/service-order-detail-view.component';
import { CustomerWishlistComponent } from './pages/customer/customer-wishlist/customer-wishlist.component';
import { CustomerAddToCartComponent } from './pages/customer/customer-add-to-cart/customer-add-to-cart.component';
import { SignupRetailerComponent } from './pages/retailer/auth/signup-retailer/signup-retailer.component';
import { ForgotPasswordRetailerComponent } from './pages/retailer/auth/forgot-password-retailer/forgot-password-retailer.component';
import { ResetPasswordRetailerComponent } from './pages/retailer/auth/reset-password-retailer/reset-password-retailer.component';
import { BusinessDetailsRetailerComponent } from './pages/retailer/auth/business-details-retailer/business-details-retailer.component';
import { UploadDocumentRetailerComponent } from './pages/retailer/auth/upload-document-retailer/upload-document-retailer.component';
import { CustomerProductAddressComponent } from './pages/customer/customer-product-address/customer-product-address.component';
import { AddCustomerAddressComponent } from './pages/customer/add-customer-address/add-customer-address.component';
import { EditCustomerAddressComponent } from './pages/customer/edit-customer-address/edit-customer-address.component';
import { CustomerOrderOverviewComponent } from './pages/customer/customer-order-overview/customer-order-overview.component';
import { SuccesCustomerPaymentComponent } from './pages/customer/succes-customer-payment/succes-customer-payment.component';
import { CancelCustomerPaymentComponent } from './pages/customer/cancel-customer-payment/cancel-customer-payment.component';
import { ErrorCustomerPaymentComponent } from './pages/customer/error-customer-payment/error-customer-payment.component';
import { CustomerServiceListComponent } from './pages/customer/customer-service-list/customer-service-list.component';
import { ServiceOrderOtpVerificationComponent } from './pages/service-provider/service-order-otp-verification/service-order-otp-verification.component';
import { ServiceProviderRequestServicesComponent } from './pages/service-provider/service-provider-request-services/service-provider-request-services.component';
import { ServiceProviderRequestServicesViewComponent } from './pages/service-provider/service-provider-request-services-view/service-provider-request-services-view.component';
import { ServiceProviderPaymentFromCustomersComponent } from './pages/service-provider/service-provider-payment-from-customers/service-provider-payment-from-customers.component';
import { CustomerServiceAvailableComponent } from './pages/customer/customer-service-available/customer-service-available.component';

import { CustomerServiceAvailableAddComponent } from './pages/customer/customer-service-available-add/customer-service-available-add.component';
import { CustomerServiceAddQuantityComponent } from './pages/customer/customer-service-add-quantity/customer-service-add-quantity.component';

import { ServiceProviderDealsToCustomerComponent } from './pages/service-provider/service-provider-deals-to-customer/service-provider-deals-to-customer.component';
import { ServiceProviderAvailableDealsComponent } from './pages/service-provider/service-provider-available-deals/service-provider-available-deals.component';
import { ServiceProviderAddDealsComponent } from './pages/service-provider/service-provider-add-deals/service-provider-add-deals.component';
import { CustomerCategoryListComponent } from './pages/customer/customer-category-list/customer-category-list.component';
import { CustomerCategoryViewListComponent } from './pages/customer/customer-category-view-list/customer-category-view-list.component';
import { CustomerCategoryParticularViewComponent } from './pages/customer/customer-category-particular-view/customer-category-particular-view.component';
import { CustomerDealsOnProductListComponent } from './pages/customer/customer-deals/customer-deals-on-product-list/customer-deals-on-product-list.component';
import { ServiceProviderListDealsComponent } from './pages/service-provider/service-provider-list-deals/service-provider-list-deals.component';
import { CustomerProductListComponent } from './pages/customer/customer-product-list/customer-product-list.component';
import { CustomerDealsProductViewComponent } from './pages/customer/customer-deals/customer-deals-product-view/customer-deals-product-view.component';
import { CustomerRequestListComponent } from './pages/customer/customer-deals/customer-request-list/customer-request-list.component';
import { CustomerDealsServiceListComponent } from './pages/customer/customer-deals/customer-deals-service-list/customer-deals-service-list.component';
import { CustomerDealsServiceViewComponent } from './pages/customer/customer-deals/customer-deals-service-view/customer-deals-service-view.component';
import { CustomerDealsServiceViewParticularComponent } from './pages/customer/customer-deals/customer-deals-service-view-particular/customer-deals-service-view-particular.component';
import { HomepageRetailerComponent } from './pages/retailer/homepage-retailer/homepage-retailer.component';
import { ServiceProviderViewDealsComponent } from './pages/service-provider/service-provider-view-deals/service-provider-view-deals.component';
import { ServiceProviderEditDealsComponent } from './pages/service-provider/service-provider-edit-deals/service-provider-edit-deals.component';
import { OrderHistoryCustomerComponent } from './pages/customer/customer-history/order-history-customer/order-history-customer.component';
import { CustomerProductEnquiryComponent } from './pages/customer/customer-history/customer-product-enquiry/customer-product-enquiry.component';
import { OrderHistoryViewCustomerComponent } from './pages/customer/customer-history/order-history-view-customer/order-history-view-customer.component';
import { CustomerAddressListComponent } from './pages/customer/customer-history/customer-address-list/customer-address-list.component';
import { ReatilerOrderListComponent } from './pages/retailer/order-management-retailer/reatiler-order-list/reatiler-order-list.component';
import { ReatilerCategoryViewListComponent } from './pages/retailer/order-management-retailer/reatiler-category-view-list/reatiler-category-view-list.component';
import { RetailerOrderListViewComponent } from './pages/retailer/order-management-retailer/retailer-order-list-view/retailer-order-list-view.component';
import { RetailerOrderOtpVerificationComponent } from './pages/retailer/order-management-retailer/retailer-order-otp-verification/retailer-order-otp-verification.component';
import { RetailerProductListComponent } from './pages/retailer/product-management-retailer/retailer-product-list/retailer-product-list.component';
import { RetailerPaymentFromCustomerComponent } from './pages/retailer/order-management-retailer/retailer-payment-from-customer/retailer-payment-from-customer.component';
import { RetailerPaymentFromWholesalerComponent } from './pages/retailer/order-management-retailer/retailer-payment-from-wholesaler/retailer-payment-from-wholesaler.component';
import { RetailerResetPasswordOtpVerificationComponent } from './pages/retailer/auth/retailer-reset-password-otp-verification/retailer-reset-password-otp-verification.component';
import { RetailerAddProductComponent } from './pages/retailer/product-management-retailer/retailer-add-product/retailer-add-product.component';
import { RetailerEditProductComponent } from './pages/retailer/product-management-retailer/retailer-edit-product/retailer-edit-product.component';
import { RetailerViewProductComponent } from './pages/retailer/product-management-retailer/retailer-view-product/retailer-view-product.component';
import { RetailerDealListComponent } from './pages/retailer/deal-management-retailer/retailer-deal-list/retailer-deal-list.component';
import { RetailerAddDealsComponent } from './pages/retailer/deal-management-retailer/retailer-add-deals/retailer-add-deals.component';
import { RetailerEditDealsComponent } from './pages/retailer/deal-management-retailer/retailer-edit-deals/retailer-edit-deals.component';
import { RetailerViewDealsComponent } from './pages/retailer/deal-management-retailer/retailer-view-deals/retailer-view-deals.component';
import { DatePipe } from '@angular/common';
import { EditBusinessDetailsRetailerComponent } from './pages/retailer/edit-business-details-retailer/edit-business-details-retailer.component';
import { EditUploadDocumentRetailerComponent } from './pages/retailer/edit-upload-document-retailer/edit-upload-document-retailer.component';
import { EditBusinessDetailServiceComponent } from './pages/service-provider/auth/edit-business-detail-service/edit-business-detail-service.component';
import { EditUploadDocumentServiceComponent } from './pages/service-provider/auth/edit-upload-document-service/edit-upload-document-service.component';
import { BookingDataViewComponent } from './pages/customer/booking-data-view/booking-data-view.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { DealsCardComponent } from './components/deals-card/deals-card.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { ServiceCardCategoryComponent } from './components/service-card-category/service-card-category.component';
import { LinkerRouteComponent } from './linker-route/linker-route.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { MenuPanelComponent } from './components/menu-panel/menu-panel.component';
import { WishlistCardComponent } from './components/wishlist-card/wishlist-card.component';
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component';
import { CartLayoutComponent } from './components/cart-layout/cart-layout.component';
import { TableUiComponent } from './table-ui/table-ui.component';
import { MycartLayoutComponent } from './components/mycart-layout/mycart-layout.component';
import { PaymentLayoutComponent } from './components/payment-layout/payment-layout.component';
import { ServiceProviderDealLayoutComponent } from './components/service-provider-deal-layout/service-provider-deal-layout.component';
import { OtpModalComponent } from './component/otp-modal/otp-modal.component';
import { DealsLayoutServiceComponent } from './components/deals-layout-service/deals-layout-service.component';
import { CategoryLayoutHomeComponent } from './components/category-layout-home/category-layout-home.component';

import {LoginComponent} from './auth/login-customer/login-customer.component';
import { TableLoaderComponent } from './skeleton-loader/table-loader/table-loader.component';
import { SubCategoryFilterComponent } from './components/sub-category-filter/sub-category-filter.component';
import { SelectLoginCategoryComponent } from './pages/service-provider/select-login-category/select-login-category.component';
import { ProductReviewComponent } from './pages/service-provider/product-review/product-review.component';
import { SetFeeConfigurationComponent } from './pages/service-provider/set-fee-configuration/set-fee-configuration.component';
import { OrderTrackerComponent } from './pages/customer/customer-history/order-history-view-customer/order-tracker/order-tracker.component';
import { TransactionHistoryComponent } from './pages/sharing/transaction-history/transaction-history.component';
import { MapNavigationComponent } from './pages/service-provider/service-order-detail-view/map-navigation/map-navigation.component';
import { SelectServiceComponent } from './pages/service-provider/auth/select-service/select-service.component';
import { SelectServiceTypeComponent } from './pages/service-provider/select-login-category/select-service-type/select-service-type.component';
import { CustomerWalletComponent } from './pages/customer/customer-wallet/customer-wallet.component';
import { UpdatePopUpComponent } from './pages/sharing/update-pop-up/update-pop-up.component';
import { PaymentStatusComponent } from './pages/sharing/payment-status/payment-status.component';
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    //Auth 
    LoginComponent,

    FooterComponent,
    LoginCustomerComponent,
    SignupCustomerComponent,
    BusinessDetailCustomerComponent,
    UploadDocumentCustomerComponent,
    ForgotCustomerComponent,
    ResetCustomerComponent,
    ChangePasswordCustomerComponent,
    OtpVerificationCustomerComponent,
    SearchProductComponent,
    LoginServiceProviderComponent,
    SignupServiceProviderComponent,
    BusinessDetailsServiceProviderComponent,
    UploadDocumentServiceProviderComponent,
    ForgotServiceProviderComponent,
    ResetServiceProviderComponent,
    OtpVerificationServiceProviderComponent,
    ChangePasswordServiceProviderComponent,
    HomepageServiceProviderComponent,
    CustomerProfileComponent,
    OtpVerificationResetPasswordCustomerComponent,
    FaqComponent,
    AboutusComponent,
    ContactusComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    CustomerEditProfileComponent,
    ProductViewCustomerComponent,
    LoginRetailerComponent,
    SignupRetailerComponent,
    ForgotPasswordRetailerComponent,
    OtpVerificationRetailerComponent,
    ResetPasswordRetailerComponent,
    ServiceProviderServiceComponent,
    ServiceOrderDetailComponent,
    ServiceOrderDetailViewComponent,
    CustomerWishlistComponent,
    CustomerAddToCartComponent,
    BusinessDetailsRetailerComponent,
    UploadDocumentRetailerComponent,
    CustomerProductAddressComponent,
    AddCustomerAddressComponent,
    EditCustomerAddressComponent,
    CustomerOrderOverviewComponent,
    SuccesCustomerPaymentComponent,
    CancelCustomerPaymentComponent,
    ErrorCustomerPaymentComponent,
    CustomerServiceListComponent,
    ServiceOrderOtpVerificationComponent,
    CustomerServiceAvailableComponent,
    CustomerServiceAvailableAddComponent,
    ServiceProviderRequestServicesComponent,
    ServiceProviderRequestServicesViewComponent,
    ServiceProviderPaymentFromCustomersComponent,
    CustomerServiceAddQuantityComponent,
    CustomerServiceAvailableComponent,
    ServiceProviderDealsToCustomerComponent,
    ServiceProviderAvailableDealsComponent,
    ServiceProviderAddDealsComponent,
    CustomerCategoryListComponent,
    CustomerCategoryViewListComponent,
    CustomerCategoryParticularViewComponent,
    CustomerDealsOnProductListComponent,
    ServiceProviderListDealsComponent,
    CustomerProductListComponent,
    CustomerDealsProductViewComponent,
    CustomerRequestListComponent,
    CustomerDealsServiceListComponent,
    CustomerDealsServiceViewComponent,
    CustomerDealsServiceViewParticularComponent,
    HomepageRetailerComponent,
    ServiceProviderViewDealsComponent,
    ServiceProviderEditDealsComponent,
    OrderHistoryCustomerComponent,
    CustomerProductEnquiryComponent,
    OrderHistoryViewCustomerComponent,
    CustomerAddressListComponent,
    ReatilerOrderListComponent,
    ReatilerCategoryViewListComponent,
    RetailerOrderListViewComponent,
    RetailerOrderOtpVerificationComponent,
    RetailerProductListComponent,
    RetailerPaymentFromCustomerComponent,
    RetailerPaymentFromWholesalerComponent,
    RetailerResetPasswordOtpVerificationComponent,
    RetailerAddProductComponent,
    RetailerEditProductComponent,
    RetailerViewProductComponent,
    RetailerDealListComponent,
    RetailerAddDealsComponent,
    RetailerEditDealsComponent,
    RetailerViewDealsComponent,
    EditBusinessDetailsRetailerComponent,
    EditUploadDocumentRetailerComponent,
    EditBusinessDetailServiceComponent,
    EditUploadDocumentServiceComponent,
    BookingDataViewComponent,
    ProductCardComponent,
    DealsCardComponent,
    ServiceCardComponent,
    ServiceCardCategoryComponent,
    LinkerRouteComponent,
    AuthLayoutComponent,
    MenuPanelComponent,
    WishlistCardComponent,
    ProfileLayoutComponent,
    CartLayoutComponent,
    TableUiComponent,
    MycartLayoutComponent,
    PaymentLayoutComponent,
    ServiceProviderDealLayoutComponent,
    OtpModalComponent,
    DealsLayoutServiceComponent,
    CategoryLayoutHomeComponent,
    TableLoaderComponent,
    SubCategoryFilterComponent,
    SelectLoginCategoryComponent,
    ProductReviewComponent,
    SetFeeConfigurationComponent,
    OrderTrackerComponent,
    TransactionHistoryComponent,
    MapNavigationComponent,
    SelectServiceComponent,
    SelectServiceTypeComponent,
    CustomerWalletComponent,
    UpdatePopUpComponent,
    PaymentStatusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,

    // ToastNotificationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SimpleSmoothScrollModule,
    CarouselModule,
    GoogleMapsModule,
    GooglePlaceModule,
    NgxPaginationModule,
    NgxImageZoomModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        // Enabliong theme combination
        extendsFromRoot: true,
        // ... list of CSS theme attributes
        minHeight: '50px',
      },
    }),
    MaterialModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
