import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-reatiler-order-list',
  templateUrl: './reatiler-order-list.component.html',
  styleUrls: ['./reatiler-order-list.component.scss']
})
export class ReatilerOrderListComponent implements OnInit {
  btn: any = "Pending"
  headingName:any ='Order Management'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Order History',route:'/retailer-order-list'}
  ]
  orderList: any =[]
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  isLoading:boolean =false
  notFoundData:boolean =false
  constructor(public commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.serviceOrderList();
  }

  selectBtn(status) {
    this.btn = status;
    this.notFoundData =false
    if (this.btn == 'Pending') {
      this.serviceStatus = 'PENDING';
      this.serviceOrderList();
    } else {
      this.serviceStatus = 'DELEVERED';
      this.serviceOrderList();
    }
  }

  getPrice(e: any) {
    
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }

  serviceOrderList() {
    this.isLoading =true
    this.orderList =[]
    this.count = 0
    const data = {
      page: this.currentPage,
      limit: this.pageSize,
      orderType: 'PRODUCT',
      deliveredStatus: this.serviceStatus
    }
    this.commonService.postApi('order/v2/retailerOrderList', data, 1).subscribe((res: any) => {
      this.isLoading =false
      if (res['responseCode'] == 200) {
        this.commonService.hideSpinner()
        this.orderList = res.result.docs;
        if(res.result.docs.length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true
        }
        this.count = res.result.count
      }

    }, (err: any) => {
      this.notFoundData =true
      this.isLoading =false
      this.orderList = []
      if (err.responseCode == 409) {
        this.orderList = []
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  changePageNumber(page) {
    this.currentPage = page;
    this.serviceOrderList();
  }
}
