import { CommonService } from './../../../../provide/common.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';

@Component({
  selector: 'app-upload-document-service-provider',
  templateUrl: './upload-document-service-provider.component.html',
  styleUrls: ['./upload-document-service-provider.component.scss']
})
export class UploadDocumentServiceProviderComponent implements OnInit {
  serviceRole: string;
  signupTitle: string;
  userType: string;

  constructor(public authService: AuthService,public commonService: CommonService, private router: Router) { 
    this.serviceRole = localStorage.getItem('serviceRole')
    if(this.serviceRole == "PICKUP"){
      this.signupTitle  = "Signup As Pickup Partner"
      this.userType = "PICKUP_PARTNER"
    }
    else if(this.serviceRole == "DELIVERY"){
      this.signupTitle  = "Signup As Delivery Partner"
      this.userType = "DELIVERY_PARTNER"
      
    }
    else if(this.serviceRole == "FIELD"){
      this.signupTitle  = "Signup As Fulfilment"
      this.userType = "FIELD_ENTITY"
    }
    else{
      this.signupTitle  = "Signup As Service Provider"
      this.userType = "SERVICE_PROVIDER"
    }
  }

  imageUrl: any

  IncorporationForntUrl: any
  IncorporationBackUrl: any
  VatForntUrl: any
  VatBackUrl: any
  DirectorForntUrl: any
  DirectorBackUrl: any
  ConfirmationForntUrl: any
  ConfirmationBacktUrl: any
  DirectorIDDocumentUrl: any

  pickupDocumentVerification = [
    {
      name : "Vehicle Insurance",
      key : "vehicleInsureance",
      pdf:''
    },
    {
      name : "Tax Clearance Certificate",
      key : "taxClearanceCertificate",
      pdf:''
    },
    {
      name : "Business Registration (if applicable)",
      key : "businessRegistration",
      pdf:''
    },
    {
      name : "Criminal Record Check",
      key : "criminalRecordCheck",
      pdf:''
    },
    {
      name : "Proof of Address",
      key : "proofOfAddress",
      pdf:''
    },
    {
      name : "Work Permit (if applicable)",
      key : "workPermit",
      pdf:''
    },
    {
      name : "Health and Safety",
      key : "healthAndSafety",
      pdf:''
    },
  ]

  ngOnInit(): void {
    this.getDocumentList()
  }

getDocumentList(){
  let url = `user/documentList`
  this.commonService.getApi(url, 1).subscribe((res: any) => {
      
    if (res['responseCode'] == 200) {
      this.pickupDocumentVerification = res.result
    }

  }, err => {
    
    this.commonService.hideSpinner()
    this.commonService.errorToast(err.error['responseMessage'])
  })


}

  imgeUplode() {
    let data = {
      "businessDocumentUpload": {
        "cirtificationOfIncorporation": {
          "pdf": this.IncorporationForntUrl,
          // "backImage": "IncorporationBackUrl"
        },
        "directConsentForm": {
          "pdf": this.DirectorForntUrl,
          // "backImage": "DirectorBackUrl"
        },
        "directorId": {
          "pdf": this.DirectorIDDocumentUrl,

        },
        "VatRegConfirmationDocs": {
          "pdf": this.VatForntUrl,
          // "backImage": "VatBackUrl"
        },

        "bankConfirmationLetter": {
          "pdf": this.ConfirmationForntUrl,
          // "backImage": "ConfirmationBacktUrl"
        }

      },
      completeProfile: true,
      flag: 2,
    }
    this.commonService.showSpinner()
    this.commonService.postApi('user/fillDetails', data, 1).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        this.commonService.successToast('Thank you for Registration.Please wait for vetting and approval.')
        // localStorage.setItem('email', this.businessform.value.email)
        this.commonService.hideSpinner()
        // set current url to localstorage 
        this.router.navigate(['/login-service-provider']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {
      
      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })



  }



  imgeUplode2() {
    let obj = {}
    for(let item of this.pickupDocumentVerification){
      obj[item.key] = {
        pdf : item.pdf
      }
    }

      
    let data = {
      "businessDocumentUpload": obj,
      completeProfile: true,
      flag: 3,
    }

    for (const key in data.businessDocumentUpload) {
      if(!data.businessDocumentUpload[key].pdf){
        this.loading = false  
        return this.commonService.errorToast("Please upload all documents")
      }
    }
    console.log(data);
    
    
    this.commonService.showSpinner()

    this.commonService.postApi('user/fillDetails', data, 1).subscribe((res: any) => {
    this.loading = false  
      if (res['responseCode'] == 200) {
        this.commonService.successToast('Thank you for Registration.Please wait for vetting and approval.')
        // localStorage.setItem('email', this.businessform.value.email)
        this.commonService.hideSpinner()
        // set current url to localstorage 
        this.authService.logout()
        this.router.navigate(['/login-service-provider']);
        
        // this.router.navigate(['/set-fee-pickup-partner']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {
      
      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })



  }





  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-



  uploadImg(event: any, uplodeImage: any,index?): void {
    var img = event.target.files[0];
    const fileSize = img.size; // Size in bytes
    const fileSizeKB = fileSize / 1024; // Size in kilobytes (KB)
    const fileSizeMB = fileSizeKB / 1024; // Size in megabytes (MB)

    const fileType = String(img.type).split('/')[1];
    console.log(fileType);
    if(fileType == 'pdf'){
      if (fileSizeMB > 3) {
        var fileInput: any = document.getElementById('fileInput_'+index);
        fileInput.value = '';
        return this.commonService.errorToast("Please select a document that is 3 MB or smaller.")
      }
    }
    else{
      if (fileSizeMB > 2) {
        var fileInput: any = document.getElementById('fileInput_'+index);
        fileInput.value = '';
        return this.commonService.errorToast("Please select a document that is 3 MB or smaller.")
      }
    }
    this.uploadImageFunc(img, uplodeImage,index);
  }
  flag : boolean = false
  uploadImageFunc(img: any, uplodeImage: any,index?) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.flag = true
    this.commonService.postFormDataApi('user/uploadDocument', fb, 1).subscribe((res: any) => {
      this.flag = false
      if (res.responseCode == 200) {
        // this.imageUrl = res['result']['secure_url'];
        // this.imageUrl = res['result']

        if (uplodeImage == 'INCORPORATIONFORNT') {

          this.IncorporationForntUrl = res['result']['mediaUrl']
          // } else if (uplodeImage == 'INCORPORATIONBACK') {

          // this.IncorporationBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'VATFORNT') {
          this.VatForntUrl = res['result']['mediaUrl']

          // } else if (uplodeImage == 'VATBACK') {

          // this.VatBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'DIRECTORFORNT') {

          this.DirectorForntUrl = res['result']['mediaUrl']

          // } else if (uplodeImage == 'DIRECTORBACK') {
          // this.DirectorBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'DIRECTORDOCUMENT') {
          this.DirectorIDDocumentUrl = res['result']['mediaUrl']
        }
        else if (uplodeImage == 'CONFIRMATIONFORNT') {
          this.ConfirmationForntUrl = res['result']['mediaUrl']
        }
        else {
          // this.ConfirmationBacktUrl = res['result']['mediaUrl']
          this.pickupDocumentVerification[index].pdf = res['result']['mediaUrl']

        }
        this.commonService.hideSpinner();

      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

  goBack(){
    this.commonService.backLocation()
  }
  isPDF(e):boolean{
    if(String(e).toLowerCase().includes('storage')||
    String(e).toLowerCase().includes('vehicle') ||  String(e).toLowerCase().includes('driving')) {
      return false
    }
    return true
  }

  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }
}
