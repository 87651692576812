<auth-layout title="Upload Documentation" type="SIGNUP" subTitile="" sideImage="assets/latest/signup_retailer.svg">

    <div class="d-flex justify-content-between flex-wrap">
        <div class="w-100">
            <p class="Letter">Certificate Of Incorporation ( Business
                Registration Letter )</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->
                    <input id="file-upload" #fileInput1 type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                        (change)="uploadImg($event,'INCORPORATIONFORNT',1)">
                  

                </div>
            </div>

        </div>



        <div class="w-100">
            <p class="Letter">VAT Registration Confirmation ( If Applicable )</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->

                    <input id="file-upload" #fileInput2 type="file" accept=".pdf,.docx" class="form-control custom-file-input "
                        (change)="uploadImg($event,'VATFORNT',2)">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>




        <div class="w-100">
            <p class="Letter">Director Consent Form ( To Be Completed )</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->
                    <input id="file-upload" #fileInput3 type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                        (change)="uploadImg($event,'DIRECTORFORNT',3)">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>


        <div class="w-100">
            <p class="Letter">Director (s) ID Document</p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->
                    <input id="file-upload" #fileInput4 type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                        (change)="uploadImg($event,'DIRECTORDOCUMENT',4)">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>


        <div class="w-100">
            <p class="Letter">Bank Confirmation Letter </p>

            <div class=" textaligncenter">
                <div class="form-group jj ">
                    <!-- <label for="file-upload" class="custom-file-upload">
                    Upload Image
                </label> -->

                    <input id="file-upload" #fileInput5 type="file" accept=".pdf,.docx" class="form-control custom-file-input"
                        (change)="uploadImg($event,'CONFIRMATIONFORNT',5)">
                    <!-- <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt=""> -->

                </div>
            </div>

        </div>


        <div class="d-flex justify-content-center submit-div">
            <button class="btn btn-outline-success2 my-2 my-sm-0 mt-3" *ngIf="!loading" (click)="load();imgeUplode()"
                type="submit">Submit</button>
            <button class="btn btn-outline-success2 my-2 my-sm-0 mt-3 op05" *ngIf="loading" 
                type="submit">Loading...</button>
        </div>

    </div>

</auth-layout>