import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provide/common.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-add-customer-address',
  templateUrl: './add-customer-address.component.html',
  styleUrls: ['./add-customer-address.component.scss']
})
export class AddCustomerAddressComponent implements OnInit {
  addProduct!: FormGroup
  profileData: any = {}
  imageUrl: any;
  products: any;
  countryCode = new FormControl('+91');
  country: any = []
  state: any = []
  city: any = []
  isoCode: any
  govtDocument: any = "assets/lard.png";
  websiteUrl: any;
  socialLink: any;
  userType: any;
  firstName: any;
  lastName: any;
  // countryCode: any;
  mobileNumber: any;
  email: any;
  storeName: any;
  storeLocation: any
  faceBook: any;
  linkedIn: any;
  twitter: any;
  instagram: any;
  updateProfile: any;
  profilePic: any;

  lat: any;
  lng: any;
  // uplode image 

  addDynamicImageArray: any = []
  validationMessage: any; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  cityList: any;
  stateList: any;
  countryList: any;

  categoryId: any
  qtyValue: any
  userId: any
  categoryName: any
  headingName:any ='ADD ADDRESS'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    { name:'Account',route: '/customer-profile'},
    {name:'Address',route:'/customer-address-list'}
  ]
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private http: HttpClient, public commonService: CommonService, private router: Router) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.qtyValue = res.qnty
      this.categoryId = res.categoryId
      this.userId = res.userId
      this.categoryName = res.categoryName
    })
  }
  datas: any = []
  LatLongValue: any
  ngOnInit() {
    this.add();

    this.getCountryList();
    // this.getProfile();
    this.http.get("assets/countrycode.json").subscribe((data: any) => {
      this.products = data;
      this.datas = data.result

    })
    // this.getCountryList()

    // this.updateProfile();
    // this.editProfileDetail();  


    navigator.geolocation.getCurrentPosition(function (position) {
      // alert('allow');  
    }, function () {
      alert('You need to enable geolocation services in your browser to use this feature.');
    });
    this.getPosition().then(pos => {
      localStorage.setItem('location', JSON.stringify(pos))
    });


  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }


  // log and lat============
  Position: any
  getLocation() {

  }

  address: any
  options: any = []
  longitude: any;
  latitude: any;
  is_addressInValid: boolean = true
  public handleAddressChange(address: any) {
    if ('formatted_address' in address) {
      this.addProduct.patchValue({
        address: address.formatted_address
      })
      this.options = []
      this.is_addressInValid = false

      this.address = address.formatted_address

      this.longitude = address.geometry.location.lng()
      this.latitude = address.geometry.location.lat()
      this.options[0] = Number(address.geometry.location.lng())
      this.options[1] = Number(address.geometry.location.lat())
    }
    else {
      this.is_addressInValid = true

    }



  }
  // form validation

  add() {
    this.addProduct = this.fb.group({
      // 'VJuicer': new FormControl('', [
      //   Validators.required,
      //   Validators.maxLength(32),

      // ]),
      'fName': new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
        Validators.minLength(2)
      ]),
      'lName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),
      'email': new FormControl('', [
        Validators.required,
        // Validators.pattern("^[a-z0-9-]+@[a-z-]+\\.[a-z]{2,4}$")
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
      'Phone': new FormControl('', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      ]),
      'address': new FormControl('', [
        // Validators.required,
        Validators.maxLength(32),
      ]),
      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),
      'address1': new FormControl('', Validators.required),
      'address2': new FormControl(''),

      'location': new FormControl('', [
        // Validators.required

      ]),

      countryCode: new FormControl('+91', [
        Validators.required
      ]),
      // 'url': new FormControl('', [
      //   Validators.required,
      //   Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
      // ]),


      'country': new FormControl('', [
        Validators.required,

      ]),

      'state': new FormControl('', [

      ]),
      'city': new FormControl('', [

      ]),

      'zipCode': new FormControl('', [

      ]),

    });


  }

  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  valueLatLong: any

  getProfile() {
    this.commonService.showSpinner();
    this.commonService.getApi("user/myProfile", 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.profileData = res["result"];
        this.profileData.country ? this.getStateList(this.profileData.countryIsoCode) : '';
        this.profileData.state ? this.getCityList(this.profileData.stateIsoCode) : '';
        this.addProduct.patchValue({
          fName: this.profileData.firstName,
          lName: this.profileData.lastName,
          email: this.profileData.email,
          Phone: this.profileData.mobileNumber,
          address: this.profileData.addressLine1,
          address1: this.profileData.addressLine2,
          url: this.profileData.websiteUrl,
          zipCode: this.profileData.zipCode
          ,
          // facebook: this.profileData?.socialLink?.faceBook,
          // linkedin: this.profileData?.socialLink?.linkedIn,
          // twitter: this.profileData?.socialLink?.twitter,
          // instagram: this.profileData?.socialLink?.instagram,


          city: this.profileData.city,
          state: this.profileData.stateIsoCode,
          // zipCode: this.profileData.zipCode,
          country: this.profileData.countryIsoCode,
        });
        this.latitude = res.result.storeLocation.coordinates[0]
        this.longitude = res.result.storeLocation.coordinates[1]
        this.imageUrl = res['result']['profilePic']
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }

  // patch state and city


  patchState(country: any) {
    let apiReqUrl = `${'user/listState?countryCode=' + country}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {

      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.stateList = res.result
      this.addProduct.patchValue({
        'state': this.profileData.state
      })
    })
  }
  patchCity(state: any) {
    let apiReqUrl = `${'user/listCity?countryCode=' + this.profileData.country + '&stateCode=' + state}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {

      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.cityList = res.result
      this.addProduct.patchValue({
        'city': this.profileData.city
      })
    })
  }

  getCountryList() {
    this.commonService.getApi('user/listCountry', 1).subscribe((res: any) => {

      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.countryList = res.result

    })
  }
  countryIsoCode: any;
  getStateList(event: any) {
    this.addProduct.patchValue(({
      state : '',
      city : ''
    }))
    this.countryIsoCode = typeof event == 'string' ? event : event.target.value
    let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {

      this.stateList = res.result
    })
  }

  getCityList(event: any) {
    this.addProduct.patchValue(({
      city : ''
    }))
    let stateIsoCode = typeof event == 'string' ? event : event.target.value
    let apiReqUrl = `${'user/listCity?countryCode=' + this.countryIsoCode + '&stateCode=' + stateIsoCode}`
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {

      this.cityList = res.result
    })
  }



  getCountryName(iso: string) {
    let temp = this.countryList.filter((res: any) => {
      return res.isoCode == iso
    })
    return temp[0].name
  }

  getStateName(iso: string) {
    let temp = this.stateList.filter((res: any) => {
      return res.isoCode == iso
    })
    return temp[0].name
  }
  // update profile


  editProfileDetail() {
    let url = "user/updateProfile"

    // var socialLinks = {
    //   "faceBook": this.addProduct.value.facebook,
    //   "twitter": this.addProduct.value.twitter,
    //   "instagram": this.addProduct.value.instagram,
    //   "linkedIn": this.addProduct.value.linkedin

    // }


    const data = {
      firstName: this.addProduct.value.fName,
      lastName: this.addProduct.value.lName,
      email: this.addProduct.value.email,
      mobileNumber: this.addProduct.value.Phone,
      addressLine1: this.addProduct.value.address,
      addressLine2: this.addProduct.value.address1,
      country: this.getCountryName(this.addProduct.value.country),
      city: this.addProduct.value.city,
      state: this.getStateName(this.addProduct.value.state),
      stateIsoCode: this.addProduct.value.state,
      countryIsoCode: this.addProduct.value.country,
      zipCode: this.addProduct.value.zipCode,
      // storeName: this.addProduct.value.storeName,
      // storeLocation: this.addProduct.value.storeLocation,
      websiteUrl: this.addProduct.value.url,
      userType: 'CUSTOMER',
      "storeLocation": {
        "type": "Point",
        "coordinates": [
          this.longitude ||0.00,
          this.latitude || 0.00
        ]
      },
      // socialLink: socialLinks,

      profilePic: this.imageUrl
    }

    // this.commonService.putApi(url, data, 1)
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.router.navigate(['/customer-profile']);
        this.commonService.successToast(res["responseMessage"]);
        // this.getProfileDetails()
        // this.authService.loggedInData.next(res['result']['profilePic'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }


  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-
  uploadImg($event: any): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 0).subscribe((res: any) => {

      this.commonService.hideSpinner();
      if (res.responseCode == '200') {
        this.imageUrl = res.result.mediaUrl;
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }
  addId: any
  addAddress() {
    let url = `user/addAddress`
    let apiReqData = {
      "addressLine1": this.addProduct.value.address1,
      "addressLine2": this.addProduct.value.address2,
      "address": this.addProduct.value.address1 + "," + this.addProduct.value.address2,
      "zipCode": this.addProduct.value.zipCode,
      "state": this.getStateName(this.addProduct.value.state),
      "city": this.addProduct.value.city,
      "country": this.getCountryName(this.addProduct.value.country),
      "firstName": this.addProduct.value.fName,
      "lastName": this.addProduct.value.lName,
      "mobileNumber": this.addProduct.value.mobileNumber,
      "countryCode": this.addProduct.value.countryCode,
      "email": this.addProduct.value.email,
      stateIsoCode: this.addProduct.value.state,
      countryIsoCode: this.addProduct.value.country,
      "location": {
        "type": "Point",
        "coordinates": [
          this.longitude || 0.00,
          this.latitude || 0.00
        ]
      }
    }
    this.commonService.postApi(url, apiReqData, 1).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
        this.addId = res['result']['_id'];
        localStorage.setItem('primaryAddressId', this.addId)
        if (this.qtyValue == 'quantity') {
          this.router.navigate(['/customer-service-quantity-add'], { queryParams: { categoryId: this.categoryId, userId: this.userId, categoryName: this.categoryName } })
        }
        else {
          this.router.navigate(['/customer-address-list'])
        }
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }


}
