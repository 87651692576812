import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'address-pop-up',
  templateUrl: './update-pop-up.component.html',
  styleUrls: ['./update-pop-up.component.scss']
})
export class UpdatePopUpComponent implements OnInit {
  @Input() addressData : any
  @Output() closeModal = new EventEmitter
  constructor(public commonService: CommonService, private fb: FormBuilder, private http: HttpClient) {
   
  }
  countryList: any = []
  addressForm: FormGroup
  stateList: any = []
  datas: any = []
  countryIsoCode: any;
  products: any;
  options: any = []
  address: any
  longitude: any;
  latitude: any;
  is_addressInValid: boolean = true
  checked = false
  ngOnInit(): void {
    $('#addressDetailModal').modal({backdrop: 'static', keyboard: false},"show")
    this.http.get("assets/countrycode.json").subscribe((data: any) => {
      this.products = data;
      this.datas = data.result
    })
    
    this.getCountryList()
    this.add()
    navigator.geolocation.getCurrentPosition(function (position) {
    }, function () {
      alert('You need to enable geolocation services in your browser to use this feature.');
    });
    this.getPosition().then(pos => {
      localStorage.setItem('location', JSON.stringify(pos))
    });
  }
  add() {
    this.addressForm = this.fb.group({
      'fName': new FormControl('', [
        Validators.required,
        Validators.maxLength(60),
        Validators.minLength(2),
        Validators.pattern('^[A-Za-z ]+$')
      ]),
      'lName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern('^[A-Za-z ]+$')
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
      'address': new FormControl('', [
        Validators.maxLength(250),
      ]),
      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),
      'address1': new FormControl('', Validators.required),
      'address2': new FormControl(''),
      'location': new FormControl('', [
      ]),
      countryCode: new FormControl('+27', [
        Validators.required
      ]),
      'url': new FormControl('', [
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
      ]),
      'country': new FormControl('', [
        Validators.required
      ]),
      'state': new FormControl('', [
        Validators.required
      ]),
      'city': new FormControl('', [
        Validators.required
      ]),
      'zipCode': new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]+$')
      ]),
    });
   
    if (this.addressData) {
      this.patchForm()
     
    }
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  getAddressDetails() {
    const address = this.addressForm.value.address1; // Replace with your desired address
    this.commonService.getLatLongFromAddress(address).subscribe((data: any) => {
      console.log(data);
      if(data.result.results.length){
        const location = data.result.results[0].geometry.location;
        this.latitude = location.lat;
        this.longitude = location.lng;
        this.addAddress() 
      }
      else{
        this.commonService.errorToast('You have entered a wrong address')
      }
  
      
    });
  }
  getAddressDetails2() {
    const address = this.addressForm.value.address1; // Replace with your desired address
    this.commonService.getLatLongFromAddress(address).subscribe((data: any) => {
      console.log(data);
      if(data.result.results.length){
        const location = data.result.results[0].geometry.location;
        this.latitude = location.lat;
        this.longitude = location.lng;
        this.editAddress() 
      }
      else{
        this.commonService.errorToast('You have entered a wrong address')
      }
  
      
    });
  }
  patchForm() {
    this.addressData.country ? this.getStateList(this.addressData.countryIsoCode) : '';
    this.addressData.state ? this.getCityList(this.addressData.stateIsoCode) : '';
    this.addressForm.patchValue({
      fName: this.addressData.firstName,
      lName: this.addressData.lastName,
      email: this.addressData.email,
      mobileNumber: this.addressData.mobileNumber,
     
      address1: this.addressData.addressLine1,
      address2: this.addressData.addressLine2,
      url: this.addressData.websiteUrl,
      zipCode: this.addressData.zipCode,
      countryCode: this.addressData.countryCode,
      city: this.addressData.city,
      state: this.addressData.stateIsoCode,
      country: this.addressData.countryIsoCode,
    });
    this.latitude = this.addressData.location.coordinates[0]
    this.longitude = this.addressData.location.coordinates[1]
  }
  public handleAddressChange(address: any) {
    if ('formatted_address' in address) {
      this.addressForm.patchValue({
        address1: address.formatted_address
      })
      this.options = []
      this.is_addressInValid = false

      this.address = address.formatted_address

      this.longitude = address.geometry.location.lng()
      this.latitude = address.geometry.location.lat()
      this.options[0] = Number(address.geometry.location.lng())
      this.options[1] = Number(address.geometry.location.lat())
    }
    else {
      this.is_addressInValid = true

    }



  }
  getCountryList() {
    this.commonService.getApi('user/listCountry', 1).subscribe((res: any) => {

      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.countryList = res.result

    })
  }

  getStateList(event: any) {
    // this.addProduct.patchValue(({
    //   state : '',
    //   city : ''
    // }))
    if (event) {
      this.countryIsoCode = event
    }
    let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {

      this.stateList = res.result
    })
  }
  cityList: any = []
  getCityList(event: any) {
    // this.addProduct.patchValue(({
    //   city : ''
    // }))
    let stateIsoCode = event
    let apiReqUrl = `${'user/listCity?countryCode=' + this.countryIsoCode + '&stateCode=' + stateIsoCode}`
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {

      this.cityList = res.result
    })
  }
  closePopup(){
    $('#addressDetailModal').modal('hide')
    this.closeModal.emit()
  }

  addId: any
  editAddress() {
    if(!this.addressForm.valid){
       return
    }
    let url = "user/editAddress"
    let data = {
      "addressId": this.addressData._id,
      "addressLine1": this.addressForm.value.address1,
      "addressLine2": this.addressForm.value.address2,
      "address": this.addressForm.value.address1 + "," + this.addressForm.value.address2,
      "zipCode": this.addressForm.value.zipCode,
      "state": this.getStateName(this.addressForm.value.state),
      "city": this.addressForm.value.city,
      "country": this.getCountryName(this.addressForm.value.country),
      "firstName": this.addressForm.value.fName,
      "lastName": this.addressForm.value.lName,
      "mobileNumber": this.addressForm.value.mobileNumber,
      "countryCode": this.addressForm.value.countryCode,
      "email": this.addressForm.value.email,
      stateIsoCode: this.addressForm.value.state,
      countryIsoCode: this.addressForm.value.country,
      "location": {
        "type": "Point",
        "coordinates": [
          this.longitude,
          this.latitude
        ]
      }
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
        this.addId = res['result']['_id'];
        localStorage.setItem('primaryAddressId', this.addId)
        if(this.checked){
          this.setPrimaryAddress(this.addId)
        }else{
          this.closePopup()
        }
    
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }
  setPrimaryAddress(id) {
    let url = `user/markAddressPrimary`
    let data = {
      "addressId": id,
      //  "isPrimary": true
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        // this.listAddressData = res.result.docs;
        if (res.result._id != 0) {
          localStorage.setItem('primaryAddressId', res.result._id)
        }
        this.closePopup()
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
    })

  }

  getCountryName(iso: string) {
    let temp = this.countryList.filter((res: any) => {
      return res.isoCode == iso
    })
    return temp[0].name
  }

  getStateName(iso: string) {
    let temp = this.stateList.filter((res: any) => {
      return res.isoCode == iso
    })
    return temp[0].name
  }

  addAddress() {
  
    if(!this.addressForm.valid){
     return
  }

  
  let url = `user/addAddress`
  let apiReqData = {
    "addressLine1": this.addressForm.value.address1,
    "addressLine2": this.addressForm.value.address2,
    "address": this.addressForm.value.address1 + "," + this.addressForm.value.address2,
    "zipCode": this.addressForm.value.zipCode,
    "state": this.getStateName(this.addressForm.value.state),
    "city": this.addressForm.value.city,
    "country": this.getCountryName(this.addressForm.value.country),
    "firstName": this.addressForm.value.fName,
    "lastName": this.addressForm.value.lName,
    "mobileNumber": this.addressForm.value.mobileNumber,
    "countryCode": this.addressForm.value.countryCode,
    "email": this.addressForm.value.email,
    stateIsoCode: this.addressForm.value.state,
    countryIsoCode: this.addressForm.value.country,
    "location": {
      "type": "Point",
      "coordinates": [
        this.longitude,
        this.latitude
      ]
    }
  }
  // let apiReqData = {
  //   "addressLine1": this.addProduct.value.address1,
  //   "addressLine2": this.addProduct.value.address2,
  //   "address": this.addProduct.value.address1 + "," + this.addProduct.value.address2,
  //   "zipCode": this.addProduct.value.zipCode,
  //   "state": this.getStateName(this.addProduct.value.state),
  //   "city": this.addProduct.value.city,
  //   "country": this.getCountryName(this.addProduct.value.country),
  //   "firstName": this.addProduct.value.fName,
  //   "lastName": this.addProduct.value.lName,
  //   "mobileNumber": this.addProduct.value.mobileNumber,
  //   "countryCode": this.addProduct.value.countryCode,
  //   "email": this.addProduct.value.email,
  //   stateIsoCode: this.addProduct.value.state,
  //   countryIsoCode: this.addProduct.value.country,
  //   "location": {
  //     "type": "Point",
  //     "coordinates": [
  //       this.longitude,
  //       this.latitude
  //     ]
  //   }
  // }
  this.commonService.postApi(url, apiReqData, 1).subscribe((res) => {
    if (res["responseCode"] == 200) {
      this.commonService.hideSpinner();
      this.commonService.successToast(res["responseMessage"]);
      this.addId = res['result']['_id'];
      localStorage.setItem('primaryAddressId', this.addId)
      this.closePopup()
     
    } else {
      this.commonService.hideSpinner();
      this.commonService.errorToast(res["responseMessage"]);
    }
  })
}
}
