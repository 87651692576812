import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-business-details-retailer',
  templateUrl: './business-details-retailer.component.html',
  styleUrls: ['./business-details-retailer.component.scss']
})
export class BusinessDetailsRetailerComponent implements OnInit {
  heroes = ['Windstorm', 'Bombasto', 'Magneta', 'Tornado'];
  businessform!: FormGroup;
  products: any = [];
  showInputes: boolean = false;
  showInput: boolean = false;
  isVatRegistered: boolean | any;
  addNewSubcategoryField: any = []
  addNewSubcategoryBussinessField: any = []
  countryCode = new FormControl('+27');
  brandName: any

  constructor(public commonService: CommonService, private http: HttpClient, private router: Router) { }

  ngOnInit(): void {


    this.addNewSubCategryDynamic();
    // this.addNewSubCategryBussinessDynamic();

    this.businessform = new FormGroup({
      fName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),

      lName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),

      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
      Phone: new FormControl(''),

      countryCode: new FormControl('+27', [
        Validators.required
      ]),

      numberproducts: new FormControl('', [
        Validators.required,

      ]),

      // product: new FormControl(''),

      keepStock: new FormControl(''),

      isPhysicalStore: new FormControl(''),

      storeess: new FormControl(''),

      storeessed: new FormControl(''),

      storesgg: new FormControl(''),

      preferred: new FormControl(''),


      additional: new FormControl(''),

      company: new FormControl('', [
        Validators.required,

      ]),

      businessRegNumber: new FormControl('', [
        Validators.required,

      ]),
      // cotegory: new FormControl('', [
      //   Validators.required,

      // ]),
      url: new FormControl('', [
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)

      ]),

      vatRegistered: new FormControl(''),

      vatNumberInput: new FormControl('', [
        Validators.required,
      ]),

      monthlyRevenue: new FormControl(''),

      // bankname: new FormControl('', [
      //   Validators.required,

      // ]),

      bankname: new FormControl('', [Validators.required,
      Validators.pattern(/^[a-z A-Z]*$/i)
      ]),


      branchname: new FormControl('', [
        Validators.required,

      ]),
      swiftcode: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
        Validators.pattern("^[A-Z][a-zA-Z]*")

      ]),
      accountType: new FormControl('', [
        Validators.required,

      ]),
      accountName: new FormControl('', [
        Validators.required,

      ]),
      supplier: new FormControl('', [
      ]),
      accountNumber: new FormControl('', [
        Validators.required,

      ]),


    })
    this.http.get("assets/countrycode.json").subscribe(data => {
      this.products = data['result'];
      
    })
  }


  addNewSubCategryDynamic() {
    if (this.addNewSubcategoryField.length && this.addNewSubcategoryField[this.addNewSubcategoryField.length - 1].sub_Category_List == '') {
      return this.commonService.infoToast('Please enter the value in previous added, after that click on add new.')
    }
    this.addNewSubcategoryField.push({
      sub_Category_List: ''
    })
  }



  removeBodySectionDynamic(index: number) {
    this.addNewSubcategoryField.splice(index, 1)
  }



  businessDetaile() {
    let subCategoryData = this.addNewSubcategoryField.map((x: any) => {
      return x.sub_Category_List
    })
    let emptyString = subCategoryData.includes("")
    var fillDetails = {
      ownerFirstName: this.businessform.value.fName,
      ownerLastName: this.businessform.value.lName,
      ownerEmail: this.businessform.value.email,
      phoneNumber: this.businessform.value.Phone,
      countryCode: this.businessform.value.countryCode,
      noOfUniqueProducts: this.businessform.value.numberproducts,
      "storeAddress": this.businessform.value.storeessed,
      "storeName": this.businessform.value.storeess,
      "storeContactNo": this.businessform.value.storesgg,
      // listOfBrandOrProducts: this.businessform.value.product,
      "listOfBrandOrProducts": subCategoryData,
      keepStock: this.businessform.value.keepStock,
      isPhysicalStore: this.businessform.value.isPhysicalStore,
      comments: this.businessform.value.additional,
      "businessBankingDetails": {
        "bankName": this.businessform.value.bankname,
        "branchName": this.businessform.value.branchname,
        // "branchCode": "string",
        "swiftCode": this.businessform.value.swiftcode,
        "accountType": this.businessform.value.accountType,
        "accountName": this.businessform.value.accountName,
        "accountNumber": this.businessform.value.accountNumber
      },

      "businessDetails": {
        "companyName": this.businessform.value.company,
        "businessRegNumber": this.businessform.value.businessRegNumber,
        // "categoryId": "string",
        "websiteUrl": this.businessform.value.url,
        "socialMediaAccounts": "string",
        "isVatRegistered": this.businessform.value.vatRegistered,
        "vatNumber": this.businessform.value.vatNumberInput,
        "monthlyRevenue": this.businessform.value.monthlyRevenue,

        // "storeAddress": this.businessform.value.storeessed,
        // "storeName": this.businessform.value.storeess,
        // "storeContactNo": this.businessform.value.storesgg,



      },
      "preferredSupplierOrWholeSalerId": this.wholesalerIdNew,
      flag: 1,
      userType: 'RETAILER',
      // "preferredSupplierOrWholeSalerId": this.selectedCarObjS
    }

    
    this.commonService.showSpinner()
    this.commonService.postApi('user/fillDetails', fillDetails, 1).subscribe((res: any) => {
      
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        // localStorage.setItem('email', this.businessform.value.email)
        this.commonService.hideSpinner()


        if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {


          this.router.navigate(['/upload-document-retailer'])

        }

        else if (!res.result.completeProfile) {
          this.commonService.successToast(res['responseMessage'])
          this.router.navigate(['/upload-document-retailer']);

        }
        // set current url to localstorage 
        // this.router.navigate(['/upload-document-retailer']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {
      
      this.commonService.hideSpinner()
      this.commonService.errorToast(err['responseMessage'])
    })



  }


  open() {
    this.showInput = !this.showInput
  }

  close() {
    this.showInput = false
  }


  openesd() {
    this.showInputes = !this.showInputes
  }

  closeesd() {
    this.showInputes = false
  }

  // serchlistApi




  // addNewSubCategryBussinessDynamic() {
  //   if (this.wholeSalerList.length && this.wholeSalerList[this.wholeSalerList.length - 1].sub_Category_List == '') {
  //     return this.commonService.infoToast('Please enter the value in previous added, after that click on add new.')
  //   }
  //   this.wholeSalerList.push({
  //     sub_Category_List: ''
  //   })
  // }
  valueName: any
  srarchWholeSaler: any
  nameArray: any = []
  selectedCarObjS: any = []
  arrayVlaue: any = []
  srarchWholeSalers = "";

  selectedCarObj: any = [];

  timer
  filter() {
    let me = this
    clearInterval(this.timer)
    this.timer = setTimeout(function () {

      me.searchWholeSaler();
    }, 1000);
  }
  wholesalerid: any
  wholesalerIdNew: any = []
  searchWholeSaler() {
    if (!this.srarchWholeSaler) {
      return this.commonService.infoToast('Please enter the whole saler name.')
    }

    this.wholeSalerList.push({ srarchWholeSaler: '' });
    let data = {
      search: this.srarchWholeSaler


    }

    this.commonService.postApi('user/wholeSalerList', data, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {

        this.wholeSalerList = res['result']['docs'];

        if (!res['result']['docs'].length) {
          this.commonService.errorToast('Wholesaler not found')
        }

        if (this.wholeSalerList.length) {
          this.selectedCarObj = this.wholeSalerList.filter((c: any) => c.firstName == this.srarchWholeSaler);
          const found = this.selectedCarObj.find((element: any) => element.firstName);
          this.selectedCarObjS.push(found)
          this.selectedCarObjS.forEach(element => {
            this.wholesalerid = element._id ? element._id : '';
          });
          this.selectedCarObjS = this.commonService.removeDuplicate(this.selectedCarObjS , ['firstName'])
          this.wholesalerIdNew.push(this.wholesalerid)
        }

      }

    })

  }


  removeSearchDynamic(index: number) {
    this.selectedCarObjS.splice(index, 0)
  }
  wholeSalerList: any = []

  /** Function to restrict character */
  restrictChar(event: any) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }



  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }


  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }






  // ===================









  totalItems: any;

  getcontents($event: any) {

    this.commonService.getApi('user/listCountry', 0).subscribe((res: any) => {
      this.commonService.showSpinner()
      if (res['responseCode'] == 200) {
        // this.categoryListArray = res['result']

        this.totalItems = res['result']
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.products = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {

      this.commonService.warningToast(err['error']['responseMessage'])

      
    }
    )
  }

}
