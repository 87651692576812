<div class=" my-5">
  <footer class="text-center text-lg-start text-white classFooter" style="background-color: #BF1E2E">
    <section class="">
      <div class="container py-3 text-center text-md-start">
        <div class="row mt-3 justify-content-between" style="flex-wrap: none !important;">
          <div class="col-md-3 col-lg-4 col-xs-12  text-start">
            <h6 class="fw-bold">Subscribe Newsletter</h6>
            <div class="input-group">
              <input type="search" class="form-control new-inp mr-2"  [(ngModel)]="emailN"  maxlength="150"
              (keypress)="preventSpace($event)" placeholder="Enter email address" aria-label="Search" aria-describedby="search-addon" />
              <button type="button" class="btn  btn-bnew" [disabled]="isEmailValid()"
              (click)="getDeals()">Subscribe</button>
            </div>
             <div class="mt-2 text-align-left socialIconBox" style="text-align: left;">
        <a href="https://facebook.com/exobe.africa" target="_blank" class="data-icon me-4" >
          <i class="fa fa-facebook-f"></i>
        </a>
        <a href="https://twitter.com/exobe" target="_blank" class="data-icon me-4">
          <i class="fa fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/exobe.africa" target="_blank" class="data-icon me-4">
          <i class="fa fa-instagram"></i>
        </a>
        <a href="https://pinterest.com/exobe" target="_blank" class="data-icon me-4">
          <i class="fa fa-pinterest"></i>
        </a>
    
        <a href="https://www.tiktok.com/exobe" target="_blank" class="data-icon me-4">
          <i class="fa fa-tiktok"></i>
        </a>
      
      </div>
          </div>
          <div class="col-md-2 col-lg-2 col-sm-6  col-xs-6 text-start">
            <h6 class="fw-bold">Explore</h6>
            <p>
              <a    [ngClass]="{ active: currUrl == 'terms-and-condition' }"
              routerLink="/terms-and-condition" class="text-white">Terms & Conditions</a>
            </p>
            <p>
              <a   [ngClass]="{ active: currUrl == 'privacy-policy' }"
              routerLink="/privacy-policy" class="text-white">Privacy Policy</a>
            </p>
            <p>
              <a [ngClass]="{ active: currUrl == 'aboutUs' }"
              routerLink="/aboutUs" class="text-white">About Us</a>
            </p>
            
          </div>
          <div class="col-md-3 col-lg-2 col-xs-12 col-sm-6   text-start">
            <h6 class="fw-bold">Help</h6>
            <p>
              <a [ngClass]="{ active: currUrl == 'faq' }" routerLink="/faq" class="text-white">FAQ's</a>
            </p>
            <p>
              <a [ngClass]="{ active: currUrl == 'contactUs' }"
              routerLink="/contactUs" class="text-white">Contacts Us</a>
            </p>
          
          </div>
        </div>
      </div>
    </section>
  </footer>
</div>