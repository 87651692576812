<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="tab-pane1 ">
  <div class="sec_head_new sec_head_new_after">
      <form [formGroup]="searchForm">
          <div class="row" style="justify-content: center;">
              <div class="col-md-3 p-0">
                  <div class="date-input-box">
                      <input type="date" name="" id="fromDate"
                          class="form-control date-input mr-2" formControlName="fromDate"
                          [max]="today">
                      <input type="date" name="" id="toDate" class="form-control date-input"
                          [readonly]="!this.searchForm.controls['fromDate'].value"
                          [min]="searchForm.controls['fromDate'].value"
                          formControlName="toDate">
                  </div>
              </div>
              <div class="col-md-2 lmp">
                  <div class="text-center admin-btn " >
                      <button type="button" class="add-cart"
                          (click)="searchFormSubmit();currentPage =1;">Submit</button>
                      <button type="button" class="add-cart ml-2 Go_btn"
                          (click)="searchFormReset()">Reset</button>
                  </div>
              </div>
          </div>
      </form>
  </div>
</div>
<div class="container mb-4 topfooter">
  <div class="row mt-3">
    <div class="col-12 table-responsive">
      <table class="table table-hover" aria-describedby="">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Service Provider</th>
            <th scope="col">Image</th>
            <th scope="col">Service Id</th>
            <th scope="col">Date</th>
            <th scope="col">Category</th>
            <th width="15%" scope="col">Address</th>
            <th scope="col">Bookings Status</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of orderList
                | paginate
                  : {
                      itemsPerPage: pageSize,
                      currentPage: currentPage,
                      totalItems: count
                    };
              let i = index
            "
          >
            <td scope="row">{{ 10 * (currentPage - 1) + i + 1 }}</td>
            <td scope="row">
              {{ item?.serviceDetails[0]?.serviceId?.userId?.firstName }}
              {{ item?.serviceDetails[0]?.serviceId?.userId?.lastName }}
            </td>
            <td scope="row">
              <img  class="imagtag"
                src="{{
                  item?.serviceDetails[0]?.serviceId?.userId?.profilePic
                  || 'assets/latest/placehold.png'}}"
                alt=""
              />
            </td>
        
           
            <td>{{ item?.orderId }}</td>
            <td *ngIf="isDate(item?.duration)">{{ item?.duration }}</td>
            <td *ngIf="!isDate(item?.duration)">{{ isDate(item?.duration)| date : "longDate" || "--" }}</td>

            <td>
              {{ item?.serviceDetails[0]?.serviceId?.categoryId?.categoryName }}
            </td>
            <td>
              {{ item?.shippingFixedAddress.address
              }}{{ item?.shippingFixedAddress.addressLine1 }}
              {{ item?.shippingFixedAddress.addressLine2 }}
            </td>
            <td>{{ item?.orderStatus }}</td>
            <td  [ngClass]="{
            'green-color': item?.paymentStatus=='COMPLETED',
            'red-color': item?.paymentStatus!='COMPLETED'}">
            {{item?.paymentStatus}}</td>
            <td><i class="fa fa-eye mpl10" aria-hidden="true" style="cursor: pointer;" (click)="viewBookingHistoryDetails(item)"></i></td>
          </tr>
        </tbody>
      </table>
      <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'"  ></app-table-loader>
      <pagination-controls
        *ngIf="count > 10"
        style="float: right; margin-top: 3%"
        (pageChange)="changePageNumber($event)"
      ></pagination-controls>
    </div>
  </div>
</div>
