// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The lijjjst of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Production URL
  // baseUrl: "https://node.exobe.africa/api/v1/",
  // ozhoBaseUrl: "https://node.exobe.africa/",


  //Staging URL
  // baseUrl: 'https://nodeexobe-release.mobiloitte.io/api/v1/',
  // ozhoBaseUrl: 'https://nodeexobe-release.mobiloitte.io',

  baseUrl: 'https://node-exobe.mobiloitte.io/api/v1/',
  ozhoBaseUrl: 'https://node-exobe.mobiloitte.io',


  // baseUrl: 'http://172.16.6.130:3030/api/v1/', 
  // ozhoBaseUrl: 'http://172.16.6.130:3030',

  webUrl: window.location.protocol + '//' + window.location.host + '/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
