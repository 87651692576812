
<div class="mt-5">
    <article class="card">
       
        <div class="card-body">
      
            
            <div class="track">
                <div class="step " style="text-align: left;"  [ngClass]="{'active': orderDataDetials.orderPlaced}"> <span class="icon text-center"> <i class="fa fa-check"></i> </span> <span class="text">Order Placed</span> </div>
                <div class="step" [ngClass]="{'active': orderDataDetials.processing}"> <span class="icon"> <i class="fa fa-archive"></i> </span> <span class="text"> Processing </span> </div>
                <div class="step " [ngClass]="{'active': orderDataDetials.pickedUpCourier}"> <span class="icon"> <i class="fa fa-user"></i> </span> <span class="text"> Packaging in Progress </span> </div>
                <div class="step" [ngClass]="{'active': orderDataDetials.OnTheWay}"> <span class="icon"> <i class="fa fa-truck"></i> </span> <span class="text">Delivery on the way</span> </div>
                <div class="step" style="text-align: end;" [ngClass]="{'active': orderDataDetials.delivered}"> <span class="icon text-center" style="left:5px"> <i class="fa fa-check-square-o"></i> </span> <span class="text">Delivered</span> </div>
            </div>

        
          
        </div>
    </article>
</div>