
<div class="container">
    <div class="d-flex overflow-x">
        <div class="selector" *ngIf="subcatList?.length" (click)="getSubCatId('')" [ngClass]="{'active': !subcatId}">
            <p>All</p>
        </div>
        <div class="selector"  (click)="getSubCatId(item._id)"
        [ngClass]="{'active': item._id == subcatId}" *ngFor="let item of subcatList">
            <p>{{item.subCategoryName}}</p>
        </div>
        
    </div>
   
</div>