<div class="container  fw-b wrapper-content">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  <div class="container mb-4 wrapper-content">
  <div class="row mt-1">
    <div class="col-lg-3 col-md-2 col-sm-6" style="padding: 0;">
        <button class="tabBtn" [ngClass]="{active : btn == 'Pending'}"
            (click)="selectBtn('Pending')">PENDING </button>
    </div>
    <div class="col-lg-3 col-md-2 col-sm-6" style="padding: 0;">
        <button class="tabBtn" [ngClass]="{active : btn == 'Complete'}"
            (click)="selectBtn('Complete')">COMPLETED</button>
    </div>
</div>

  <div class="row mt-3 ">
    <div class="col-12 table-responsive" *ngIf="btn == 'Pending'">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Service Id</th>
            <th scope="col">Service Date</th>
            <th scope="col">Category</th>
            <th scope="col">Order Status</th>
            <th scope="col">Payment Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of orderList
                | paginate
                  : {
                      itemsPerPage: pageSize,
                      currentPage: currentPage,
                      totalItems: count
                    };
              let i = index
            "
          >
            <th>{{ 10 * (currentPage - 1) + i + 1 }}</th>
            <th scope="row">
              {{ item?.userId?.firstName }} {{ item?.userId?.lastName }}
            </th>
            <td>{{ item?.orderId }}</td>
            <td>{{ item?.createdAt | date : "medium" || "--" }}</td>
            <td>
              {{ item?.myOrders[0]?.serviceId?.categoryId?.categoryName }}
            </td>
            <td>{{ item?.orderStatus }}</td>
            <td class="green-color">{{ item?.paymentStatus }}</td>
            <td>
              <i
                class="fa fa-eye mpl10"
                aria-hidden="true"
                style="cursor: pointer"
                [queryParams]="{ _id: item?._id, status: item?.orderStatus }"
                [routerLink]="['/service-provider-request-services-view']"
              ></i>
            </td>
          </tr>
        
        </tbody>
      </table>
      <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'55px'" ></app-table-loader>

      <pagination-controls
        *ngIf="count > 10"
        style="float: right; margin-top: 3%"
        (pageChange)="changePageNumber($event)"
      ></pagination-controls>
    </div>
    <div class="col-12 table-responsive" *ngIf="btn == 'Complete'">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Service Id</th>
            <th scope="col">Service Date</th>
            <th scope="col">Category</th>
            <th scope="col">Order Status</th>

            <th scope="col">Payment Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of orderList
                | paginate
                  : {
                      itemsPerPage: pageSize,
                      currentPage: currentPage,
                      totalItems: count
                    };
              let i = index
            "
          >
            <th>{{ 10 * (currentPage - 1) + i + 1 }}</th>
            <th scope="row">
              {{ item?.userId?.firstName }} {{ item?.userId?.lastName }}
            </th>
            <td>{{ item?.orderId }}</td>
            <td>{{ item?.createdAt | date : "medium" || "--" }}</td>
            <td>
              {{ item?.myOrders[0]?.serviceId?.categoryId?.categoryName }}
            </td>
            <td>{{ item?.orderStatus }}</td>
            <td class="green-color">{{ item?.paymentStatus }}</td>
            <td>
              <i
                class="fa fa-eye"
                aria-hidden="true"
                style="cursor: pointer"
                [queryParams]="{ _id: item?._id, status: item?.orderStatus }"
                [routerLink]="['/service-order-detail-view']"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'55px'" ></app-table-loader>
      <pagination-controls
        *ngIf="count > 10"
        style="float: right; margin-top: 3%"
        (pageChange)="changePageNumber($event)"
      ></pagination-controls>
    </div>
  </div>
</div>
</div>
