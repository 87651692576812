import { Component, OnInit,ViewChild ,ElementRef, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-upload-document-retailer',
  templateUrl: './upload-document-retailer.component.html',
  styleUrls: ['./upload-document-retailer.component.scss']
})
export class UploadDocumentRetailerComponent implements OnInit {
  @ViewChild('fileInput1', { static: false }) fileInput1: ElementRef;
  @ViewChild('fileInput2', { static: false }) fileInput2: ElementRef;
  @ViewChild('fileInput3', { static: false }) fileInput3: ElementRef;
  @ViewChild('fileInput4', { static: false }) fileInput4: ElementRef;
  @ViewChild('fileInput5', { static: false }) fileInput5: ElementRef;

  constructor(public commonService: CommonService, private router: Router, public auth: AuthService,private renderer: Renderer2) { }

  imageUrl: any

  IncorporationForntUrl: any
  IncorporationBackUrl: any
  VatForntUrl: any
  VatBackUrl: any
  DirectorForntUrl: any
  DirectorBackUrl: any
  ConfirmationForntUrl: any
  ConfirmationBacktUrl: any
  DirectorIDDocumentUrl: any

  ngOnInit(): void {
  }



  imgeUplode() {
    let data = {
      "businessDocumentUpload": {
        "cirtificationOfIncorporation": {
          "pdf": this.IncorporationForntUrl,
          // "backImage": "IncorporationBackUrl"
        },
        "directConsentForm": {
          "pdf": this.DirectorForntUrl,
          // "backImage": "DirectorBackUrl"
        },
        "directorId": {
          "pdf": this.DirectorIDDocumentUrl,

        },
        "VatRegConfirmationDocs": {
          "pdf": this.VatForntUrl,
          // "backImage": "VatBackUrl"
        },

        "bankConfirmationLetter": {
          "pdf": this.ConfirmationForntUrl,
          // "backImage": "ConfirmationBacktUrl"
        }

      },
      completeProfile: true,
      flag: 2,
    }
    for (const key in data.businessDocumentUpload) {
      if (!data.businessDocumentUpload[key].pdf) {
        return this.commonService.errorToast("Please upload all documents")
      }
    }


    this.commonService.showSpinner()
    this.commonService.postApi('user/fillDetails', data, 1).subscribe((res: any) => {

      if (res['responseCode'] == 200) {
        this.commonService.successToast('Thank you for Registration.Please wait for vetting and approval.')
        // localStorage.setItem('email', this.businessform.value.email)
        this.commonService.hideSpinner()
        // set current url to localstorage 
        this.auth.logout()
        this.router.navigate(['/login-retailer']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {

      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })



  }






  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-



  uploadImg(event: any, uplodeImage: any, index): void {
    var img = event.target.files[0];
    if (img) {
      const fileSize = img.size; // Size in bytes
      const fileSizeKB = fileSize / 1024; // Size in kilobytes (KB)
      const fileSizeMB = fileSizeKB / 1024; // Size in megabytes (MB)

      const fileType = String(img.type).split('/')[1];
      console.log(fileType);
      if(fileType == 'pdf'){
        if (fileSizeMB > 3) {
          if(index==1){

            this.renderer.setProperty(this.fileInput1.nativeElement, 'value', '');
          }
          if(index==2){

            this.renderer.setProperty(this.fileInput2.nativeElement, 'value', '');
          }
          if(index==3){

            this.renderer.setProperty(this.fileInput3.nativeElement, 'value', '');
          }
          if(index==4){

            this.renderer.setProperty(this.fileInput4.nativeElement, 'value', '');
          }
          if(index==5){

            this.renderer.setProperty(this.fileInput5.nativeElement, 'value', '');
          }

          return this.commonService.errorToast("Please select a document that is 3 MB or smaller.")
        }
      }
      else{
        if (fileSizeMB > 2) {
          if(index==1){

            this.renderer.setProperty(this.fileInput1.nativeElement, 'value', '');
          }
          if(index==2){

            this.renderer.setProperty(this.fileInput2.nativeElement, 'value', '');
          }
          if(index==3){

            this.renderer.setProperty(this.fileInput3.nativeElement, 'value', '');
          }
          if(index==4){

            this.renderer.setProperty(this.fileInput4.nativeElement, 'value', '');
          }
          if(index==5){

            this.renderer.setProperty(this.fileInput5.nativeElement, 'value', '');
          }
          return this.commonService.errorToast("Please select a document that is 3 MB or smaller.")
        }
      }
     

    }
    this.uploadImageFunc(img, uplodeImage);
  }
  uploadImageFunc(img: any, uplodeImage: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadDocument', fb, 1).subscribe((res: any) => {

      if (res.responseCode == 200) {
        // this.imageUrl = res['result']['secure_url'];
        // this.imageUrl = res['result']

        if (uplodeImage == 'INCORPORATIONFORNT') {

          this.IncorporationForntUrl = res['result']['mediaUrl']
          // } else if (uplodeImage == 'INCORPORATIONBACK') {

          // this.IncorporationBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'VATFORNT') {
          this.VatForntUrl = res['result']['mediaUrl']

          // } else if (uplodeImage == 'VATBACK') {

          // this.VatBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'DIRECTORFORNT') {

          this.DirectorForntUrl = res['result']['mediaUrl']

          // } else if (uplodeImage == 'DIRECTORBACK') {
          // this.DirectorBackUrl = res['result']['mediaUrl']
        } else if (uplodeImage == 'DIRECTORDOCUMENT') {
          this.DirectorIDDocumentUrl = res['result']['mediaUrl']
        }
        else if (uplodeImage == 'CONFIRMATIONFORNT') {
          this.ConfirmationForntUrl = res['result']['mediaUrl']
        }
        else {
          // this.ConfirmationBacktUrl = res['result']['mediaUrl']

        }
        this.commonService.hideSpinner();

      } else {
        this.loading = false;
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }
}
