<!-- -----------------------------------------------again----------------------------------- -->

<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>
<div class="container  mb-4">
    <div class="">
        <div class="grid-container">
            <div class="section1">
                <form [formGroup]="addDeals">
                    <div class="form-row">
                        <div class="form-group col-md-6" *ngIf="searchList">
                            <label for="inputEmail4" class="tite">Search products to add deals</label>
                            <input type="text" list="codes" class="form-control" id="deal-5" formControlName="title"
                                placeholder="Search products to add deals" [(ngModel)]="codeValue" (change)="getSearch($event)">
                            <datalist id="codes">
                                <option *ngFor="let item of searchList" [value]="item.productName">{{item?.productName}}
                                </option>
                            </datalist>
                            <div class="for-validation text-danger"
                                *ngIf="addDeals.get('title')?.hasError('required') && addDeals.get('title')?.touched ">
                                *Title is required.</div>
        
        
        
                        </div>
                       
                    </div>
        
                    <div *ngIf="searchList.length">
        
                        <div class="form-row mt-2">
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Category</label>
                                <div>
                                    <input type="text" class="form-control" id="deal-5" placeholder="Enter Category" readonly
                                        maxlength="256" formControlName="category" (keypress)="preventSpace($event)">
        
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Subcategory</label>
        
        
                                <input type="text" class="form-control" id="deal-5" placeholder="Enter Subcategory"
                                    maxlength="256" formControlName="subCategory" (keypress)="preventSpace($event)" readonly>
        
        
                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('subCategory')?.hasError('required') && addDeals.get('subCategory')?.touched ">
                                    *SubCategory is required.</div>
        
                            </div>
                        </div>
        
                        <div class="form-row mb-3 mt-3">
                            <div class="col-md-4">
                                <label for="inputEmail4" class="tite">Actual Price (R)</label>
                                <input type="text" class="form-control" id="deal-5" placeholder="Enter Actual Price" maxlength="12"
                                    (keypress)="NumOnly($event)" formControlName="actualprice" (keypress)="preventSpace($event)" readonly>
                            </div>
                            <div class="col-md-4">
                                <label for="inputEmail4" class="tite">Discount</label>
                                <input type="text" class="form-control" id="deal-5" placeholder="0%" maxlength="3"
                                    (keypress)="NumOnly($event)" formControlName="discount"
                                    (keypress)="preventSpace($event);numberOnly($event)" (keyup)="calcMargin($event)"
                                    onkeyup="if(this.value > 99) this.value = 99; if(this.value < 0) this.value = 0;">
        
                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('discount')?.hasError('required') && addDeals.get('discount')?.touched ">
                                    *Discount is required.</div>
        
                                <div class="for-validation  text-danger"
                                    *ngIf="addDeals.get('discount')?.hasError('pattern') && addDeals.get('discount')?.dirty ">
                                    *Please enter valid discount</div>
                            </div>
                    <div class="col-md-4">
                        <label for="inputEmail4" class="tite">Deal Price (R)</label>
                        <input type="text" class="form-control" id="deal-5" placeholder="Enter Deal Price"
                            maxlength="12" (keypress)="NumOnly($event)" (keyup)="discountCalforvalue()" formControlName="mrp"
                            (keypress)="preventSpace($event)" >
                            <span class="for-validation text-danger"> {{priceMsg}}</span>
                    </div>
                            <div class="col-md-6">
                            </div>
                        </div>
        
        
                        <div class="form-group">
                            <label for="inputEmail4" class="tite">Description</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" id="deal-5" rows="3"
                                placeholder="Enter description" formControlName="description"
                                (keypress)="preventSpace($event)"></textarea>
                            <div class="for-validation text-danger"
                                *ngIf="addDeals.get('description')?.hasError('required') && addDeals.get('description')?.touched ">
                                *Description is required.</div>
        
                        </div>
        
                        <div class="form-row mb-3 mt-3">
                            <div class="col-md-6">
                                <label for="inputEmail4" class="tite">Start Date & Time</label>
                                <input type="datetime-local" class="form-control" id="deal-5" formControlName="dealStartTime"
                                    [min]="today">
                                <div class="for-validation text-danger"
                                    *ngIf="addDeals.get('dealStartTime')?.hasError('required') && addDeals.get('dealStartTime')?.touched ">
                                    *Enter dealStartTime required.</div>
                            </div>
                            <div class="col-md-6 ">
                                <label for="inputEmail4" class="tite">End Date & Time</label>
                                <input type="datetime-local" class="form-control" id="deal-5" formControlName="dealEndTime"
                                    [min]="today">
                            </div>
                        </div>
                        <div class=" mt-4 mb-4">
                            <button type="button" class="SAVE" style=" cursor: pointer;" [disabled]="addDeals.invalid"
                                (click)="getDeals()">SAVE</button>
                        </div>
                    </div>
                    <div *ngIf="!searchList.length" class="no-result">
                        <span>Not Data Found</span>
                    </div>
                </form>
            </div>
            <div class="section2">
              <!-- Content for section 2 -->
              <div class="image-upload" *ngIf="imageLinks.length != 0">
                <div class="" *ngFor="let item of imageLinks ; let index = index" style="background-color: #f2f2f2;">
                    <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i>
                    <img mat-card-sm-image [src]="item" alt="image">

                </div>

            </div>
              <div class="image-upload" *ngIf="!imageLinks?.length">
                <div class=""  >
                    <!-- <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i> -->
                    <img mat-card-sm-image src="assets/latest/dummy-place.svg" alt="image">

                </div>

            </div>
            <div class="row justify-content-start">
                <div class="col-md-3 mt-4">
                    <div class="input-file-container file">
                        <input class="input-file image hidden" id="my-file" accept=".png, .jpg, .jpeg, .webp "
                            multiple type="file" (change)="uploadImg($event)">
                        <label tabindex="0" for="my-file" class="input-file-trigger">Upload Images</label>
                    </div>
                </div>
            </div>
            </div>
          </div>



    </div>

</div>