import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-service-provider-request-services-view',
  templateUrl: './service-provider-request-services-view.component.html',
  styleUrls: ['./service-provider-request-services-view.component.scss']
})
export class ServiceProviderRequestServicesViewComponent implements OnInit {
  headingName:any ='Service Details'
  linkerRouteItems:any =[
    {name:'Home',route: '/service-order-detail'},
    {name:'Account',route:'/customer-profile'},
    {name:'Bookings',route:'/service-provider-request-services'},
    {name:'View',route:'/service-provider-request-services-view'}
  ]
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  userId: any;
  productDetails: any;
  serviceDetail: any
  productId: any;
  serviceImage: any
  orderStatus: any
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.orderStatus = res.status;
    })
  }

  ngOnInit(): void {
    this.serviceOrderList();
  }







  serviceOrderList() {
    let url = `v2/service/viewOrder?orderId=${this._id}`
    this.commonService.showSpinner()
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.productDetails = res.result.serviceDetails
        this.serviceImage = res.result.serviceDetails[0]
        this.serviceDetail = res.result
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
      }

    }, (err: any) => {
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {

    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
  }
  imgSrc: any
  productImage: any
  serviceData: any = []

}
