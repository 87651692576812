import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-succes-customer-payment',
  templateUrl: './succes-customer-payment.component.html',
  styleUrls: ['./succes-customer-payment.component.scss']
})
export class SuccesCustomerPaymentComponent implements OnInit {
  paymentList:any
  constructor(public commonService:CommonService) { }

  ngOnInit(): void {
  this.paymentList =  localStorage.getItem('paymentList')
  }

}
