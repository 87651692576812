<div>
  <div>
    <div class="bannerContent position-relative">

      <div class="card innerDiv" >
        <!-- <div class="card innerDiv" (click)="navigateToViewProduct(data?._id);navigateTocategory(data?._id,data?.categoryName)"> -->
        <div class="image" style="position: relative">
          <span class="heart" (click)="addWishlist(data?._id)" *ngIf="showHeart">
            <!-- <span class="heart" > -->
            <i class="fa fa-heart-o" *ngIf="data?.isLike == false"></i>
            <i
              class="fa fa-heart"
              style="color: red"
              *ngIf="data?.isLike == true"
            ></i>
            
          </span>
          <span class="heart" (click)="addWishlist(data?._id)" *ngIf="showEdit">
           
        
            <i
            class="fa fa-pencil"
            *ngIf="showEdit"
            style="color: #bf1e2e; cursor: pointer"
            (click)="onEdit(data?._id)"
          ></i>
          </span>
          <img (click)="navigateToViewProduct(data?._id)"
            [ngClass]="{ imgTrue: isImage }"
            class="img"
            [src]="data?.thumbnail"
            [hidden]="!isLoaded"
            (load)="onImageLoad()"
            alt="categoryImage"
          />
          <ngx-skeleton-loader
            *ngIf="!isLoaded"
            [theme]="theme"
            
            count="1"
          ></ngx-skeleton-loader>
        </div>
        <div class="mt-3" (click)="navigateToViewProduct(data?._id)">
          <p class="description">
            {{
              commonService.splitString(data?.productName || data?.categoryName)
            }}
          </p>
        </div>
        <div style="margin-top: -8px" *ngIf="data?.priceSizeDetails" (click)="navigateToViewProduct(data?._id)">
          <div class="row">
            <div class="col-12 p-0 m-0">
              <p class="price">
                <span [ngClass]="{ crossPrice: data.isDealActive }">
                  {{
                    data?.priceSizeDetails &&
                      data?.priceSizeDetails[0]?.price &&
                      getPrice(
                        data?.priceSizeDetails[0]?.price
                          | currency : "ZAR" : "R"
                      )
                  }}
                </span>
                <span *ngIf="data.isDealActive" class="price-color ml-0">{{
                  data?.dealPrice &&
                    getPrice(data?.dealPrice | currency : "ZAR" : "R")
                }}</span>
              </p>
            </div>
          </div>

          <div class="row" style="margin-top: -8px" *ngIf="data.isDealActive">
            <div class="col-12 p-0 m-0">
              <p class="dealPrice" style="font-size: 16px">
                Discount :
                <span class="newn"> {{ data.dealDiscount }}% off </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
