<body class="otp-modal-body">
    <div class="container-fluid p-3" style="height: 523px;">
       
        <div class="row justify-content-center p-4 mt-5">
            <div class="col-md-5" style="margin-top: 60px;">
                <div class="innerDiv">
                    <a  class="closeLink" (click)="onClose()">Close</a>
                    <h3 class="head mb-2 p-1">OTP Verification</h3>
                    <p style="color: gray;">Please enter the 4-digit verification code that was sent to {{customerType ? customerType :'customer'}} email
                        or phone number. The
                        code is valid for 3 minute.</p>
                    <form [formGroup]="otpForm">
                        <div class="otp">
                            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>
                            <div class="mt-2 d-flex " style="justify-content: space-evenly; margin: auto;">
                                <p class="mt-10">{{remainingTime}}</p>
                                <!-- <strong style="position: relative;left: 65%;">{{remainingTime}}</strong> -->
                                <a class="res-otp" (click)="resendOTPService()"  *ngIf="isExpire && isService">Resend OTP</a>
                                <a class="res-otp" (click)="resendOtp()"  *ngIf="isExpire && !isService">Resend OTP</a>
                                <a class="res-otp"  *ngIf="!isExpire">Resend OTP</a>
                            </div>
                        </div>

                        <div class="vca mt-3" *ngIf="!custom">
                          <ng-container *ngIf="!loading">
                            <button class="o-f"  *ngIf="!isService" (click)="load();verifyOTP()">SUBMIT</button>
                            <button class="o-f" *ngIf="isService"  (click)="load();verifyOTPService()">SUBMIT</button>
                          </ng-container>
                          <ng-container *ngIf="loading">
                            <button class="o-f op05"  >Loading</button>
                          
                          </ng-container>
                     
                        </div>
                        <div class="vca mt-3" *ngIf="custom">
                          
                            
                            <button class="o-f" *ngIf="!loading"   (click)="load();onSumbitOtp()">SUBMIT</button>
                
                            <button class="o-f op05"  *ngIf="loading" >Loading...</button> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</body>




