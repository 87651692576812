<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
  </div>

<div class="container mt-5 mb-5">
    <div class="">
      <div class="row mt-3 mb-5">
        <div class="col-md-7">
          <div class="row">
            <span>
              <!-- <img class="image-custom qwqwqw" [src]="imgurl || '/assets/img/no-img.png'" alt=""> -->
            <img class="image-custom qwqwqw" [hidden]="!isLoaded" (load)="onImageLoad()" [src]="imgurl" alt="">

              <ngx-skeleton-loader *ngIf="!isLoaded" [theme]="{width: '670px',
              height: '405px',
              position: 'relative'}" count="1"></ngx-skeleton-loader>
            </span>
          </div>
          <div class="row mt-4" *ngIf="productDetails?.productImage?.length">
            <div *ngFor="let item of productDetails?.productImage" class="col-4 p-0 col-md-4">
              <img class="custom-img h-100 change-img" style=" cursor: pointer;" [src]="item"
                (click)="changeProductimage(item)">
            </div>
          </div>
        </div>
        <div class="col-md-5 p-0">
          <div class="row">
  
            <div class="col-12">
              <p class="prdct-home">{{productDetails?.productName}}</p>
            </div>
            <div class="col-12">
              <p class="para-text-common mb-0">
                Product ID : {{productDetails?._id | slice:0:7}}
              </p>
            </div>
            <div class="col-6">
              <div class="row  justify-content-between">
                <span [ngClass]="{'priceTestP':productDetails?.isDealActive}">
                  {{ mrp ? getPrice(mrp | currency:"ZAR":"R") :
                  productDetails?.priceSizeDetails[0]?.price && getPrice(productDetails?.priceSizeDetails[0]?.price |
                  currency:"ZAR":"R")}}
                </span>
                <!-- <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                  {{ mrp ? getPrice(mrp | currency:"ZAR":"R") :
                  productDetails?.priceSizeDetails[0]?.price && getPrice(productDetails?.priceSizeDetails[0]?.price |
                  currency:"ZAR":"R")}}
                </span> -->
                <span *ngIf="productDetails?.isDealActive" class="priceTestPColor">
                  {{ afterDiscountPrice ? getPrice(afterDiscountPrice | currency:"ZAR":"R") :
                  getPrice(afterDiscountPrice | currency:"ZAR":"R") }}
                </span>
                <span *ngIf="productDetails?.isDealActive" class="priceTestPColorD">
                  {{productDetails?.dealDiscount}}% off
                </span>
              </div>
            </div>
            <div class="col-12 mt-2 mb-2">
              <img src="assets/latest/veriticalline.svg" style="height: 2px;width: 100%;" alt="">
            </div>
          </div>
  
          <div class="" style="margin-top: 1em;">
           
            <div class="row">
  
              <div class="col-4 pr-0">
                <p class="pid">Product Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{productDetails?.productName }}</p>
              </div>
            </div>
            <div class="row">
  
              <div class="col-4 pr-0">
                <p class="pid">Category Name</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{productDetails?.categoryId.categoryName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4 pr-0">
                <p class="pid">Quantity Available</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{ quantity ? quantity : productDetails?.priceSizeDetails[0]?.quantity}}</p>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-4 pr-0">
                <p class="pid">Unit</p>
              </div>
              <div class="">:</div>
              <div class="col-7">
                <p class="pid-1">{{ productDetails?.unit}}</p>
              </div>
            </div> -->
            <div class="row">
              <div class="col-4 pr-0">
                <p class="pid">Size/Value</p>
              </div>
              <div class="">:</div>
              <div class="col-7 flex-data">
                <ng-container *ngFor="let item of productDetails?.priceSizeDetails">
                  <p class="pid-1 new-loop-para"
                    (click)="price(item?.price, item?.quantity, item?.value, item?.id, item?.unit)"
                    [ngClass]="{ active: value == item?.value }">
                    {{ item?.value }} {{ item?.unit !== 'other' ? '' + item?.unit : '' }}
                  </p>
                </ng-container>
              </div>
            </div>
  
          </div>
  
          <div class="mt-4 pt-3">
            <span class="d-flex justify-content-between">
              <h1 class="text-center desc">Description</h1>
            </span>
            <p style="word-break: break-word;" [innerHTML]="getRawText( productDetails?.description)"  class=" text-left descrip ml-3">
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>