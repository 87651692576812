import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-retailer-add-product',
  templateUrl: './retailer-add-product.component.html',
  styleUrls: ['./retailer-add-product.component.scss'],
})
export class RetailerAddProductComponent implements OnInit {
  headingName:any ='ADD PRODUCT'
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Add Product',route:'/retailer-add-product'}
  ]
  codeValue: any;
  addProduct!: FormGroup;
  addImage!: FormGroup;
  unit: any;
  categoryListArray: any = [];
  subCategoryListArray: any = [];
  imageUrl: any;
  thumbnail: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  categoryId: any;
  subCategoryId: any;
  unitsArray : any = ["kg", "gm", "mg", "mm", "cm", "m", "in", "ft", "l", "ml","Yrs","Size","Pieces","other"]
  // =============
  mediaType: any = 'media';

  // addDynamicImageArray: any

  addDynamicImageArray: any = [];
  validationMessage: any; //global validation code
  productReferenceId: any;
  imageLinks: any = [];
  productId: any;
  mediaUrl: any;
  catId: any;
  subid: any;
  imagThumb: any;
  attendeeArray = [{ value: '', price: '', quantity: '', unit: '' }];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getCategoryList();
    this.addDynamicImage();
    // this. getSubCategoryList()
    // form validation

    this.addImage = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(256),
      ]),
    });

    this.addProduct = new FormGroup({
      category: new FormControl('',[Validators.required]),

      subCategory: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
      ]),

      // mrp: new FormControl('', [
      //   Validators.required,
      //   // Validators.pattern('^([R0-9$.])*$'),
      //   // Validators.pattern(/^[1-9][0-9]/),
      // ]),

      // qty: new FormControl('', [
      //   Validators.required,
      //   // Validators.pattern('^([R0-9$.])*$'),
      // ]),
      unitProduct: new FormControl('', []),
      // unit: new FormControl(''),
      // length: new FormControl('')
      deliveryDays: new FormControl('',[Validators.required]),

      description: new FormControl(''),
      // productForss: new FormControl(''),
    });
    this.getSearchfirst();
  }

  NumOnly(event: any) {
    let Numpattern = /^([R0-9$.])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  addAttendee() {
    let mesage = '';
    for (let item of this.attendeeArray) {
     

      if (!item.value || !item.price || !item.quantity) {
        if (!item.value) {
          mesage = 'Please enter size/value';
        } else if (!item.price) {
          mesage = 'Please enter amount';
        } else {
          mesage = 'Please enter quantity';
        }

        return this.commonService.errorToast(mesage);
      }
    }
    // for (let item of this.attendeeArray) {
    //   item.unit = this.addProduct.value.unitProduct;
    // }
    this.attendeeArray.push({
      value: '',
      price: '',
      quantity: '',
      unit: this.addProduct.value.unitProduct,
    });
  }
  removeAttendee(i: any) {
    this.attendeeArray.splice(i, 1);
  }
  dataArry: any;
  // search get api===========================================

  searchList: any = [];
  aarayData: any = [];
  getSearch(event: any) {
    if (!event.target.value) {
      // setTimeout(() => {
      this.catId = '';
      this.subid = '';
      this.searchList = [];
      this.imageLinks = [];
      this.productReferenceId = '';
      this.addProduct.reset();
      return;
      // }, 10);
    }
    let title = this.addImage.value.title;
    if (title.length > 0) {
      let url = `admin/productListByAdmin`;
      let data = {
        search: this.addImage.value.title,
        page: this.currentPage,
        limit: this.itemPerPage,
      };
      this.commonService.postApi(url, data, 1).subscribe(
        (res: any) => {
          if (res['responseCode'] == 200) {
            this.searchList = res['result']['docs'];
            this.addProduct.patchValue({
              description: this.searchList[0].description,
              category: this.searchList[0].categoryId.categoryName,
              subCategory: this.searchList[0].subCategoryId.subCategoryName,
              unit: this.searchList[0].unit,
            });
            this.catId = this.searchList[0].categoryId._id;
            this.subid = this.searchList[0].subCategoryId._id;
            let temp = [];
            let ind = this.imageLinks.length;

            res['result']['docs'][0]['productImage'].forEach(
              (element: any, index: number) => {
                if (ind < 3) {
                  temp.push({ url: element, isListed: true });
                  ind++;
                }
              }
            );

            this.imageLinks = this.commonService.removeDuplicate(temp, ['url']);
            this.productReferenceId = res['result']['docs'][0]['_id'];
          } else {
            this.catId = '';
            this.subid = '';
            this.searchList = [];
            // this.imageLinks = [];
            this.imageLinks = this.imageLinks.filter((res: any) => {
              return !res.isListed;
            });
            this.productReferenceId = '';
            this.addProduct.reset();
          }
        },
        (err) => {
          this.catId = '';
          this.subid = '';
          this.searchList = [];
          this.imageLinks = this.imageLinks.filter((res: any) => {
            return !res.isListed;
          });
          this.productReferenceId = '';
          this.addProduct.reset();
        }
      );
    }
    // this.isValide = true;
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  UnitValue: any;
  changeValue(event: any) {
    this.UnitValue = event.target.value;
  }
  getSearchfirst() {
    let url = `admin/productListByAdmin`;
    let data = {
      page: this.currentPage,
      limit: this.itemPerPage,
    };
    this.commonService.postApi(url, data, 1).subscribe(
      (res: any) => {
        if (res['responseCode'] == 200) {
          this.searchList = res['result']['docs'];
        } else {
          this.searchList = [];
          this.imageLinks = [];
        }
      },
      (err) => {}
    );

    // this.isValide = true;
  }

  _id: any;
  removeEmptyKey(obj: any) {
    Object.entries(obj).forEach(([key, val]) => val === '' && delete obj[key]);
    return obj;
  }
  productAdd() {
    if(!this.addProduct.valid){
      return this.commonService.errorToast('Please fill all the fields.')
    }
    let productImage = [];
    for (let item of this.imageLinks) {
      productImage.push(item.url);
    }
    let temp = false;
    let mesage = '';
    

    for (let item of this.attendeeArray) {
     

      if (!item.value || !item.price || !item.quantity) {
        temp = true;
        if (!item.value) {
          mesage = 'Please enter size/value';
        } else if (!item.price) {
          mesage = 'Please enter amount';
        } else {
          mesage = 'Please enter quantity';
        }

        return this.commonService.errorToast(mesage);
      }
      // item.unit = this.addProduct.value.unitProduct;
    }
    

    var productData = {
      productName: this.addImage.value.title,
      productImage: productImage,
      // categoryId: this.catId,
      categoryId: this.catId || this.addProduct.value.category,
      subCategoryId: this.subid || this.addProduct.value.subCategory,
      // subCategoryId: this.subid,
      price: this.addProduct.value.mrp,
      quantity: this.addProduct.value.qty,
      expectedDeliveryDays: this.addProduct.value.deliveryDays,
      description: this.addProduct.value.description,

      brand: 'Myntra',
      productFor: 'RETAILER',
      productReferenceId: this.productReferenceId
        ? this.productReferenceId
        : '',
      // thumbnail: this.imagThumb ||this.dataArry,
      thumbnail: productImage[0] || this.dataArry,
      // unit : this.attendeeArray,
      unit: this.addProduct.value.unitProduct,
      priceSizeDetails: this.attendeeArray,
    };
    this.commonService.removeEmptyKey(productData);
    this.commonService.showSpinner();
    this.commonService.postApi('product/addProduct', productData, 1).subscribe(
      (res: any) => {
        
        if (res['responseCode'] == 200) {
          this.commonService.successToast(res['responseMessage']);
          this.commonService.hideSpinner();
          // set current url to localstorage
          this.router.navigate(['/retailer-product-list']);
          // localStorage.setItem('token', res.result.token)
          // after seting current path to localstorage
          // this.route.navigate(['/auth/otp'])
        }
      },
      // (err) => {
      //   this.commonService.hideSpinner();
      //   this.commonService.errorToast(err.error['responseMessage']);
      // }

      (err: any) => {
        
        if (err.responseCode == 409) {
          this.commonService.hideSpinner();
          this.commonService.errorToast(err.responseMessage);
        }
      }
    );
  }

  getCategoryList() {
    let apiReqUrl: any = `admin/listCategory`;
    this.commonService.showSpinner();
    let apiReqData: any = {
      // page: String(this.currentPage),
      // limit: String(this.itemPerPage)
    };
    // remove empty keys from request body
    // apiReqData = this.commonService['removeEmptyKey'](apiReqData)
    apiReqData = this.commonService.removeEmptyKey(apiReqData);

    this.commonService
      .postApi(apiReqUrl, apiReqData, 1)
      .subscribe((res: any) => {
        if (res.responseCode == 200) {
          this.categoryListArray = res.result.docs ? res.result.docs : '';
          this.totalItems = res.result.total;
          this.commonService.hideSpinner();
          // this.commonService.successToast(res.responseMessage);
        } else {
          this.categoryListArray = [];
          this.totalItems = 0;
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }

  getCategoryId(id: any) {
    this.categoryId = id.target.value;
    
    this.getSubCategoryList();
  }

  getSubCategoryList() {
    let apiReqUrl = `${
      'admin/subcategoryListWithCategory' + '?categoryId=' + this.categoryId
    }`;

    this.commonService.showSpinner();

    // remove empty keys from request body
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      if (res.responseCode == 200) {
        this.subCategoryListArray = res.result.subCategory
          ? res.result.subCategory
          : '';
        this.totalItems = res.result;
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.categoryListArray = [];
        this.totalItems = 0;
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    });
  }

  getSubCategoryId(id: any) {
    this.subCategoryId = id.target.value;
  }

  // =======================================new=============

  addDynamicImage() {
    if (
      this.addDynamicImageArray.length &&
      this.addDynamicImageArray[this.addDynamicImageArray.length - 1].image ==
        ''
    ) {
      return this.commonService.infoToast(
        'Please choose the image in previous added image field.'
      );
    }
    if (
      this.addDynamicImageArray.length >= 0 &&
      this.addDynamicImageArray.length <= 2
    ) {
      this.addDynamicImageArray.push('');
    } else {
      this.commonService.infoToast('You can only upload maximum 3 images.');
    }
  }
  removeDynamicImage(index: number) {
    this.addDynamicImageArray.splice(index, 1);
  }

  // naviagetToHomepage() {
  //   this.router.navigate(['/homepage'])

  // }

  isValide: boolean = false;

  uploadImgS(event: any, index: any): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img, index);
  }
  uploadImageFunc(img: any, index: any) {
    var fb = new FormData();
    fb.append('file', img);
    this.commonService.showSpinner();
    this.commonService
      .postFormDataApi('user/uploadFile', fb, 1)
      .subscribe((res: any) => {
        
        if (res.responseCode == 200) {
          // this.imageUrl = res.result
          this.addDynamicImageArray[index] = res.result.mediaUrl;

          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      });
  }

  postImage: any = [];
  imgSrc: any = [];
  // onvertToBase(file, i) {
  //   var me = this
  //   var reader = new FileReader();
  //   reader.onloadend = function () {

  //     me.imgSrc[i] = reader.result
  //   }
  //   reader.readAsDataURL(file);
  // }
  inds: any;
  async uploadImg(event: any) {
    if (event.target.files.length + this.imageLinks.length > 3) {
      this.commonService.infoToast('Please select maximum 3 image');
      return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.imageUrl = file;
      await this.uploadProfilePic(file, index);
    }
    this.mediaType = 'png';
  }

  async uploadProfilePic(img: any, i: number) {
    let url = 'user/uploadMultipleFile';
    this.imageLinks.push('assets/img/imageLoader.gif');

    const formData = new FormData();
    formData.append('uploaded_file', img);
    let res: any = await this.commonService
      .postFormDataApi(url, formData, 1)
      .toPromise();
    if ((await res.responseCode) == 200) {
      this.imageLinks.pop();

      this.imageLinks.push({
        url: res['result'][0]['mediaUrl'],
        isListed: false,
      });

      this.imagThumb = res['result'][0]['thumbnail'];

      // this.commonService.showSpinner();
    } else {
      this.commonService.errorToast("Can't Upload This File");
    }
  }
  closeimage(i: number) {
    this.imageLinks.splice(i, 1);
    if (this.imageLinks.length == 0) {
      this.mediaType = 'png';
    }
  }
 
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 48 && !event.target.value) {
      return false;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
