<section>
    <div class="container  fw-b ">
        <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    </div>
    <div class="container mb-3">
        <!-- <iframe class="w-100" src="https://maps.google.com/maps/@28.534792,77.2707152,19.97z?output=embed"
                frameborder="0" style="border:0;visibility: visible;" allowfullscreen ></iframe> -->
        <div class="map" #map>

            <google-map [center]="center" [zoom]="zoom">
                <map-marker [options]="marker"></map-marker>
            </google-map>

        </div>
    </div>
    <div class="container wrapper-content">
        <div class="row">
            <div class="col-lg-8 col-md-12 p-0">
                <form [formGroup]="contactUs" id="contact-form" name="contact-form" action="mail.php" method="POST">

                    <!--Grid row-->
                    <div class="row ">

                        <!--Grid column-->
                        <div class="col-md-6 ">
                            <div class="md-form mb-3 ">
                                <label for="name" class="lb">First Name</label>
                                <input type="text" id="name" name="name" formControlName="fName" class="form-control"
                                    placeholder=" Enter First Name" (keypress)="preventSpace($event);restirictChar($event)" maxlength="32">

                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('fName')?.hasError('required') && contactUs.get('fName')?.touched ">
                                    Please enter first name.</div>

                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('fName')?.hasError('minlength') && contactUs.get('fName')?.dirty ">
                                    *Please enter minimum 2 character.</div>

                                <!-- <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('fName')?.hasError('pattern') && contactUs.get('fName')?.dirty">
                                    First latter should be alphanumeric.

                                </div> -->
                                <!-- <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('fName')?.hasError('required') && contactUs.get('fName')?.touched ">
                                    *First name is required.</div>
                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('fName')?.hasError('maxlength') && contactUs.get('fName')?.touched ">
                                    *Can not enter more than 32 character.</div>
                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('fName')?.hasError('pattern') && contactUs.get('fName')?.dirty">
                                    *Please enter valid name.</div> -->
                            </div>
                        </div>
                        <!--Grid column-->

                        <!--Grid column-->
                        <div class="col-md-6">
                            <div class="md-form mb-0">
                                <label for="email" class="lb">Last Name</label>
                                <input type="text" formControlName="lName" id="email" name="email" class="form-control"
                                    placeholder=" Enter Last Name" (keypress)="preventSpace($event)" maxlength="32">

                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('lName')?.hasError('required') && contactUs.get('lName')?.touched ">
                                    Please enter last name.</div>

                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('lName')?.hasError('minlength') && contactUs.get('lName')?.dirty ">
                                    *Please enter minimum 2 character.</div>

                                <!-- <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('lName')?.hasError('pattern') && contactUs.get('lName')?.dirty">
                                    first latter should be alphanumeric.

                                </div> -->

                                <!-- <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('lName')?.hasError('required') && contactUs.get('lName')?.touched ">
                                    *Last name is required.</div>
                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('lName')?.hasError('maxlength') && contactUs.get('lName')?.touched ">
                                    *Can not enter more than 32 character.</div>
                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('lName')?.hasError('pattern') && contactUs.get('lName')?.dirty">
                                    *Please enter valid name.</div> -->
                            </div>
                        </div>
                        <!--Grid column-->

                    </div>
                    <!--Grid row-->

                    <!--Grid row-->
                    <div class="row">
                        <div class="col-md-12 mt-3">
                            <div class="md-form mb-0">
                                <label for="subject" class="lb">Email</label>
                                <input type="email" formControlName="email" id="subject" name="subject"
                                    placeholder=" Enter Email" (keypress)="preventSpace($event)" maxlength="250"
                                    class="form-control form-set">
                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('email')?.hasError('required') && contactUs.get('email')?.touched ">
                                    *Email is required.</div>
                                <div class="for-validation text-danger"
                                    *ngIf="contactUs.get('email')?.hasError('pattern') && contactUs.get('email')?.dirty ">
                                    *Please
                                    enter a valid email</div>
                            </div>
                        </div>
                    </div>
                    <!--Grid row-->
                    <!--Grid row-->
                    <div class="row">
                        <!--Grid column-->
                        <div class="col-md-6 mt-3">

                            <label class="form-label Eadd lb">Country<span class="yy"></span></label>
                            <select class="form-control email-pass-pro" id="asd3" formControlName="Country"
                                (change)="getcontents($event)">
                                <option value="" selected>Select Country</option>
                                <option *ngFor="let item of products" value="{{item.name}}">{{item.name}}
                                </option>
                            </select>
                        </div>
                        <!--Grid column-->
                        <!--Grid column-->
                        <div class="col-md-6 mt-3">
                            <div class="md-form mb-0">
                                <label for="name" class="lb">Phone</label>
                                <div class="">
                                    <div class="input-group">

                                        <div class="input-group-prepend ">
                                            <span class="asd3">
                                                <input list="browsers"
                                                    class="form-control email-pass-pro ddd"
                                                    formControlName="countryCode" name="browser" id="browser"
                                                    style="    height: 36px !important;
                                                    border: none !important;
                                                    background: transparent !important;"
                                                    maxlength="3" (keypress)="NumOnly($event)"
                                                    >
                                                </span>

                                            <datalist id="browsers">
                                                <option *ngFor="let code of products" value="{{code.phonecode}}">
                                                    {{code.flag}}
                                                    {{code.phonecode}}
                                                    {{code.isoCode}} </option>

                                            </datalist>
                                            <input type="text" class="fbb" (keypress)="preventSpace($event)"
                                                (keypress)="NumOnly($event)" formControlName="Phone" maxlength="15">

                                        </div>

                                        <div class="for-validation  text-danger"
                                            *ngIf="contactUs.get('countryCode')?.hasError('invalidCountryCode') && contactUs.get('countryCode')?.touched">
                                            *Country code is required.</div>
                                        <div class="for-validation  text-danger"
                                            *ngIf="contactUs.get('Phone')?.hasError('required') && contactUs.get('Phone')?.touched">
                                            *Mobile number is required.</div>
                                        <div class="for-validation  text-danger"
                                            *ngIf="contactUs.get('Phone')?.hasError('pattern') && contactUs.get('Phone')?.dirty">
                                            Please enter valid Mobile number</div>


                                        <div class="for-validation text-danger"
                                            *ngIf="contactUs.get('Phone')?.hasError('minlength') && contactUs.get('Phone')?.dirty ">
                                            *Please enter valid number between 8-15 digit.</div>

                                        <div class="for-validation text-danger"
                                            *ngIf="contactUs.get('Phone')?.hasError('maxlength') && contactUs.get('Phone')?.dirty">
                                            *Please enter valid number between 8-15 digit.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Grid column-->



                    </div>
                    <!--Grid row-->

                    <!--Grid row-->
                    <div class="row">

                        <!--Grid column-->
                        <div class="col-md-12 mt-3">

                            <div class="md-form">
                                <label for="message" class="lb">Comment</label>
                                <textarea type="text" formControlName="Comment" (keypress)="preventSpace($event)"
                                    id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                                <div class="for-validation  text-danger"
                                    *ngIf="contactUs.get('Comment')?.hasError('required') && contactUs.get('Comment')?.touched ">
                                    *Comment is required.</div>
                            </div>

                        </div>
                    </div>
                    <!--Grid row-->


                    <div class="d-flex justify-content-center submit-div">
                        <button class="btn btn-outline-success2 my-2 my-sm-0" [disabled]="!contactUs.valid"
                            type="submit" (click)="contactUss()">Submit</button>
                    </div>
                </form>
            </div>



            <div class=" col-lg-4 col-md-12" style="
            margin-top: 24px;">
                <mat-card class="example-card1" *ngIf="termsData">
                    <mat-card-title class="mt-4 mb-4 titie">Support Available 24*7</mat-card-title>
                    <mat-card-content>
                        <span innerHtml="{{termsData?.description}}" id="para_alex"></span>
                        <!-- <div>
                            <p><span>Phone</span><span>:</span><span> {{termsData?.mobileNumber}}</span></p>
                        </div> -->

                        <!-- <div>
                            <p><span>Phone Number</span><span>:</span><span>&nbsp; {{this.termsData?.mobileNumber}}</span></p>
                        </div> -->
                        <div *ngIf="termsData?.email">
                            <span>Support Email</span><span> : </span><span> <a href="mailto:{{termsData?.email}}">{{termsData?.email}}</a> </span>
                        </div>

                        <!-- <div>
                            <p><span>Address</span><span>:</span><span>
                                {{termsData?.address}}
                                </span></p>
                        </div> -->
                        <!-- <div class="">
                            {{termsData?.link}}
                        </div> -->
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </div>


</section>
