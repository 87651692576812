import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { CommonService } from 'src/app/provide/common.service';
declare var $ :any
@Component({
  selector: 'app-customer-wallet',
  templateUrl: './customer-wallet.component.html',
  styleUrls: ['./customer-wallet.component.scss']
})
export class CustomerWalletComponent implements OnInit {

  btn: any = "Pending"
  orderList: any
  count: any
  catName: any
  currentPage: any = 1;
  pageSize: any = 10;
  serviceStatus: any = 'PENDING'
  notFoundData: boolean = false
  headingName: any = 'My Wallet'
  linkerRouteItems: any = [
    // { name:'Home',route: '/service-order-detail'},
    { name: 'Transaction History', route: '/customer-profile' }
  ]

  service
  serviceRole: string;
  myId
  constructor(public commonService: CommonService, public router: Router, public sanitizer : DomSanitizer) { }

  ngOnInit(): void {
    this.serviceOrderList();
    this.serviceRole = localStorage.getItem("serviceRole")
    this.myId = localStorage.getItem("userId")

  }

  selectBtn(status) {

    this.serviceOrderList();

  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  isLoading: boolean = false
  wallet: any
  wallet2: any
  serviceOrderList() {
    this.isLoading = true;
    this.orderList = []
    this.count = 0;
    const data = {
      page: this.currentPage,
      limit: this.pageSize,
    }
    this.commonService.getApi(`deliveryOrders/getWalletTransaction?isCommission=true&page=${this.currentPage}&limit=${this.pageSize}`, 1).subscribe((res: any) => {
      this.isLoading = false
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        this.commonService.hideSpinner()
        this.orderList = res.result.docs;
        this.wallet = res.result.wallet
        this.wallet2 = res.result
        if (res.result.docs.length > 0) {
          this.notFoundData = false
        } else {
          this.notFoundData = true
        }
        this.count = res.result.total
        this.catName = res.result.docs.myOrders[0]
      }

    }, (err: any) => {
      this.isLoading = false
      this.notFoundData = true
      if (err.responseCode == 409) {
        this.commonService.hideSpinner()
        this.commonService.errorToast(err.responseMessage)
      }
    })
  }

  changePageNumber(page) {
    this.currentPage = page;
    this.serviceOrderList();
  }

  getWalletName(usType: string) {
    let walletName = usType == "PICKUP_PARTNER" ? "PW" :
      usType == "FIELD_ENTITY" ? "FW" :
        usType == "DELIVERY_PARTNER" ? "DDW" :
          usType == "CUSTOMER" ? "CW" :
            usType == "RETAILER" ? "RW" :
              usType == "ADMIN" ? "XAW" : ""



    if (walletName == 'XAW') {
      if (this.serviceRole == 'DELIVERY') {
        walletName = "DW"
      }
    }

    return walletName
  }
amount = 0
  OzhoUrl: any
  ozhoPayment() {
    let url = `order/ozowWalletCheckOut`
    let data = {
      webFailure: this.commonService.webSiteUrl + 'customer-payment-cancel',
      webError: this.commonService.webSiteUrl + 'customer-payment-error',
      webSuccess: this.commonService.webSiteUrl + 'customer-payment-success',
      cancelUrl: this.commonService.ozhoBaseUrl,
      errorUrl: this.commonService.ozhoBaseUrl,
      SuccessUrl: this.commonService.ozhoBaseUrl,
      amount: this.amount,
      testMode: true,
      trxFrom: 'WEB'
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.OzhoUrl = res.result
        this.ozhoRedirect()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  htmlData : any
  walletPayment() {
    let url = `payment/checkoutWalletPayfast`
    let data = {
      webFailure: this.commonService.webSiteUrl + 'customer-payment-cancel',
      webError: this.commonService.webSiteUrl + 'customer-payment-error',
      webSuccess: this.commonService.webSiteUrl + 'customer-payment-success',
      cancelUrl: this.commonService.ozhoBaseUrl,
      errorUrl: this.commonService.ozhoBaseUrl,
      SuccessUrl: this.commonService.ozhoBaseUrl,
      amount: this.amount,
      testMode: true,
      trxFrom: 'WEB'
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.htmlData =  this.sanitizer.bypassSecurityTrustHtml(res.result.file)
       
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  ozhoRedirect() {
    window.location.href = this.OzhoUrl
  }
  openModal(){
    $('#addMoneyModal').modal('show')
  }
  paymentMethod : any
  getPayamnetMethod(e){
    this.paymentMethod = e
  }
  onProceed(){
    if(this.paymentMethod == 'ozow'){
      this.ozhoPayment()
    }
    else{
      this.walletPayment()
    }
  }
}
