import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-customer-service-list',
  templateUrl: './customer-service-list.component.html',
  styleUrls: ['./customer-service-list.component.scss']
})
export class CustomerServiceListComponent implements OnInit {
  _id: any
  currentPage = 1;
  pageSize = 15;
  serviceName: any
  headingName:any ='BOOKING CATEGORY'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Booking Category',route:'/customer-service-list'}
  ]
  isLoaded: boolean = false;
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }

  ngOnInit(): void {
    this.getServiceList();
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  totalItems: any
  isLoading: boolean = false
  // get retailer detail by id
  getServiceList() {

    let apiReqUrl: any = `service/listCategoryService`
    let apiReqData = {
      search: this.serviceName,
      page: this.currentPage,
      limit: this.pageSize,
    }

    this.commonService.removeEmptyKey(apiReqData)
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    // this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, apiReqData, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        

        this.productDetails = res.result.docs;
        if (!this.productDetails.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        // this.imgurl = res.result.productImage[0]
        this.totalItems = res.result.total
        this.imageLinks = res["result"]["thumbnail"]
        // this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading = true;
        this.productDetails = []
        // this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.isLoading = true;
      this.productDetails = []
      // this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  navigateToViewProduct(_id, catName,desc) {
    this.router.navigate(['/customer-service-available'], { queryParams: { _id: _id, catName: catName,desc:desc } })
  }
  pagination(event) {
    this.currentPage = event;
    this.getServiceList();
  }
  onImageLoad(){
    this.isLoaded = true
  }
  length(n){
    return new Array(n)
  }
}
