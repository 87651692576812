<div class="container  fw-b ">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-4 mt-4 wrapper-content">
    <div class="row mt-1">
        <div class="col-lg-3 col-md-6 mb-2 col-sm-6">
            <button class="tabBtn" [ngClass]="{active : btn == 'Pending'}"
                (click)="selectBtn('Pending')">PENDING</button>
        </div>
        <div class="col-lg-3 col-md-6 mb-2 col-sm-6">
            <button class="tabBtn" [ngClass]="{active : btn == 'Complete'}"
                (click)="selectBtn('Complete')">DELIVERED</button>
        </div>
    </div>
    
    <div class="row mt-3">
        <div class="col-12 table-responsive table-striped" *ngIf="btn == 'Pending' ">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Order Id</th>
                        <th scope="col">Order Date</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of orderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <th>{{10 * (currentPage - 1) + i+1}}</th>
                        <th>
                            <img class="imagtag" 
                                [src]="item?.myOrders[0]?.productId?.thumbnail" alt="">
                        </th>
                        <th scope="row">{{item?.userId?.firstName}} {{item?.userId?.lastName}}</th>
                        <td>{{item?.orderId}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td class="red-color">{{item?.actualPrice && getPrice(item?.actualPrice | currency:"ZAR":"R")}}</td>
                        <td  [ngClass]="{
                            'green-color': item?.paymentStatus=='COMPLETED',
                            'red-color': item?.paymentStatus!='COMPLETED'}">
                            {{item?.paymentStatus}}</td>
                        <td><i class="fa fa-eye mpl10" aria-hidden="true" style="cursor: pointer;"
                                [queryParams]="{_id : item?._id, status: item?.orderStatus}"
                                [routerLink]="['/retailer-order-history-view']"></i></td>
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'" ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
        <div class="col-12 table-responsive" *ngIf="btn == 'Complete' ">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Order Id</th>
                        <th scope="col">Order Date</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of orderList | paginate:{itemsPerPage:pageSize, currentPage: currentPage,totalItems: count}; let i=index; ">
                        <th>{{10 * (currentPage - 1) + i+1}}</th>
                        <th>
                            <img class="imagtag" 
                                [src]="item?.myOrders[0]?.productId?.thumbnail" alt="">
                        </th>
                        <th scope="row">{{item?.userId?.firstName}} {{item?.userId?.lastName}}</th>
                        <td>{{item?.orderId}}</td>
                        <td>{{item?.createdAt | date: 'yyyy-MM-dd h:mm:ss a' || '--'}}</td>
                        <td class="red-color">{{item?.actualPrice && getPrice(item?.actualPrice | currency:"ZAR":"R")}}</td>
                        <td  [ngClass]="{
                            'green-color': item?.paymentStatus=='COMPLETED',
                            'red-color': item?.paymentStatus!='COMPLETED'}">
                            {{item?.paymentStatus}}</td>
                        <td><i class="fa fa-eye mpl10" aria-hidden="true" style="cursor: pointer;"
                                [queryParams]="{_id : item?._id, status: item?.orderStatus}"
                                [routerLink]="['/retailer-order-history-view']"></i></td>
                    </tr>
                </tbody>
            </table>
            <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10" [isWidth]="'80px'"  ></app-table-loader>
            <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%;"
                (pageChange)="changePageNumber($event)"></pagination-controls>
        </div>
    </div>



</div>