<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container  mb-4">
  <div class=" mb-3">
    <!-- <div class="row mt-4 mb-4">
      <span><i class="fa fa-arrow-left titt" style="cursor: pointer" routerLink="/retailer-product-list"></i></span>
      <h2 class="ml-5 deal-2">Add Product</h2>
    </div> -->
    <div class="grid-container">
      <div class="section1">
    <form [formGroup]="addImage">
      <div class="form-row">
        <div class="form-group col-md-6" *ngIf="searchList">
          <label for="inputEmail4" class="tite">Search or Add New Product</label>
      

          <input type="text" list="codes" class="form-control" id="deal-5" formControlName="title"
            [(ngModel)]="codeValue" (change)="getSearch($event)" placeholder="Search or Add New Product" />
          <datalist id="codes">
            <option *ngFor="let item of searchList" [value]="item.productName">
              {{ item?.productName }}
            </option>
          </datalist>
        

          <div class="for-validation text-danger" *ngIf="
              addImage.get('title')?.hasError('required') &&
              addImage.get('title')?.touched
            ">
            *Title is required.
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="!searchList.length">
    

      <!-- ============= -->

      <form [formGroup]="addProduct" (ngSubmit)="f1.form.valid" #f1="ngForm">
        <div class="form-row">
          <div class="col-md-6">
            <label for="inputEmail4" class="tite">Category</label>
            <div>
              <select class="sel-2" formControlName="category" (change)="getCategoryId($event)">
                <option value="" selected>Select Category</option>
                <option *ngFor="let item of categoryListArray" [value]="item._id">
                  {{ item?.categoryName || "--" }}
                </option>
              </select>
              <div class="for-validation text-danger" *ngIf="
                addProduct.get('category')?.hasError('required') &&
                addProduct.get('category')?.touched
              ">
                *Category is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- <label for="inputEmail4" class="tite">SubCategory</label>
                    <input type="text" class="form-control" id="deal-5" placeholder="Enter description" maxlength="150"
                        formControlName="
                    subCategory" (keypress)="preventSpace($event)"> -->
            <label for="inputEmail4" class="tite">Subcategory</label>
            <select class="form-control sel-2" formControlName="subCategory" (change)="getSubCategoryId($event)">
              <option value="" selected>Select Sub-Category</option>
              <option *ngFor="let item of subCategoryListArray" [value]="item._id">
                {{ item?.subCategoryName || "--" }}
              </option>
            </select>
            <div class="for-validation text-danger" *ngIf="
                addProduct.get('subCategory')?.hasError('required') &&
                addProduct.get('subCategory')?.touched
              ">
              *SubCategory is required.
            </div>
          </div>
        </div>
        <!-- <div class="form-group" style="margin-top: 1rem">
          <div class="">
            <label for="inputEmail4" class="tite">Unit</label>

              <select  class="form-control" formControlName="unitProduct">
                <option value="">Select Unit</option>
                <option [value]="item" *ngFor="let item of unitsArray">{{item}}</option>
              </select>
          
            <div class="for-validation text-danger" *ngIf="
                addProduct.get('unitProduct')?.hasError('required') &&
                addProduct.get('unitProduct')?.touched
              ">
              *Unit is required.
            </div>
          </div>
        </div> -->
        <div class="card mt-2" *ngFor="let item of attendeeArray; let i = index; let j = last">
          <div class="form-row mb-3 mt-3 add-pading">
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Size/Value</label>
              <input type="text" class="form-control" id="value" #value="ngModel" required name="value"
                placeholder="Enter Value" maxlength="32" (keypress)="preventSpace($event)" [(ngModel)]="item.value"
                [ngModelOptions]="{ standalone: true }" />
                <div *ngIf="value?.errors && (value?.touched || f1?.submitted)">
                  <div class="error_message" *ngIf="value?.errors?.required">
                    <span class="e_arrow"></span>
                    <i>Please enter value</i>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Unit</label>
             

                <select  class="form-control" [(ngModel)]="item.unit" required name="unit"  #unit="ngModel"
                [ngModelOptions]="{ standalone: true }">
                  <option value="">Select Unit</option>
                  <option [value]="items" *ngFor="let items of unitsArray">{{items}}</option>
                </select>
                <div *ngIf="unit.errors && (unit.touched || f1.submitted)">
                  <div class="error_message" *ngIf="unit.errors?.required">
                    <span class="e_arrow"></span>
                    <i>Please select unit</i>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Amount (R)</label>
              <input type="text" class="form-control" id="price" #price="ngModel" required name="price"
                placeholder="Enter Amount" (keypress)="preventSpace($event)" (keypress)="NumOnly($event)"
                [(ngModel)]="item.price" [ngModelOptions]="{ standalone: true }" maxlength="60" />
                <div *ngIf="price?.errors && (price?.touched || f1?.submitted)">
                  <div class="error_message" *ngIf="price?.errors?.required">
                    <span class="e_arrow"></span>
                    <i>Please enter price</i>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Qty. Available</label>
              <input type="text" class="form-control" id="quantity" #quantity="ngModel" required name="quantity"
                placeholder="Enter qty. available" maxlength="60" [(ngModel)]="item.quantity"
                [ngModelOptions]="{ standalone: true }" (keypress)="numberOnly($event)" />
                <div *ngIf="quantity.errors && (quantity.touched || f1.submitted)">
                  <div class="error_message" *ngIf="quantity.errors?.required">
                    <span class="e_arrow"></span>
                    <i>Please enter quantity</i>
                  </div>
                </div>
            </div>

          
          </div>
          <div class="ml-2" style="width: 20%">
            <button type="button" class="btn add-button w-100" style="margin-bottom: 10px" *ngIf="j"
              (click)="addAttendee()">
              Add
            </button>
            <button class="btn btn-danger w-100" *ngIf="!j" style="margin-bottom: 10px" (click)="removeAttendee(i)">
              Remove
            </button>
          </div>
        </div>
        <div class="form-row mb-3 mt-3">
          <div class="">
            <label class="tite">Expected Delivery Days</label>
            <select class="form-control sel-2" formControlName="deliveryDays">
              <option value="">Select Expected Days</option>
              <option value="3 to 7 days">3 to 7 days</option>
              <option value="3 to 8 days">3 to 8 days</option>
              <option value="3 to 9 days">3 to 9 days</option>
              <option value="3 to 10 days">3 to 10 days</option>
            </select>
            <div class="for-validation text-danger" *ngIf="
              addProduct.get('deliveryDays')?.hasError('required') &&
              addProduct.get('deliveryDays')?.touched
            ">
              *Expected delivery days is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="inputEmail4" class="tite">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" id="deal-5" rows="3"
            formControlName="description"></textarea>
          <div class="for-validation text-danger" *ngIf="
              addProduct.get('description')?.hasError('required') &&
              addProduct.get('description')?.touched
            ">
            *Description is required.
          </div>
        </div>
        <div class="text-left mt-5 mb-5">
          <button type="button" class="SAVE" (click)="productAdd()" style="cursor: pointer"
            >
            SAVE
          </button>
         
        </div>
      </form>
    </div>

    <div *ngIf="searchList.length">
      <!-- <mat-card class="mat mb-3">
        <div class="image-upload">
          <div class="mr-2" *ngFor="let item of imageLinks; let index = index" style="border: 1px solid">
            <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i>
            <img mat-card-sm-image [src]="item.url" alt="image" />
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-3 mt-4">
            <div class="input-file-container file">
              <input class="input-file image hidden" id="my-file" accept=".png, .jpg, .jpeg" multiple type="file"
                (change)="uploadImg($event)" />
              <label tabindex="0" for="my-file" class="input-file-trigger">Upload Images</label>
            </div>
          </div>
        </div>
      </mat-card> -->

      <form [formGroup]="addProduct" (ngSubmit)="f1.form.valid" #f1="ngForm">
        <div class="form-row">
          <div class="col-md-6">
            <label for="inputEmail4" class="tite">Category</label>
            <div>
              <input type="text" class="form-control" id="deal-5" placeholder="Enter Category" readonly maxlength="256"
                formControlName="category" (keypress)="preventSpace($event)" />
            </div>
            <div class="for-validation text-danger" *ngIf="
              addProduct.get('category')?.hasError('required') &&
              addProduct.get('category')?.touched
            ">
              *Category is required.
            </div>
          </div>
          <div class="col-md-6">
            <label for="inputEmail4" class="tite">Subcategory</label>

            <input type="text" class="form-control" id="deal-5" placeholder="Enter Subcategory" maxlength="256"
              formControlName="subCategory" (keypress)="preventSpace($event)" readonly />

            <div class="for-validation text-danger" *ngIf="
                addProduct.get('subCategory')?.hasError('required') &&
                addProduct.get('subCategory')?.touched
              ">
              *SubCategory is required.
            </div>
          </div>
        </div>
        <!-- <div class="form-group" style="margin-top: 1rem">
          <div class="">
            <label for="inputEmail4" class="tite">Unit</label>
            <input type="text" class="form-control" placeholder="Enter unit" id="deal-5" formControlName="unitProduct"
              maxlength="16" (keypress)="preventSpace($event)" />


              <select  class="form-control" formControlName="unitProduct">
                <option value="">Select Unit</option>
                <option [value]="item" *ngFor="let item of unitsArray">{{item}}</option>
              </select>
            <select class="form-control sel-2" formControlName="unitProduct">
              <option value="" selected>Select Unit</option>
              <option value="Liter">Liter</option>
              <option value="Kilogram">Kilogram</option>
              <option value="Gram">Gram</option>
              <option value="Size">Size</option>
            </select>
            <div class="for-validation text-danger" *ngIf="
                addProduct.get('unitProduct')?.hasError('required') &&
                addProduct.get('unitProduct')?.touched
              ">
              *Unit is required.
            </div>
          </div>
        </div> -->
        <div class="card mt-2" *ngFor="let item of attendeeArray; let i = index; let j = last">
          <div class="form-row mb-3 mt-3 add-pading">
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Size/Value</label>
              <input type="text" class="form-control" id="value" #value="ngModel" required name="value"
                placeholder="Enter Value" maxlength="32" (keypress)="preventSpace($event)" [(ngModel)]="item.value"
                [ngModelOptions]="{ standalone: true }" />
              <div *ngIf="value.errors && (value.touched || f1.submitted)">
                <div class="error_message" *ngIf="value.errors?.required">
                  <span class="e_arrow"></span>
                  <i>Please enter value</i>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Unit</label>
           
                <select  class="form-control" [(ngModel)]="item.unit" required name="unit" #unit="ngModel"
                [ngModelOptions]="{ standalone: true }">
                  <option value="">Select Unit</option>
                  <option [value]="items" *ngFor="let items of unitsArray">{{items}}</option>
                </select>
                <div *ngIf="unit.errors && (unit.touched || f1.submitted)">
                  <div class="error_message" *ngIf="unit.errors?.required">
                    <span class="e_arrow"></span>
                    <i>Please select unit</i>
                  </div>
                </div>
            </div>
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Amount (R)</label>
              <input type="text" class="form-control" id="price" #price="ngModel" required name="price"
                placeholder="Enter Amount" (keypress)="preventSpace($event)" (keypress)="NumOnly($event)"
                [(ngModel)]="item.price" [ngModelOptions]="{ standalone: true }" maxlength="60" />
              <div *ngIf="price.errors && (price.touched || f1.submitted)">
                <div class="error_message" *ngIf="price.errors?.required">
                  <span class="e_arrow"></span>
                  <i>Please enter price</i>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <label for="inputEmail4" class="tite">Qty. Available</label>
              <input type="text" class="form-control" id="quantity" #quantity="ngModel" required name="quantity"
                placeholder="Enter qty. available" maxlength="60" [(ngModel)]="item.quantity"
                [ngModelOptions]="{ standalone: true }" (keypress)="preventSpace($event)"
                (keypress)="NumOnly($event)" />
                <div *ngIf="quantity.errors && (quantity.touched || f1.submitted)">
                  <div class="error_message" *ngIf="quantity.errors?.required">
                    <span class="e_arrow"></span>
                    <i>Please enter quantity</i>
                  </div>
                </div>
            </div>
            
          </div>
          <div class="ml-2" style="width: 20%">
            <button type="button" class="btn add-button w-100" style="margin-bottom: 10px" *ngIf="j"
              (click)="addAttendee()">
              Add
            </button>
            <button class="btn btn-danger w-100" *ngIf="!j" style="margin-bottom: 10px" (click)="removeAttendee(i)">
              Remove
            </button>
          </div>
        </div>
        <div class="form-group mb-3 mt-3">
          <div class="">
            <label class="tite">Expected Delivery Days</label>
            <select class="form-control sel-2" formControlName="deliveryDays">
              <option value="">Select Expected Days</option>
              <option value="3 to 7 days">3 to 7 days</option>
              <option value="3 to 8 days">3 to 8 days</option>
              <option value="3 to 9 days">3 to 9 days</option>
              <option value="3 to 10 days">3 to 10 days</option>
            </select>
            <div class="for-validation text-danger" *ngIf="
              addProduct.get('deliveryDays')?.hasError('required') &&
              addProduct.get('deliveryDays')?.touched
            ">
              *Expected delivery days is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="inputEmail4" class="tite">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" id="deal-5" rows="3"
            formControlName="description"></textarea>
          <div class="for-validation text-danger" *ngIf="
              addProduct.get('description')?.hasError('required') &&
              addProduct.get('description')?.touched
            ">
            *Description is required.
          </div>
        </div>

        <!-- <div class="form-group">
                <label for="inputEmail4" class="tite">ProductFor</label>
                <input type="text" class="form-control" id="deal-5" placeholder="0%" maxlength="50"
                    formControlName="productForss" (keypress)="preventSpace($event)">
                <div class="for-validation text-danger"
                    *ngIf="addProduct.get('productForss')?.hasError('required') && addProduct.get('productForss')?.touched ">
                    *Enter discount is required.</div>


            </div> -->

        <div class="text-left mt-5 mb-5">
          <button type="button" class="SAVE" (click)="productAdd()" >
            SAVE
          </button>
       
        </div>
      </form>
    </div>
    </div>
    <!-- =========================== -->
    <div class="section2">
      <mat-card class="mat1 m-auto">
        <div class="image-upload" *ngIf="imageLinks.length != 0">
          <div class="mr-2" *ngFor="let item of imageLinks; let index = index" style="background-color: #F0F0F0;">
            <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i>
            <img mat-card-sm-image [src]="item?.url" alt="image" />
          </div>
        </div>
        <div class="image-upload" *ngIf="!imageLinks.length">
          <div class="mr-2" style="background-color: #F0F0F0;">
            <!-- <i class="fa fa-times" aria-hidden="true" (click)="closeimage(index)" id="closeImage"></i> -->
            <img mat-card-sm-image src="assets/latest/dummy-place.svg" alt="image" />
            <!-- <img mat-card-sm-image src="assets/latest/dummy-place.svg" alt="image" />  -->
          </div>
        </div>
        <!-- <div class="image-upload" *ngIf="imagThumb">
                        <div class="mr-2" 
                            style="border: 1px solid;">
                            <i class="fa fa-times" aria-hidden="true" id="closeImage"></i>
                            <img mat-card-sm-image [src]="imagThumb" alt="image">

                        </div>

                    </div> -->
        <div class="row justify-content-start">
          <div class="col-md-3 mt-4">
            <div class="input-file-container file">
              <input class="input-file image hidden" id="my-file" accept=".png, .jpg, .jpeg, .webp " multiple
                type="file" (change)="uploadImg($event)" />
              <label tabindex="0" for="my-file" class="input-file-trigger">Upload Images</label>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
   
  </div>
</div>