import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $

@Component({
  selector: 'app-customer-deals-service-view-particular',
  templateUrl: './customer-deals-service-view-particular.component.html',
  styleUrls: ['./customer-deals-service-view-particular.component.scss']
})
export class CustomerDealsServiceViewParticularComponent implements OnInit {
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  catID: any
  UsID: any
  categoryName: any
  panelOpenState = false; //accordian open close question's and answer's
  selectedService: any
  addressId: any
  step = 0;
  todayDate = new Date().toISOString().split('T')[0];
  headingName:any ='BOOKING PROVIDERS'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Bookings',route:'/customer-service-deal-list'},
    {name:'Bookings Providers',route:'/customer-service-deal-list'}

  ]
  timeArray = []
  valueQuantityAvailabe = 1
  isLoaded: boolean;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }
  getcurrentdatetime(e) {
    let currentDate = new Date().toISOString().split('T')[0]
    let currentTime = new Date().getHours()
    let i = 1
    this.timeArray = []
    if (currentDate == new Date(e.target.value).toISOString().split('T')[0]) {
      for (let it = currentTime; it < 20; it++) {
        let pmAm = currentTime > 12 ? 'PM' : 'AM'

        if (currentTime < 12) {
          let t = `${currentTime}:00${pmAm} - ${currentTime + 1}:00${currentTime + 1 == 12 ? 'PM' : pmAm} `
          this.timeArray.push(t)

        }
        if (currentTime > 12) {
          let t = `${currentTime - 11}:00${pmAm} - ${currentTime - 10}:00${pmAm} `
          this.timeArray.push(t)

        }
        currentTime++
        i++
      }

    }
    else {
      let c = 8
      for (let it = c; it < 21; it++) {
        let pmAm = c > 12 ? 'PM' : 'AM'
        if (c < 12) {
          let t = `${c}:00${pmAm} - ${c + 1}:00${c + 1 == 12 ? 'PM' : pmAm} `
          this.timeArray.push(t)

        }
        if (c > 12) {
          let t = `${c - 12}:00${pmAm} - ${c - 11}:00${pmAm} `
          this.timeArray.push(t)

        }
        c++
        i++
      }
    }


  }
  isLoading:boolean =false
  prevStep() {
    this.step--;
  }
  isDiscount = false
  serviceDetails = []
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService, private datePipe: DatePipe) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this.catID = res.categoryId;
      this.UsID = res.userId;
      this.categoryName = res.categoryName
    })
    
    this.selectedService = JSON.parse(sessionStorage.getItem('selectedService'))
    console.log(this.selectedService );
    if(this.selectedService.dealPrice != this.selectedService.serviceId.price){
      this.isDiscount = true
    }
    
    if(this.selectedService){
      this.isLoading =true
    }else{
      this.isLoading =false
    }
    this.selectedService['quantity'] = 1
    this.addressId = localStorage.getItem('primaryAddressId')
    // for(let item of this.selectedService){
    //   for(let items of item.serviceArray){
    //     items['quantity'] = 1
    //   }

    // }
    this.serviceDetails
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {


      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {

      this.loggedInUserType = res
    })
    // Data Picker Initialization
    // this.getProduct();
    // this.viewCategoryAvailableService();
    if (this.addressId) {
      this.viewaddress();
    }

    this.listAddress()
    this.getTotalProductPrice()
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  getProduct() {
    if (this.isLoggedIn) {
      this.viewRetailerProductd()
    } else {
      this.viewRetailerProductdWithoutLogin()
    }
  }
  firstPriceSize: any = [];
  productDetailsSize: any = []
  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    // this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result;
        this.productDetailsSize = res.result.priceSizeDetails

        this.firstPriceSize = res.result.priceSizeDetails[0]
        this.price(this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit)
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  viewRetailerProductdWithoutLogin() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result;
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }



  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    this.imgurl = url
  }


  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {
    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
  }
  imgSrc: any
  productImage: any
  serviceData: any = []

  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  UserDetailsData: any
  serviceDetailsArray: any = []
  // viewCategoryAvailableService(){
  //   let apiReqUrl = `v2/service/listServiceNearMe`
  //   let apiReqData = {
  //     userId : this.UsID,
  //     categoryId : this.catID
  //   }
  //   this.commonService.showSpinner();
  //   this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       
  //       this.commonService.hideSpinner();
  //       this.UserDetailsData = res.result.userDeails
  //       this.serviceDetailsArray =res.result.docs
  //       this.getTotalProductPrice()
  //       // this.commonService.successToast(res.responseMessage);
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.responseMessage)
  //       this.getTotalProductPrice()
  //       if (res.responseCode == 401) {
  //       }
  //     }
  //   }, (err) => {
  //     this.commonService.hideSpinner()
  //     this.getTotalProductPrice()
  //     if (err.responseCode == 401) {
  //     }
  //   })
  // }
  profileData: any = []
  viewaddress() {
    let url = `user/viewAddress?addressId=${this.addressId}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.profileData = res["result"];

      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  navigateToserviceQuantity(qnty) {
    this.router.navigate(['/edit-address-customer'], { queryParams: { qnty: qnty, categoryId: this.catID, userId: this.userId, categoryName: this.categoryName } })
  }
  navigateToEdit(qnty) {
    this.router.navigate(['/edit-address-customer'], { queryParams: { id: this.addressId, qnty: qnty, categoryId: this.catID, userId: this.userId, categoryName: this.categoryName } })
  }
  subtotal: any = 0
  total: any = 0
  sum: any = 0
  vatValue = 0
  sumToatal = 0
  totalPayAmount = 0
  priceValueOfService: any = 0
  getTotalProductPrice() {
    if (this.selectedService) {
      this.sum = 0
      this.total = 0
      this.vatValue = 0
      this.subtotal = 0
      this.sumToatal = 0
      this.totalPayAmount = 0
      this.priceValueOfService = 0
      // this.selectedService.forEach(element => {
      // element.serviceArray.forEach(ele => {
      this.priceValueOfService += this.selectedService.dealPrice * this.quantityValue
      // this.priceValueOfService += (ele.price * this.quantityValue)
      // this.total = (element.totalPrice * element.quantity)
      this.sum += this.total
      this.subtotal = this.sum
      this.vatValue = (15 / 100)
      this.sumToatal = (this.vatValue * this.priceValueOfService)
      this.totalPayAmount = (this.sumToatal + this.priceValueOfService)
      console.log(this.sumToatal);
      console.log(this.totalPayAmount);
      
      // })
      // });
    } else {
      this.sum = 0
    }
    // this.commonService.totalItemsInShoppingBag.next(this.totalItems)
  }
  timeSlots: any = ''
  duration: any
  buyOrderData: any
  /***
   * @bookAService
   */
  bookService() {
    let url = `v2/service/buyService`
    // let details = []
    // for(let item of this.selectedService){
    //   for(let items of item.serviceArray){
    //     let obj =   {
    //       "serviceId": items._id,
    //       "quantity": items.quantity,
    //       "price": items.price 
    //     }
    //     details.push(obj)
    //   }

    // }
    if (!this.duration || !this.timeSlots) {
      return this.commonService.errorToast('Please select slot')
    }
    let apiReqData = {

      "serviceDetails": [{
        "serviceId": this.selectedService.serviceId._id,
        "quantity": this.selectedService.quantity,
        "price": this.selectedService.dealPrice
      }],
      "orderPrice": this.totalPayAmount,
      "actualPrice": this.priceValueOfService,
      "taxPrice": this.sumToatal,
      "duration": this.duration,
      "slots": this.timeSlots,
      "address": this.addressId
    }
    this.commonService.postApi(url, apiReqData, 1).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        // this.profileData = res["result"];
        this.buyOrderData = res["result"];
        localStorage.setItem('paymentList', 'booking')
        this.ozhoPayment()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  OzhoUrl: any
  ozhoPayment() {
    let url = `order/ozowCheckOut?orderId=${this.buyOrderData._id}`
    let data = {
      webFailure: this.commonService.webSiteUrl + 'customer-payment-cancel',
      webError: this.commonService.webSiteUrl + 'customer-payment-error',
      webSuccess: this.commonService.webSiteUrl + 'customer-payment-success',
      cancelUrl: this.commonService.ozhoBaseUrl,
      errorUrl: this.commonService.ozhoBaseUrl,
      SuccessUrl: this.commonService.ozhoBaseUrl,
      testMode: true,
      trxFrom: 'WEB'
    }
    this.commonService.showSpinner();
    this.commonService.postApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.OzhoUrl = res.result
        this.ozhoRedirect()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }
  ozhoRedirect() {
    window.location.href = this.OzhoUrl
  }
  listAddressData: any = []
  listAddress() {
    let apiReqUrl = `user/listAddress`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.listAddressData = res.result.docs;
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  addId: any
  isPrimary: any
  addressModals(id, prmy) {
    this.addId = id
    this.isPrimary = prmy
    $('#addressModal').modal('show')
  }

  deleteAddress() {
    let apiReqUrl = `user/deleteAddress?addressId=${this.addId}`
    if (this.isPrimary == true) {
      return this.commonService.errorToast('You can not delete primary address')

    } else {
      this.commonService.showSpinner();
      this.commonService.deleteApi(apiReqUrl, 1).subscribe((res: any) => {
        if (res.responseCode == 200) {
          // this.listAddressData = res.result.docs;
          $('#addressModal').modal('hide')
          this.listAddress()
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage)
        }
      })
    }
  }
  openListAddress() {
    $('#listAddressModal').modal('show')
  }
  toAddressId(id) {
    localStorage.setItem('primaryAddressId', id)
    $('#listAddressModal').modal('hide')

  }
  quantityValue: any = 1
  updateCartValue(actionType?) {
    this.duration = ''
    
    actionType == 'ADD' ? ++this.quantityValue : --this.quantityValue;
    if (this.quantityValue == 0) {
      return this.commonService.infoToast('Product quantity can not be 0.')
    }
    console.log(this.quantityValue);

    this.getTotalProductPrice()
  }
  navigateToback() {
    this.router.navigate(['/customer-service-available-add'], { queryParams: { categoryId: this.catID, userId: this.UsID, categoryName: this.categoryName } })
  }
  slotData: any
  getQuantity(date) {
    let url = `service/makeSlots`
    let quantity = 0
    // for(let item of this.selectedService){
    //   for(let items of item.serviceArray){
    //     quantity = items.quantity+quantity

    //   }

    // }
    let startDate = ''
    let currentDate = new Date().toISOString().split('T')[0]
    let incommingDate = new Date(date).toISOString().split('T')[0]
    if (currentDate === incommingDate) {
      startDate = this.datePipe.transform(new Date(date).toLocaleString(), 'hh:mm')
    }
    else {
      startDate = '09:00'
    }
    quantity = this.selectedService.quantity + quantity
    let data = {
      userId: this.selectedService.userId._id,
      quantity: quantity,
      date: new Date(date).toLocaleString(),
      startDate: startDate
    }
    this.commonService.postApi(url, data, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.slotData = res.result.docs
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  onImageLoad(){
    this.isLoaded = true
  }
}
