import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';
import { AuthService } from 'src/app/provide/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  user: any;
  visible: boolean = false;
  changetype: boolean = true;
  route: any;
  userType:any ='CUSTOMER'
  title:any='Customer'
  serviceRole: string;
  isSubmitted = false
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient, public commonService: CommonService, public authService: AuthService) {
   this.commonService.userTypeSelectionUpdate.subscribe((value) => {
      this.userType = this.commonService.isUserType()
      this.isHeadingTitle(this.userType)
    });
  this.userType = this.commonService.isUserType()
  this.isHeadingTitle(this.userType)
   }

  //RETAILER // CUSTOMER  // SERVICE_PROVIDER
  isHeadingTitle(userType){
    if(userType=='CUSTOMER'){
      this.title = 'Customer'
    }
    if(userType=='RETAILER'){
      this.title =  'Retailer'
    }
    if(userType=='SERVICE_PROVIDER'){
      this.serviceRole = localStorage.getItem('serviceRole')
      if(this.serviceRole == 'PICKUP'){
        this.title = 'Pickup Partner'
        this.userType = 'PICKUP_PARTNER'
      }
      else if(this.serviceRole == 'DELIVERY'){
        this.title = 'Delivery Partner'
        this.userType = 'DELIVERY_PARTNER'
      }
      else if(this.serviceRole == 'FIELD'){
        this.title = 'Fulfilment Entity'
        this.userType = 'FIELD_ENTITY'
      }
      else{

        this.title =  'Service Provider'
      }
    }
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loginForm = this.fb.group({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]
      ),
      'password': new FormControl('', [Validators.required,]),
      checkbox: new FormControl(false)
    })
    let checkbox = localStorage.getItem('customerLogin') ? JSON.parse(localStorage.getItem('customerLogin')!) : '';
    if (checkbox) {
      this.loginForm.patchValue({
        "email": atob(checkbox.email),
        "password": atob(checkbox.password),
        "checkbox": checkbox.checkbox
      })
    }
  }
  signUpUser(){
   
   this.router.navigate([this.commonService.routingSinup(this.userType)])
  }
  login() {
    if(this.loginForm.invalid){
      this.loading = false
      this.isSubmitted = true
      return
    }
    let apiReqData = {
      userType: this.userType,
      emailNumberId: String(this.loginForm.value.email).toLowerCase(),
      password: this.loginForm.value.password,
    }
    let apiReqUrl = `user/usersLogin`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          localStorage.setItem('token', res.result.token);
          localStorage.setItem('userType', this.userType);
          localStorage.setItem('userId', res.result._id);
          if((this.userType===res.result.userType) && this.userType == 'CUSTOMER'){
            this.commonService.hideSpinner();
            localStorage.setItem('token', res.result.token);
            localStorage.setItem('userType', this.userType);
            localStorage.setItem('userId', res.result._id);
            localStorage.setItem('email', String(this.loginForm.value.email).toLowerCase())
            if(res.result.primaryAddressId != 0){
              localStorage.setItem('primaryAddressId', res.result.primaryAddressId)
            }
            localStorage.setItem('completeProfile', 'true')
            this.authService.SharingData.next(true);
            this.authService.isLoggedIn.next(true);
            this.authService.loggedInUserType.next(this.userType);
            localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
            this.authService.userRequestStatus.next(res.result.userRequestStatus);
            // this.router.navigate(['/home'])
         
            if (this.loginForm.value.checkbox == true) {
              let remData = {
                "email": btoa(String(this.loginForm.value.email).toLowerCase()),
                "password": btoa(this.loginForm.value.password),
                "checkbox": this.loginForm.value.checkbox

              }
              localStorage.setItem('customerLogin', JSON.stringify(remData))
            } 
            else {
              localStorage.removeItem('customerLogin')
            }
            this.startScheduler()
           
            if(!res.result.otpVerification){
              return this.router.navigate(['/otp-verification-customer'])
            }
          }
          if((this.userType===res.result.userType) && (this.userType == 'SERVICE_PROVIDER' || this.userType == 'PICKUP_PARTNER' || this.userType == 'DELIVERY_PARTNER' || this.userType == 'FIELD_ENTITY')){
     
              this.commonService.hideSpinner()
              localStorage.setItem('token', res.result.token);
              localStorage.setItem('userId', res.result._id);
              localStorage.setItem('email', String(this.loginForm.value.email).toLowerCase())
              localStorage.setItem('completeProfile', res.result.completeProfile)
              this.authService.SharingData.next(res.result.completeProfile);
              this.authService.isLoggedIn.next(true);
              this.authService.loggedInUserType.next('SERVICE_PROVIDER');
              localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
              this.authService.userRequestStatus.next(res.result.userRequestStatus);
              if (res.result.completeProfile && res.result.userRequestStatus == 'APPROVED') {
                localStorage.setItem('token', res['result']['token'])
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('myId', res['result']['_id'])
                this.router.navigate(['/service-order-detail'])
          
              }
              else if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 2) {
                this.commonService.errorToast('Thanks For Connecting With Us. We will verify your account and will send your approval soon.')
              
              }
              else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/business-details-service-provider']);
              }
              else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 1) {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/upload-document-service-provider']);
              }
         
              else if (res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/upload-document-service-provider']);
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason)
                } else {
                  this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
                }
              }
              else if (!res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/business-details-service-provider']);
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason)
                } else {
                  this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
                }
              }
              if (this.loginForm.value.checkbox == true) {
                let remData = {
                  "email": btoa(String(this.loginForm.value.email).toLowerCase()),
                  "password": btoa(this.loginForm.value.password),
                  "checkbox": this.loginForm.value.checkbox
                }
                localStorage.setItem('serviceLogin', JSON.stringify(remData))
      
      
              } else {
                localStorage.removeItem('serviceLogin')
              }
             
      
            
          }
          if((this.userType===res.result.userType) && this.userType == 'RETAILER'){
     
              this.commonService.hideSpinner()
              if(!res.result.otpVerification){
                
              }
              localStorage.setItem('token', res.result.token);
              localStorage.setItem('userId', res.result._id);
              localStorage.setItem('email', this.loginForm.value.email)
              if(res.result.primaryAddressId != 0){
                localStorage.setItem('primaryAddressId', res.result.primaryAddressId)
              }
              localStorage.setItem('completeProfile', res.result.completeProfile)
              localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
              this.authService.SharingData.next(res.result.completeProfile);
              this.authService.userRequestStatus.next(res.result.userRequestStatus);
              this.authService.isLoggedIn.next(true);
              this.authService.loggedInUserType.next('RETAILER');
              if (res.result.completeProfile && res.result.userRequestStatus == 'APPROVED') {
                localStorage.setItem('token', res['result']['token'])
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('myId', res['result']['_id'])
                this.router.navigate(['/retailer-home'])
              }
        
              else if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 2) {
                this.commonService.errorToast('Thanks For Connecting With Us. We will verify your account and will send your approval soon.')
                this.authService.logout()
              }
              else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/business-details-retailer']);
              }
              else if (!res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 1) {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/upload-document-retailer']);
              }
      
              else if (res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/upload-document-retailer']);
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason)
                } else {
                  this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
                }
              }
              else if (!res.result.completeProfile && res.result.userRequestStatus == 'REJECTED') {
                localStorage.setItem('userRequestStatus', res.result.userRequestStatus)
                localStorage.setItem('token', res['result']['token'])
                this.router.navigate(['/business-details-retailer']);
                if (res.responseCode == 200) {
                  this.commonService.errorToast(res.result.rejectReason)
                } else {
                  this.commonService.errorToast('Your application has been rejected. Plesae complete the application and then proceed')
                }
              } else if (res.result.userRequestStatus == 'PENDING') {
                this.commonService.errorToast("Thanks For Connecting With Us. We will verify your account and will send your approval soon.")
              }
      
              if (this.loginForm.value.checkbox == true) {
                let remData = {
                  "email": btoa(String(this.loginForm.value.email).toLowerCase()),
                  "password": btoa(this.loginForm.value.password),
                  "checkbox": this.loginForm.value.checkbox
                }
                localStorage.setItem('retailerLogin', JSON.stringify(remData))
      
      
              } else {
                localStorage.removeItem('retailerLogin')
              }
      
          
        }
        } else {
          this.loading = false;
          this.commonService.hideSpinner()
          this.commonService.errorToast(res['responseMessage'])
        }
      }, (err: any) => {
        this.loading = false;
        if (err.responseCode == 402) {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        }
        else if (err.responseCode == 404) {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        }
      })
  }
  preventSpace(event: any) {
    if ((event.charCode == 32) && !event.target.value) {
      event.preventDefault();
    }
  }

  startScheduler(){
    let buffer = JSON.parse(localStorage.getItem('buffer'))
    let i = 0
    if(buffer){
      let lastLocation = buffer.lastLocation
      let params
      delete buffer.lastLocation
      if(buffer.params){
        params = buffer.params
        delete buffer.params
      }
      if(Object.keys(buffer).length){
        for (const key in buffer) {
          i++
         for (const item of buffer[key]) {
          if(item.apiObj.method == 'POST'){
            this.commonService.postApi(item.apiObj.endUrl, item.apiObj.inbody,1).subscribe(
             
            )
          }
         }
        if(i == (Object.keys(buffer).length)){
         
          if(params){
            this.router.navigate([lastLocation],{queryParams : params})
          }
          else{
            this.router.navigate([lastLocation])
          }
          localStorage.removeItem('buffer')
         
        }
         
        }
      }
      else{
        if(params){
          this.router.navigate([lastLocation],{queryParams : params})
        }
        else{
          this.router.navigate([lastLocation])
        }
        localStorage.removeItem('buffer')
      }
    
    }
    else{
       this.router.navigate(['/home'])
    }
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }
}
