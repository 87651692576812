<div class="container mb-5 wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div>
        <span class="dateset">Ordered on {{orderDataDetials?.createdAt | date : 'mediumDate' }} </span>
        <span class="line"> </span>
        <span class="orderSet">Order {{orderDataDetials?.orderId}}</span>
    </div>
    <div class="right-side" style="width: 100%;">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Product</p>
                        <div class=" mt-4 " *ngFor="let item of viewOrderData">
                            <div class="row">
                                <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                    <div class="bg-image hover-overlay hover-zoom ripple rounded"
                                        data-ripple-color="light">
                                        <img [src]="item?.productId?.thumbnail" [hidden]="!item?.isLoaded"
                                            (load)="onLoadImage(item)" class="productImageNew" alt="" />
                                        <ngx-skeleton-loader *ngIf="!item?.isLoaded" count="1"
                                            [theme]="{width: '116%',height: '110px'}"></ngx-skeleton-loader>
                                        <a>
                                            <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                                    <ng-container *ngIf="item?.productId">
                                        <p style="margin: 1px;" class="product">
                                            {{item?.productId?.productName}}
                                        </p>
                                        <p *ngIf="!item?.productId?.dealReferenceId" class="new-pr" style="margin: 1px;">
                                            {{getPrice((item?.price) |
                                            currency:"ZAR":"R")}}
                                        </p>
                                        <span *ngIf="item?.productId?.dealReferenceId" class="d-flex">
                                            <p  style="text-decoration: line-through;margin: 1px;">
                                                {{getPrice((item?.price) |
                                                currency:"ZAR":"R")}}
                                            </p>
                                            <p class="new-pr" style="margin: 3px 3px;">
                                                {{getPrice(item?.price - (item?.price * item?.productId?.dealReferenceId?.dealDiscount/100) |
                                                currency:"ZAR":"R")}}
                                            </p>
                                            <p class="new-pr" style="margin: 3px 3px;">
                                                {{item?.productId?.dealReferenceId?.dealDiscount}}% Off
                                            </p>
                                        </span>
                                        
                                        <p class="mb-0 deliv">Qty: {{item?.quantity}}</p>
                                        <p class="mb-1 deliv">Value/Size:
                                            {{item?.priceSizeDetails?.value}} <span
                                                *ngIf="item?.priceSizeDetails?.unit">-</span>
                                            {{item?.priceSizeDetails?.unit}}</p>
                                        <span>
                                            <span class="deliv"
                                            *ngIf="orderDataDetials?.deliveryType == 'STANDARD'">Expected
                                                delivery
                                                date : {{getAddedDate(item?.productId?.createdAt,7) | date : 'yyyy-MM-dd' }}</span>
                                            <span class="deliv"
                                            *ngIf="orderDataDetials?.deliveryType == 'SAMEDAY'">Expected
                                                delivery
                                                date : {{getNextDayOrSameDay(orderDataDetials?.createdAt) | date : 'yyyy-MM-dd' }}</span>
                                            <span class="deliv"
                                            *ngIf="orderDataDetials?.deliveryType == 'BUISNESSDAY'">Expected
                                                delivery
                                                date : {{getNextBusinessDayFromDate(orderDataDetials?.createdAt) | date : 'yyyy-MM-dd' }}</span>
                                            <span class="deliv"
                                            *ngIf="orderDataDetials?.deliveryType == 'SATURDAY'">Expected
                                                delivery
                                                date : {{getNextSaturdayFromDate(orderDataDetials?.createdAt) | date : 'yyyy-MM-dd' }}</span>
                                            <span class="deliv"
                                            *ngIf="orderDataDetials?.deliveryType == 'SUNDAY'">Expected
                                                delivery
                                                date : {{getSundayDate(orderDataDetials?.createdAt) | date : 'yyyy-MM-dd' }}</span>
                                            
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!item?.productId">
                                        <ngx-skeleton-loader count="5"
                                            [theme]="{ 'border-radius': '0', height: '16px' }"></ngx-skeleton-loader>
                                    </ng-container>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Deliver To</p>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                <ng-container *ngIf="orderDataDetials?.shippingAddress">
                                    <p style="margin: 1px;margin-top: 5px;" class="text-sty text-del">
                                        {{orderDataDetials?.shippingAddress?.firstName}}
                                        {{orderDataDetials?.shippingAddress?.lastName}}

                                    </p>
                            
                                    <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                        {{orderDataDetials?.shippingAddress?.address}}
                                        {{orderDataDetials?.shippingAddress?.addressLine1}}
                                        {{orderDataDetials?.shippingAddress?.addressLine2}},
                                    </p>

                                    <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                        {{orderDataDetials?.shippingAddress?.city}}
                                        {{orderDataDetials?.shippingAddress?.state}}
                                        {{orderDataDetials?.shippingAddress?.country}}
                                        {{orderDataDetials?.shippingAddress?.zipCode}}
                                    </p>
                                    <div class="mt-1">
                                        <span class="phoen">
                                            <i class="fa fa-phone"></i>
                                            {{orderDataDetials?.shippingAddress?.countryCode}}
                                            {{orderDataDetials?.shippingAddress?.mobileNumber}}</span>
                                    </div>
                                    <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                        {{orderDataDetials?.shippingAddress?.email}}


                                    </p>
                                </ng-container>
                                <ng-container *ngIf="!orderDataDetials?.shippingAddress">
                                    <ng-container>
                                        <ngx-skeleton-loader count="5"
                                            [theme]="{ 'border-radius': '0', height: '16px' }"></ngx-skeleton-loader>
                                    </ng-container>
                                </ng-container>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="container">
                        <p class="titlemPay">Payment Details</p>
                        <div class="">
                            <div class=" justify-content-between  text-center"
                                style="align-items: center;margin-left: 8px;">
                                <ng-container *ngIf="orderDataDetials?.actualPrice">
                                    <ul>
                                        <li>
                                            <span>Subtotal </span>
                                            <span> {{orderDataDetials?.actualPrice &&
                                                getPrice(orderDataDetials?.actualPrice | currency:"ZAR":"R")}} </span>
                                        </li>
                                        <li style="margin-top: 5px;">
                                            <span>Delivery Fee </span>
                                            <span> {{orderDataDetials?.deliveryFee &&
                                                getPrice((orderDataDetials?.deliveryFee) |
                                                currency:"ZAR":"R")}}</span>
                                        </li>
                                        <li style="margin-top: 5px;">
                                            <span>VAT@15% </span>
                                            <span> {{orderDataDetials?.actualPrice &&
                                                getPrice((orderDataDetials?.actualPrice/100 * 15) |
                                                currency:"ZAR":"R")}}</span>
                                        </li>
                                        <li style="margin-top: 5px;">
                                            <span>Grand Amount </span>
                                            <span> {{orderDataDetials?.orderPrice &&
                                                getPrice((orderDataDetials?.orderPrice) | currency:"ZAR":"R")}}</span>
                                        </li>
                                        <li style="margin-top: 5px;">
                                            <span>Delivery Status</span>
                                            <span  [ngClass]="orderDataDetials?.deliveryStatus == 'PENDING' ? 'text-theme' : 'text-success'"> {{orderDataDetials?.deliveryStatus}}</span>
                                        </li>
                                        <li style="margin-top: 5px;">
                                            <span>Payment Status</span>
                                            <span [ngClass]="orderDataDetials?.paymentStatus == 'COMPLETED' ? 'text-success' : 'text-theme'"> {{orderDataDetials?.paymentStatus}}</span>
                                        </li>

                                    </ul>
                                </ng-container>
                                <ng-container *ngIf="!orderDataDetials?.actualPrice">
                                    <ng-container>
                                        <ngx-skeleton-loader count="3"
                                            [theme]="{ 'border-radius': '0', height: '16px' }"></ngx-skeleton-loader>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <order-tracker [orderDataDetials]="orderDataDetials"></order-tracker>
    <div class="ui-footer row justify-content-between mt-2 text-center" style="align-items: center;">
        <span class="m-auto">
            <button class="butCh" *ngIf="loggedInUserType == 'CUSTOMER'" routerLink="/home">Continue
                Shopping</button>
            <button class="butCh" *ngIf="loggedInUserType == 'RETAILER'" routerLink="/retailer-home">Continue
                Shopping</button>
        </span>
    </div>


</div>