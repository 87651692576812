import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provide/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-edit-profile',
  templateUrl: './customer-edit-profile.component.html',
  styleUrls: ['./customer-edit-profile.component.scss']
})
export class CustomerEditProfileComponent implements OnInit {
  addProduct!: FormGroup
  profileData: any = {}
  imageUrl: any;
   headingName:any ='Edit Profile'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Edit Profile',route:'/edit-customer-profile'}
  ]
  govtDocument: any = "assets/lard.png";
  websiteUrl: any;
  socialLink: any;
  userType: any;
  firstName: any;
  lastName: any;
  mobileNumber: any;
  email: any;
  storeName: any;
  storeLocation: any
  faceBook: any;
  linkedIn: any;
  twitter: any;
  instagram: any;
  updateProfile: any;
  profilePic: any;
  countryCode = new FormControl('+91');
  isLoading = false
  lat: any;
  lng: any;
  // uplode image 
  datas: any = []

  addDynamicImageArray: any = []
  validationMessage: any; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];


  country: any = []
  state: any = []
  city: any = []
  isoCode: any
  cityList: any =[]
  stateList: any =[]
  countryList: any =[]
  products: any;



  constructor(private fb: FormBuilder,private http: HttpClient, public commonService: CommonService, private router: Router) {
    this.add();

  }
  LatLongValue: any
  async ngOnInit() {
    this.getCountryList();
    this.getProfile();
    this.http.get("assets/countrycode.json").subscribe((data: any) => {
      this.products = data;
      this.datas = data.result

    })
 


    navigator.geolocation.getCurrentPosition(function (position) {
      // alert('allow');  
    }, function () {
      alert('You need to enable geolocation services in your browser to use this feature.');
    });
    this.getPosition().then(pos => {
      localStorage.setItem('location', JSON.stringify(pos))
    });
    // this.getLocation();

  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }


  // log and lat============
  Position: any
  getLocation() {
  
  }

  address: any
  options: any = []
  longitude: any;
  latitude: any;
  is_addressInValid: boolean = true
  public handleAddressChange(address: any) {
    if ('formatted_address' in address) {
      this.addProduct.patchValue({
        address: address.formatted_address
      })
      this.options = []
      this.is_addressInValid = false

      this.address = address.formatted_address

      this.longitude = address.geometry.location.lng()
      this.latitude = address.geometry.location.lat()
      this.options[0] = Number(address.geometry.location.lng())
      this.options[1] = Number(address.geometry.location.lat())
    }
    else {
      this.is_addressInValid = true

    }



  }
  // form validation

  add() {
    this.addProduct = this.fb.group({
      'fName': new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
        Validators.minLength(2)
      ]),
      'lName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
      'Phone': new FormControl('', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{8,15}$")
      ]),
      'address': new FormControl('', [
        Validators.required,
        Validators.maxLength(250),
      ]),
      'address1': new FormControl(''),
      'location': new FormControl('', [
      ]),
      'countryCode': new FormControl('+91', [
        Validators.required
      ]),
      // 'url': new FormControl('', [
      //   Validators.required,
      //   Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
      // ]),
      'country': new FormControl('', [
        Validators.required,
      ]),

      'state': new FormControl('', [

      ]),
      'city': new FormControl('', [

      ]),

      'zipCode': new FormControl('', [  Validators.required,    Validators.pattern('^[a-zA-Z0-9]+$')

      ]),

    });


  }

  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  valueLatLong: any

  userTypes: any
  getProfile() {
    this.commonService.showSpinner();
    this.commonService.getApi("user/myProfile", 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.profileData = res["result"];
        this.userTypes = this.profileData.userType
        this.profileData.country ? this.getStateList(this.profileData.countryIsoCode) : '';
        this.profileData.state ? this.getCityList(this.profileData.stateIsoCode) : '';
        this.addProduct.patchValue({
          fName: this.profileData.firstName,
          lName: this.profileData.lastName,
          email: this.profileData.email,
          Phone: this.profileData.mobileNumber,
          address: this.profileData.addressLine1,
          address1: this.profileData.addressLine2,
          // url: this.profileData.websiteUrl,
          zipCode: this.profileData.zipCode,
          countryCode : this.profileData.countryCode,
          city: this.profileData.city,
          state: this.profileData.stateIsoCode,
          country: this.profileData.countryIsoCode
          ,
        });
        this.latitude = res.result.storeLocation.coordinates[0]
        this.longitude = res.result.storeLocation.coordinates[1]
        this.imageUrl = res['result']['profilePic']
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }

  // patch state and city


  patchState(country: any) {
    let apiReqUrl = `${'user/listState?countryCode=' + country}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.stateList = res.result
      this.addProduct.patchValue({
        'state': this.profileData.state
      })
    })
  }
  patchCity(state: any) {
    let apiReqUrl = `${'user/listCity?countryCode=' + this.profileData.country + '&stateCode=' + state}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.cityList = res.result
      this.addProduct.patchValue({
        'city': this.profileData.city
      })
    })
  }

  getCountryList() {
    this.commonService.getApi('user/listCountry', 1).subscribe((res: any) => {
      
      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.countryList = res.result

    })
  }
  countryIsoCode: any;
  getStateList(event: any) {
    if(event){
      this.countryIsoCode = typeof event == 'string' ? event : event.target.value
      let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`
      this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
        
        this.stateList = res.result
      })
    }
   
  }

  getCityList(event: any) {
    if(event){
      let stateIsoCode = typeof event == 'string' ? event : event.target.value
      let apiReqUrl = `${'user/listCity?countryCode=' + this.countryIsoCode + '&stateCode=' + stateIsoCode}`
      this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
        
        this.cityList = res.result
      })
    }
   
  }



  getCountryName(iso: string) {
    if(this.countryList.length>0){
      let temp = this.countryList.filter((res: any) => {
        return res.isoCode == iso
      })
      return  temp.length>0 ? temp[0].name : ''
    }else{
      return ''
    }
  }

  getStateName(iso: string) {
    if(this.stateList.length>0){
      let temp = this.stateList.filter((res: any) => {
        return res.isoCode == iso
      })
      return temp.length>0 ? temp[0].name : ''
    }else{
      return ''
    }

  }
  // update profile
  isValidURL(string) {
    // Regular expression pattern for a simple URL check
    var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/\S*)?$/i;
    return urlPattern.test(string);
  }

  editProfileDetail() {
   
    let url = "user/updateProfile"
    const data = {
      firstName: this.addProduct.value.fName,
      lastName: this.addProduct.value.lName,
      email: this.addProduct.value.email,
      countryCode : this.addProduct.value.countryCode,
      mobileNumber: this.addProduct.value.Phone,
      addressLine1: this.addProduct.value.address,
      addressLine2: this.addProduct.value.address1,
      country: this.getCountryName(this.addProduct.value.country),
      countryIsoCode: this.addProduct.value.country,
      city: this.addProduct.value.city,
      state: this.getStateName(this.addProduct.value.state),
      stateIsoCode: this.addProduct.value.state,
      zipCode: this.addProduct.value.zipCode,
      websiteUrl: this.addProduct.value.url,
      userType: this.userTypes,
      "storeLocation": {
        "type": "Point",
        "coordinates": [
          this.longitude,
          this.latitude
        ]
      },
      // socialLink: socialLinks,

      profilePicBase64: this.isValidURL(this.imageUrl) ? '' : this.imageUrl
    }
    if(data.storeLocation.coordinates && !data.storeLocation.coordinates[0] && !data.storeLocation.coordinates[1] ){
      this.commonService.infoToast('Please select address as you find in dropdown.')
      return false
   }

    // this.commonService.putApi(url, data, 1)
    this.commonService.showSpinner();
    this.isLoading = true
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.commonService.profileUpdateItem.next(true)
        this.router.navigate(['/customer-profile']);
        this.commonService.successToast(res["responseMessage"]);
        this.isLoading = false
        // this.getProfileDetails()
        // this.authService.loggedInData.next(res['result']['profilePic'])
      } else {
        this.commonService.hideSpinner();
        this.isLoading = false
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }


  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-
  uploadImg($event: any): void {
    var img = $event.target.files[0];


    if (img) {
      const reader : any = new FileReader();
      const me = this
      reader.onload = function () {
        const base64String = reader.result // Get the Base64 part of the data URL
        me.imageUrl  = base64String; // Display the Base64 string

      
      };

      // Read the image file as a data URL
      reader.readAsDataURL(img);
    } else {
      alert('Please select an image file.');
    }
    // const maxSize = 2 * 1024 * 1024; // 2 MB
    // const me = this
  //   if (img) {
  //     this.resizeImageToBase64(img, maxSize, function (base64String) {
         
  //       me.imageUrl = base64String; // Display the image
  //     });
  // }
    // this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 0).subscribe((res: any) => {
      
      this.commonService.hideSpinner();
      if (res.responseCode == '200') {
        this.imageUrl = res.result.mediaUrl;
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }


  resizeImageToBase64(file, maxSize, callback) {
    const reader = new FileReader();
    reader.onload = function (event) {
        const img : any = new Image();
        img.src = event.target.result;

        img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const maxWidth = img.width;
            const maxHeight = img.height;

            let newWidth = maxWidth;
            let newHeight = maxHeight;

            if (file.size > maxSize) {
                const scaleFactor = Math.min(maxWidth / newWidth, maxHeight / newHeight);
                newWidth *= scaleFactor;
                newHeight *= scaleFactor;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            canvas.toBlob(function (blob) {
                const blobReader = new FileReader();
                blobReader.onload = function (blobEvent) {
                    const base64String = blobEvent.target.result;
                    callback(base64String);
                };
                blobReader.readAsDataURL(blob);
            }, file.type);
        };
    };

    reader.readAsDataURL(file);
}


  // async getCountryList() {
  //   let res: any = await lastValueFrom(this.commonService.getApi('user/listCountry', 1))
  //   
  //   this.country = res.result


  // }
  // countryIsoCode: any;
  // getStateList(event: any) {
  //   // this.countryIsoCode = this.addProduct.value.Country.isoCode
  //   this.countryIsoCode = event.target.value
  //   let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`
  //   this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
  //     
  //     this.state = res.result
  //   })
  // }

  // getCityList(event: any) {
  //   let stateIsoCode = event.target.value
  //   let apiReqUrl = `${'user/listCity?countryCode=' + this.countryIsoCode + '&stateCode=' + stateIsoCode}`
  //   this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
  //     
  //     this.city = res.result
  //   })
  // }

}
