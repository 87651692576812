import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

declare var $
@Component({
  selector: 'app-customer-deals-product-view',
  templateUrl: './customer-deals-product-view.component.html',
  styleUrls: ['./customer-deals-product-view.component.scss']
})
export class CustomerDealsProductViewComponent implements OnInit {
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  imageLoaded: boolean = false
    headingName:any ='DEAL DETAILS'
    name: string
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'Deal Detail',route:'/customer-product-view'}
  ]
  isLoading: boolean =false;

  flag = true
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.name = res.q
      if(res.q){
        this.linkerRouteItems[1] = {name: this.name,route:'/customer-product-view'}
      }
    })
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {


      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {

      this.loggedInUserType = res
    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    this.getProduct();
    // this.getSimiliarProduct()
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  getProduct() {
    if (this.isLoggedIn) {
      this.viewRetailerProductd()
    } else {
      this.viewRetailerProductdWithoutLogin()
    }
  }
  firstPriceSize: any = [];
  productDetailsSize: any = []
  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `deal/viewDeal?dealId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
  
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        this.isLoading=true
        this.productDetailsSize = res.result.dealDetails
        this.imgurl = res.result.dealImage[0]
        this.firstPriceSize = res.result.dealDetails[0]
        this.price(this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit, this.firstPriceSize.dealPrice)
        // this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading=false
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
    })
  }
  viewRetailerProductdWithoutLogin() {
    let apiReqUrl: any = `deal/viewDeal?dealId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`

    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result;
        this.isLoading=true
        this.imgurl = res.result.dealImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        this.firstPriceSize = res.result.dealDetails[0]
        this.price(this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit, this.firstPriceSize.dealPrice)

        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading=false
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.commonService.hideSpinner()
    })
  }



  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    this.imgurl = url
  }


  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  dealP: any
  price(e: any, q: any, v: any, id, u, dp) {
    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id,
      this.dealP = dp
  }
  imgSrc: any
  productImage: any
  serviceData: any = []
  getSimiliarProduct() {
    // let apiReqUrl: any = `product/similarProductList?productId=${this._id}`
    let apiReqUrl: any = `product/productMightLike`
    // let apiReqUrl: any = `product/viewProduct?productId=${this._id}`/    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    //this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.serviceData = res.result.docs;
        // this.imgurl = res.result.productImage[0]
        // this.imgSrc = res.result.productImage[0]
        // this.imageLinks = res["result"]["thumbnail"]
        // this.productImage =res.result.productImage

        $(document).ready(function () {
          $('.service_slider').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  addToCart() {
    let apiReqUrl = `user/v2/productAddToCart`
    let apiReqData = {

      // "productId": this._id,
      "productId": this.productDetails.productId[0]._id,
      "priceSizeDetails": {
        "value": this.value,
        "price": Number(this.dealP),
        "unit": this.unit,
        "id": this.pdId
      },
      "orderType": "DEAL",
      "quantity": 1,
      "totalPrice": this.dealP,
      "addType": "DEAL"

    }

    if (this.loggedInUserType == 'RETAILER') {
      apiReqData.priceSizeDetails['quantity'] = this.quantity
    }
    this.flag = false
    this.commonService.postApi(apiReqUrl, apiReqData, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.router.navigate(['/addtoCart-customer'])
        this.flag = true
        this.commonService.successToast(res.responseMessage);
      } else {
        this.flag = true
        this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.flag = true
      this.commonService.errorToast(err.responseMessage)
    })
  }
  showMessage() {

    
    let buffer = localStorage.getItem('buffer')
    if (!buffer) {
      let cartJson = {
        cart: [{

          'apiObj': {
            endUrl : 'user/v2/productAddToCart',
            method: 'POST',
           
          
            inbody: {
              "productId": this._id,
              "priceSizeDetails": {
                "value": this.value,
                "price": Number(this.mrp),
                "unit": this.unit,
                "id": this.pdId
              },
              "orderType": "PRODUCT",
              "quantity": 1,
              "totalPrice": this.mrp,
              "addType": "PRODUCT"
            }
          }

        }],
        lastLocation : '/addtoCart-customer'
      }
     
      
      localStorage.setItem('buffer', JSON.stringify(cartJson))

    }
    else {
      let parseBuffer = JSON.parse(buffer)
      let cartObj = {
        'apiObj': {
          endUrl : 'user/v2/productAddToCart',
          method: 'POST',
          inQuery: '',
          inbody: {
            "productId": this._id,
            "priceSizeDetails": {
              "value": this.value,
              "price": Number(this.mrp),
              "unit": this.unit,
              "id": this.pdId
            },
            "orderType": "PRODUCT",
            "quantity": 1,
            "totalPrice": this.mrp,
            "addType": "PRODUCT"
          }
        },
     

      }

      if (parseBuffer.cart) {
        parseBuffer.cart.push(cartObj)
        parseBuffer.lastLocation  = '/addtoCart-customer'
      }
      else {
        parseBuffer.cart = [cartObj]
        parseBuffer.lastLocation  = '/addtoCart-customer'

      }



      localStorage.setItem('buffer', JSON.stringify(parseBuffer))
    }
    this.router.navigate(['/login'])
    this.commonService.errorToast('Please Login')
    return
  }
  onImageLoad(){
    this.imageLoaded = true
  }
  getRawText(str : string):string{
    return str.replace(/\n/g, '<br>')
  }
}
