<div class="container mb-5 wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div *ngIf="serviceRole == 'SERVICE'">
        <span class="dateset">Ordered on {{serviceDetail?.createdAt | date : 'mediumDate' }} </span>
        <span class="line"> </span>
        <span class="orderSet">Order {{serviceDetail?.orderId}}</span>


    </div>
    <div *ngIf="serviceRole != 'SERVICE'" class="position-relative">
        <span class="dateset">Ordered on {{orderResultDetail?.createdAt | date : 'mediumDate' }} </span>
        <span class="line"> </span>
        <span class="orderSet">Order {{orderResultDetail?.orderId?.orderId}}</span>
        <span class="line"> </span>
        <span class="orderSet">Delivery Type : {{getDeliveryName(orderResultDetail?.orderId?.deliveryType)}}</span>
        <button class="btn btn-primary btn-theme"
            *ngIf="orderResultDetail?.requestStatus == 'PENDING' && orderResultDetail?.userType != 'FIELD_ENTITY'"
            (click)="openRejectModal()" style="position: absolute;
        right: -15px;
        top: -15px;
    ">Reject</button>
    </div>
    <div class="right-side" style="width: 100%;" *ngIf="serviceRole == 'SERVICE'">
        <div class="row mt-3">
            <div class="col-md-5">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Product</p>
                        <div class=" mt-4 " *ngFor="let item of productDetails">
                            <div class="row">
                                <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                    <div class="bg-image hover-overlay hover-zoom ripple rounded"
                                        data-ripple-color="light">
                                        <img [src]="serviceImage?.serviceId?.categoryId?.categoryImage"
                                            class="productImageNew" alt="" />
                                        <a>
                                            <div class="mask" style="background-color: rgba(251, 251, 251, 0.2)"></div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                                    <p style="margin: 1px;" class="product">
                                        {{item?.serviceId?.serviceName}}
                                    </p>
                                    <p class="new-pr" style="margin: 1px;">
                                        {{item?.serviceId?.price ? getPrice(item?.serviceId?.price | currency:"ZAR":"R")
                                        :
                                        '--' }}
                                    </p>
                                    <p class="mb-0 deliv">Qty: {{item?.quantity}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="container">
                        <p class="titlem">Customer Details</p>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                <p style="margin: 1px;margin-top: 5px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.firstName}}
                                    {{serviceDetail?.shippingFixedAddress?.lastName}}

                                </p>
                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.email}}


                                </p>
                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.address}}
                                    {{serviceDetail?.shippingFixedAddress?.addressLine1}}
                                    {{serviceDetail?.shippingFixedAddress?.addressLine2}},
                                </p>

                                <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                    {{serviceDetail?.shippingFixedAddress?.city}},
                                    {{serviceDetail?.shippingFixedAddress?.state}},{{serviceDetail?.shippingFixedAddress?.country}},
                                    {{serviceDetail?.shippingFixedAddress?.zipCode}}
                                </p>
                                <div class="mt-1">
                                    <span class="phoen"> {{serviceDetail?.shippingFixedAddress?.countryCode}}
                                        {{serviceDetail?.shippingFixedAddress?.mobileNumber}}
                                    </span>
                                </div>
                                <p style="margin: 1px;margin-top: 5px;"><strong class="text-sty"> <a
                                            style="color: #bf1e2e; cursor: pointer; text-decoration: none;"
                                            [href]="'https://www.google.com/maps/@'+serviceDetail?.shippingFixedAddress?.location?.coordinates[1]+','+serviceDetail?.shippingFixedAddress?.location?.coordinates[0]+',10z'"
                                            target="_blank" rel="noopener noreferrer">
                                            Find Location
                                        </a></strong>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="container">
                        <p class="titlemPay">Payment Details</p>
                        <div class="">
                            <div class=" justify-content-between  text-center"
                                style="align-items: center;margin-left: 8px;">

                                <ul>
                                    <li>
                                        <span>Subtotal </span>
                                        <span>
                                            {{serviceDetail?.actualPrice ? getPrice(serviceDetail?.actualPrice |
                                            currency:"ZAR":"R") : "--"}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;">
                                        <span>VAT@15% </span>
                                        <span>
                                            {{serviceDetail?.taxPrice ? getPrice(serviceDetail?.taxPrice |
                                            currency:"ZAR":"R") : "--"}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;">
                                        <span> Grand Total </span>
                                        <span>
                                            {{serviceDetail?.orderPrice ? getPrice(serviceDetail?.orderPrice
                                            | currency:"ZAR":"R") : "--"}}
                                        </span>
                                    </li>
                                    <li style="margin-top: 5px;">
                                        <span> ID: </span>
                                        <span style="font-size: 13px;">
                                            {{txnId?.trxId}}
                                        </span>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>



    <ng-container *ngIf="serviceRole != 'SERVICE'">
        <div class="right-side field-Detail" style="width: 100%;">

            <div class="row mt-3">
                <div *ngIf="serviceRole == 'DELIVERY' || serviceRole == 'PICKUP'"
                    [ngClass]="serviceRole == 'DELIVERY' ? 'col-md-6' : 'col-md-12'">
                    <div class="row">
                        <div class="container">
                            <p class="titlem">Fulfilment Details</p>
                            <div class=" mt-2 ">
                                <div class="row position-relative">

                                    <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                        <p style="margin: 1px;" class="product">
                                            Name <span class="coln">:</span>
                                            {{orderResultDetail?.filedEntity?.assignedUser?.firstName + " " +
                                            orderResultDetail?.filedEntity?.assignedUser?.lastName}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Address <span class="coln">:</span>
                                            {{orderResultDetail?.filedEntity?.assignedUser?.addressLine1}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Zip Code <span class="coln">:</span>
                                            {{orderResultDetail?.filedEntity?.assignedUser?.zipCode}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Contact Number <span class="coln">:</span>
                                            {{orderResultDetail?.filedEntity?.assignedUser?.countryCode}}
                                            {{orderResultDetail?.filedEntity?.assignedUser?.mobileNumber}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Email <span class="coln">:</span>
                                            {{orderResultDetail?.filedEntity?.assignedUser?.email}}
                                        </p>

                                    </div>

                                    <a (click)="openGoogleMapsWithRouting(orderResultDetail?.filedEntity?.assignedUser?.storeLocation?.coordinates[1],orderResultDetail?.filedEntity?.assignedUser?.storeLocation?.coordinates[0])"
                                        class="findrouter defalta" *ngIf="type != 'Complete'">
                                        Find Route
                                    </a>

                                    <!-- <button class="btn btn-primary proceed">Received</button> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="serviceRole == 'DELIVERY' || serviceRole == 'FIELD'"
                    [ngClass]="serviceRole == 'DELIVERY' || serviceRole == 'FIELD' ?  'col-md-6' : 'col-md-12'">
                    <div class="row">
                        <div class="container">
                            <p class="titlem">Customer Detail</p>
                            <div class=" mt-2 ">
                                <div class="row position-relative">

                                    <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                        <p style="margin: 1px;" class="product">
                                            Name <span class="coln">:</span>
                                            {{orderResultDetail?.orderId?.shippingFixedAddress?.firstName + " " +
                                            orderResultDetail?.orderId?.shippingFixedAddress?.lastName}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Address <span class="coln">:</span>
                                            {{orderResultDetail?.orderId?.shippingFixedAddress?.addressLine1}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Zip Code <span class="coln">:</span>
                                            {{orderResultDetail?.orderId?.shippingFixedAddress?.zipCode}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Contact Number <span class="coln">:</span>
                                            {{orderResultDetail?.orderId?.shippingFixedAddress?.countryCode}}
                                            {{orderResultDetail?.orderId?.shippingFixedAddress?.mobileNumber}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Email <span class="coln">:</span>
                                            {{orderResultDetail?.orderId?.shippingFixedAddress?.email}}
                                        </p>

                                    </div>

                                    <a (click)="openGoogleMapsWithRouting(orderResultDetail?.orderId?.shippingFixedAddress?.location?.coordinates[1],orderResultDetail?.orderId?.shippingFixedAddress?.location?.coordinates[0])"
                                        class="findrouter defalta" *ngIf="type != 'Complete'">
                                        Find Route
                                    </a>

                                    <!-- <button class="btn btn-primary proceed">Received</button> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="serviceRole == 'FIELD' && orderResultDetail?.deliveryDriver"
                    [ngClass]="serviceRole == 'FIELD' ?  'col-md-6' : 'col-md-12'">
                    <div class="row">
                        <div class="container">
                            <p class="titlem">Delivery Driver Detail</p>
                            <div class=" mt-2 ">
                                <div class="row position-relative">

                                    <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                        <p style="margin: 1px;" class="product">
                                            Name <span class="coln">:</span>
                                            {{orderResultDetail?.deliveryDriver?.assignedUser?.firstName + " " +
                                            orderResultDetail?.deliveryDriver?.assignedUser?.lastName}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Address <span class="coln">:</span>
                                            {{orderResultDetail?.deliveryDriver?.assignedUser?.addressLine1}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Zip Code <span class="coln">:</span>
                                            {{orderResultDetail?.deliveryDriver?.assignedUser?.zipCode}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Contact Number <span class="coln">:</span>
                                            {{orderResultDetail?.deliveryDriver?.assignedUser?.countryCode}}
                                            {{orderResultDetail?.deliveryDriver?.assignedUser?.mobileNumber}}
                                        </p>
                                        <p style="margin: 1px;">
                                            Email <span class="coln">:</span>
                                            {{orderResultDetail?.deliveryDriver?.assignedUser?.email}}
                                        </p>

                                    </div>

                                    <a (click)="openGoogleMapsWithRouting(orderResultDetail?.deliveryDriver?.assignedUser?.storeLocation?.coordinates[1],orderResultDetail?.deliveryDriver?.assignedUser?.storeLocation?.coordinates[0])"
                                        class="findrouter defalta" *ngIf="type != 'Complete'">
                                        Find Route
                                    </a>

                                    <!-- <button class="btn btn-primary proceed">Received</button> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="serviceRole == 'FIELD' && !orderResultDetail?.deliveryDriver"
                    [ngClass]="serviceRole == 'FIELD' ?  'col-md-6' : 'col-md-12'">
                    <div class="row">
                        <div class="container">
                            <p class="titlem">Delivery Driver Detail</p>
                            <div class=" mt-2 ">
                                <div class="row position-relative">

                                    <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                        <p style="margin: 1px;" class="product">
                                            Not yet Assigned
                                        </p>

                                    </div>



                                    <!-- <button class="btn btn-primary proceed">Received</button> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <ng-container *ngIf="orderResultDetail?.userType != 'DELIVERY_PARTNER'">
            <div class="right-side" style="width: 100%;"
                *ngFor="let item of orderResultDetail?.retailers; let i = index;let j = last">
                <p>#Retailer{{i+1}}

                </p>
                <div class="row mt-3">
                    <div class="col-md-5">
                        <div class="row">
                            <div class="container">
                                <p class="titlem">Product</p>
                                <div class=" mt-4 " *ngFor="let products of item.products">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                                            <div class="bg-image hover-overlay hover-zoom ripple rounded"
                                                data-ripple-color="light">
                                                <img [src]="products.productId.thumbnail"
                                                    [hidden]="!products.productId.thumbnail" class="productImageNew"
                                                    alt="" />
                                                <ngx-skeleton-loader *ngIf="!products.productId.thumbnail" [theme]="{width: '100%', height:'100%',
                                                position: 'relative'}" count="1"></ngx-skeleton-loader>
                                                <a>
                                                    <div class="mask"
                                                        style="background-color: rgba(251, 251, 251, 0.2)">
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-lg-9 col-md-12 mb-4 mb-lg-0">
                                            <p style="margin: 1px;" class="product">
                                                {{products?.productId?.productName}}
                                            </p>
                                            <p class="new-pr" *ngIf="!products?.productId?.dealReferenceId"
                                                style="margin: 1px;">
                                                {{ getPrice(products?.price | currency:"ZAR":"R")
                                                }}
                                            </p>
                                            <span class="d-flex" *ngIf="products?.productId?.dealReferenceId">
                                                <p style="margin: 1px;text-decoration: line-through;">{{
                                                    getPrice(products?.price | currency:"ZAR":"R")}}</p>
                                                <p class="new-pr" style="margin: 3px;">{{ getPrice(products?.price -
                                                    (products?.price *
                                                    products?.productId?.dealReferenceId?.dealDiscount / 100) |
                                                    currency:"ZAR":"R")}}</p>
                                                <p class="new-pr" style="margin: 3px;">{{
                                                    products?.productId?.dealReferenceId?.dealDiscount }}% Off</p>
                                            </span>

                                            <p class="mb-0 deliv">Qty: {{products?.quantity}}</p>
                                            <p class="mb-0 deliv">Value/Size : {{products?.priceSizeDetails?.value}}
                                                {{products?.priceSizeDetails?.unit != 'other'
                                                ?products?.priceSizeDetails?.unit : '' }}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="container">
                                <p class="titlem">Retailer Details</p>
                                <div class="row position-relative">
                                    <div class="col-lg-12 col-md-12 mb-4 mb-lg-0">
                                        <p style="margin: 1px;margin-top: 5px;" class="text-sty text-del">
                                            {{item?.userId?.firstName}} {{item?.userId?.lastName}}

                                        </p>

                                        <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                            {{item?.userId?.addressLine1}}
                                        </p>

                                        <p style="margin: 1px;margin-top: 3px;" class="text-sty text-del">
                                            {{item?.userId?.city}},
                                            {{item?.userId?.state}},
                                            {{item?.userId?.country}}
                                        </p>
                                        <div class="mt-1">
                                            <span class="phoen"> <i class="fa fa-phone"></i>
                                                {{item?.userId?.countryCode}}
                                                {{item?.userId?.mobileNumber}}
                                            </span> <br>
                                            <span class="phoen"> <i class="fa fa-envelope-o"></i>
                                                {{item?.userId?.email}}

                                            </span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 position-relative">
                        <div class="row ">
                            <div class="container">

                            </div>

                        </div>
                        <ng-container *ngIf="orderResultDetail?.userType != 'FIELD_ENTITY'">
                            <a (click)="openGoogleMapsWithRouting(item?.userId?.storeLocation.coordinates[1],item?.userId?.storeLocation.coordinates[0])"
                                *ngIf="type != 'Complete'" class="findrouter" style="right: 30px;">
                                Find Route
                            </a>
                        </ng-container>

                    </div>
                    <ng-container *ngIf="orderResultDetail?.userType != 'FIELD_ENTITY'">
                        <div class="col-md-12 text-right mt-3" *ngIf="type != 'Complete'">
                            <button class="btn btn-primary btn-theme" *ngIf="!item?.isOrderPickedUp"
                                (click)="onProceed(item?.userId?._id)">Proceed</button>
                            <button class="btn btn-primary btn-theme"
                                *ngIf="item?.isOrderPickedUp && !item?.isOrderDelivered"
                                (click)="markAsDelivered(item?.userId?._id)">Mark as Delivered</button>
                        </div>
                    </ng-container>


                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="orderResultDetail?.userType == 'FIELD_ENTITY'">
            <div class="col-md-12 text-center mt-3" *ngIf="!orderResultDetail?.deliveryDriver">
                <button class="btn btn-primary btn-theme" *ngIf="orderResultDetail?.isDeliverToFieldEntity"
                    (click)="load();outForDelivered()">Assign a Delivery Driver</button>
                <button class="btn btn-primary btn-theme op05" *ngIf="!orderResultDetail?.isDeliverToFieldEntity">Order
                    Not Recived</button>
                <!-- <button class="btn btn-primary btn-theme op05" *ngIf="loading" disabled >Searching for delivery driver...</button> -->
            </div>
            <div class="col-md-12 text-center mt-3 container" *ngIf="orderResultDetail?.deliveryDriver">
                <button class="btn btn-primary btn-theme" (click)="load();reassiginDelivery()">Reassigin Delivery
                    Driver</button>
                <!-- <button class="btn btn-primary btn-theme op05" *ngIf="loading" disabled >Searching for delivery driver...</button> -->

            </div>
        </ng-container>
        <ng-container *ngIf="orderResultDetail?.userType == 'DELIVERY_PARTNER'">
            <div class="col-md-12 text-center mt-5">
                <ng-container *ngIf="!loading">
                    <button class="btn btn-primary btn-theme" *ngIf="!orderResultDetail?.isOrderPickedUpFromFieldEntity"
                        (click)="load();markAsDelivered('')">Order collect from fulfilment entity</button>
                    <button class="btn btn-primary btn-theme"
                        *ngIf="orderResultDetail?.isOrderPickedUpFromFieldEntity && type != 'Complete'"
                        (click)="load();markAsDelivered('',true)">Deliver order to customer</button>
                </ng-container>
                <!-- && type != 'Complete' -->
                <ng-container *ngIf="loading">
                    <button class="btn btn-primary btn-theme op05" *ngIf="loading" disabled>Loading...</button>

                </ng-container>

            </div>
        </ng-container>
    </ng-container>



    <div *ngIf="serviceRole == 'SERVICE'" class="ui-footer row justify-content-between mt-2 text-center"
        style="align-items: center;">
        <span class="m-auto">
            <ng-container *ngIf="orderStatus != 'ACCEPTED' ">
                <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'PENDING'"
                    (click)="updateServiceOrderStatus('ONTHEWAY')"
                    [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Mark as On The Way</button>
                <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'ONTHEWAY'"
                    (click)="openModalView(serviceDetail._id)"
                    [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Start service</button>

                <button class="butCh" *ngIf="serviceDetail.serviceStatus == 'EXECUTION'"
                    (click)="openModalView(serviceDetail._id)"
                    [queryParams]="{flag:true, _id: _id, orderId: serviceDetail._id}">Mark as Done</button>
            </ng-container>

        </span>
    </div>
</div>

<otp-modal *ngIf="openModal" [isService]="true" [orderId]="serviceOrderId"
    (closeModal)="navigateBack();openModal = false"></otp-modal>




<otp-modal *ngIf="showModal" custom="true" (closeModal)="showModal = false" (onSumit)="onSumbitOtp($event)"
    (onResend)="onResend()" customerType='field entity'></otp-modal>
<otp-modal *ngIf="showModal2" custom="true" (closeModal)="showModal2 = false" (onSumit)="onSumbitOtp2($event)"
    (onResend)="onResend()" customerType='field entity'></otp-modal>
<otp-modal *ngIf="showModal3" custom="true" (closeModal)="showModal3 = false" (onSumit)="onSumbitOtp3($event)"
    (onResend)="onResend()" customerType='customer'></otp-modal>


<div class="modal fade" id="rejectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 230px;">
            <div class="modal-header" style="height: 90px; background: #BF1E2E;">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
                        style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>

            </div>
            <div class="modal-body">
                <textarea class="w-100" [(ngModel)]="reason" name="" placeholder="Enter Reject Reason" id="" cols="30"
                    rows="10"></textarea>

            </div>
            <div class="modal-footer">
                <button class="btn btn-primary btn-theme" *ngIf="!flag" (click)="rejectOrder()">Submit</button>
                <button class="btn btn-primary btn-theme op05" *ngIf="flag">Loading</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="rejectCancel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 230px;">
            <div class="modal-header" style="height: 90px; background: #BF1E2E;">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
                        style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>

            </div>
            <div class="modal-body">
                <h3>{{message}}</h3>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary btn-theme" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="driverSearchingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 230px;">
            <div class="modal-header" style="height: 90px; background: #BF1E2E;">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 135px; height:60px"><img
                        style="height: 100%; width:100%; object-fit: cover;" src="assets/img/whiteLogo.png" alt=""></h5>

            </div>
            <div class="modal-body">
                <div class="row justify-content-center">
                    <div class="col-3">
                        <i class="fa fa-spinner fa-spin" *ngIf="!searching" aria-hidden="true" style="color: #bf1e2e;
                    font-size: 60px;"></i>
                        <i class="fa fa-check" *ngIf="searching" aria-hidden="true" style="color: green;
                    font-size: 60px;"></i>
                    </div>
                    <div class="col-12 text-center">
                        <p class="srchTxt" *ngIf="!searching">
                            Searching for nearest delivery driver.
                        </p>
                        <p class="srchTxt" *ngIf="searching">
                            "{{deliveryDriverDetail?.assignedUser.firstName}}
                            {{deliveryDriverDetail?.assignedUser.lastName}}" has
                            been assigned as a delivery driver for this order.
                        </p>
                    </div>

                </div>

            </div>
            <div class="modal-footer" *ngIf="searching">
                <button class="btn btn-primary btn-theme" data-dismiss="modal" (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>

<map-navigation *ngIf="navigateModal" [navDetail]="navDetail">
    <map-navigation>