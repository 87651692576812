<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container">
  <hr class="mt-0 mb-4">
  <div class="row">
    <div class="col-xl-4 mt-2 " style="width:100% ;">
      <div class="text-center mt-3">
        <div class="profle-sec mt-5">
          <img [src]="this.imageUrl || 'assets/images/man.png'">

          <i class="fa fa-camera cmra-icon" aria-hidden="true">
            <input type="file" class="input-file" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)" />
          </i>
        </div>
      </div>
      <div class="card-body text-center">
        <!-- <h1>{{profileData?.firstName}} {{profileData?.lastName}}</h1>
        <p class="driv1">{{profileData?.phoneNumber}}</p>
        <p class="driv2">{{profileData?.email
          }}</p> -->
        <div class="new-email mb-1">{{profileData?.firstName}} {{profileData?.lastName}}</div>
        <div class="new-name mb-4">{{profileData?.email}}</div>
      </div>
    </div>

    <div class="col-xl-7 mt-2" id="xyz">

      <div class="myprofile">Edit Profile</div>
      <div class="card-body pl-0">
        <form [formGroup]="addProduct" class="form mt-3 ">
          <!-- <h1 class="add mb-5 ml-2">Edit Profile</h1> -->
          <div class="mb-3">
            <div class="mt-3">
              <p class="prsnlInfo">Personal Information</p>
            </div>
            <label class="smallTes mb-1" for="inputUsername">Username (how your name will appear to other users on the
              site)</label>
            <!-- <input class="form-control" id="inputUsername" type="text" placeholder="Enter your username" attr.value="username"> -->
          </div>
          <!-- new comp  -->
          <div class="row mb-4">
            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">First Name<span class="astrict">*</span></label>
                <input type="text" placeholder="Enter first name" formControlName="fName" maxlength="62"
                  (keypress)="preventSpace($event)" class="form-control form-control-small" />
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('fName')?.hasError('required') && addProduct.get('fName')?.touched ">
                  *Please enter first name.</div>

                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('fName')?.hasError('minlength') && addProduct.get('fName')?.dirty ">
                  *Please enter minimum 2 character.</div>

                <!-- <div class="for-validation text-danger"
                  *ngIf="addProduct.get('fName')?.hasError('pattern') && addProduct.get('fName')?.dirty">
                  *Please enter valid first name.
  
                  </div> -->
              </div>
            </div>
            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Last Name<span class="astrict">*</span></label>
                <input type="text" id="form3Examplea8" placeholder=" Enter last name" formControlName="lName"
                  maxlength="250" (keypress)="preventSpace($event)" class="form-control form-control-small" />
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('lName')?.hasError('required') && addProduct.get('lName')?.touched ">
                  *Please enter last name.</div>

                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('lName')?.hasError('minlength') && addProduct.get('lName')?.dirty ">
                  *Please enter minimum 2 character.</div>

                <!-- <div class="for-validation text-danger"
                  *ngIf="addProduct.get('lName')?.hasError('pattern') && addProduct.get('lName')?.dirty">
                  *Please enter valid Last name.</div> -->
              </div>
            </div>
          </div>


          <div class="row mb-4">
            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Email<span class="astrict">*</span></label>
                <input type="text" id="addr" placeholder="Enter your email " formControlName="email" maxlength="250"
                  (keypress)="preventSpace($event)" class="form-control form-control-small" />
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('email')?.hasError('required') && addProduct.get('email')?.touched ">
                  *Email is required.</div>
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('email')?.hasError('pattern') && addProduct.get('email')?.dirty ">
                  *Please
                  enter a valid email.</div>
              </div>
            </div>
            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Mobile No.<span class="astrict">*</span></label>
                <div class="row">
                  <div class="col-md-4 pl-0 pr-0">
                    <span class="asd3"> <input list="browsers" class="form-control email-pass-pro ddd"
                        formControlName="countryCode" name="browser" id="browser"></span>

                    <datalist id="browsers">
                      <option *ngFor="let code of datas" value="{{code?.phonecode}}">{{code?.flag}}
                        {{code?.phonecode}}
                        {{code?.isoCode}} </option>

                    </datalist>
                  </div>
                  <div class="col-md-8 pl-0 pr-0">
                    <input type="text" id="form3Examplea8" placeholder=" Enter mobile number" formControlName="Phone"
                      maxlength="10" (keypress)="preventSpace($event)" (keypress)="NumOnly($event)"
                      class="form-control form-control-small" />
                  </div>
                </div>
                <div class="for-validation  text-danger"
                  *ngIf="addProduct.get('Phone')?.hasError('required') && addProduct.get('Phone')?.touched ">
                  *Contact number is required.</div>
                <div class="for-validation  text-danger"
                  *ngIf="addProduct.get('Phone')?.hasError('pattern') && addProduct.get('Phone')?.dirty ">
                  *Please enter valid number.</div>
              </div>
            </div>
          </div>




          <!-- name  -->
          <div class="row mb-4">
            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Address <span class="astrict">*</span></label>
                <!-- <input type="text" id="addr" placeholder="Enter Address" formControlName="address" maxlength="250"
                  (keypress)="preventSpace($event)" class="form-control form-control-small" /> -->
                <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event)" id="addr" placeholder="Enter Address 1"
                  formControlName="address" (keypress)="preventSpace($event)" class="form-control form-control-small" />
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('address')?.hasError('required') && addProduct.get('address')?.touched ">
                  *Address1 is required.</div>
                <!-- <div class="for-validation text-danger"
                  *ngIf="addProduct.get('address')?.hasError('maxlength') && addProduct.get('address')?.touched">
                  *Can not enter more than maxlength 32 character</div> -->
                <div class="for-validation text-danger"
                  *ngIf="!addProduct.get('address')?.hasError('required') && addProduct.get('address')?.touched && is_addressInValid">
                  *Please enter valid address.</div>
              </div>
            </div>
            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Address 1</label>
                <input type="text" id="form3Examplea8" placeholder="Enter Address 2" formControlName="address1"
                  (keypress)="preventSpace($event)" maxlength="250" class="form-control form-control-small" />
                <!-- <div class="for-validation text-danger"
                  *ngIf="addProduct.get('address1')?.hasError('required') && addProduct.get('address1')?.touched ">
                  *Address2 is required.</div>
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('address1')?.hasError('maxlength') && addProduct.get('address1')?.touched ">
                  *Can not enter more than maxlength 250 character.</div> -->
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <!-- <div class="col-md-6 fna">
  
              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Website URL</label>
                <input type="text" id="form3Examplea8" placeholder="www.abcdef.com" formControlName="url"
                  (keypress)="preventSpace($event)" class="form-control form-control-small" />
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('url')?.hasError('required') && addProduct.get('url')?.touched ">
                  *website URL is required.</div>
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('url')?.hasError('pattern') && addProduct.get('url')?.touched ">
                  *website URL is required*</div>
              </div>
            </div> -->

            <div class="col-md-6 fna">
              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">Country<span class="astrict">*</span></label>

                <!-- <div class="col-6 "> -->
                <select class="form-control email-pass-pro" id="asd3" formControlName="country"
                  (change)="getStateList($event)">
                  <option value="" selected style="text-align: start;">Select your country
                  </option>
                  <option *ngFor="let item of countryList" [value]="item.isoCode">
                    {{item?.name}}</option>
                </select>
                <!-- </div> -->
              </div>
            </div>
            <div class="col-md-6 fna">
              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">State</label>
                <select class="form-control email-pass-pro" id="asd3" formControlName="state"
                  (change)="getCityList($event)">
                  <option value="" selected>Select your state</option>
                  <option *ngFor="let item of stateList" [value]="item.isoCode">{{item?.name}}
                  </option>
                </select>
              </div>
            </div>


            <!-- <div class="loc1">
              <mat-form-field class="location" appearance="outline">
                <mat-label>Store Location</mat-label>
                <input matInput placeholder="Location" formControlName="storeLocation" ngx-google-places-autocomplete
                  (onAddressChange)="handleAddressChange($event)">
                
                <mat-icon matSuffix class="material-icons-outlined location on" style="cursor: pointer;">
                  location_on</mat-icon>
              </mat-form-field>
            </div> -->

          </div>

          <div class="row mt-4">



            <div class="col-md-6 fna">

              <div class="form-outline form-white">
                <label class="form-label vantiy" for="form3Examplea8">City</label>
                <select class="form-control email-pass-pro" id="asd3" formControlName="city">
                  <option value="" selected>Select your city</option>
                  <option *ngFor="let item of cityList" [value]="item.name">{{item?.name}}
                  </option>
                </select>

              </div>
            </div>
            <div class="col-md-6 ">

              <div class="form-outline form-white">
                <label class="vantiy" for="form3Examplea8">Zip Code<span class="astrict">*</span></label>
                <input type="text" id="asd" formControlName="zipCode" placeholder=" Enter Zip Code"
                  (keypress)="preventSpace($event)" class="form-control email-pass-pro" maxlength="8" />
                <div class="for-validation text-danger mt-1 mb-1"
                  *ngIf="addProduct.get('zipCode')?.hasError('required') && addProduct.get('zipCode')?.touched">
                  *Zip code is required.</div>
                <div class="for-validation text-danger"
                  *ngIf="addProduct.get('zipCode')?.hasError('pattern') && addProduct.get('zipCode')?.dirty">
                  *Please enter valid zipcode.

                </div>


              </div>

            </div>
          </div>
          <!-- <div class="col-md-12 mt-3">
            <mat-divider></mat-divider>
          </div> -->

          <!-- <div class="row">
            
            <div class="col-md-6 ">
  
  
            </div>
          </div> -->

          <!-- <div class="col-md-12 mt-3">
            <mat-divider></mat-divider>
          </div> -->


          <!-- sosallinkkkkkkkkk2222222 -->




          <div class="text-center mt-4 mb-4">
            <button class="btn btn-outline-success mr-3 back-button newBty" type="submit"
              routerLink="/customer-profile">Back</button>
            <button class="btn btn-outline-success my-2 my-sm-0 back-button" *ngIf="!isLoading" [disabled]="addProduct.invalid"
              (click)="editProfileDetail()" type="submit">Update</button>
            <button class="btn btn-outline-success my-2 my-sm-0 back-button op05" *ngIf="isLoading">Loading...</button>
          </div>
          <!-- <div class="d-flex justify-content-end mb-3">
            <span class="vies text-align-end" routerLink="/view-profile" style=" cursor: pointer;"><a id="vie">Edit Business Form</a></span>
  
          </div> -->
        </form>
      </div>


    </div>


  </div>

</div>

<!-- <button routerLink="/business-detaile">Business</button> -->