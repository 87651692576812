<div class="container fw-b">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>
<div class="container mb-5 wrapper-content">
  <!-- <div class="row justify-content-center startBox">
    <div class="form-group has-search">
      <span class="fa fa-search form-control-feedback"></span>
      <input type="text" class="form-control" [(ngModel)]="serviceName" placeholder="Search by deal name"
        (input)="serviceOrderList(); currentPage = 1" />
    </div>
  </div> -->
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mt-3">
    <ng-container *ngIf="serviceDetail?.length">
      <div class="mb-3 col newCardWidth newCardWidth1" *ngFor="
      let data of serviceDetail
        | paginate
          : {
              itemsPerPage: pageSize,
              currentPage: currentPage,
              totalItems: totalItems
            };
      let i = index
    ">
        <deals-card [isImage]="false" [data]="data" [countDown]="countDown[i]"
          (on_Click)="navigateToViewProduct(data?._id)"></deals-card>
      </div>
    </ng-container>
    <ng-container *ngIf="!serviceDetail?.length && !isLoading">
      <div class="mb-3 col newCardWidth newCardWidth1" *ngFor="
      let data of length(12)">
        <deals-card [isImage]="false" [data]="data"></deals-card>
      </div>
    </ng-container>

  </div>
  <div *ngIf="isLoading" class="no-data">
    <p style="text-align: center; font-size: 16px; font-weight: 500">
      No Data Found
    </p>
  </div>

  <div class="custom-pagination mt-2 text-align-right" style="text-align: right"
    *ngIf="totalItems > 15 || totalCounts > 15">
    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
  </div>
</div>