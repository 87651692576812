import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
@Component({
  selector: 'app-reatiler-category-view-list',
  templateUrl: './reatiler-category-view-list.component.html',
  styleUrls: ['./reatiler-category-view-list.component.scss']
})
export class ReatilerCategoryViewListComponent implements OnInit {
  _id: any
  currentPage = 1;
  pageSize = 15;
  serviceName: any
  latitude: any
  longitude: any
  isLoggedIn: any
  loggedInUserType: any
  categoryName: any
  apiLoaded : boolean = false
  headingName:any ='Category'
  linkerRouteItems:any =[
    { name:'Home',route: this.getEndUrlSegment() == 'retailer-category-list-view' ? '/retailer-home' : '/home'},
    {name:'Category',route:'/retailer-category-list-view'}

  ]
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      this.categoryName = res.categoryName
      this.headingName =res.categoryName
      this.linkerRouteItems[1]={name:res.categoryName,route:'/retailer-category-list-view'}
    })
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {


      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {

      this.loggedInUserType = res
    })
    navigator.geolocation.getCurrentPosition(function(position) {  
      // alert('allow');  
  }, function() {
      alert('You need to enable geolocation services in your browser to use this feature.');
  });
  this.getPosition().then(pos=>
    
    {
    
    localStorage.setItem('location',JSON.stringify(pos))
     
  });
  this.getLocation();
  
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any
  totalItems: any

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getProduct()
        }
      },
        (error) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getProduct() {
    if (this.isLoggedIn) {
      this.getServiceListwithlogin()
    } else {
      this.getServiceList()
    }
  }
  isLoading: boolean = false;
  // get retailer detail by id
  getsubCatId(id){
    this.currentPage = 1
    this.productDetails = []
    this.getServiceList(id)
  }
  getEndUrlSegment(): string {
    const url = this.router.url;
    const segments = url.split('/');
    const lastSegmentWithQueryParamsAndFragments = segments[segments.length - 1];
    const endUrlSegment = lastSegmentWithQueryParamsAndFragments.split('?')[0].split('#')[0];
    return endUrlSegment;
  }
  getServiceList(id?) {
    this.commonService.showSpinner();
    let apiReqUrl: any = `product/listProductBySortion`
    let apiReqData = {
      search: this.serviceName,
      page: String(this.currentPage),
      limit: String(this.pageSize),
      categoryId: this._id,
      subCategoryId : id ? id :'',
      userType: this.getEndUrlSegment() == 'retailer-category-list-view' ? 'WHOLE_SALER' : 'RETAILER',
      lng: this.longitude,
      lat: this.latitude
    }
    this.commonService.removeEmptyKey(apiReqData)
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    if(!this.serviceName){
    }
    this.apiLoaded = false

    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, apiReqData, a).subscribe((res: any) => {
      if (res.responseCode == 200) {



        this.productDetails = res.result.docs;
        if (!this.productDetails.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        // this.imgurl = res.result.productImage[0]
        this.totalItems = res.result.count
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.isLoading = true;
        this.apiLoaded = true
        this.productDetails = []
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.isLoading = true;
      this.apiLoaded = true
      this.productDetails = []
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  getServiceListwithlogin() {
    this.commonService.showSpinner();
    let apiReqUrl: any = `product/listProductBySortion`
    let apiReqData = {
      search: this.serviceName,
      page: String(this.currentPage),
      limit: String(this.pageSize),
      categoryId: this._id,
      userType: this.getEndUrlSegment() == 'retailer-category-list-view' ? 'WHOLE_SALER' : 'RETAILER',
      lng: this.longitude,
      lat: this.latitude
    }
    this.commonService.removeEmptyKey(apiReqData)
    this.apiLoaded = false
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, apiReqData, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result.docs;
        if (!this.productDetails.length) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        // this.imgurl = res.result.productImage[0]
        this.totalItems = res.result.count
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.productDetails = []
        this.commonService.hideSpinner();
        this.apiLoaded = true
        // this.commonService.errorToast(res.responseMessage)
      }
    }, (err) => {
      this.productDetails = []
      this.apiLoaded = true
      this.commonService.hideSpinner();
      this.commonService.errorToast(err.responseMessage)
    })
  }
  navigateToViewProduct(_id) {
    this.router.navigate(['/customer-category-particular-view'], { queryParams: { _id: _id , otId : this._id, otName: this.categoryName } })
  }
  pagination(event) {
    this.productDetails = []
    this.currentPage = event;
    this.getProduct();
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.commonService.hideSpinner();
        this.getProduct()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login-retailer')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login-retailer')]
      }
    })
  }

  length(n){
   return new Array(n)
  }
}
