import { CommonService } from './../../../../provide/common.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-details-service-provider',
  templateUrl: './business-details-service-provider.component.html',
  styleUrls: ['./business-details-service-provider.component.scss']
})
export class BusinessDetailsServiceProviderComponent implements OnInit {

  heroes = ['Windstorm', 'Bombasto', 'Magneta', 'Tornado'];
  businessform!: FormGroup;
  products: any = [];
  showInputes: boolean = false;
  showInput: boolean = false;
  isVatRegistered: boolean | any;
  addNewSubcategoryField: any = []
  addNewSubcategoryBussinessField: any = []
  countryCode = new FormControl('+27');
  brandName: any
  serviceRole: string
  userType: string
  signupTitle: string
  constructor(public commonService: CommonService, private http: HttpClient, private router: Router) {
    this.serviceRole = localStorage.getItem('serviceRole')
    if (this.serviceRole == "PICKUP") {
      this.signupTitle = "Pickup Partner detail"
      this.userType = "PICKUP_PARTNER"
    }
    else if (this.serviceRole == "DELIVERY") {
      this.signupTitle = "Signup As delivery Driver"
      this.userType = "DELIVERY_PARTNER"
    }
    else if (this.serviceRole == "FIELD") {
      this.signupTitle = "Signup As field entity"
      this.userType = "FIELD_ENTITY"
    }
    else {
      this.signupTitle = "Signup As Service Provider"
      this.userType = "SERVICE_PROVIDER"
    }
  }

  ngOnInit(): void {

    this.addNewSubCategryDynamic();
    // this.addNewSubCategryBussinessDynamic();

    this.businessform = new FormGroup({
      fName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),

      lName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),

      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),

      company: new FormControl('', [
        Validators.required,

      ]),

      businessRegNumber: new FormControl('', [
        Validators.required,

      ]),
      // cotegory: new FormControl('', [
      //   Validators.required,

      // ]),
      url: new FormControl('', [
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)

      ]),

      vatRegistered: new FormControl(''),

      // vatNumberInput: new FormControl('', [
      //   Validators.required,
      // ]),

      monthlyRevenue: new FormControl(''),


      bankname: new FormControl('', [Validators.required,
      Validators.pattern(/^[a-z A-Z]*$/i)
      ]),


      branchname: new FormControl('', [
        Validators.required,

      ]),
      swiftcode: new FormControl('', [
        Validators.required,
        Validators.pattern("^[A-Z][a-zA-Z]*")

      ]),
      accountType: new FormControl('', [
        Validators.required,

      ]),
      accountName: new FormControl('', [
        Validators.required,

      ]),
      accountNumber: new FormControl('', [
        Validators.required,

      ]),



    })
    this.http.get("assets/countrycode.json").subscribe(data => {
      this.products = data['result'];

    })
    // this.getValidation()
  }


  getValidation() {
    if (this.serviceRole != 'SERVICE') {
      this.businessform.removeControl('company');
      this.businessform.removeControl('businessRegNumber');
      this.businessform.removeControl('monthlyRevenue');
      this.businessform.removeControl('url');
      this.businessform.removeControl('vatRegistered');
      this.businessform.removeControl('vatNumberInput');


      if (this.serviceRole != 'FIELD') {
        this.businessform.addControl('licenceNumber', new FormControl('', Validators.required));
        this.businessform.addControl('vehicleRegistartionNumber', new FormControl('', Validators.required));
        this.businessform.addControl('vehicleModel', new FormControl('', Validators.required));
        this.businessform.addControl('vehicleYear', new FormControl('', Validators.required));
        this.businessform.addControl('vehicleType', new FormControl('', Validators.required));
        this.businessform.addControl('vehicleColour', new FormControl('', Validators.required));
        this.businessform.addControl('insuranceNumber', new FormControl('', Validators.required));

      }
      else{
        this.businessform.addControl('buisnessName', new FormControl('', Validators.required));
        this.businessform.addControl('buisnessType', new FormControl('', Validators.required));
        this.businessform.addControl('buisnessAddress', new FormControl('', Validators.required));
        this.businessform.addControl('buisnessWebsite', new FormControl(''));
        this.businessform.addControl('buisnessEmail', new FormControl('', [Validators.required,  Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]));
        this.businessform.addControl('einNum', new FormControl('', Validators.required));
        this.businessform.addControl('monthlyOrderVolume', new FormControl('', Validators.required));
        this.businessform.addControl('salesTaxId', new FormControl(''));
      }
    }
  }
  addNewSubCategryDynamic() {
    if (this.addNewSubcategoryField.length && this.addNewSubcategoryField[this.addNewSubcategoryField.length - 1].sub_Category_List == '') {
      return this.commonService.infoToast('Please enter the value in previous added, after that click on add new.')
    }
    this.addNewSubcategoryField.push({
      sub_Category_List: ''
    })
  }



  removeBodySectionDynamic(index: number) {
    this.addNewSubcategoryField.splice(index, 1)
  }



  businessDetaile() {
    let subCategoryData = this.addNewSubcategoryField.map((x: any) => {
      return x.sub_Category_List
    })
    let emptyString = subCategoryData.includes("")
    var fillDetails = {
      ownerFirstName: this.businessform.value.fName,
      ownerLastName: this.businessform.value.lName,
      ownerEmail: this.businessform.value.email,
      "businessBankingDetails": {
        "bankName": this.businessform.value.bankname,
        "branchName": this.businessform.value.branchname,
        // "branchCode": "string",
        "swiftCode": this.businessform.value.swiftcode,
        "accountType": this.businessform.value.accountType,
        "accountName": this.businessform.value.accountName,
        "accountNumber": this.businessform.value.accountNumber
      },

      "businessDetails": {
        "companyName": this.businessform.value.company,
        "businessRegNumber": this.businessform.value.businessRegNumber,
        // "categoryId": "string",
        "websiteUrl": this.businessform.value.url,
        "socialMediaAccounts": "string",
        "isVatRegistered": this.businessform.value.vatNumberInput ? true : false,
        "vatNumber": this.businessform.value.vatNumberInput,
        "monthlyRevenue": this.businessform.value.monthlyRevenue,

        // "storeAddress": this.businessform.value.storeessed,
        // "storeName": this.businessform.value.storeess,
        // "storeContactNo": this.businessform.value.storesgg,



      },

      flag: 1,
      userType: 'SERVICE_PROVIDER',
      // "preferredSupplierOrWholeSalerId": selectedCarObjS
    }
    this.commonService.showSpinner()
    this.commonService.postApi('user/fillDetails', fillDetails, 1).subscribe((res: any) => {

      if (res['responseCode'] == 200) {
      
        this.commonService.hideSpinner()


        if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {


          this.router.navigate(['/service-provider-select-role'])

        }

        else if (!res.result.completeProfile) {

          this.router.navigate(['/service-provider-select-role']);

        }
        // set current url to localstorage 
        // this.router.navigate(['/upload-documentation']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {

      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })



  }
  pickupDetaile() {
    let subCategoryData = this.addNewSubcategoryField.map((x: any) => {
      return x.sub_Category_List
    })
    let emptyString = subCategoryData.includes("")
    var fillDetails = {
      ownerFirstName: this.businessform.value.fName,
      ownerLastName: this.businessform.value.lName,
      ownerEmail: this.businessform.value.email,
      "businessBankingDetails": {
        "bankName": this.businessform.value.bankname,
        "branchName": this.businessform.value.branchname,
        // "branchCode": "string",
        "swiftCode": this.businessform.value.swiftcode,
        "accountType": this.businessform.value.accountType,
        "accountName": this.businessform.value.accountName,
        "accountNumber": this.businessform.value.accountNumber
      },

      "driverDetail": {
        "licenceNumber": this.businessform.value.licenceNumber,
        "vehicleRegistartionNumber": this.businessform.value.vehicleRegistartionNumber,
        "vehicleModel": this.businessform.value.vehicleModel,
        "vehicleYear": this.businessform.value.vehicleYear,
        "vehicleType": this.businessform.value.vehicleType,
        "vehicleColour": this.businessform.value.vehicleColour,
        "insuranceNumber": this.businessform.value.insuranceNumber
      },

      flag: 1,
      userType: this.userType,
      // "preferredSupplierOrWholeSalerId": selectedCarObjS
    }
    this.commonService.showSpinner()
    this.commonService.postApi('user/fillDetails', fillDetails, 1).subscribe((res: any) => {

      if (res['responseCode'] == 200) {
        // this.commonService.successToast(res['responseMessage'])
        // localStorage.setItem('email', this.businessform.value.email)
        this.commonService.hideSpinner()


        if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {


          this.router.navigate(['/upload-document-service-provider'])

        }

        else if (!res.result.completeProfile) {

          this.router.navigate(['/upload-document-service-provider']);

        }
        // set current url to localstorage 
        // this.router.navigate(['/upload-documentation']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {

      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })



  }

  fieldDetaile() {
    let subCategoryData = this.addNewSubcategoryField.map((x: any) => {
      return x.sub_Category_List
    })
    let emptyString = subCategoryData.includes("")
    var fillDetails = {
      ownerFirstName: this.businessform.value.fName,
      ownerLastName: this.businessform.value.lName,
      ownerEmail: String(this.businessform.value.email).toLowerCase(),
      "businessBankingDetails": {
        "bankName": this.businessform.value.bankname,
        "branchName": this.businessform.value.branchname,
        // "branchCode": "string",
        "swiftCode": this.businessform.value.swiftcode,
        "accountType": this.businessform.value.accountType,
        "accountName": this.businessform.value.accountName,
        "accountNumber": this.businessform.value.accountNumber
      },

      "fieldEntityDetails": {
        "businessName": this.businessform.value.buisnessName,
        "businessType": this.businessform.value.buisnessType,
        "businessAddress": this.businessform.value.buisnessAddress,
        "businessWebsite": this.businessform.value.buisnessWebsite,
        "businessEmail": this.businessform.value.buisnessEmail,
        "einNum": this.businessform.value.einNum,
        "salesTaxId": this.businessform.value.monthlyOrderVolume,
        "monthlyOrderVolume": this.businessform.value.salesTaxId
      },

      flag: 1,
      userType: this.userType,
      // "preferredSupplierOrWholeSalerId": selectedCarObjS
    }
    this.commonService.showSpinner()
    this.commonService.postApi('user/fillDetails', fillDetails, 1).subscribe((res: any) => {
      this.loading = false
      if (res['responseCode'] == 200) {
        // this.commonService.successToast(res['responseMessage'])
        // localStorage.setItem('email', this.businessform.value.email)
        this.commonService.hideSpinner()


        if (res.result.completeProfile && res.result.userRequestStatus == 'PENDING' && res.result.flag == 0) {


          this.router.navigate(['/upload-document-service-provider'])

        }

        else if (!res.result.completeProfile) {

          this.router.navigate(['/upload-document-service-provider']);

        }
        // set current url to localstorage 
        // this.router.navigate(['/upload-documentation']);

        // this.currentUrl = this.route.url;
        // localStorage.setItem('token', res.result.token)
      }

    }, err => {

      this.commonService.hideSpinner()
      this.commonService.errorToast(err.error['responseMessage'])
    })



  }


  open() {
    this.businessform.addControl('vatNumberInput', new FormControl('', Validators.required));

    this.showInput = !this.showInput
  }

  close() {
    this.businessform.removeControl('vatNumberInput');

    this.showInput = false
  }


  openesd() {
    this.showInputes = !this.showInputes
  }

  closeesd() {
    this.showInputes = false
  }

  // serchlistApi




  // addNewSubCategryBussinessDynamic() {
  //   if (this.wholeSalerList.length && this.wholeSalerList[this.wholeSalerList.length - 1].sub_Category_List == '') {
  //     return this.commonService.infoToast('Please enter the value in previous added, after that click on add new.')
  //   }
  //   this.wholeSalerList.push({
  //     sub_Category_List: ''
  //   })
  // }
  valueName: any
  srarchWholeSaler: any
  nameArray: any = []
  selectedCarObjS: any = []
  arrayVlaue: any = []
  srarchWholeSalers = "";

  selectedCarObj: any = [];

  removeSearchDynamic(index: number) {
    this.selectedCarObjS.splice(index, 1)
  }
  wholeSalerList: any = []

  /** Function to restrict character */
  restrictChar(event: any) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }



  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }


  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }






  // ===================









  totalItems: any;

  getcontents($event: any) {

    this.commonService.getApi('user/listCountry', 0).subscribe((res: any) => {
      this.commonService.showSpinner()
      if (res['responseCode'] == 200) {
        // this.categoryListArray = res['result']

        this.totalItems = res['result']
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.products = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    }, err => {

      this.commonService.warningToast(err['error']['responseMessage'])


    }
    )
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }


}
