
<auth-layout title="Forgot Password" subTitile="Please enter your email address below. You will receive an OTP to reset your password." sideImage="assets/latest/forgotScreen.svg" >
    <form [formGroup]="forgotPassword">
        <div class="form-group">
            <label for="username">Email Address<span class="redis">*</span></label>
            <div class="input-control">
                <input type="text" class="form-control" placeholder="Enter your email" type="text"
                    formControlName="email" maxlength="256" (keypress)="preventSpace($event)" id="username">
                <span class="eyeicon">
                    <img src="assets/latest/email.svg" alt="">
                </span>
            </div>
            <div class="for-validation mt-2">
                <div class="for-validaton text-danger"
                    *ngIf="forgotPassword.get('email')?.hasError('required') && forgotPassword.get('email')?.touched">
                    *Please enter your email address.

                </div>
                <div class="for-validation text-danger"
                    *ngIf="forgotPassword.get('email')?.hasError('pattern') && forgotPassword.get('email')?.dirty">
                    *Please enter a valid email address.

                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between" style="align-items: center;">
            <div class="left-wrap">
                <button class="for-login btn text-white btn-block btn-primary" *ngIf="!isLoading" (click)="add()"
                [disabled]="!forgotPassword.valid" type="submit">Reset
                Password</button>
                <button class="for-login btn text-white btn-block btn-primary op05" *ngIf="isLoading">Loading...</button>
            </div>
            <div class="right-wrap">
                <span class="d-block text-left my-4 text-muted" [routerLink]="['/login']">Back to
                    <span class="cols">Login.</span></span>
            </div>
        </div>
    </form>
</auth-layout>
