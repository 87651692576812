import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-retailer-add-deals',
  templateUrl: './retailer-add-deals.component.html',
  styleUrls: ['./retailer-add-deals.component.scss']
})
export class RetailerAddDealsComponent implements OnInit {
  headingName:any ='ADD DEALS'
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Add Deals',route:'/retailer-add-deals'}
  ]
  addDeals!: FormGroup;
  addNewSubcategoryField: any = [];
  imageUrl: any;
  categoryListArray: any;
  totalItems: any;
  categoryId: any;
  subCategoryListArray: any;
  subCategoryId: any;
  currentPage = 1;
  itemPerPage = 10;
  searchListArray: any;
  dealName: any;
  aarayData: any;
  imageLinks: any = [];
  thumbnail: any;
  subid: any;
  _id: any = [];

  codeValue: any;
  today: any = new Date().toISOString().slice(0, 16);
  mediaType: any = 'media';
  imagThumb: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    // this.getSearch(),

    this.addDeals = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(256),
      ]),

      category: new FormControl('', Validators.required),

      subCategory: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
      ]),

      description: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
      ]),
      actualprice: new FormControl(''),
      
      mrp: new FormControl('', [
        Validators.required,
        Validators.pattern('^([R0-9$.])*$'),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),

      // qty: new FormControl('', [
      //   Validators.required,
      //   Validators.pattern('^([R0-9$.])*$'),
      //   // Validators.pattern("^[A-Z][a-zA-Z]*")
      // ]),

      discount: new FormControl('', [
        Validators.required,
        Validators.pattern('^([R0-9$.])*$'),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),

      dealStartTime: new FormControl('', Validators.required),

      dealEndTime: new FormControl('', Validators.required),
    });
    this.getSearchfirst()
  }

  NumOnly(event: any) {
    let Numpattern = /^([R0-9$.%])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  // validation() {

  // }

  priceMsg:any
  discountCalforvalue(){
    this.priceMsg=''
    if(this.addDeals.value.actualprice < this.addDeals.value.mrp){
      this.addDeals.controls.mrp.setValue('')
       this.priceMsg = 'Please enter deal price less then actual price '+this.addDeals.value.actualprice
    }
   if(Number(this.addDeals.value.mrp)==0){
    this.addDeals.controls.mrp.setValue('')
    this.priceMsg = 'Please enter deal price between 0 to '+this.addDeals.value.actualprice
    }
    let DiscountPercentage = ((this.addDeals.value.actualprice - this.addDeals.value.mrp) / this.addDeals.value.actualprice) * 100
    let dsp = DiscountPercentage.toFixed(0)
    let fndsp = dsp
    console.log(fndsp)
    this.addDeals.controls.discount.setValue(fndsp)

  }
  productId: any = [];
  // ==============================search====================
  searchList: any = [];
  catId: any;
  dataArry: any;
  attendeeArray: any = []
  getSearch(event: any) {
    if (!event.target.value) {
      // setTimeout(() => {
      this.catId = '';
      this.subid = '';
      this.searchList = [];
      this.imageLinks = [];
      this.addDeals.reset();
      return;
      // }, 10);
    }
    let data = {
      search: this.addDeals.value.title,
    };
    
    let apiReqUrl: any = `product/productOfOwn`;
    // this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.searchList = res['result']['docs'];
        this.addDeals.patchValue({
          description: this.searchList[0].description,
          category: this.searchList[0].categoryId.categoryName,
          subCategory: this.searchList[0].subCategoryId.subCategoryName,
          mrp: this.searchList[0]?.priceSizeDetails[0]?.price,
          actualprice: this.searchList[0]?.priceSizeDetails[0]?.price,
        });
        this.catId = this.searchList[0].categoryId._id;
        this.subid = this.searchList[0].subCategoryId._id;
        this.imageLinks = res['result']['docs'][0]['productImage'];
        this.dataArry = res['result']['docs'][0]['thumbnail'];
        this.productId = res['result']['docs'][0]['_id'];
        this.attendeeArray = this.searchList[0]?.priceSizeDetails
      } else if (res.responseCode == 404) {
        this.searchList = []
      }
      else {
        this.searchList = []
      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.searchList = []
      }
    });
  }

  calcMargin($event: any) {
    let myval = $event.target.value
    let urlData = (((myval / 100) * this.searchList[0]?.priceSizeDetails[0]?.price))
    let totalPrice = (this.searchList[0]?.priceSizeDetails[0]?.price - urlData)
    this.addDeals.patchValue({
      mrp: totalPrice
    })
  }
  getSearchfirst() {
    let data = {


    }
    let apiReqUrl: any = `product/productOfOwn`;
    // this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.searchList = res['result']['docs'];
      } else if (res.responseCode == 404) {
        this.searchList = []
        this.imageLinks = [];

      }
      else {
        this.searchList = []
        this.imageLinks = [];

      }
    }, (err: any) => {
      if (err.responseCode == 404) {
        this.searchList = []
        this.imageLinks = [];

      }
    });
  }

  // ====================================================save button================================
  getDeals() {
    let s = new Date(this.addDeals.value.dealStartTime)
    let e = new Date(this.addDeals.value.dealEndTime)
    let startDate = s.toISOString();
    let endTime = e.toISOString();
    let data = {
      dealName: this.addDeals.value.title,
      categoryId: this.catId,
      subCategoryId: this.subid,
      // categoryId:this.addDeals.value.category._id,
      // subCategoryId: this.addDeals.value.subCategory._id,

      dealImage: this.imageLinks,
      // dealPrice: this.addDeals.value.mrp - this.addDeals.value.discount / 100,
      dealPrice: this.addDeals.value.mrp,
      quantity: this.addDeals.value.qty,
      description: this.addDeals.value.description,
      dealDiscount: this.addDeals.value.discount,
      dealStartTime: startDate,
      dealEndTime: endTime,
      dealsFor: 'RETAILER',
      // productId:'62fc7f8cd327f54ba9c997e1',
      productId: this.productId,
      // thumbnail: this.imageLinks || this.dataArry,
      // thumbnail: this.imageLinks[0] ||this.dataArry
      thumbnail: this.dataArry,
      priceSizeDetails: this.attendeeArray
    };
    
    let apiReqUrl: any = `deal/dealAdd`;
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, data, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(['/retailer-deal-list']);
        // localStorage.setItem('token', res.result.token)
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }, (err: any) => {
      this.commonService.hideSpinner();
      // this.commonService.errorToast("Please enter percentage of discount.");
    });
  }

  postImage: any = [];
  imgSrc: any = [];

  inds: any;
  async uploadImg(event: any) {
    if (event.target.files.length + this.imageLinks.length > 3) {
      this.commonService.infoToast('Please select maximum 3 image');
      return;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.imageUrl = file;
      await this.uploadProfilePic(file, index);
    }
    this.mediaType = 'png';
  }

  async uploadProfilePic(img: any, i: number) {
    let url = 'user/uploadMultipleFile';
    this.imageLinks.push('assets/img/imageLoader.gif');

    const formData = new FormData();
    formData.append('uploaded_file', img);
    let res: any = await
      this.commonService.postFormDataApi(url, formData, 1).toPromise();

    if ((await res.responseCode) == 200) {
      this.imageLinks.pop();
      this.imageLinks.push(res['result'][0]['mediaUrl']);

      this.imagThumb = res['result'][0]['thumbnail'];
      // this.commonService.showSpinner();
    } else {
      this.commonService.errorToast("Can't Upload This File");
    }
  }
  closeimage(i: number) {
    this.imageLinks.splice(i, 1);
    if (this.imageLinks.length == 0) {
      this.mediaType = 'png';
    }
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 48 && !event.target.value) {
      return false
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {

      return false;
    }
    return true;

  }

  // getPrice(e : any){
  //   
  //   let a = e.replace(/,/g,' ')
  //   return a.replace(/R/g,'R ')
  // }


}
