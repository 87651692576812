import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-signup-retailer',
  templateUrl: './signup-retailer.component.html',
  styleUrls: ['./signup-retailer.component.scss']
})
export class SignupRetailerComponent implements OnInit {

  products: any = [];

  signup!: FormGroup;

  lanlog: any

  imageUrl: any;
  vertical: any = "true";
  categoryListArray: any;
  countryCode = new FormControl('+27');
  country: any = []
  state: any = []
  city: any = []
  isoCode: any




  // =============for icon 1==========

  visible: boolean = false;
  changetype: boolean = true;

  myToken: any;

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;

  }

  // 2nd

  visibles: boolean = false;
  changetypes: boolean = true

  viewpas() {
    this.visibles = !this.visibles;
    this.changetypes = !this.changetypes
  }
  // myImage: Observable<any> | undefined;
  // title = 'imgtobase64';
  // base64code: any;
  // signUpForm!: FormGroup;
  user: any = {}
  constructor(private http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {



    this.signup = new FormGroup({

      // image: new FormControl('', [
      //   Validators.required
      // ]),
      fName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)

      ]),
      lName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),

      Phone: new FormControl('', []),

      countryCode: new FormControl('+27', [
        Validators.required
      ]),


      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),

      address: new FormControl('', [
        Validators.required,

      ]),
      term: new FormControl('', [
        Validators.requiredTrue,

      ]),

      locatione: new FormControl('', [

      ]),

      city: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),

      state: new FormControl('', [
        Validators.required,
        // Validators.maxLength(32),
        // Validators.pattern("^[A-Z][a-zA-Z]*")
      ]),

      zipCode: new FormControl('', [
        Validators.required,
        // Validators.pattern("/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(sZip)")
      ]),

      Country: new FormControl('', [
        Validators.required,

      ]),

      Password: new FormControl('', [
        Validators.required,
        // Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')
        // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'),
      ]),
      // Validators.pattern("(?=^.{8,}$)((?=.*\\d)|(?=.*\\W+))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$")]),


      confirmPassword: new FormControl('', [
        Validators.required
      ]),
    })
    this.getCountryList()
    this.http.get("assets/countrycode.json").subscribe(data => {
      this.products = data['result'];
    })
  }
  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }
  preventSpace(event: any) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault();
    }
  }
  address: any
  options: any = []
  longitude: any;
  latitude: any;
  public handleAddressChange(address: any) {
    if ('formatted_address' in address) {
      this.options = []
      this.address = address.formatted_address
      this.longitude = address.geometry.location.lng()
      this.latitude = address.geometry.location.lat()
      this.options[0] = Number(address.geometry.location.lng())
      this.options[1] = Number(address.geometry.location.lat())
    }
  }
  getCountryCode(e: string) {
  }
  getCirtyISO(e: any) {
  }
  signUp() {
    if(this.signup.invalid){
      return this.commonService.errorToast("Please fill all required field.")
    }
    if(this.signup.value.Password != this.signup.value.confirmPassword){
      return this.commonService.errorToast("Password and confirm password must be same.")
    }
    var signUpData = {
      firstName: this.signup.value.fName,
      lastName: this.signup.value.lName,
      email: String(this.signup.value.email).toLowerCase(),
      phoneNumber: this.signup.value.Phone,
      mobileNumber: this.signup.value.mobileNumber,
      countryCode: this.signup.value.countryCode,
      addressLine1: this.signup.value.address,
      addressLine2: this.signup.value.locatione,
      city: this.signup.value.city,
      state: this.signup.value.state.name,
      stateIsoCode: this.signup.value.state.isoCode,
      zipCode: this.signup.value.zipCode,
      country: this.signup.value.Country.name,
      countryIsoCode: this.signup.value.Country.isoCode,
      password: this.signup.value.Password,
      profilePic: this.imageUrl,
      userType: 'RETAILER',
      "storeLocation": {
        "type": "Point",
        "coordinates": [
          this.longitude || 0.00,this.latitude || 0.00
        ]
      },
    }
this.loading = true
    this.commonService.postApi('user/signUp', signUpData, 0).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.commonService.successToast(res['responseMessage'])
        localStorage.setItem('email', this.signup.value.email)
        this.loading = false
        this.router.navigate(['/login-retailer']);
     }
    },
      (err: any) => {
        this.loading = false
        if (err.responseCode == 409) {
          this.commonService.hideSpinner()
          this.commonService.errorToast(err.responseMessage)
        }
      }


    )



  }



  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-
  uploadImg($event: any): void {
    var selectedFile = $event.target.files[0];
    let me = this
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = function () {
        me.imageUrl = reader.result; // Get the Base64 part of the data URL

      };

      // Read the image file as a data URL
      reader.readAsDataURL(selectedFile);
    }
    // this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 0).subscribe((res: any) => {
     
      this.commonService.hideSpinner();
      if (res.responseCode == '200') {
        this.imageUrl = res.result.mediaUrl
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }
  getCountryList() {
    this.commonService.getApi('user/listCountry', 1).subscribe((res: any) => {
     
      this.country = res.result

    })
  }
  countryIsoCode: any;
  getStateList(event: any) {
    this.signup.patchValue(({
      state : '',
      city : ''
    }))
    this.countryIsoCode = this.signup.value.Country.isoCode
    let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
     
      this.state = res.result
    })
  }

  getCityList(event: any) {
    this.signup.patchValue(({

      city : ''
    }))
    let stateIsoCode = this.signup.value.state.isoCode
    let apiReqUrl = `${'user/listCity?countryCode=' + this.countryIsoCode + '&stateCode=' + stateIsoCode}`
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
     
      this.city = res.result
    })
  }
  loading = false;

  load() {
    this.loading = true;

    setTimeout(() => {
      this.loading = false;

    }, 60000); // 1 minute
  }
}
