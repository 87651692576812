<div class="container  fw-b ">
    <app-linker-route [headingName]="name" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container mt-2 mb-5 wrapper-content">
    <div class="">
        <div class="row">
            <div class="col-12 mt-3 d-flex justify-content-end">
                <button class="addBtn" (click)="navigateToAddDeals()">+Deals</button>
            </div>
        </div>
        <div class="row mt-3 mb-5">
            <div class="col-lg-12 col-md-12 col-sm-6">
                <div class="" style="margin-top: 1em;">
                    <h3>{{categoryName}}</h3>
                    <div id="accordion" *ngFor="let item of serviceDetailsArray;let i = index">
                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                [disabled]="!item?.serviceArray.length">
                                <mat-expansion-panel-header class="faq-qsn">
                                    <mat-panel-title class="gg" style="color:#bf1e2e ;">
                                        {{item?.subCategory?.subCategoryName || '---'}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container *ngIf="item?.serviceArray.length">
                                    <ng-container *ngIf="item?.subCategory.categoryEnum != 'FIELD'">
                                        <div class="d-flex justify-content-between"
                                            *ngFor="let data of item?.serviceArray;let j = index"
                                            style="text-align: center;align-items: center;">
                                            <div class="custom-control custom-checkbox my-1 mr-sm-2"
                                                style="padding: 0px !important;">
                                                <input type="checkbox" class="custom-control-input"
                                                    [(ngModel)]="data.isSelected"
                                                    [attr.id]="'customControlInline'+i + '' + j"
                                                    (input)="checkValidation($event.target.checked,data.price, data?.serviceDetails?._id)">
                                                <label class="custom-control-label"
                                                    [attr.for]="'customControlInline'+i+ '' + j">{{data?.serviceDetails?.serviceName}}</label>
                                            </div>
                                            <div>

                                                <p class="mb-0 !important ner">R <input type="number" class="inpPrice"
                                                        (keypress)="commonService.numberOnly($event)"
                                                        [(ngModel)]="data.price" placeholder="Amount"
                                                        [value]="data.price">
                                                    /unit
                                                </p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="item?.subCategory.categoryEnum == 'FIELD'">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Daily</th>
                                                    <th>Weekly</th>
                                                    <th>Monthly</th>
                                                    <th>Quarterly</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of item?.serviceArray;let j = index">
                                                    <td>
                                                        <div class="custom-control custom-checkbox my-1 mr-sm-2"
                                                            style="padding: 0px !important;">
                                                            <input type="checkbox" class="custom-control-input"
                                                                [(ngModel)]="data.isSelected"
                                                                [attr.id]="'customControlInline'+i + '' + j"
                                                                (input)="checkValidation($event.target.checked,data.price, data?.serviceDetails?._id)">
                                                            <label class="custom-control-label"
                                                                [attr.for]="'customControlInline'+i+ '' + j">{{data?.serviceDetails?.serviceName}}</label>
                                                        </div>
                                                    </td>
                                                   
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_D" placeholder="Amount"
                                                                [value]="data.price_D">
                                                            
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_W" placeholder="Amount"
                                                                [value]="data.price_W">
                                                           
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_M" placeholder="Amount"
                                                                [value]="data.price_M">
                                                            
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 !important ner">R <input type="number"
                                                                class="inpPrice"
                                                                (keypress)="commonService.numberOnly($event)"
                                                                [(ngModel)]="data.price_Q" placeholder="Amount"
                                                                [value]="data.price_Q">
                                                            
                                                        </p>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </ng-container>

                                </ng-container>
                                <ng-container *ngIf="!item?.serviceArray.length">
                                    <p class="text-center">No data found</p>
                                </ng-container>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="right-totalamout">
                        <!-- <span><button class="butCh" (click)="navigateToserviceAvailableAdd()">Update</button></span> -->
                        <span><button class="butCh" (click)="update()">Update</button></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>