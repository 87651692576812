<auth-layout title="Signup As Customer" type="SIGNUP" subTitile="Access the Exobe using your email and password"
sideImage="assets/latest/customer-register.png"
>

    <form [formGroup]="signup">


        <div class="form-group">
            <div class="text-center">
                <div class="profle-sec">
                    <img [src]="imageUrl || '/assets/images/man.png'" alt="">
                    <i class="fa fa-camera cmra-icon" aria-hidden="true">
                        <input type="file" class="input-file" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)" />
                    </i>
                </div>

            </div>
            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">First Name<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" class="form-control" placeholder="Enter your email" type="text"
                            formControlName="fName" placeholder=" Enter First Name" (keypress)="preventSpace($event)"
                            id="username">



                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('fName')?.hasError('required') && signup.get('fName')?.touched   ">
                            *Please enter first name.
                        </div>
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('fName')?.hasError('minlength') &&  signup.get('fName')?.touched  ">
                            *Please enter minimum 2 character.

                        </div>
                    </div>
                </div>
                <div class="w-50">
                    <label for="username">Last Name<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" class="form-control" formControlName="lName" placeholder=" Enter Last Name"
                            (keypress)="preventSpace($event)" id="username">
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('lName')?.hasError('required') &&  signup.get('lName')?.touched  ">
                            Please enter last name.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('lName')?.hasError('minlength') &&  signup.get('lName')?.touched  ">
                            Please enter minimum 2 character.</div>
                    </div>


                </div>

            </div>

            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Email<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" id="asd" formControlName="email" placeholder="  Enter your email"
                            maxlength="60" (keypress)="preventSpace($event)" class="form-control p-email" />


                        <span class="eyeicon">
                            <img src="assets/latest/email.svg" alt="">
                        </span>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('email')?.hasError('required') && signup.get('email')?.touched ">
                            Email is required.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('email')?.hasError('pattern') && signup.get('email')?.touched ">
                            Please
                            enter a valid email</div>
                    </div>
                </div>
                <div class="w-50">
                    <label for="username">Phone Number (optional )</label>
                    <div class="input-control d-flex">


                        <select id="browsers" class="form-select tel p-0" formControlName="countryCode">

                            <option *ngFor="let code of products" value="{{code?.phonecode}}">{{code?.flag}}
                                {{code?.phonecode}}
                                {{code?.isoCode}} </option>

                        </select>
                        <input type="text" class="form-control tel" (keypress)="preventSpace($event)"
                            (keypress)="NumOnly($event)" placeholder="Enter Phone Number" formControlName="Phone"
                            maxlength="15">
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('Phone')?.hasError('required') && signup.get('Phone')?.touched ">
                            Phone number is required.</div>
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('Phone')?.hasError('pattern') && signup.get('Phone')?.touched ">
                            Please enter valid Phone number</div>
                    </div>



                </div>

            </div>

            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Mobile Number<span class="redis">*</span></label>
                    <div class="input-control d-flex">


                        <select id="browsers" class="form-select tel p-0" formControlName="countryCode">

                            <option *ngFor="let code of products" value="{{code?.phonecode}}">{{code?.flag}}
                                {{code?.phonecode}}
                                {{code?.isoCode}} </option>

                        </select>
                        <input type="text" class="form-control tel" (keypress)="preventSpace($event)"
                            (keypress)="NumOnly($event)" formControlName="mobileNumber" maxlength="15">

                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('required') && signup.get('mobileNumber')?.touched ">
                            Mobile number is required.</div>
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('pattern') && signup.get('mobileNumber')?.touched ">
                            Please enter valid Mobile number</div>


                        <div class="for-validation text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('minlength') && signup.get('mobileNumber')?.touched ">
                            Please enter valid number between 8-15 digit.</div>

                        <div class="for-validation text-danger"
                            *ngIf="signup.get('mobileNumber')?.hasError('maxlength') && signup.get('mobileNumber')?.touched">
                            Please enter valid number between 8-15 digit.</div>
                    </div>



                </div>
                <div class="w-50">
                    <label for="username">Address Line 1<span class="redis">*</span></label>
                    <div class="input-control">

                        <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                            (onAddressChange)="handleAddressChange($event)" id="asd" placeholder="Enter Address 1"
                            formControlName="address" (keypress)="preventSpace($event)"
                            class="form-control form-control-small" />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('address')?.hasError('required') && signup.get('address')?.touched ">
                            Please enter valid address line 1.</div>
                    </div>



                </div>

            </div>


            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Address Line 2</label>
                    <div class="input-control">

                        <input type="text" id="asd" formControlName="locatione" placeholder=" Enter Address Line 2"
                            (keypress)="preventSpace($event)" class="form-control" />

                    </div>


                </div>
                <div class="w-50">
                    <label for="username">Country<span class="redis">*</span></label>
                    <div class="input-control">

                        <select class="form-select" id="asd3" formControlName="Country" (change)="getStateList($event)">

                            <option value="" selected style="text-align: start;">Enter Your Country</option>
                            <option *ngFor="let item of country" [ngValue]="item">{{item?.name}}</option>
                        </select>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('Country')?.hasError('required') && signup.get('Country')?.touched">
                            Please select country.</div>
                    </div>



                </div>

            </div>

            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">State<span class="redis">*</span></label>
                    <div class="input-control">

                        <select class="form-select" id="asd3" formControlName="state" (change)="getCityList($event)">
                            <option value="" selected>Enter Your State</option>
                            <option *ngFor="let item of state" [ngValue]="item">{{item?.name}} </option>
                        </select>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('state')?.hasError('required') && signup.get('state')?.touched">
                            Please select state.</div>
                    </div>


                </div>
                <div class="w-50">
                    <label for="username">City<span class="redis">*</span></label>
                    <div class="input-control">

                        <select class="form-select" id="asd3" formControlName="city">
                            <option value="" selected>Enter Your City</option>
                            <option *ngFor="let item of city">{{item?.name}}</option>
                        </select>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                        *ngIf="signup.get('city')?.hasError('required') && signup.get('city')?.touched">
                        Please select city.</div>
                    </div>



                </div>

            </div>


            <div class="d-flex justify-content-between">

                <div class="w-48">
                    <label for="username">Zip Code<span class="redis">*</span></label>
                    <div class="input-control">
                        <input type="text" id="asd" formControlName="zipCode" placeholder=" Enter Zip Code"
                            (keypress)="preventSpace($event)"  class="form-control"
                            maxlength="10" />
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation  text-danger"
                            *ngIf="signup.get('zipCode')?.hasError('required') && signup.get('zipCode')?.touched">
                            Zip code field can not be empty.</div>
                    </div>



                </div>

            </div>


            <div class="d-flex justify-content-between">
                <div class="w-48">
                    <label for="username">Password<span class="redis">*</span></label>
                    <div class="input-control">
                        <input [type]="changetype?'password':'text'" id="asd" class="form-control"
                            placeholder=" Enter Password" (keypress)="preventSpace($event)" formControlName="Password"
                            maxlength="60" onselectstart="return false;" onpaste="return false;" oncopy="return false;"
                            oncut="return false" ondrag="return false" ondrop="return false" autocomplete="off" />

                        <span class="eyeicon" (click)="viewpass()">
                            <!-- <img src="assets/latest/pass.svg" alt=""> -->
                            <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetype">
                            <img src="assets/latest/eye1.svg" alt="" *ngIf="changetype">
                        </span>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('Password')?.hasError('required') && signup.get('Password')?.touched">
                            Please enter valid password.</div>


                    </div>
                </div>
                <div class="w-50">
                    <label for="username">Confirm Password<span class="redis">*</span></label>
                    <div class="input-control">

                        <input [type]="changetypes?'password':'text'" id="asd" class="form-control"
                            placeholder=" Enter Confirm Password" (keypress)="preventSpace($event)"
                            formControlName="confirmPassword" maxlength="60" onselectstart="return false;"
                            onpaste="return false;" oncopy="return false;" oncut="return false" ondrag="return false"
                            ondrop="return false" autocomplete="off" />

                        <span class="eyeicon" (click)="viewpas()">
                            <!-- <img src="assets/latest/pass.svg" alt=""> -->
                            <img src="assets/latest/eye2.svg" alt="" *ngIf="!changetypes">
                            <img src="assets/latest/eye1.svg" alt="" *ngIf="changetypes">
                        </span>
                    </div>
                    <div class="for-validation mb-4">
                        <div class="for-validation text-danger"
                            *ngIf="signup.get('confirmPassword')?.hasError('required') && signup.get('confirmPassword')?.touched">
                            Please enter valid confirm password.</div>
                        <div class="for-validation text-danger"
                            *ngIf="(signup.get('Password')?.value != signup.get('confirmPassword')?.value) &&  signup.get('confirmPassword')?.touched">
                            Password and Confirm Password must be match.</div>

                    </div>



                </div>

            </div>
            <div class="d-flex justify-content-between">
                <div class="w-100">


                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="term" value="term" formControlName="term">
                        <label class="form-check-label" style="margin-top: 2px;" for="exampleCheck1">I agree to <a
                                routerLink="/terms-and-condition" target="_blank">Terms & Conditions</a></label>
                    </div>



                </div>

            </div>


            <div class="d-flex justify-content-between" style="align-items: center;">


                <div class="w-48">
                    <button class="for-login btn text-white btn-block btn-primary" *ngIf="!loading"  (click)="load();signUp()"
                    type="submit">Register Now</button>
                  <button class="for-login btn text-white btn-block btn-primary op05"  *ngIf="loading"
                    type="submit">Loading...</button>
                </div>
                <div class="w-50">
                    
                    <span class="d-block text-right my-4 text-muted" [routerLink]="['/login']">Already Have Account?
                        <span class="cols">Login</span></span>


                </div>

               

              </div>
        </div>
    </form>

</auth-layout>
