import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';

declare var $
@Component({
  selector: 'app-customer-service-available-add',
  templateUrl: './customer-service-available-add.component.html',
  styleUrls: ['./customer-service-available-add.component.scss']
})
export class CustomerServiceAvailableAddComponent implements OnInit {
  _id: any
  isLoggedIn: any
  loggedInUserType: any
  catID: any
  UsID: any
  isLoaded: boolean = false
  categoryName: any
  headingName: any = 'BOOKING PROVIDERS'
  linkerRouteItems: any = [
    { name: 'Home', route: '/home' },
    { name: 'Booking', route: '/customer-service-list' },
    { name: 'Booking Providers', route: '/customer-service-available' }
  ]
  panelOpenState = false; //accordian open close question's and answer's

  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private router: Router, public authService: AuthService) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this.catID = res.categoryId;
      this.UsID = res.userId;
      this.categoryName = res.categoryName
    })
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe((res: any) => {


      this.isLoggedIn = res
    })
    this.isLoggedIn = this.authService.isLogin()
    this.authService.loggedInUserType.subscribe((res: any) => {

      this.loggedInUserType = res
    })
    // this.getProduct();
    this.viewCategoryAvailableService()
  }



  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  getProduct() {
    if (this.isLoggedIn) {
      this.viewRetailerProductd()
    } else {
      this.viewRetailerProductdWithoutLogin()
    }
  }
  firstPriceSize: any = [];
  productDetailsSize: any = []
  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result;
        this.productDetailsSize = res.result.priceSizeDetails

        this.firstPriceSize = res.result.priceSizeDetails[0]
        this.price(this.firstPriceSize.price, this.firstPriceSize.quantity, this.firstPriceSize.value, this.firstPriceSize.id, this.firstPriceSize.unit)
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }
  viewRetailerProductdWithoutLogin() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {


        this.productDetails = res.result;
        this.imgurl = res.result.productImage[0]
        this.imageLinks = res["result"]["thumbnail"]
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }



  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    this.imgurl = url
  }


  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  mrp: any
  quantity: any
  value: any
  unit: any
  pdId: any
  price(e: any, q: any, v: any, id, u) {


    this.mrp = e,
      this.quantity = q
    this.value = v
    this.unit = u
    this.pdId = id
  }
  imgSrc: any
  productImage: any
  serviceData: any = []

  addWishlist(id) {
    let apiReqUrl = `user/addWishListProduct?productId=${id}`
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.commonService.hideSpinner();
        this.viewRetailerProductd()
        // this.router.navigate(['/edit-product'])
        this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  UserDetailsData: any
  serviceDetailsArray: any = []
  viewCategoryAvailableService() {
    let apiReqUrl = `v2/service/listServiceNearMe`
    let apiReqData = {
      userId: this.UsID,
      categoryId: this.catID
    }
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqData, 0).subscribe((res: any) => {
      if (res.responseCode == 200) {

        this.commonService.hideSpinner();
        this.UserDetailsData = res.result.userDeails
        this.serviceDetailsArray = res.result.docs
        this.serviceDetailsArray.forEach((item, index) => {
          item['_id'] = index
        });
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
        if (res.responseCode == 401) {
          this.router.navigate[('/login')]
        }
      }
    }, (err) => {
      this.commonService.hideSpinner()
      if (err.responseCode == 401) {
        this.router.navigate[('/login')]
      }
    })
  }
  navigateToserviceAvailableAdd() {
    if (!this.selectedService.length) {
      return this.commonService.errorToast("Please select any service")
    }
    sessionStorage.setItem('selectedService', JSON.stringify(this.selectedService))
    this.router.navigate(['/customer-service-quantity-add'], { queryParams: { categoryId: this.catID, userId: this.UsID, categoryName: this.categoryName } })
  }
  selectedService = []

  showMessage() {
    if (!this.selectedService.length) {
      return this.commonService.errorToast("Please select any service")
    }
    sessionStorage.setItem('selectedService', JSON.stringify(this.selectedService))
    let buffer = localStorage.getItem('buffer')
    if (!buffer) {
      let cartJson = {

        lastLocation: `/customer-service-quantity-add`,
        params : {
          categoryId : this.catID ,
          userId :this.UsID,
          categoryName :this.categoryName
        }
      }


      localStorage.setItem('buffer', JSON.stringify(cartJson))

    }
    else {
      let parseBuffer = JSON.parse(buffer)
      parseBuffer.lastLocation = `/customer-service-quantity-add`
      parseBuffer.params = {
        categoryId : this.catID ,
        userId :this.UsID,
        categoryName :this.categoryName
      }
      localStorage.setItem('buffer', JSON.stringify(parseBuffer))
    }
    this.router.navigate(['/login'])
    this.commonService.errorToast('Please Login')
    return
  }
  getSelectedService(e, obj, data, index) {
    // let innerObj = this.selectedService.find(e => { return e.id == obj.id })



    let tempObj = { ...obj }

    let tempData = data
    if (e.target.checked) {
      tempData['isPushed'] = true
      let arr = tempObj.serviceArray.filter((res) => {
        return res.isPushed
      })
      tempObj['serviceArray'] = arr
      let tempIdIndex = this.selectedService.findIndex((x) => { return x._id == tempObj._id })
      if (tempIdIndex >= 0) {
        this.selectedService[tempIdIndex] = tempObj
      } else {

        this.selectedService.push(tempObj);
      }
    } else {
      tempData['isPushed'] = false

      let arr = tempObj.serviceArray.filter((res) => {
        return res.isPushed
      })

      if (arr.length) {

        tempObj['serviceArray'] = arr
        let tempIdIndex = this.selectedService.findIndex((x) => { return x._id == tempObj._id })

        if (tempIdIndex >= 0) {
          this.selectedService[tempIdIndex] = tempObj
        } else {

          this.selectedService.push(tempObj);
        }
        // this.selectedService.push(tempObj);
      }

      else {
        let ind = this.selectedService.find(e => { return e._id == tempObj._id })
        this.selectedService.splice(ind, 1);

      }


    }


  }
  navigateToback() {
    this.router.navigate(['/customer-service-available'], { queryParams: { _id: this.catID } })
  }
  onLoadImage() {
    this.isLoaded = true
  }
}
