import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provide/common.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-edit-customer-address',
  templateUrl: './edit-customer-address.component.html',
  styleUrls: ['./edit-customer-address.component.scss']
})
export class EditCustomerAddressComponent implements OnInit {

  addProduct!: FormGroup
  profileData: any = {}
  imageUrl: any;
  products: any;
  countryCode = new FormControl('+91');
  country: any = []
  state: any = []
  city: any = []
  isoCode: any
  govtDocument: any = "assets/lard.png";
  websiteUrl: any;
  socialLink: any;
  userType: any;
  firstName: any;
  lastName: any;
  // countryCode: any;
  mobileNumber: any;
  email: any;
  storeName: any;
  storeLocation: any
  faceBook: any;
  linkedIn: any;
  twitter: any;
  instagram: any;
  updateProfile: any;
  profilePic: any;

  lat: any;
  lng: any;
  // uplode image 

  addDynamicImageArray: any = []
  validationMessage: any; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  cityList: any;
  stateList: any;
  countryList: any;

  id: any
  qtyValue: any
  userId: any
  categoryId: any
  categoryName: any
  addType : string
  constructor(private fb: FormBuilder, private http: HttpClient, public commonService: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.id = res.id
      this.qtyValue = res.qnty
      this.categoryId = res.categoryId
      this.userId = res.userId
      this.categoryName = res.categoryName
      this.addType = res.q
    })
  }
  datas: any = []
  LatLongValue: any
  headingName:any ='EDIT ADDRESS'
  typeFrom:any ='Edit'
  linkerRouteItems:any
  ngOnInit() {
    this.add();
    // this.getCountryList();
    if(this.id){
      this.getProfile();
    }else{
      this.headingName='Add Address'
      this.typeFrom = 'Add'
    }
    this.http.get("assets/countrycode.json").subscribe((data: any) => {
      this.products = data;
      this.datas = data.result
    })
    this.getCountryList()
    navigator.geolocation.getCurrentPosition(function (position) {
    }, function () {
      alert('You need to enable geolocation services in your browser to use this feature.');
    });
    this.getPosition().then(pos => {
      localStorage.setItem('location', JSON.stringify(pos))
    });
  this.linkerRouteItems = [
    { name:'Home',route: '/home'},
    { name:'Account',route: '/customer-profile'},
    { name:'Address',route: '/customer-address-list'},
    {name: this.id ? 'Edit' : 'Add' ,route:'/edit-address-customer'}
  ]
  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

  Position: any
  getLocation() {
   
  }
 
  address: any
  options: any = []
  longitude: any;
  latitude: any;
  is_addressInValid: boolean = true
  public handleAddressChange(address: any) {
    if ('formatted_address' in address) {
      this.addProduct.patchValue({
        address1: address.formatted_address
      })
      this.options = []
      this.is_addressInValid = false

      this.address = address.formatted_address

      this.longitude = address.geometry.location.lng()
      this.latitude = address.geometry.location.lat()
      this.options[0] = Number(address.geometry.location.lng())
      this.options[1] = Number(address.geometry.location.lat())
    }
    else {
      this.is_addressInValid = true

    }



  }
  // form validation

  add() {
    this.addProduct = this.fb.group({
      'fName': new FormControl('', [
        Validators.required,
        Validators.maxLength(60),
        Validators.minLength(2),
        Validators.pattern('^[A-Za-z ]+$')
      ]),
      'lName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern('^[A-Za-z ]+$')
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
      'address': new FormControl('', [
        Validators.maxLength(250),
      ]),
      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[^0][0-9]*$/),
        Validators.minLength(8),
        Validators.maxLength(15)
      ]),
      'address1': new FormControl('', Validators.required),
      'address2': new FormControl(''),
      'location': new FormControl('', [
      ]),
      countryCode: new FormControl('+27', [
        Validators.required
      ]),
      'url': new FormControl('', [
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
      ]),
      'country': new FormControl('', [
        Validators.required
      ]),
      'state': new FormControl('', [
        Validators.required
      ]),
      'city': new FormControl('', [
        Validators.required
      ]),
      'zipCode': new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]+$')
      ]),
    });
  }

  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }
  valueLatLong: any

  getProfile() {
    let url = `user/viewAddress?addressId=${this.id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.profileData = res["result"];
        this.profileData.country ? this.getStateList(this.profileData.countryIsoCode) : '';
        this.profileData.state ? this.getCityList(this.profileData.stateIsoCode) : '';
        this.addProduct.patchValue({
          fName: this.profileData.firstName,
          lName: this.profileData.lastName,
          email: this.profileData.email,
          mobileNumber: this.profileData.mobileNumber,
          // address: this.profileData.addressLine1 || this.profileData.address,
          address1: this.profileData.addressLine1,
          address2: this.profileData.addressLine2,
          url: this.profileData.websiteUrl,
          zipCode: this.profileData.zipCode,
          countryCode :this.profileData.countryCode
          ,
          // facebook: this.profileData?.socialLink?.faceBook,
          // linkedin: this.profileData?.socialLink?.linkedIn,
          // twitter: this.profileData?.socialLink?.twitter,
          // instagram: this.profileData?.socialLink?.instagram,


          city: this.profileData.city,
          state: this.profileData.stateIsoCode,
          // zipCode: this.profileData.zipCode,
          country: this.profileData.countryIsoCode
          ,


          // storeLocation: this.profileData.storeLocation,
          // storeLocation: this.profileData.storeLocation.coordinates,

        });
        this.latitude = res.result.location.coordinates[0]
        this.longitude = res.result.location.coordinates[1]
        // this.profileData.country ? this.patchState(this.profileData.country) : '';
        this.imageUrl = res['result']['profilePic']
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }

  // patch state and city


  patchState(country: any) {
    let apiReqUrl = `${'user/listState?countryCode=' + country}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.stateList = res.result
      this.addProduct.patchValue({
        'state': this.profileData.state
      })
    })
  }
  patchCity(state: any) {
    let apiReqUrl = `${'user/listCity?countryCode=' + this.profileData.country + '&stateCode=' + state}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.cityList = res.result
      this.addProduct.patchValue({
        'city': this.profileData.city
      })
    })
  }

  getCountryList() {
    this.commonService.getApi('user/listCountry', 1).subscribe((res: any) => {
      
      // this.toastr.success('Hello world!', 'Toastr fun!');
      this.countryList = res.result

    })
  }
  countryIsoCode: any;
  getStateList(event: any) {
    this.addProduct.patchValue(({
      state : '',
      city : ''
    }))
    if(event){
      this.countryIsoCode = typeof event === 'string' ? event : event.target.value
    }
    let apiReqUrl = `${'user/listState?countryCode=' + this.countryIsoCode}`
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      
      this.stateList = res.result
    })
  }

  getCityList(event: any) {
    this.addProduct.patchValue(({
      city : ''
    }))
    let stateIsoCode = typeof event == 'string' ? event : event.target.value
    let apiReqUrl = `${'user/listCity?countryCode=' + this.countryIsoCode + '&stateCode=' + stateIsoCode}`
    this.commonService.getApi(apiReqUrl, 0).subscribe((res: any) => {
      
      this.cityList = res.result
    })
  }



  getCountryName(iso: string) {
    let temp = this.countryList.filter((res: any) => {
      return res.isoCode == iso
    })
    return temp[0].name
  }

  getStateName(iso: string) {
    let temp = this.stateList.filter((res: any) => {
      return res.isoCode == iso
    })
    return temp[0].name
  }
  // update profile

  addId: any
  editProfileDetail() {
    if(!this.addProduct.valid){
       return
    }
    let url = "user/editAddress"
    let data = {
      "addressId": this.id,
      "addressLine1": this.addProduct.value.address1,
      "addressLine2": this.addProduct.value.address2,
      "address": this.addProduct.value.address1 + "," + this.addProduct.value.address2,
      "zipCode": this.addProduct.value.zipCode,
      "state": this.getStateName(this.addProduct.value.state),
      "city": this.addProduct.value.city,
      "country": this.getCountryName(this.addProduct.value.country),
      "firstName": this.addProduct.value.fName,
      "lastName": this.addProduct.value.lName,
      "mobileNumber": this.addProduct.value.mobileNumber,
      "countryCode": this.addProduct.value.countryCode,
      "email": this.addProduct.value.email,
      stateIsoCode: this.addProduct.value.state,
      countryIsoCode: this.addProduct.value.country,
      "location": {
        "type": "Point",
        "coordinates": [
          this.longitude,
          this.latitude
        ]
      }
    }
    this.commonService.showSpinner();
    this.commonService.putApi(url, data, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
        this.addId = res['result']['_id'];
        localStorage.setItem('primaryAddressId', this.addId)
        this.commonService.backLocation()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }


  addAddress() {
      if(!this.addProduct.valid){
       return
    }
    let url = `user/addAddress`
    let apiReqData = {
      "addressLine1": this.addProduct.value.address1,
      "addressLine2": this.addProduct.value.address2,
      "address": this.addProduct.value.address1 + "," + this.addProduct.value.address2,
      "zipCode": this.addProduct.value.zipCode,
      "state": this.getStateName(this.addProduct.value.state),
      "city": this.addProduct.value.city,
      "country": this.getCountryName(this.addProduct.value.country),
      "firstName": this.addProduct.value.fName,
      "lastName": this.addProduct.value.lName,
      "mobileNumber": this.addProduct.value.mobileNumber,
      "countryCode": this.addProduct.value.countryCode,
      "email": this.addProduct.value.email,
      stateIsoCode: this.addProduct.value.state,
      countryIsoCode: this.addProduct.value.country,
      "location": {
        "type": "Point",
        "coordinates": [
          this.longitude,
          this.latitude
        ]
      }
    }
    // let apiReqData = {
    //   "addressLine1": this.addProduct.value.address1,
    //   "addressLine2": this.addProduct.value.address2,
    //   "address": this.addProduct.value.address1 + "," + this.addProduct.value.address2,
    //   "zipCode": this.addProduct.value.zipCode,
    //   "state": this.getStateName(this.addProduct.value.state),
    //   "city": this.addProduct.value.city,
    //   "country": this.getCountryName(this.addProduct.value.country),
    //   "firstName": this.addProduct.value.fName,
    //   "lastName": this.addProduct.value.lName,
    //   "mobileNumber": this.addProduct.value.mobileNumber,
    //   "countryCode": this.addProduct.value.countryCode,
    //   "email": this.addProduct.value.email,
    //   stateIsoCode: this.addProduct.value.state,
    //   countryIsoCode: this.addProduct.value.country,
    //   "location": {
    //     "type": "Point",
    //     "coordinates": [
    //       this.longitude,
    //       this.latitude
    //     ]
    //   }
    // }
    this.commonService.postApi(url, apiReqData, 1).subscribe((res) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res["responseMessage"]);
        this.addId = res['result']['_id'];
        localStorage.setItem('primaryAddressId', this.addId)
        if (this.qtyValue == 'quantity') {
          this.router.navigate(['/customer-service-quantity-add'], { queryParams: { categoryId: this.categoryId, userId: this.userId, categoryName: this.categoryName } })
        }
        else {
          if(this.addType){
            this.router.navigate(['/list-address-customer'])
          }
          else{
            this.router.navigate(['/customer-address-list'])
          }
        }
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"]);
      }
    })
  }
  uploadImg($event: any): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 0).subscribe((res: any) => {
      
      this.commonService.hideSpinner();
      if (res.responseCode == '200') {
        this.imageUrl = res.result.mediaUrl;
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }


 


}
