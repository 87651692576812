<div class="container">
  <app-linker-route
    [headingName]="headingName"
    [linkerRouteItems]="linkerRouteItems"
  ></app-linker-route>
</div>

<div class="container mt-2 mb-5">
  <div class="">
    <div class="row rowpaddingBox mt-3 mb-5">
      <div class="col-lg-5 col-md-5 col-sm-5 rowpaddingBox">
        <span>
          <img
            class="image-custom qwqwqw"
            [hidden]="!isLoaded"
            (load)="onImageLoad()"
            [src]="selectedService?.serviceId?.categoryId?.categoryImage"
            alt=""
          />

          <ngx-skeleton-loader
            *ngIf="!isLoaded"
            [theme]="{
              width: '456px',
              height: '405px',
              position: 'relative'
            }"
            count="1"
          ></ngx-skeleton-loader>
        </span>
      </div>
      <app-table-loader *ngIf="!isLoading"></app-table-loader>
      <div *ngIf="isLoading" class="col-lg-7 col-md-7 col-sm-7 rowpaddingBox">
        <div class="row">
          <div class="col-12 pl-0">
            <p class="prdct-home">
              {{ selectedService?.userId?.firstName }}
              {{ selectedService?.userId?.lastName }}
            </p>
            <p>Details of Selected Service</p>
          </div>
        </div>
        <div class="" style="margin-top: 1em">
          <h3>{{ categoryName }}</h3>
          <!-- <div id="accordion" *ngFor="let item of commonService.removeDuplicate(selectedService,['_id']);let i = index"> -->
          <div id="accordion">
            <mat-accordion>
              <mat-expansion-panel
                [expanded]="step === 0"
                (opened)="setStep(0)"
                hideToggle
              >
                <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" > -->
                <mat-expansion-panel-header class="faq-qsn">
                  <!-- <mat-panel-title class="gg" style="color:#bf1e2e ;"> -->
                  <mat-panel-title class="gg" style="color: #bf1e2e">
                    {{
                      selectedService?.serviceSubCategoryId?.subCategoryName ||
                        "---"
                    }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <p innerHtml="{{item?.answer || '---'}}"></p> -->
                <div
                  class="d-flex justify-content-between"
                  style="text-align: center; align-items: center"
                >
                  <div class="" style="padding: 0px !important; width: auto">
                    <!-- <input type="checkbox" class="custom-control-input" [attr.id]="'customControlInline'+i"> -->
                    <p class="">
                      {{ selectedService?.serviceId?.serviceName }}
                    </p>
                  </div>
                  <div class="d-flex mb-0" style="min-width: 115px">
                    <button
                      class="btn qty-less-high px-3 me-2"
                      [disabled]="selectedService.quantity <= 1"
                      (click)="
                        updateCartValue('SUB');
                        selectedService.quantity = selectedService.quantity - 1
                      "
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                    >
                      <em class="fa fa-minus" style="margin-left: -5px"></em>
                    </button>

                    <div class="form-outline qty">
                      <p class="qty m-0">{{ selectedService.quantity }}</p>
                      <!-- <label class="form-label" for="form1">Quantity</label> -->
                    </div>

                    <button
                      class="btn qty-less-high px-3 ms-2"
                      (click)="
                        updateCartValue('ADD');
                        selectedService.quantity = selectedService.quantity + 1
                      "
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                    >
                      <em class="fa fa-plus" style="color: #6e7174"></em>
                    </button>
                  </div>
                  <div>
                    <!-- <p class="mb-0 !important ner">{{data?.price && getPrice(data?.price * data.quantity  | currency:"ZAR":"R")}}/unit</p> -->
                    <p class="mb-0 !important ner" *ngIf="!isDiscount">
                      {{
                        selectedService?.dealPrice &&
                          getPrice(
                            selectedService?.dealPrice | currency : "ZAR" : "R"
                          )
                      }}
                    </p>
                    <span *ngIf="isDiscount">
                      <p style="text-decoration: line-through;">
                        {{
                          selectedService?.serviceId.price &&
                            getPrice(
                              selectedService?.serviceId.price | currency : "ZAR" : "R"
                            )
                        }}
                      </p>

                      <p class="mb-0 ner" *ngIf="isDiscount">
                        
                        {{
                          selectedService?.dealPrice &&
                            getPrice(
                              selectedService?.dealPrice | currency : "ZAR" : "R"
                            )
                        }}

                        ({{selectedService.dealDiscount}}% Off )
                      </p>
                    </span>
                    
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div class="mt-4">
          <div
            class="row justify-content-between mt-4 text-center"
            style="align-items: center"
          >
            <h1 class="amount-details-text">Amount Details</h1>
          </div>
          <div
            class="row justify-content-between text-center"
            style="align-items: center"
          >
            <div class="totalamout">
              <span class="amnt-total-details">Sub-total</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                priceValueOfService &&
                  getPrice(priceValueOfService | currency : "ZAR" : "R")
              }}</span>
            </div>
          </div>
          <div
            class="row justify-content-between mt-1 text-center"
            style="align-items: center"
          >
            <div class="totalamout">
              <span class="amnt-total-details">VAT @15%</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">
                {{
                  sumToatal && getPrice(sumToatal | currency : "ZAR" : "R")
                }}</span
              >
            </div>
          </div>
          <div
            class="row justify-content-between mt-1 text-center"
            style="align-items: center"
          >
            <div class="totalamout">
              <span class="amnt-total-details">Total to pay</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                totalPayAmount &&
                  getPrice(totalPayAmount | currency : "ZAR" : "R")
              }}</span>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="row">
            <p>Share your details for the delivery of the service</p>
            <div class="container card card-data" *ngIf="addressId">
              <div class="row">
                <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
                  <p style="margin: 1px; margin-top: 5px">
                    <strong class="text-sty"
                      >{{ profileData?.address }},
                    </strong>
                  </p>
                  <p style="margin: 1px; margin-top: 5px">
                    <strong class="text-sty"
                      >{{ profileData?.city }}, {{ profileData?.state }},{{
                        profileData?.country
                      }}, {{ profileData?.zipCode }}</strong
                    >
                  </p>
                  <div class="mt-3">
                    <span
                      ><em
                        class="fa fa-phone"
                        aria-hidden="true"
                        style="color: #c01e2f"
                      ></em>
                    </span>
                    &nbsp;&nbsp;
                    <span class="phoen"
                      >{{ profileData?.countryCode }}
                      {{ profileData?.mobileNumber }}</span
                    >
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 mb-4 mb-lg-0">
                  <div class="d-flex trash" style="max-width: 300px">
                    <em
                      class="fa fa-edit ml-2"
                      style="font-size: 22px; cursor: pointer"
                      (click)="navigateToEdit('quantity')"
                    ></em>
                    <!-- <i class="fa fa-trash ml-2" (click)="addressModals(item?._id)" style="font-size: 21px;color: #BF1E2E;"></i> -->
                    <em
                      class="fa fa-list ml-3"
                      style="font-size: 22px; cursor: pointer"
                      (click)="openListAddress()"
                      aria-hidden="true"
                    ></em>
                  </div>
                  <!-- <div class="form-check d-flex trash" style="max-width: 300px;margin-top: 20px;cursor: pointer;">
                                        <input class="form-check-input" type="radio" name="flexRadioDisabled"
                                            id="flexRadioDisabled">
                                    </div> -->
                </div>
              </div>
            </div>
            <div
              class="row justify-content-between"
              style="padding: 15px; margin-left: -36px"
            >
              <div class="col-md-12 ml-2">
                <button
                  class="add-cart-btn"
                  (click)="navigateToserviceQuantity('quantity')"
                >
                  Add new address
                </button>
              </div>
              <!-- <div>
                                <button class="continue-btn" (click)="navigateToOrderOverview(a)">Continue with this address</button>
                            </div> -->
            </div>
          </div>
          <div class="" style="padding: 10px 0px">
            <div class="">
              <div
                id="date-picker-example"
                class="md-form md-outline input-with-post-icon datepicker"
                inline="true"
              >
                <input
                  placeholder="Select date"
                  type="datetime-local"
                  id="example"
                  [min]="todayDate"
                  (input)="getQuantity($event.target.value)"
                  [(ngModel)]="duration"
                  class="form-control change-clr heit"
                />
              </div>
            </div>
            <div class="mt-2">
              <select
                class="form-control change-clr heit"
                [(ngModel)]="timeSlots"
              >
                <option value="" selected disabled>Select Slot</option>
                <option *ngFor="let item of slotData" [value]="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="right-totalamout">
            <!-- routerLink="/customer-service-quantity-add" -->
            <span
              ><button class="butCh" (click)="bookService()">
                Proceed to Book
              </button></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container mt-5">
  <div class="d-flex justify-content-start">
      <h1 class="heading-text-cat">
        Similiar Products
      </h1>
  </div>
  <div class="items mt-4">
      <div class="slick_slider">
          <div class="service_slider slider slider-customize">
              <div *ngFor="let data of serviceData; let i = index">
                  <div style="height: 340px;">
                      <div class="bannerContent">
                          <div class="innerDiv">
                              <div>
                                  <a target="_blank" rel="noopener noreferrer">
                                      <img class="bannerSlideIamge" [src]="data?.thumbnail" /></a>
                              </div>
                              <div class="mt-3">
                                  <p style="color: black;">{{data?.productName}}</p>
                              </div>
                              <div class="mt-3">
                                  <p class="description-text">{{data?.priceSizeDetails[0]?.price && getPrice(data?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</p>
                              </div>
                              <div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->
<div
  class="modal fade"
  id="listAddressModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content ggg" style="min-width: 600px">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="d-flex justify-content-center mt-5 mb-4" id="book">
                <img class="for-img1" style="height: 100%;" src="assets/images/book.png" alt="login form"
                    class="img-fluid" />
            </div> -->
      <!-- <h2 class="modal-title d-flex justify-content-center">Are you sure you want to logout?</h2> -->
      <div class="right-side">
        <h1 class="container right-side-text">Address</h1>
        <div
          class="container mt-4 card card-data"
          *ngFor="let item of listAddressData"
        >
          <div class="row" (click)="toAddressId(item?._id)">
            <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
              <p style="margin: 1px; margin-top: 5px">
                <strong class="text-sty">
                  {{ item?.addressLine1 }}
                  <span *ngIf="item?.addressLine2">,</span>
                  {{ item?.addressLine2 }}
                </strong>
              </p>
              <p style="margin: 1px; margin-top: 5px">
                <strong class="text-sty"
                  >{{ item?.city }}, {{ item?.state }},{{ item?.country }},
                  {{ item?.zipCode }}</strong
                >
              </p>
              <div class="mt-3">
                <span
                  ><em
                    class="fa fa-phone"
                    aria-hidden="true"
                    style="color: #c01e2f"
                  ></em>
                </span>
                &nbsp;&nbsp;
                <span class="phoen"
                  >{{ item?.countryCode }} {{ item?.mobileNumber }}</span
                >
              </div>
            </div>

            <div class="col-lg-4 col-md-4 mb-4 mb-lg-0">
              <div class="d-flex trash" style="max-width: 300px">
                <em
                  class="fa fa-edit ml-2"
                  style="font-size: 22px"
                  (click)="navigateToEdit('quantity')"
                ></em>
                <em
                  class="fa fa-trash ml-2"
                  (click)="addressModals(item?._id, item?.isPrimary)"
                  style="font-size: 21px; color: #bf1e2e"
                ></em>
              </div>
              <!-- <div class="form-check d-flex trash" style="max-width: 300px;margin-top: 20px;cursor: pointer;">
                                <input class="form-check-input" style="cursor: pointer;" type="radio" name="flexRadioDisabled" (click)="toAddressId(item?._id)" 
                                    id="flexRadioDisabled">
                            </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="row justify-content-between" style="padding: 10px;">
                    <div>
                        <button class="add-cart-btn" routerLink="/edit-address-customer">Add new address</button>
                    </div>
                    <div>
                        <button class="continue-btn" (click)="navigateToOrderOverview()">Continue with this address</button>
                    </div>
                </div> -->
      </div>

      <!-- <div class="modal-body d-flex justify-content-center mb-4">
                <button type="button" class="btn btntt1" data-dismiss="modal" routerLink="/homepage">No</button>
                <button type="button" class="btn btntt ml-3" data-dismiss="modal" routerLink="/login"
                    (click)="logout()">Yes</button>
            </div> -->
    </div>
  </div>
</div>
<!-- delete address Modal -->
<div
  class="modal fade"
  id="addressModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-4" id="book">
        <img
          class="for-img1"
          style="height: 100%"
          src="assets/images/book.png"
          alt="login form"
          class="img-fluid"
        />
      </div>
      <h2 class="modal-title d-flex justify-content-center">
        Are you sure you want to delete this address?
      </h2>

      <div class="modal-body d-flex justify-content-center mb-4">
        <button
          type="button"
          class="btn btntt1"
          data-dismiss="modal"
          routerLink="/homepage"
        >
          No
        </button>
        <button
          type="button"
          class="btn btntt ml-3"
          data-dismiss="modal"
          (click)="deleteAddress()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
