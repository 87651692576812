import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/provide/auth.service';
import { CommonService } from 'src/app/provide/common.service';
declare var $
@Component({
  selector: 'app-order-history-view-customer',
  templateUrl: './order-history-view-customer.component.html',
  styleUrls: ['./order-history-view-customer.component.scss']
})
export class OrderHistoryViewCustomerComponent implements OnInit {
  headingName:any ='Order Details'
  linkerRouteItems:any =[
    {name:'Home',route: '/home'},
    {name:'Account',route:'/customer-profile'},
    {name:'Order History',route:'/customer-order-history'},
    {name:'View',route:'/customer-order-history-view'}

  ]

  _id: any
  qtyA: any
  serviceData: any
  idOfAddress: any
  loggedInUserType: any;

  constructor(private activatedroute: ActivatedRoute, public authService: AuthService, public commonService: CommonService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
      // this.idOfAddress =res.idOfAddress //
    })
  }

  ngOnInit(): void {
    this.authService.loggedInUserType.subscribe((res: any) => {
      this.loggedInUserType = res

    })
    this.loggedInUserType = this.authService.checkUserTypeLoggedIn()
    this.viewOpenOrder()
  }

  viewOrderData: any = new Array(1)
  orderDataDetials: any
  viewOpenOrder() {
    let url = `order/v2/viewOrder?orderId=${this._id}`
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.commonService.hideSpinner();
        this.orderDataDetials = res["result"];
        this.viewOrderData = res['result']['productDetails']

      } else {
        this.viewOrderData = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.viewOrderData = []
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }


  getSundayDate(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date(inputDate);

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayOfWeek = currentDate.getDay();

    // Calculate the number of days until the next Sunday
    const daysUntilNextSunday = 7 - currentDayOfWeek;

    // Create a new Date object for the next Sunday
    const nextSunday = new Date(currentDate);
    nextSunday.setDate(currentDate.getDate() + daysUntilNextSunday);

    // Format the date as a string (e.g., "YYYY-MM-DD")
    const nextSundayDate = nextSunday.toISOString().split('T')[0];

    return nextSundayDate;

  }



  getNextSaturdayFromDate(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date(inputDate);
  
    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayOfWeek = currentDate.getDay();
  
    // Calculate the number of days until the next Saturday
    const daysUntilNextSaturday = 6 - currentDayOfWeek + 7;
  
    // Create a new Date object for the next Saturday
    const nextSaturday = new Date(currentDate);
    nextSaturday.setDate(currentDate.getDate() + daysUntilNextSaturday);
  
    // Format the date as a string (e.g., "YYYY-MM-DD")
    const nextSaturdayDate = nextSaturday.toISOString().split('T')[0];
  
    return nextSaturdayDate;
  }

  getNextBusinessDayFromDate(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date(inputDate);
  
    // Calculate the next day of the week
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
  
    // Check if the next day is a weekend (Saturday or Sunday)
    if (nextDay.getDay() === 0) {
      // If it's Sunday, add 2 days to get to the next business day (Monday)
      nextDay.setDate(nextDay.getDate() + 2);
    } else if (nextDay.getDay() === 6) {
      // If it's Saturday, add 1 day to get to the next business day (Monday)
      nextDay.setDate(nextDay.getDate() + 1);
    }
  
    // Format the date as a string (e.g., "YYYY-MM-DD")
    const nextBusinessDay = nextDay.toISOString().split('T')[0];
  
    return nextBusinessDay;
  }

  getNextDayOrSameDay(inputDate) {
    // Create a new Date object from the input date
    const currentDate = new Date(inputDate);
  
    // Get the current hour of the day (0-23)
    const currentHour = currentDate.getHours();
  
    // Check if the current hour is less than 17 (5 PM in 24-hour format)
    if (currentHour < 17) {
      // If it's before 5 PM, return the same day
      return currentDate.toISOString().split('T')[0];
    } else {
      // If it's 5 PM or later, return the next day
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      return nextDay.toISOString().split('T')[0];
    }
  }
  getPrice(e: any) {
    let a = e.replace(/,/g, ' ')
    return a.replace(/R/g, 'R ')
  }
  date: any = Date

  getAddedDate(createdAt: string, days: number) {
    let date = new Date(createdAt).setDate(new Date(createdAt).getDate() + 3);
    return date
  }

  onLoadImage(item){
    return item.isLoaded = true
  }
}
