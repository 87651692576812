<div class="container  fw-b ">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<!-- <div class="container mb-5 wrapper-content">
  <div class="addnewicon"> 
    <img  (click)="navigatetoAdd()" src="assets/latest/plusicon.png"> <span
      (click)="navigatetoAdd()" class="addcolr">Add New Address</span> </div>
  <div class="right-side position-relative" *ngFor="let item of listAddressData">
    <input type="radio" name="add" (change)="getChackBox($event.target.checked, item._id)" class="position-absolute mlp" *ngIf="getEndUrlSegment() == 'list-address-customer'">
    <div class="d-flex ad1">
     
      <div class="left-container">
        <p class="addressName">{{item?.firstName}} {{item?.lastName}}</p>
        <p style="margin: 1px;"><span class="addressName1">Address:</span><br>
          <strong class="text-sty">
            {{item?.addressLine1}} <span *ngIf="item?.addressLine2">,</span>
            {{item?.addressLine2}}

          </strong>
        </p>
        <p style="margin: 1px;"><strong class="text-sty">{{item?.city}},
            {{item?.state}},{{item?.country}}</strong>,
          <strong class="text-sty">{{item?.zipCode}}</strong>
        </p>
        <p style="margin: 1px;">
          <strong class="text-sty"> {{item?.countryCode}} </strong>
          <strong class="text-sty"> {{item?.mobileNumber}} </strong>
        </p>
      </div>
      <div class="right-container">
        <div class="d-flex trash">
          <span><img class="pd20 pointer imagHover" (click)="navigateToEdit(item?._id)" src="assets/latest/edit1.png">
          </span>
          <span *ngIf="!item?.isPrimary"> <img class="pointer imagHover"
              (click)="addressModals(item?._id,item?.isPrimary)" src="assets/latest/delete1.png"></span>
        </div>
        <div class="pr-set">
          <button class="add-primary-add " [disabled]="item?.isPrimary" (click)="setPrimaryAddress(item?._id)">
            {{item?.isPrimary ? 'Your Primary Address' : 'Set Primary Address' }} </button>
          <spna *ngIf="item?.isPrimary" class="seticonfixed">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </spna>
        </div>

      </div>
    </div>
  </div>

  
  <app-table-loader [isTable]="'ADDRESS'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10">
  </app-table-loader>
  <app-table-loader [isTable]="'ADDRESS'" [isLoading]="isLoading" [notFoundData]="notFoundData" [colspan]="10">
  </app-table-loader>
  <div style="text-align: center;" *ngIf="getEndUrlSegment() == 'list-address-customer'">
    <button class="continue-btn"    [ngClass]="{'disableButton': !newAddressId}" [disabled]="!newAddressId" (click)="navigateToOrderOverview()">Continue with this
      address</button>
  </div>




</div> -->

<section id="maincontent" class="page-main container"> <a id="contentarea" tabindex="-1"></a>
  <div class="row">
      <div class="row column main p-0 col-lg-12 col-md-12 col-sm-12 col-xs-12">

          <div class="clr"></div>
          <div class="cart-container col-md-8 p-0 uf_cartleftbox uf_cart_container border">
              <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                  data-attribute="cart-products-toolbar-top"></div> <input type="hidden" id="uf_mmtdetailurl"
                  value="https://udesignapi.utsavfashion.com/api/v1/mmt/get.svc/getdetails"><input type="hidden"
                  id="totalshipamt" value="0">
              <form action="https://www.utsavfashion.com/checkout/cart/updatePost/" method="post" id="form-validate"
                  class="form form-cart" novalidate="novalidate"><input name="form_key" type="hidden"
                      value="iqOHv1Upl97Gkn6q">
                  <div class="cart table-wrapper uf_cartwrapper">
                      <table id="shopping-cart-table" class="cart items data table">
                          <caption role="heading" aria-level="2" class="table-caption">Shopping Cart Items</caption>
                          <thead>
                              <tr>
                                 
                                  <th class="col item" scope="col" colspan="2"><span>Your addresses</span></th>
                               
                              </tr>
                          </thead>
                          <tbody class="cart item uf_bagitem" data-sku="KMDF127_32" data-productid="3240488">
                              <tr class="item-info" *ngFor="let item of listAddressData;let i = index">
                                  <td>
                                      <input type="checkbox" name="" (change)="setCheckbox(i, item._id)" [checked]="checkedBox[i]" id="">
                                  </td>
                                  <td data-th="Item" class="col item">
                                    <div class="postion-relative">
                                      <p class="uname">{{item?.firstName}} {{item?.lastName}}</p>
                                      <p class="address">
                                        {{item?.addressLine1}} <span *ngIf="item?.addressLine2">,</span>
                                        {{item?.addressLine2}},{{item?.city}},
                                        {{item?.state}},{{item?.country}},{{item?.zipCode}}

                                      </p>
                                      <div class="actions">
                                        <a (click)="openAddress(item)" style="min-width: 100px;">Edit Address</a>
                                        <hr class="diff" *ngIf="!item?.isPrimary">
                                        <a (click)="addressModals(item?._id,item?.isPrimary)" style="min-width: 100px;" *ngIf="!item?.isPrimary">Delete Address</a>
                                      </div>
                                      <div *ngIf="item?.isPrimary" class="primary-address position-absolute">
                                        <p>Your Primary address</p>
                                      </div>
                                    </div>
                                  

                                  </td>
                                
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  <div class="addnewicon"> 
                                    <img  (click)="navigatetoAdd()" src="assets/latest/plusicon.png"> <span
                                      (click)="navigatetoAdd()" class="addcolr ml-3" >Add New Address</span> </div>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>

              </form>
              <div class="cart-products-toolbar cart-products-toolbar-top toolbar"
                  data-attribute="cart-products-toolbar-top"></div>
          </div>
          <div id="uf_cartdockable" class="col-md-4 uf_cartdockable">
              <div class="row shopping-cart-bottom w-100 uf_cartrightbox uf_cartdockable_inner">

                  <div class="uf_cartsummary w-100">
                      <div class="summary title cart_summary">Order Summary
                      </div>
                      <div id="cart-totals" class="cart-totals" data-bind="scope:'block-totals'">
                          <!-- ko template: getTemplate() -->
                          <div class="table-wrapper" data-bind="blockLoader: isLoading">
                              <table class="data table totals">

                                  <tbody>

                                      <tr class="totals sub">
                                          <th data-bind="i18n: title" scope="row">Subtotal</th>
                                          <td class="amount">
                                              <span class="price"
                                                  data-bind="text: getValue(), attr: {'data-th': title}"
                                                  data-th="Subtotal">{{payOutDetail && getPrice(payOutDetail.subTotal
                                                  |
                                                  currency:"ZAR":"R")}}</span>
                                          </td>
                                      </tr>

                                      <tr class="totals sub uf_disdetailhead uf_discountcont">
                                          <td colspan="1" class="uf_title" scope="row">
                                              Discount

                                          </td>
                                          <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                              data-th="Discount">
                                              <span><span class="price" data-bind="text: getValue()">-
                                                      {{getPrice(payOutDetail.totalDiscount |
                                                      currency:"ZAR":"R")}}</span></span>
                                          </td>
                                      </tr>
                                      <tr class="totals sub uf_disdetailhead uf_discountcont">
                                          <td colspan="1" class="uf_title" scope="row">
                                              Total

                                          </td>
                                          <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                              data-th="Discount">
                                              <span><span class="price" data-bind="text: getValue()">
                                                      {{getPrice(payOutDetail.totalAmount |
                                                      currency:"ZAR":"R")}}</span></span>
                                          </td>
                                      </tr>
                                      <tr class="totals sub uf_disdetailhead uf_discountcont">
                                          <td colspan="1" class="uf_title" scope="row">
                                              Shipping & Handling <br> ({{getDeliveryName(payOutDetail?.deliveryMode)}})

                                          </td>
                                          <td class="amount discount_td" data-bind="attr: {'data-th': title}"
                                              data-th="Discount">
                                              <span class="price"
                                                  data-bind="html: getShipValueUf(), attr: {'data-th': title}"
                                                  data-th="Shipping &amp; Handling">

                                                  <!-- <span
                                                      class="reg_price_c text-muted"
                                                      style="text-decoration: line-through;"><span
                                                          class="reg_price">₹200.00 </span></span> -->

                                                  <span class="spl_price"> {{payOutDetail.deliveryFee ?'+ '+
                                                      getPrice(payOutDetail.deliveryFee | currency:"ZAR":"R") :
                                                      'Free'}}</span>
                                              </span>
                                          </td>
                                      </tr>




                                      <tr class="grand totals grand-total redcolor">
                                          <th scope="row">
                                              <strong data-bind="i18n: title">Grand Total</strong>
                                          </th>
                                          <td data-bind="attr: {'data-th': title}" class="amount"
                                              data-th="Grand Total">
                                              <strong><span style="color:#c01e2e;" class="price"
                                                      data-bind="text: getValue()">{{payOutDetail.totalAmount &&
                                                      getPrice(payOutDetail.totalAmount + payOutDetail.deliveryFee
                                                      | currency:"ZAR":"R")}}</span></strong>
                                          </td>
                                      </tr>

                                  </tbody>
                              </table>
                          </div>

                      </div>
                      <div class="cart-summary">
                          <!-- <div *ngIf="discountPercent>0" id="uf-yousave-ko" class="uf_yousaved_box special-price"
                              data-bind="scope:'utsav-yousave-ko'">

                              <span class="uf_yousave_text" data-bind="afterRender: getYouSaved()">You saved <b><span
                                          class="price">{{discountPercent}}%</span></b> on this purchase.</span>

                          </div> -->
                          <ul class="checkout methods items checkout-methods-items">
                              <li class="item">
                                  <button type="button" data-role="proceed-to-checkout"
                                      (click)="navigateToOrderOverview()" title="Proceed to Checkout"
                                      class="action primary checkout"><span>Proceed to
                                          Checkout</span></button>
                                  <!-- <button type="button" *ngIf="!flag" data-role="proceed-to-checkout"
                                      title="Proceed to Checkout"
                                      class="action primary checkout"><span>Loading..</span></button> -->

                              </li>
                          </ul>
                      </div>
                  </div>
                  <!-- <div class="option mt-2 w-100">
                      <p class="text-center">You can choose delivery options <a class="deliveryLink" (click)="openDeliveryModal()">here.</a> </p>
                  </div> -->
              </div>
          </div>
      </div>
  </div>
</section>

<div class="modal fade" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg" >
      <div class="d-flex justify-content-center mt-3 mb-3" id="book">
        <img class="for-img1" style="height: auto;width: 20%;" src="assets/latest/trashAddress.png" alt="login form"
          class="img-fluid" />
      </div>
      <div class="text-center">
       <h2> Delete Address</h2>
       <p>Are you sure you want to delete <br> this address?</p>
      </div>
     
      <div class="modal-body d-flex justify-content-center p-0">
        <button type="button" class="btn btntt1 reject-close" data-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btntt1 confirm" data-dismiss="modal" (click)="deleteAddress()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<address-pop-up *ngIf="showAddress" [addressData]="addressData" (closeModal)="closeModals()"></address-pop-up>
<payment-status *ngIf="paymentStatus" [paymentStatus]="paymentStatus"></payment-status>