<div class="container">
  <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>


<div class="container mt-2 mb-5">
  <!-- <span routerLink="/home" class="circle-arrow"><i class="fa fa-arrow-left tittr" style=" cursor: pointer;"></i></span> -->
  <div class="">
    <!-- <div class="text-center" style="padding-top: 20px;">
      <h2 class=" pro-2">Product Details</h2>
    </div> -->

    <div class="row mt-3 mb-5">
      <div class="col-lg-5 col-md-5 col-sm-5">
        <div class="row">
          <span>
            <img class="image-custom qwqwqw" [hidden]="!isLoaded" (load)="onLoadImage()" [src]="UserDetailsData?.profilePic" />
            <ngx-skeleton-loader *ngIf="!isLoaded"
            count="5"
            
            [theme]="{ 'border-radius': '0', height: '50px' }"
          ></ngx-skeleton-loader>
            <!-- <span class="heart" (click)="addWishlist(productDetails?._id)">

                 <i class="fa fa-heart-o"  *ngIf="productDetails?.isLike == false"></i>
                 <i class="fa fa-heart" style="color: red;"  *ngIf="productDetails?.isLike == true"></i>
             </span> -->
          </span>
        </div>
        <!-- <div class="row mt-4">
          <div *ngFor="let item of productDetails?.productImage" class="col-md-4">

            <img class="custom-img h-100 change-img" style=" cursor: pointer;" [src]="item" (click)="changeProductimage(item)">

          </div>
        </div> -->
      </div>
      <div class="col-lg-7 col-md-7 col-sm-7">
        <div class="row">
          <div class="col-12 pl-0">
            <p class="prdct-home">
              {{ UserDetailsData?.ownerFirstName }}
              {{ UserDetailsData?.ownerLastName }}
            </p>
            <p>Details of Selected Service</p>
          </div>
        </div>
        <div class="" style="margin-top: 1em">
          <h3>{{ categoryName }}</h3>
          <div id="accordion" *ngFor="
              let item of commonService.removeDuplicate(selectedService, [
                '_id'
              ]);
              let i = index
            ">
            <mat-accordion>
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" > -->
                <mat-expansion-panel-header class="faq-qsn">
                  <!-- <mat-panel-title class="gg" style="color:#bf1e2e ;"> -->
                  <mat-panel-title class="gg" style="color: #bf1e2e">
                    {{ item?.subCategory?.subCategoryName || "---" }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <p innerHtml="{{item?.answer || '---'}}"></p> -->
                <div class="d-flex justify-content-between" *ngFor="let data of item?.serviceArray"
                  style="text-align: center; align-items: center">
                  <div class="" style="padding: 0px !important;">
                    <!-- <input type="checkbox" class="custom-control-input" [attr.id]="'customControlInline'+i"> -->
                    <p class="">{{ data?.serviceName }}</p>
                  </div>
                  <div class="d-flex mb-0" style="min-width: 115px">
                    <button class="btn qty-less-high px-3 me-2" [disabled]="data.quantity <= 1" (click)="
                        updateCartValue('SUB');
                        data.quantity = data.quantity - 1
                      " onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                      <i class="fa fa-minus" style="margin-left: -5px"></i>
                    </button>

                    <div class="form-outline">
                      <input id="form1" min="0" name="quantity" [(ngModel)]="data.quantity" type="number"
                        class="form-control new-inp" disabled />
                      <!-- <label class="form-label" for="form1">Quantity</label> -->
                    </div>

                    <button class="btn qty-less-high px-3 ms-2" (click)="
                        updateCartValue('ADD');
                        data.quantity = data.quantity + 1
                      " onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                      <i class="fa fa-plus" style="margin-left: -5px"></i>
                    </button>
                  </div>
                  <div>
                    <p class="mb-0 ner"  *ngIf="!data.dealDiscount">
                      {{
                      data?.price &&
                      getPrice(
                      data?.price * data.quantity | currency : "ZAR" : "R"
                      )
                      }}/unit
                    </p>
                    <!-- <span *ngIf="data.dealDiscount" style="display: flex;align-items:baseline"> -->
                      <!-- <p style="text-decoration: line-through;">
                        {{
                          data?.price &&
                            getPrice(
                              data?.price * data.quantity | currency : "ZAR" : "R"
                            )
                        }}
                      </p> -->

                      <p class="mb-0 ner" *ngIf="data.dealDiscount">
                        
                        {{
                          data?.dealPrice &&
                            getPrice(
                              data?.dealPrice * data.quantity | currency : "ZAR" : "R"
                            )
                        }}/unit

                        <!-- ({{data.dealDiscount}}% Off ) -->
                      </p>
                    <!-- </span> -->
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div class="mt-4">
          <div class="row justify-content-between mt-4 text-center" style="align-items: center">
            <h1 class="amount-details-text">Amount Details</h1>
          </div>
          <div class="row justify-content-between text-center" style="align-items: center">
            <div class="totalamout">
              <span class="amnt-total-details">Sub-total</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                priceValueOfService &&
                getPrice(priceValueOfService | currency : "ZAR" : "R")
                }}</span>
            </div>
          </div>
          <div class="row justify-content-between mt-1 text-center" style="align-items: center">
            <div class="totalamout">
              <span class="amnt-total-details">VAT @15%</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                sumToatal && getPrice(sumToatal | currency : "ZAR" : "R")
                }}</span>
            </div>
          </div>
          <div class="row justify-content-between mt-1 text-center" style="align-items: center">
            <div class="totalamout">
              <span class="amnt-total-details">Total to pay</span>
            </div>
            <div class="totalamout">
              <span class="total-price-sum">{{
                totalPayAmount &&
                getPrice(totalPayAmount | currency : "ZAR" : "R")
                }}</span>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <!-- <h1 class="text-center desc">Description</h1>
                    <p class="mt-4 text-left descrip">
                        {{serviceDetailsArray[0]?.subCategory?.categoryId?.description}}</p> -->
          <div class="row">
            <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3" *ngFor="let item of serviceData">
                            <div class="card innerDiv">
                                <div class="image">
                                    <img class="img" [src]="item?.thumbnail">
                                </div>
                                <div class="mt-3">
                                    <h3 class="text-center">{{item?.productName}}</h3>
                                </div>
                                <div class="mt-2" (click)="navigateToViewProduct(item?.productId?._id)">
                                    <h3 class="text-center">{{item?.priceSizeDetails[0]?.price &&
                                        getPrice(item?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</h3>
                                </div>
                            </div>
                        </div> -->
            <p>Share your details for the delivery of the service</p>
            <div class="container card card-data" *ngIf="addressId">
              <div class="row">
                <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
                  <p style="margin: 1px; margin-top: 5px">
                    <strong class="text-sty">{{ profileData?.address }}
                    </strong>
                  </p>
                  <p style="margin: 1px; margin-top: 5px">
                    <strong class="text-sty">{{ profileData?.city }}, {{ profileData?.state }},{{
                      profileData?.country
                      }}, {{ profileData?.zipCode }}</strong>
                  </p>
                  <div class="mt-3">
                    <span><i class="fa fa-phone" aria-hidden="true" style="color: #c01e2f"></i>
                    </span>
                    &nbsp;&nbsp;
                    <span class="phoen">{{ profileData?.countryCode }}
                      {{ profileData?.mobileNumber }}</span>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 mb-4 mb-lg-0">
                  <div class="d-flex trash" style="max-width: 300px">
                    <i class="fa fa-edit ml-2" style="font-size: 22px; cursor: pointer"
                      (click)="navigateToEdit('quantity')"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-between" style="padding: 10px 0">
              <div class="col-md-12 m-0 p-0">
                <button class="add-cart-btn" (click)="navigateToserviceQuantity('quantity')">
                  Add new address
                </button>
              </div>
              <!-- <div>
                                <button class="continue-btn" (click)="navigateToOrderOverview(a)">Continue with this address</button>
                            </div> -->
            </div>
          </div>
          <div class="" style="padding: 10px 0px">
            <div class="">
              <div id="date-picker-example" class="md-form md-outline input-with-post-icon datepicker col-md-6 pl-0 mb-3" inline="true">
                <label for="">Choose Date<span class="dataColor">*</span></label>
                <input placeholder="Select date" type="date" id="example" [min]="todayDate"
                  (input)="getQuantity($event.target.value)" [(ngModel)]="duration" class="form-control change-clr" />
              </div>
            </div>
            <div class="mt-2 col-md-6 pl-0 mb-3">
              <label for="">Choose Time Slot<span class="dataColor">*</span></label>
              <select class="form-control change-clr" [(ngModel)]="timeSlots">
                <option value="" selected disabled>Select Slot</option>
                <option *ngFor="let item of slotData" [value]="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="">
            <!-- routerLink="/customer-service-quantity-add" -->
            <span>
              <button class="butCh" *ngIf="!onLoadButton" (click)="bookService()">
                Proceed to Book
              </button>
              <button class="butCh" *ngIf="onLoadButton" style="opacity: 0.75;">
               Loading...
              </button>
            
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container mt-5">
  <div class="d-flex justify-content-start">
      <h1 class="heading-text-cat">
        Similiar Products
      </h1>
  </div>
  <div class="items mt-4">
      <div class="slick_slider">
          <div class="service_slider slider slider-customize">
              <div *ngFor="let data of serviceData; let i = index">
                  <div style="height: 340px;">
                      <div class="bannerContent">
                          <div class="innerDiv">
                              <div>
                                  <a target="_blank" rel="noopener noreferrer">
                                      <img class="bannerSlideIamge" [src]="data?.thumbnail" /></a>
                              </div>
                              <div class="mt-3">
                                  <p style="color: black;">{{data?.productName}}</p>
                              </div>
                              <div class="mt-3">
                                  <p class="description-text">{{data?.priceSizeDetails[0]?.price && getPrice(data?.priceSizeDetails[0]?.price | currency:"ZAR":"R")}}</p>
                              </div>
                              <div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->
<div class="modal fade" id="listAddressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ggg" style="min-width: 600px">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="d-flex justify-content-center mt-5 mb-4" id="book">
                <img class="for-img1" style="height: 100%;" src="assets/images/book.png" alt="login form"
                    class="img-fluid" />
            </div> -->
      <!-- <h2 class="modal-title d-flex justify-content-center">Are you sure you want to logout?</h2> -->
      <div class="right-side">
        <h1 class="container right-side-text">Address</h1>
        <div class="container mt-4 card card-data" *ngFor="let item of listAddressData">
          <div class="row" (click)="toAddressId(item?._id)">
            <div class="col-lg-8 col-md-8 mb-4 mb-lg-0">
              <p style="margin: 1px; margin-top: 5px">
                <strong class="text-sty">
                  {{item?.addressLine1}} <span *ngIf="item?.addressLine2">,</span>
                  {{item?.addressLine2}}
                </strong>
              </p>
              <p style="margin: 1px; margin-top: 5px">
                <strong class="text-sty">{{ item?.city }}, {{ item?.state }},{{ item?.country }},
                  {{ item?.zipCode }}</strong>
              </p>
              <div class="mt-3">
                <span><i class="fa fa-phone" aria-hidden="true" style="color: #c01e2f"></i>
                </span>
                &nbsp;&nbsp;
                <span class="phoen">{{ item?.countryCode }} {{ item?.mobileNumber }}</span>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 mb-4 mb-lg-0">
              <div class="d-flex trash" style="max-width: 300px">
                <i class="fa fa-edit ml-2" style="font-size: 22px" (click)="navigateToEdit('quantity')"></i>
                <i class="fa fa-trash ml-2" (click)="addressModals(item?._id, item?.isPrimary)"
                  style="font-size: 21px; color: #bf1e2e"></i>
              </div>
              <!-- <div class="form-check d-flex trash" style="max-width: 300px;margin-top: 20px;cursor: pointer;">
                                <input class="form-check-input" style="cursor: pointer;" type="radio" name="flexRadioDisabled" (click)="toAddressId(item?._id)" 
                                    id="flexRadioDisabled">
                            </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="row justify-content-between" style="padding: 10px;">
                    <div>
                        <button class="add-cart-btn" routerLink="/edit-address-customer">Add new address</button>
                    </div>
                    <div>
                        <button class="continue-btn" (click)="navigateToOrderOverview()">Continue with this address</button>
                    </div>
                </div> -->
      </div>

      <!-- <div class="modal-body d-flex justify-content-center mb-4">
                <button type="button" class="btn btntt1" data-dismiss="modal" routerLink="/homepage">No</button>
                <button type="button" class="btn btntt ml-3" data-dismiss="modal" routerLink="/login"
                    (click)="logout()">Yes</button>
            </div> -->
    </div>
  </div>
</div>
<!-- delete address Modal -->
<div class="modal fade" id="addressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content ggg">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-4" id="book">
        <img class="for-img1" style="height: 100%" src="assets/images/book.png" alt="login form" class="img-fluid" />
      </div>
      <h2 class="modal-title d-flex justify-content-center">
        Are you sure you want to delete this address?
      </h2>

      <div class="modal-body d-flex justify-content-center mb-4">
        <button type="button" class="btn btntt1" data-dismiss="modal" routerLink="/homepage">
          No
        </button>
        <button type="button" class="btn btntt ml-3" data-dismiss="modal" (click)="deleteAddress()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>