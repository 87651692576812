<div class="container">
    <app-linker-route [headingName]="headingName"  [linkerRouteItems]="linkerRouteItems"></app-linker-route>
</div>

<div class="container">
    <div class="row mt-4">
        <div class="col-md-8 pl-0">

            <div class="">
                <!-- <div class="row  d-flex justify-content-center mt-3">
                    <h5 class="text1">Business Details</h5>
                </div> -->
                <p class="owner">Business Owner / Director Details</p>
                <form [formGroup]="businessform">
                    <div class="row">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">First Name<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="fName"
                                (keypress)="preventSpace($event)" maxlength="32" placeholder="Enter First Name">


                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('fName')?.hasError('required') && businessform.get('fName')?.touched ">
                                Please enter first name.</div>

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('fName')?.hasError('minlength') && businessform.get('fName')?.dirty ">
                                *Please enter minimum 2 character.</div>
                        </div>
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Last Name<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="lName"
                                (keypress)="preventSpace($event)" maxlength="32" placeholder=" Enter Last Name">

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('lName')?.hasError('required') && businessform.get('lName')?.touched ">
                                Please enter last name.</div>

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('lName')?.hasError('minlength') && businessform.get('lName')?.dirty ">
                                *Please enter minimum 2 character.</div>

                        </div>

                    </div>

                    <div class="row mt-4">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Email Address<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="email"
                                (keypress)="preventSpace($event)" maxlength="250" placeholder="Enter Email Address">

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('email')?.hasError('required') && businessform.get('email')?.touched ">
                                *Email is required.</div>
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('email')?.hasError('pattern') && businessform.get('email')?.dirty ">
                                *Please
                                enter a valid email</div>

                        </div>
                        <!-- <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Phone Number (optional )
                            </label>

                            <div class="hh bor1">
                                <div class="input-group bor">
                                    <div class="input-group-prepend ">
                                        <span class="asd3"> <input list="browsers"
                                                class="form-control email-pass-pro ddd" formControlName="countryCode"
                                                name="browser" id="browser"></span>

                                        <datalist id="browsers">
                                            <option *ngFor="let code of products" value="{{code.phonecode}}">
                                                {{code.flag}}
                                                {{code.phonecode}}
                                                {{code.isoCode}} </option>

                                        </datalist>
                                        <input type="text" class="fbb" (keypress)="preventSpace($event)"
                                            (keypress)="NumOnly($event)" formControlName="Phone" maxlength="15">

                                    </div>
                                    <div class="for-validation  text-danger"
                                        *ngIf="businessform.get('Phone')?.hasError('required') && businessform.get('Phone')?.touched ">
                                        *Mobile number is required.</div>
                                    <div class="for-validation  text-danger"
                                        *ngIf="businessform.get('Phone')?.hasError('pattern') && businessform.get('Phone')?.dirty ">
                                        Please enter valid Mobile number</div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <!-- <P class="owner mt-3">Product Details</P>


                    <div class="row mt-4">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label" id="rr">Number Of Unique Products<span
                                    class="yy">*</span></label>
                            <input type="text" class="form-control mt-2" formControlName="numberproducts"
                                (keypress)="preventSpace($event);restrictChar($event);NumOnly($event)" maxlength="17"
                                placeholder="Enter number of unique products">

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('numberproducts')?.hasError('required') && businessform.get('numberproducts')?.touched">
                                Please Enter Number.</div>


                        </div>
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">List Of Brands Or Products<span
                                    class="yy">*</span></label>
                            <div class="form-group row" *ngFor="let item of addNewSubcategoryField; let i = index"
                                style="flex-wrap: unset;">
                                <input type="text" class="form-control" placeholder="Enter list Of Brands "
                                    [(ngModel)]="item.sub_Category_List" [ngModelOptions]="{standalone: true}"
                                    (keypress)="preventSpace($event)">
                                <button *ngIf="addNewSubcategoryField?.length>1" type="button"
                                    class="btn8 col-md-2 " (click)="removeBodySectionDynamic(i)"> <i
                                        class="fa fa-trash" aria-hidden="true"></i></button>

                            </div>
                            <div class="d-flex justify-content-center">
                                <button class="btn9" (click)="addNewSubCategryDynamic()"> <i class="fa fa-plus"
                                        aria-hidden="true"> </i>Add</button>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-md-6 pl-0">
                            <div class="row ">
                             

                            </div>

                            <div class="row">
                                <div class="col-md-6 pl-0">


                                    <div class="form-group">
                                        <div class="row">
                                            <label class="md-check">
                                                <input type="radio" value="true" name="" formControlName="keepStock">
                                                yes
                                            </label>
                                            <label class="md-check ">
                                                <input type="radio" value="false" name="" formControlName="keepStock">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6 pl-0">
                            <div class="row ">
                                <h6 class="textt">Do you have a physical store? ( Optional )</h6>

                            </div>

                            <div class="row">
                                <div class="col-md-6 pl-0">
                                    <div class="form-group">
                                        <div class="row">
                                            <label class="md-check">
                                                <input (click)="openesd()" type="radio" value="true" name=""
                                                    formControlName="isPhysicalStore">
                                                Yes I have a store
                                            </label>

                                            <label class="md-check ">
                                                <input (click)="closeesd()" type="radio" value="false" name=""
                                                    formControlName="isPhysicalStore">
                                                No I sell through other channels
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 pl-0" *ngIf="showInputes">


                                        <div class="hhh mt-2">
                                            <input type="text" class="form-control" formControlName="storeess"
                                                (keypress)="preventSpace($event)" maxlength="15"
                                                placeholder="Enter Store Name">
                                            <div class="for-validation text-danger"
                                                *ngIf="businessform.get('storeess')?.hasError('required') && businessform.get('storeess')?.touched ">
                                                *VAT registered number is required.</div>
                                        </div>
                                        <div class="hhh mt-2">
                                            <input type="text" class="form-control" formControlName="storeessed"
                                                (keypress)="preventSpace($event)" maxlength="15"
                                                placeholder="Enter Store Address">
                                            <div class="for-validation text-danger"
                                                *ngIf="businessform.get('storeessed')?.hasError('required') && businessform.get('storeessed')?.touched ">
                                                *VAT registered number is required.</div>
                                        </div>


                                        <div class="hhh mt-2">
                                            <input type="text" class="form-control" formControlName="storesgg"
                                                (keypress)="preventSpace($event)" maxlength="15"
                                                placeholder="Enter Store Contact">
                                            <div class="for-validation text-danger"
                                                *ngIf="businessform.get('storesgg')?.hasError('required') && businessform.get('storesgg')?.touched ">
                                                *VAT registered number is required.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row fdf">
                        <div class="col-md-6 pl-0">
                            <div class="form-group row mt-3">
                                <label for="datepicker" class="for-label">Additional Comment? ( optional )</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1"
                                    formControlName="additional" (keypress)="preventSpace($event)"
                                    placeholder=" Enter Additional Comment" rows="3"></textarea>


                            </div>

                        </div>


                    </div> -->

                    <p class="owner mt-4">Business Details</p>
                    <div class="row">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Company Name<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="company"
                                (keypress)="preventSpace($event)" placeholder=" Enter Company Name">
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('company')?.hasError('required') && businessform.get('company')?.touched">
                                *Company name is required.</div>

                        </div>
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Business Registration Number<span
                                    class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="businessRegNumber" maxlength="17"
                                (keypress)="preventSpace($event)" placeholder=" Enter Business Registration Number">

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('businessRegNumber')?.hasError('required') && businessform.get('businessRegNumber')?.touched ">
                                *Registration number is required.</div>
                        </div>

                    </div>

                    <div class="row mt-4">

                        <div class="col-md-6 pl-0">
                            <div class="row ">
                                <p>Monthly Revenue<span class="yy">*</span></p>
                                <select class="form-control sel-2" formControlName="monthlyRevenue">
                                    <option value="">Select Monthly Revenue</option>
                                    <option value="Less than R20k">Less than R20k</option>
                                    <option value="R20k to R50k days">R20k - R50k</option>
                                    <option value="R20k to R50k days">R20k - R50k</option>
                                    <option value="More than R500k">More than R500k</option>

                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 pl-0 mt-3">
                            <label for="datepicker" class="for-label">Website’s URL ( Optional )
                            </label>
                            <input type="text" class="form-control" formControlName="url"
                                (keypress)="preventSpace($event)" placeholder=" Enter The Url">
                            <!-- <div class="for-validation text-danger"
                                *ngIf="businessform.get('url')?.hasError('required') && businessform.get('url')?.touched ">
                                *URL is required.</div> -->

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('url')?.hasError('pattern') && businessform.get('url')?.dirty">
                                * Please enter valid url required.
                            </div>
                        </div>

                    </div>


                    <div class="row mt-3">
                        <!-- <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Birthdate</label>
                            <input type="date" class="form-control" formControlName="birthdate" id="datepicker">
                        </div> -->
                        <div class="col-md-6 pl-0">
                            <div class="row ">
                                <p class="textt">
                                    <!-- Are <span class="yy">y</span>ou VAT <span class="yy">r</span>egistered? -->
                                    Are you VAT registered?
                                </p>

                            </div>



                            <div class="row">
                                <div class="col-md-6 pl-0">
                                    <div class="form-check form-check-inline">
                                        <input (click)="open()" class="form-check-input" type="radio" name="" id=""
                                            [value]="true" placeholder="Enter VAT Number"
                                            formControlName="vatRegistered">
                                        <label class="form-check-label" for="radios">Yes

                                        </label>
                                    </div>


                                    <div class="form-check form-check-inline">
                                        <input (click)="close()" class="form-check-input" type="radio" name="" id=""
                                            value="false" placeholder="Enter VAT Number"
                                            formControlName="vatRegistered">
                                        <label class="form-check-label">No</label>

                                    </div>

                                    <div class="hhh" *ngIf="showInput">
                                        <input type="text" class="form-control" formControlName="vatNumberInput"
                                            (keypress)="preventSpace($event)" maxlength="15"
                                            placeholder="Enter VAT Number">
                                        <div class="for-validation text-danger"
                                            *ngIf="businessform.get('vatNumberInput')?.hasError('required') && businessform.get('vatNumberInput')?.touched ">
                                            *VAT registered number is required.</div>
                                    </div>

                                </div>

                            </div>
                        </div>



                    </div>

                    <!-- 111111111111111111111 -->

                    <!-- <div class="row mt-3">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Birthdate</label>
                            <input type="date" class="form-control" formControlName="birthdate" id="datepicker">
                        </div>
                        <div class="col-md-6 pl-0">
                            <div class="row ">
                                <h6 class="Do you keep stock?*">Do you have a physical store? ( Optional )</h6>
                                <p>Monthly Revenue<span class="yy">*</span></p>
                                <select class="form-control sel-2" formControlName="monthlyRevenue">
                                    <option value="">Select Expected Days</option>
                                    <option value="Less than R20k">Less than R20k</option>
                                    <option value="R20k to R50k days">R20k - R50k</option>
                                    <option value="R20k to R50k days">R20k - R50k</option>
                                    <option value="More than R500k">More than R500k</option>

                                </select>
                            </div>

                            <div class="row">
                                <div class="col-md-6 pl-0">
                                    <div class="form-check form-check-box">
                                        <input class="form-check-input" type="checkbox" value="Less than R20k"
                                            formControlName="monthlyRevenue">
                                        <label class="form-check-label" for="checkbox">Less than R20k</label>

                                    </div>
                                    <div class="form-check form-check-box">
                                        <input class="form-check-input" type="checkbox" value="R20k - R50k"
                                            formControlName="monthlyRevenue">
                                        <label class="form-check-label" for="checkbox">R20k - R50k
                                        </label>

                                    </div>
                                    <div class="form-check form-check-box">
                                        <input class="form-check-input" type="checkbox" formControlName="monthlyRevenue"
                                            value="R20k - R50k">
                                        <label class="form-check-label" for="checkbox">R20k - R50k
                                        </label>

                                    </div>
                                    <div class="form-check form-check-box">
                                        <input class="form-check-input" type="checkbox" value="R20k - R50k"
                                            formControlName="monthlyRevenue">
                                        <label class="form-check-label" for="checkbox">R20k - R50k
                                        </label>

                                    </div>
                                    <div class="form-check form-check-box">
                                        <input class="form-check-input" type="checkbox" name=""
                                            formControlName="monthlyRevenue" value="more than R500k">
                                        <label class="form-check-label" for="checkbox">More than R500k
                                        </label>

                                    </div>

                                </div>
                            </div>



                        </div>

                    </div> -->


                    <p class="owner mt-3">Business Banking Details</p>


                    <div class="row">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Bank Name<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="bankname"
                                (keypress)="preventSpace($event)" placeholder="Enter Bank Name">

                            <!-- <div *ngIf="businessform.get('bankname').hasError('required') &&  businessform.get('bankname').dirty"
                                class="danger" padding>*Please enter bank name.</div>
                            <div *ngIf="businessform.get('bankname').hasError('pattern') &&  businessform.get('bankname').dirty"
                                class="danger" padding>*Invalid bank name.</div> -->

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('bankname')?.hasError('required') && businessform.get('bankname')?.touched ">
                                *Please enter bank name.</div>
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('bankname')?.hasError('pattern') && businessform.get('bankname')?.touched ">
                                *Invalid bank name.</div>
                        </div>
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Branch Name<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="branchname"
                                (keypress)="preventSpace($event)" placeholder="Enter Branch Name">
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('branchname')?.hasError('required') && businessform.get('branchname')?.touched ">
                                *Branch name is required.</div>

                        </div>

                    </div>

                    <div class="row mt-4">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">SWIFT Code<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="swiftcode" maxlength="11"
                                (keypress)="preventSpace($event)" placeholder=" Enter Swift Code">

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('swiftcode')?.hasError('required') && businessform.get('swiftcode')?.touched ">
                                *Swiftcode should be alphanumeric.</div>

                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('swiftcode')?.hasError('pattern') && businessform.get('swiftcode')?.dirty">
                            </div>
                            <!-- <div class="for-validation text-danger"
                                *ngIf="businessform.get('swiftcode')?.hasError('required') && businessform.get('swiftcode')?.touched ">
                                *Swift code is required.</div> -->
                        </div>
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Account Type<span class="yy">*</span></label>
                            <!-- <input type="text" class="form-control" formControlName="accountType"
                                (keypress)="preventSpace($event)" placeholder="  Enter account type"> -->

                            <select class="form-control sel-2" formControlName="accountType"
                                placeholder="  Enter account type">
                                <option value="">Account type</option>
                                <option value="Savings Account">Savings Account</option>
                                <option value="Current Account">Current Account</option>
                                <option value="Business Account">Business Account</option>
                                <option value="Cheque Account">Cheque Account</option>
                                <option value="Transmission Account">Transmission Account</option>

                            </select>
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('accountType')?.hasError('required') && businessform.get('accountType')?.touched ">
                                * Enter youre account type.</div>
                        </div>

                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Account Name<span class="yy">*</span></label>
                            <input type="text" class="form-control" formControlName="accountName"
                                (keypress)="preventSpace($event)" placeholder="  Enter Account Name">
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('accountName')?.hasError('required') && businessform.get('accountName')?.touched ">
                                * Enter youre account name.</div>
                        </div>



                        <div class="col-md-6 pl-0">
                            <label for="datepicker" class="for-label">Account Number<span class="yy">*</span></label>
                            <input type="text" class="form-control"
                                (keypress)="preventSpace($event);restrictChar($event);NumOnly($event)"
                                formControlName="accountNumber" placeholder="Enter Account Number" maxlength="17">
                            <div class="for-validation text-danger"
                                *ngIf="businessform.get('accountNumber')?.hasError('required') && businessform.get('accountNumber')?.touched ">
                                * Enter youre account number.</div>
                        </div>

                    </div>

                    <!-- old line -->
                </form>
                <div class="btn d-flex justify-content-center mt-4">

                    <button type="button" class="btn btny" (click)="updateBusinessDetail()">SUBMIT
                        <!-- [disabled]="!businessform.valid" -->
                    </button>
                </div>
            </div>

        </div>
        <div class="col-md-4">
            <img src="assets/latest/login.svg" alt="">
        </div>
    </div>
</div>