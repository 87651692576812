import { CommonService } from 'src/app/provide/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare var $

@Component({
  selector: 'app-retailer-view-product',
  templateUrl: './retailer-view-product.component.html',
  styleUrls: ['./retailer-view-product.component.scss']
})
export class RetailerViewProductComponent implements OnInit {
  isLoaded:boolean =false
  _id: any
  headingName:any ='PRODUCT DETAILS'
  linkerRouteItems:any =[
    { name:'Home',route: '/retailer-home'},
    {name:'Product',route:'/retailer-product-list'},
    {name:'View',route:'/retailer-view-product'}
  ]
  afterDiscountPrice: number;
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, private http: HttpClient, private router: Router) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this._id = res._id;
    })
  }

  ngOnInit(): void {
    this.viewRetailerProductd();
  }
  onImageLoad(){
    this.isLoaded = true
  }


  userId: any;
  productDetails: any;
  productId: any;
  retailerId: any;
  imgurl: any;
  imageLinks: any

  // get retailer detail by id
  viewRetailerProductd() {
    let apiReqUrl: any = `product/viewProduct?productId=${this._id}`
    // let apiReqUrl: any = `${ApiEndPoint.productView + '?productId=' + this.productId}`
    this.commonService.showSpinner();
    let a = this.commonService.isLoggedIn() ? 1 : 0
    this.commonService.postApi(apiReqUrl, {}, a).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.productDetails = res.result;
        this.mrp  = this.productDetails?.priceSizeDetails[0]?.price
        this.afterDiscountPrice = this.calDiscountOn(this.productDetails?.priceSizeDetails[0]?.price)
        this.imgurl = res.result.productImage[0]

        this.imageLinks = res["result"]["thumbnail"]
        $(document).ready(function () {
          $('.sevice').slick({
            dots: false,
            arrow: true,
            infinite: false,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });
        });
        this.commonService.hideSpinner();
        // this.router.navigate(['/edit-product'])
        // this.commonService.successToast(res.responseMessage);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }



  forID(_id: any) {
    this.router.navigate(['/edit-product'], { queryParams: { _id: _id } })

  }




  changeProductimage(url: any) {
    
    this.imgurl = url
  }


  getPrice(e: any) {
    if(e){
      let a = e.replace(/,/g, ' ')
      return a.replace(/R/g, 'R ')
    }
 
  }
  mrp: any
  quantity: any
  value: any
  price(e: any, q: any, v: any) {
    this.mrp = e,
      this.quantity = q
    this.value = v
    this.afterDiscountPrice = this.calDiscountOn(this.mrp)

  }
  calDiscountOn(realPrice){
    let dsp  = (Number(this.productDetails.dealDiscount)/ 100) * realPrice
    return this.mrp - dsp
  }
  getRawText(str : string):string{
    return str.replace(/\n/g, '<br>')
  }
}
