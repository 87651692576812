<div class="container  fw-b wrapper-content">
    <app-linker-route [headingName]="headingName" [linkerRouteItems]="linkerRouteItems"></app-linker-route>
    <div class="container mb-4 wrapper-content">
        <div class="row justify-content-center">
            <div class="col-3 text-center">
                <div class="cardWallet walletBlock bg-blue">

                    <p class="text-white amt">{{ wallet?.walletAmount && getPrice( wallet?.walletAmount |
                        currency:"ZAR":"R") || 'R 0.00'}}</p>
                </div>
                <div>

                    <p class="text-theme">Wallet Balance</p>
                </div>

            </div>
            <div class="col-3 text-center ml-1">
                <div class="cardWallet walletBlock bg-red">

                    <p class="text-white amt">{{ wallet?.totalWalletAmount && getPrice( wallet?.totalWalletAmount |
                        currency:"ZAR":"R") || 'R 0.00'}}</p>
                </div>
                <div>

                    <p class="text-theme">Total Earning</p>
                </div>

            </div>
            <div class="col-3 text-center ml-1">
                <div class="cardWallet walletBlock bg-theme">

                    <p class="text-white amt">{{ wallet?.totalCommissionPaid && getPrice( wallet?.totalCommissionPaid |
                        currency:"ZAR":"R") || 'R 0.00'}}</p>
                </div>
                <div>

                    <p class="text-theme">Total Commission Paid</p>
                </div>

            </div>
        </div>

        <div class="row mt-3 ">
            <div class="col-12 table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">From Wallet</th>
                            <th scope="col">To Wallet</th>
                            <th scope="col">Amount in R</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Customer Email</th>
                            <!-- <th scope="col">Order Id </th> -->
                            <th scope="col">Txn. Status</th>
                            <th scope="col">Txn. Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="
                let item of orderList
                  | paginate
                    : {
                        itemsPerPage: pageSize,
                        currentPage: currentPage,
                        totalItems: count
                      };
                let i = index
              ">
                            <th>{{ 10 * (currentPage - 1) + i + 1 }}</th>
                            <th scope="row">
                                <ng-container *ngIf="item?.fromWalletType != 'OW'">
                                    {{ item?.fromUser?.firstName || '--' }} {{ item?.fromUser?.lastName }} ({{item?.fromWalletType}})

                                </ng-container>
                                <ng-container *ngIf="item?.fromWalletType == 'OW'">
                                    {{item.orderReferenceId.userId?.firstName || '--'}} {{ item?.orderReferenceId?.userId?.lastName }} ({{item?.fromWalletType}})

                                </ng-container>
                            </th>
                            <th scope="row">
                                <ng-container *ngIf="item?.toWalletType != 'OW'">
                                    {{ item?.toUser?.firstName || '--' }} {{ item?.toUser?.lastName }} ({{item?.toWalletType}})
                                </ng-container>
                                <ng-container *ngIf="item?.toWalletType == 'OW'">
                                    {{item.orderReferenceId.userId?.firstName || '--'}} {{ item?.orderReferenceId?.userId?.lastName }} ({{item?.toWalletType}})
                                </ng-container>
                               
                            </th>
                            <td [ngClass]="item?.isCommission || item?.fromUser?._id == myId ? 'text-theme' : 'text-success'">
                               {{item?.isCommission ||  item?.fromUser?._id == myId ? '-' : '+' }} {{ item?.transactionAmount && getPrice(item?.transactionAmount | currency:"ZAR":"R") ||'--'}}
                            </td>
                            <td>{{ item?.orderReferenceId?.orderStatus }}</td>
                            <td>{{ item?.orderReferenceId?.createdAt | date : "medium" || "--" }}</td>
                            <td>{{ item?.orderReferenceId?.orderId }}</td>
                            <td>{{ item?.orderReferenceId?.userId?.firstName }} {{ item?.orderReferenceId?.userId?.lastName }}</td>
                            <td>{{ item?.orderReferenceId?.userId?.email }} </td>
                            <!-- <td>{{ item?.orderReferenceId?.orderId }}</td> -->
                            <td>{{ item?.transactionStatus }}</td>

                            <td>{{ item?.createdAt | date : "medium" || "--" }}</td>


                        </tr>

                    </tbody>
                </table>
                <app-table-loader [isTable]="'TABLE'" [isLoading]="isLoading" [notFoundData]="notFoundData"
                    [colspan]="10" [isWidth]="'55px'"></app-table-loader>

                <pagination-controls *ngIf="count > 10" style="float: right; margin-top: 3%"
                    (pageChange)="changePageNumber($event)"></pagination-controls>
            </div>

        </div>
    </div>
</div>