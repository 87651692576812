import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { firstValueFrom } from 'rxjs';
import { CommonService } from 'src/app/provide/common.service';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {

    headingName:any ='My Profile'
  linkerRouteItems:any =[
    { name:'Home',route: '/home'},
    {name:'My Profile',route:'/customer-profile'}
  ]
  addProduct!: FormGroup
  profileData: any = {}
  imageUrl: any;
  'status': "ACTIVE";
  'userType': "WHOLE_SALER";
  profilePic: any;
  socialLink: any;

  firstName: any;
  lastName: any;
  countryCode: any;
  mobileNumber: any;
  email: any;
  storeName: any;
  storeLocation: any;


  faceBook: any;
  linkedIn: any;
  twitter: any;
  instagram: any

  country: any = []
  state: any = []
  city: any = []

  // uplode image 

  addDynamicImageArray: any = []
  validationMessage: any; //global validation code
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];



  constructor(private fb: FormBuilder, http: HttpClient, public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.add();
    this.getProfile();
    // this.getCountryList()

  }



  add() {
    this.addProduct = this.fb.group({



      'fName': new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
        Validators.minLength(2)
      ]),
      'lName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(32)
      ]),
      'email': new FormControl('', [
        Validators.required,

        Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)
      ]),
      'Phone': new FormControl('', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      ]),
      'address': new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
      ]),
      'address1': new FormControl('', [
        Validators.required,
        Validators.maxLength(32)
      ]),

      'url': new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
      ]),

      city: new FormControl('', [

        Validators.maxLength(32),

      ]),

      state: new FormControl('', [
        Validators.maxLength(32),
      ]),

      zipCode: new FormControl('', [
        Validators.required,
      ]),

      country: new FormControl('', [
        Validators.required,
      ]),
    });
  }


  NumOnly(event: any) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }

  preventSpace(event: any) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  arr: any = [];
  userTypes: any
  // getProfile
  async getProfile() {
    this.commonService.showSpinner();

    this.commonService.getApi("user/myProfile", 1).subscribe((res: any) => {
      if (res["responseCode"] == 200) {
        this.profileData = res["result"];
        this.userTypes = this.profileData.userType
        // let index = this.arr.findIndex(
        //   (x: any) => x.country == this.profileData.country
        // );
        // this.profileData.country ? this.getStateList(this.profileData.country) : null || "string";
        // this.commonService.hideSpinner();
        // this.patchState(this.profileData.country)
        // this.patchCity(this.profileData.state)

        this.addProduct.patchValue({

          fName: this.profileData.firstName,
          lName: this.profileData.lastName,
          email: this.profileData.email,
          Phone: this.profileData.countryCode + this.profileData.mobileNumber,
          address: this.profileData.addressLine1,
          address1: this.profileData.addressLine2,
          city: this.profileData.city ? this.profileData.city : '',
          state: this.profileData.state ? this.profileData.state : '',
          zipCode: this.profileData.zipCode ? this.profileData.zipCode : '',
          country: this.profileData.country ? this.profileData.country : '',
          url: this.profileData.websiteUrl,
        });
        this.commonService.hideSpinner();


        this.imageUrl = res.result.profilePic;
        // this.profileData.country ? this.patchState(this.profileData.country) : '';
        // this.profileData.state ? this.patchCity(this.profileData.state) : '';
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res["responseMessage"])
      }
    }, (err) => {
      this.commonService.hideSpinner();
      this.commonService.errorToast("Server error")
    });
  }

  // =-=-=-==-=-=--------Upload Image Functionality Start Here---------=-=-=-=-==-
  uploadImg($event: any): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img: any) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi('user/uploadFile', fb, 0).subscribe((res: any) => {
      this.commonService.hideSpinner();
      if (res.responseCode == '200') {
        this.imageUrl = res.result.mediaUrl;
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }

}
